<template>
  <div :class="{ hide: status === 'hide' }">
    <h2>Chargement</h2>
    <progress class="progress is-small is-info" max="100">15%</progress>
  </div>
</template>

<style scoped>
div {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
div.hide {
  display: none;
}
h2 {
  color: white;
  font-size: 30px;
  margin-bottom: 10px;
}
progress {
  width: 60%;
}
</style>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "hide"
    }
  }
};
</script>
