<template>
  <div>
    <b-message type="is-danger" v-show="showErrorMessage()">
      Aucun equipement
    </b-message>
    <b-table
      v-show="formattedEquipmentHasDeviceList.length > 0"
      :data="formattedEquipmentHasDeviceList"
      :default-sort="['from_date', 'desc']"
      paginated
      per-page="20"
      :total="formattedEquipmentHasDeviceList.length"
    >
      <b-table-column v-slot="props" field="equipmentName" label="Equipement" sortable>
        {{ props.row.equipmentName }}
      </b-table-column>
      <b-table-column v-slot="props" field="from_date" label="Date de début" sortable>
        {{ formatDate(props.row.from_date) }}
      </b-table-column>
      <b-table-column v-slot="props" field="to_date" label="Date de fin" sortable>
        {{ formatDate(props.row.to_date) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { getEquipmentHasDeviceHistory } from "Api/equipmentHasDevice";
import { getEquipmentByIds } from "Api/equipment";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";

export default {
  props: ["device"],
  data() {
    return {
      equipmentHasDeviceList: [],
      equipmentList: {},
      dataIsLoaded: false
    };
  },
  computed: {
    formattedEquipmentHasDeviceList() {
      const { equipmentList, equipmentHasDeviceList } = this;
      return equipmentHasDeviceList.map(e => {
        const equipment = equipmentList[e.equipment_instance_id];
        e.equipmentName = "-";
        if (equipment) {
          e.equipmentName = equipment.label;
        }

        e.from_date = e.from_date ? new Date(e.from_date) : null;
        e.to_date = e.to_date ? new Date(e.to_date) : null;
        return e;
      });
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    showErrorMessage() {
      return this.formattedEquipmentHasDeviceList.length === 0 && this.dataIsLoaded;
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  mounted() {
    const { apiClient } = this.$store.getters;
    getEquipmentHasDeviceHistory(apiClient)(this.device.id)
      .then(({ data }) => {
        this.equipmentHasDeviceList = data || [];
        const equipmentIds = data.map(e => e.equipment_instance_id);
        if (this.equipmentHasDeviceList.length === 0) {
          return { data: [] };
        }
        return getEquipmentByIds(apiClient)(equipmentIds);
      })
      .then(({ data }) => {
        this.dataIsLoaded = true;
        this.equipmentList = data.reduce((acc, e) => {
          acc[e.id] = e;
          return acc;
        }, {});
      })
      .catch(e => {
        this.dataIsLoaded = true;
        this.setError({
          message: "Impossible de récupérer l'historique des equipements <br />",
          error: e
        });
      });
  }
};
</script>
