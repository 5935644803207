<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des exploitations parentes ({{ this.parentClusters.length }})</h1>
          <div class="buttons has-addons">
            <a class="button is-primary is-small" @click.prevent="showLinkModal()">
              Lier des exploitations
            </a>
            <b-button type="is-danger is-small" :loading="isLoading" @click.prevent="unlinkAllParentClusters()">
              Délier toutes les exploitations parentes
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <LinkClusterToParentsModal
        :isActive="isLinkModalActive"
        :cluster="cluster"
        :onLink="loadClusters"
        :onClose="() => (isLinkModalActive = false)"
      />
      <ClusterTable
        :clusters="parentClusters"
        :isUnlinkActive="true"
        :onUnLink="loadClusters"
        :childCluster="cluster"
        :showParcelsCountColumn="false"
      />
    </div>
  </div>
</template>

<style scoped>
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}
table {
  padding-bottom: 20px;
}
</style>
<script>
import { mapActions } from "vuex";
import { getParentClusters, unLinkClusterToCluster } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import LinkClusterToParentsModal from "Components/clusters/clusterDetailsComponents/LinkClusterToParentsModal";
import ClusterTable from "Components/clusters/clusterDetailsComponents/ClusterTable";
export default {
  props: ["cluster"],
  components: { LinkClusterToParentsModal, ClusterTable },
  mounted() {
    this.loadClusters();
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    loadClusters() {
      const { apiClient } = this.$store.getters;
      const { cluster } = this;
      getParentClusters(apiClient)(cluster.id).then(({ data }) => {
        this.parentClusters = data;
      });
    },
    unlinkAllParentClusters() {
      const { apiClient } = this.$store.getters;
      const { parentClusters, cluster } = this;
      this.$buefy.dialog.confirm({
        message: "Etes vous sûr ?",
        onConfirm: () => {
          Promise.all(
            parentClusters.map(parent => {
              if (parent.id) {
                return unLinkClusterToCluster(apiClient)(parent.id, cluster.id);
              }
            })
          )
            .then(() => {
              this.setSuccess({
                message: "Les exploitations ont bien été déliées"
              });
              this.loadClusters();
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    showLinkModal() {
      this.isLinkModalActive = true;
    }
  },
  data() {
    return {
      isLinkModalActive: false,
      isLoading: false,
      parentClusters: []
    };
  }
};
</script>
