<template>
  <div class="validations">
    <b-table :loading="isLoading" :per-page="100" :data="this.validations" paginated>
      <b-table-column v-slot="props" field="hardware_version" label="HardwareID" sortable centered>
        <b-tag :type="selectType(props.row.hardware_version)" size="is-medium">
          {{ selectLabel(props.row.hardware_version) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="ble_version" label="Version Ble" sortable centered>
        <b-tag :type="selectType(props.row.ble_version)" size="is-medium">
          {{ selectLabel(props.row.ble_version) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="firmware" label="Firmware" sortable centered>
        <b-tag :type="selectType(props.row.firmware)" size="is-medium">
          {{ selectLabel(props.row.firmware) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="location" label="Location" sortable centered>
        <b-tag :type="selectType(props.row.location)" size="is-medium">
          {{ selectLabel(props.row.location) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="pullout" label="PullOut" sortable centered>
        <b-tag :type="selectType(props.row.pullout)" size="is-medium">
          {{ selectLabel(props.row.pullout) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="connection" label="Connection" sortable centered>
        <b-tag :type="selectType(props.row.connection)" size="is-medium">
          {{ selectLabel(props.row.connection) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="gpio" label="GPIO" sortable centered>
        <b-tag :type="selectType(props.row.gpio)" size="is-medium">
          {{ selectLabel(props.row.gpio) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="battery" label="Battery" sortable centered>
        <b-tag :type="selectType(props.row.battery)" size="is-medium">
          {{ selectLabel(props.row.battery) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="bluetooth" label="Bluetooth" sortable centered>
        <b-tag :type="selectType(props.row.bluetooth)" size="is-medium">
          {{ selectLabel(props.row.bluetooth) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="watchdog_reboot" label="WatchdogReboot" sortable centered>
        <b-tag :type="selectType(props.row.watchdog_reboot)" size="is-medium">
          {{ selectLabel(props.row.watchdog_reboot) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="logs" label="Logs" sortable centered>
        <b-tag v-if="selectLabel(props.row.logs) == 'KO'" :type="selectType(props.row.logs)" size="is-medium">
          <router-link target="_blank" :to="getUrlLink(props.row.added_at)">{{
            selectLabel(props.row.logs)
          }}</router-link>
        </b-tag>
        <b-tag v-else :type="selectType(props.row.logs)" size="is-medium">
          {{ selectLabel(props.row.logs) }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="props" field="added_at" label="Date de màj">
        <span>
          {{ props.row.added_at_string ? props.row.added_at_string : "N/A" }}
        </span>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { SET_ERROR_ACTION } from "Stores/message";
import { getValidationsByDeviceId } from "Api/device";
import { mapActions } from "vuex";
import { formatToRFC3339 } from "Utils";
export default {
  props: ["device"],
  data() {
    return {
      validations: [],
      isLoading: false
    };
  },
  methods: {
    selectType(flag) {
      return flag === null ? "" : flag ? "is-success" : "is-danger";
    },
    selectLabel(flag) {
      return flag === null ? "N/A" : flag ? "OK" : "KO";
    },
    getValidations() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getValidationsByDeviceId(apiClient)(this.device.id)
        .then(({ data }) => {
          data.forEach(d => {
            if (d.added_at) {
              d.added_at = new Date(Date.parse(d.added_at));
              d.added_at_string = d.added_at.toLocaleString();
            }
          });

          this.validations = data;
          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },

    getUrlLink(date) {
      const fromDate = new Date(date);
      const toDate = new Date(date);
      fromDate.setDate(fromDate.getDate() - 15);
      const formatFromDate = formatToRFC3339(fromDate, false);
      const formatToDate = formatToRFC3339(toDate, false);
      return {
        path: `/device/${this.device.id}`,
        query: {
          currentTab: "DeviceDataTab",
          selectedDataTypeIndex: 8,
          itemsPerPage: 50,
          fromDate: formatFromDate,
          toDate: formatToDate,
          filterBy: "occurred_at",
          fromDateOption: "DATE",
          toDateOption: "DATE",
          severity: "ERROR"
        }
      };
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  mounted() {
    if (this.device) {
      this.getValidations(this.device.id);
    }
  }
};
</script>

<style scoped>
.validations {
  padding: 10px;
}
</style>
