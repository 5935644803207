<template>
  <section>
    <form @submit="onSubmit">
      <b-field label="Nom">
        <b-input v-model="cluster.name"> </b-input>
      </b-field>
      <b-field label="Adresse">
        <b-input v-model="cluster.address"> </b-input>
      </b-field>
      <b-field label="Details">
        <b-input v-model="cluster.details"> </b-input>
      </b-field>
      <b-field label="Type">
        <b-select v-model="cluster.cluster_type" placeholder="Choisir le type d'exploitation" expanded>
          <option v-for="type in CLUSTER_TYPES" :value="type" :key="type">
            {{ type }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Téléphone">
        <b-input v-model="cluster.phone"> </b-input>
      </b-field>
      <b-field label="Siren">
        <b-input v-model="cluster.siren"> </b-input>
      </b-field>
      <b-field label="Hubspot ID">
        <b-input v-model="cluster.hubspot_id"> </b-input>
      </b-field>
      <b-field label="(Si Hubspot ID seulement) Selection du plan">
        <b-select v-model="cluster.plan" :disabled="!cluster.hubspot_id || cluster.hubspot_id === ''" expanded>
          <option value="" selected="true">Aucun</option>
          <option v-for="plan in plans" :value="plan.name" :key="`plan-${plan.name}`">
            {{ PLAN_LABELS[plan.name] || plan.name }}
          </option>
        </b-select>
      </b-field>

      <button class="button is-success is-fullwidth">{{ butttonLabel }}</button>
    </form>
  </section>
</template>

<script>
import { updateCluster, updateHubspotId } from "Api/cluster";
import { getPlans, updatePlan } from "Api/plan";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { FARM_CLUSTER_TYPE, CLUSTER_TYPES } from "Constants";
import { mapActions } from "vuex";
import { createClusterWithState } from "Utils";
import { PLAN_LABELS } from "Constants";

export default {
  props: {
    cluster: {
      type: Object,
      default() {
        return {
          name: "",
          address: "",
          details: "",
          phone: "",
          siren: "",
          hubspot_id: "",
          plan: "better",
          cluster_type: FARM_CLUSTER_TYPE
        };
      }
    },
    action: {
      type: String,
      default: "create"
    },
    afterSubmit: Function
  },
  computed: {
    butttonLabel() {
      const { action } = this;
      return {
        create: "Ajouter",
        update: "Modifier"
      }[action];
    }
  },
  data() {
    return {
      plans: [],
      PLAN_LABELS: PLAN_LABELS,
      CLUSTER_TYPES: CLUSTER_TYPES
    };
  },
  mounted() {
    const { apiClient } = this.$store.getters;
    getPlans(apiClient)()
      .then(({ data }) => {
        this.plans = data;
      })
      .catch(e => {
        this.setError({
          message: "Une erreur est survenue pour charger la liste des plans",
          error: e
        });
      });
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const { cluster, action } = this;
      const { id, name, address, details, cluster_type, phone, siren, hubspot_id, plan } = cluster;
      const params = {
        name,
        address,
        details,
        phone,
        siren,
        cluster_type
      };
      const { apiClient } = this.$store.getters;
      if (action === "update") {
        updateCluster(apiClient)(id, params)
          .then(({ data }) => {
            if (hubspot_id && hubspot_id !== "") {
              updateHubspotId(apiClient)(data.id)(parseInt(hubspot_id))
                .then(() => {
                  if (plan && plan !== "") {
                    updatePlan(apiClient)(parseInt(hubspot_id), plan)
                      .then(() => {
                        this.setSuccess({
                          message: "Exploitation modifiée avec succès"
                        });
                      })
                      .catch(e => {
                        this.setError({
                          message: `l'Exploitation a ben été modifiée, mais le passage en plan ${
                            PLAN_LABELS[plan] || plan.name
                          } a échoué`,
                          error: e
                        });
                      });
                  } else {
                    this.setSuccess({
                      message: "Exploitation modifiée avec succès"
                    });
                  }
                })
                .catch(() => {
                  this.setSuccess({
                    message: "Exploitation modifiée avec succès, mais l'association au compte Hubspot a échoué"
                  });
                });
            } else {
              this.setSuccess({
                message: "Exploitation modifiée avec succès"
              });
            }
            if (this.afterSubmit) {
              this.afterSubmit(data);
            }
          })
          .catch(e => this.setError(e));
      } else {
        createClusterWithState(apiClient, params)
          .then(({ data }) => {
            if (hubspot_id && hubspot_id !== "") {
              updateHubspotId(apiClient)(data.id)(parseInt(hubspot_id))
                .then(() => {
                  if (plan && plan !== "") {
                    updatePlan(apiClient)(parseInt(hubspot_id), plan)
                      .then(() => {
                        this.setSuccess({
                          message: "Exploitation créée avec succès"
                        });
                      })
                      .catch(e => {
                        this.setError({
                          message: `l'Exploitation a ben été créée, mais le passage en plan ${
                            PLAN_LABELS[plan] || plan.name
                          } a échoué`,
                          error: e
                        });
                      });
                  } else {
                    this.setSuccess({
                      message: "Exploitation créée avec succès"
                    });
                  }
                })
                .catch(() => {
                  this.setSuccess({
                    message: "Exploitation créée avec succès, mais l'association au compte Hubspot a échoué"
                  });
                });
            } else {
              this.setSuccess({
                message: "Exploitation créée avec succès"
              });
            }
            if (this.afterSubmit) {
              this.afterSubmit(data);
            }
          })
          .catch(e => this.setError(e));
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  }
};
</script>
