import qs from "qs";

export const getOtaCountPerDay = client => status => client.get(`/ota/stats/count?status=${status}`);
export const getOtaList = client => query => client.get(`/ota?${qs.stringify(query)}`);
export const getOtaByMac = client => mac => client.get(`/ota/${mac}/all`);
export const deleteOtaActive = client => mac => client.delete(`/ota/${mac}/active`);
export const createMultipleOta = client => ({ fromFirmwareVersions, toFirmwareVersion, forceOTA, limitOTA }) =>
  client.post("/ota/multiple", {
    from_firmware_versions: fromFirmwareVersions,
    to_firmware_version: toFirmwareVersion,
    force_ota: forceOTA,
    limit_ota: limitOTA
  });
export const createMultipleOtaWithDevices = client => ({ fromDeviceIds, toFirmwareVersion, forceOTA, limitOTA }) =>
  client.post("/ota/multiple", {
    from_device_instance_ids: fromDeviceIds,
    to_firmware_version: toFirmwareVersion,
    limit_ota: limitOTA,
    force_ota: forceOTA
  });
export const createOta = client => ({ deviceInstanceID, major, minor, patch }) =>
  client.post(`/ota`, {
    device_instance_id: deviceInstanceID,
    major,
    minor,
    patch
  });
