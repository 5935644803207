<template>
  <b-modal :active="isActive" :onCancel="onClose" has-modal-card>
    <form @submit.prevent="submitLinkKarnottForm">
      <div class="modal-card js-link-cluster-to-entity-modal">
        <header class="modal-card-head">{{ title }}</header>
        <section class="modal-card-body modal-link-entity">
          <b-field label="Choisir une exploitation">
            <b-autocomplete
              :keep-first="true"
              :open-on-focus="true"
              :data="clustersResults"
              field="name"
              v-model="cluster"
              @typing="filterClusters"
              @select="c => (selectedCluster = c)"
            />
          </b-field>
          <b-field label="Statut de la liaison">
            <b-select v-model="deviceState">
              <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER_FOR_SELECT" :value="option">
                {{ fmtDeviceStateWithCluster(option) }}
              </option>
            </b-select>
          </b-field>
          <b-field
            v-if="this.askForEndDateForLending && this.deviceState === LENDING_DEVICE_STATE_WITH_CLUSTER"
            label="Date de fin d'association (défaut : j+37)"
          >
            <b-input type="date" v-model="newAssociateEndDate" />
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="onClose">Annuler</a>
        <button class="button is-info" :disabled="!deviceState || !selectedCluster">Associer</button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import {
  DEVICE_STATES_WITH_CLUSTER_FOR_SELECT,
  LENDING_DEVICE_STATE_WITH_CLUSTER,
  DEVICE_STATES_WITH_CLUSTER_LABEL
} from "Constants";
import { searchClusters } from "Api/cluster";
import { mapActions } from "vuex";
import { callApiWithSignal } from "Utils";

let timeout = null;
export default {
  props: [
    "title",
    "isActive",
    "closeModalAction",
    "linkAction",
    "successMessage",
    "errorMessage",
    "askForEndDateForLending"
  ],
  data() {
    return {
      clustersResults: [],
      cluster: null,
      selectedCluster: null,
      deviceState: null,
      newAssociateEndDate: new Date(new Date().getTime() + 37 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
      DEVICE_STATES_WITH_CLUSTER_FOR_SELECT,
      LENDING_DEVICE_STATE_WITH_CLUSTER,
      signal: null
    };
  },
  methods: {
    onClose() {
      this.clustersResults = [];
      this.closeModalAction();
    },
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    submitLinkKarnottForm() {
      const { selectedCluster, deviceState } = this;

      var newAssociateEndDate = null;
      if (
        this.deviceState === LENDING_DEVICE_STATE_WITH_CLUSTER &&
        this.askForEndDateForLending &&
        this.newAssociateEndDate
      ) {
        newAssociateEndDate = new Date(this.newAssociateEndDate).toISOString();
      }

      if (!selectedCluster || selectedCluster.id === undefined) {
        return;
      }

      const { setError, setSuccess, successMessage, errorMessage, linkAction, closeModalAction } = this;
      this.clustersResults = [];
      linkAction(selectedCluster.id, deviceState, newAssociateEndDate)
        .then(() => {
          if (closeModalAction) {
            closeModalAction(selectedCluster);
          }
          setSuccess({
            message: successMessage
          });
        })
        .catch(e => {
          setError({
            message: errorMessage,
            error: e
          });
        });
    },
    filterClusters(q) {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
      }
      this.clustersResults = [];
      this.isSearchClusterLoading = false;
      // because search filter return too many results
      if (q.length < 2) {
        return;
      }
      timeout = setTimeout(() => {
        const { apiClient } = this.$store.getters;
        const { apiFunc, signal } = callApiWithSignal(searchClusters, apiClient);
        this.signal = signal;
        this.isSearchKarnottLoading = true;
        apiFunc({ name: q })
          .then(({ data }) => {
            this.isSearchClusterLoading = false;
            this.clustersResults = data;
          })
          .catch(e => {
            this.isSearchClusterLoading = false;
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  }
};
</script>
<style scoped>
.js-link-cluster-to-entity-modal {
  width: 800px !important;
  min-height: 300px !important;
}
.modal-link-entity .autocomplete .dropdown-content {
  max-height: 130px !important;
}
</style>
