<template>
  <form @submit.prevent="submitUserForm">
    <b-field label="Email">
      <b-input v-model="localUser.email"> </b-input>
    </b-field>
    <b-field label="Prénom">
      <b-input v-model="localUser.firstname"> </b-input>
    </b-field>
    <b-field label="Nom">
      <b-input v-model="localUser.lastname"> </b-input>
    </b-field>
    <b-field label="Numéro de téléphone">
      <b-input v-model="localUser.phonenumber"> </b-input>
    </b-field>
    <b-field label="Type" v-if="currentUser && currentUser.isAdmin()">
      <b-select v-model="localUser.application_role" placeholder="Choisir le rôle" expanded>
        <option v-for="role in ROLES" :value="role" :key="role">
          {{ role }}
        </option>
      </b-select>
    </b-field>
    <b-field
      v-show="localUser.application_role === SALE_USER_ROLE"
      label="Adresse (metadata seulement éditable pour les commerciaux)"
    >
      <b-input v-model="localUserMetadata.address"> </b-input>
    </b-field>
    <button class="button is-success is-fullwidth">{{ buttonLabel }}</button>
  </form>
</template>

<script>
import { ADMIN_USER_ROLE, CARE_USER_ROLE, SALE_USER_ROLE, USER_ROLE, HARDWARE_USER_ROLE } from "Constants";
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_USER_ME_ACTION } from "Stores/users";
import { mapActions } from "vuex";
import { createUser, updateUser, createUserMetadata, updateUserMetadata } from "Api/user";
export default {
  props: {
    userId: {
      type: Number,
      default: null
    },
    user: {
      type: Object,
      default() {
        return {
          email: "",
          lastname: "",
          firstname: "",
          phonenumber: "",
          application_role: USER_ROLE
        };
      }
    },
    userMetadata: {
      type: Object,
      default() {
        return {
          user_id: null,
          address: null
        };
      }
    },
    afterSubmit: Function
  },
  computed: {
    buttonLabel() {
      if (this.userId) {
        return "Modifier l'utilisateur";
      }
      return "Créér l'utilisateur";
    }
  },
  mounted() {
    this.getCurrentUser().then(u => {
      this.currentUser = u;
    });
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getCurrentUser: GET_USER_ME_ACTION
    }),
    submitUserForm() {
      const { userId, localUser, localUserMetadata, afterSubmit } = this;
      const { email, lastname, firstname, phonenumber, application_role } = localUser;
      const { apiClient } = this.$store.getters;
      const params = { email, lastname, firstname, phonenumber, application_role };
      const promiseUser = userId ? updateUser(apiClient)(userId, params) : createUser(apiClient)(params);

      let promiseUserMetadata = new Promise(resolve => resolve({ data: localUserMetadata }));

      let userData = null;
      promiseUser
        .then(({ data }) => {
          userData = data;
          if (application_role === SALE_USER_ROLE) {
            const { user_id, address } = localUserMetadata;
            promiseUserMetadata = user_id
              ? updateUserMetadata(apiClient)(user_id, { address })
              : createUserMetadata(apiClient)({ user_id: userData.id, address });
          }
          return promiseUserMetadata;
        })
        .then(({ data }) => {
          const userMetadata = data;
          if (afterSubmit) {
            afterSubmit(userData, userMetadata);
          }
        })
        .catch(e => this.setError(e));
    }
  },
  data() {
    return {
      ROLES: [ADMIN_USER_ROLE, CARE_USER_ROLE, SALE_USER_ROLE, USER_ROLE, HARDWARE_USER_ROLE],
      SALE_USER_ROLE: SALE_USER_ROLE,
      localUser: this.user,
      localUserMetadata: this.userMetadata,
      currentUser: null
    };
  }
};
</script>
