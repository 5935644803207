export const SET_ERROR_INFO_MUTATION = "SET_ERROR_INFO_MUTATION";
export const SET_ERROR_ACTION = "SET_ERROR_ACTION";

export const SET_SUCCESS_INFO_MUTATION = "SET_SUCCESS_INFO_MUTATION";
export const SET_SUCCESS_ACTION = "SET_SUCCESS_ACTION";

let module = {
  state: {
    error: null,
    success: null
  },
  mutations: {
    [SET_ERROR_INFO_MUTATION](state, msg) {
      state.error = msg;
    },
    [SET_SUCCESS_INFO_MUTATION](state, msg) {
      state.success = msg;
    }
  },
  actions: {
    [SET_ERROR_ACTION]({ commit }, { message, error }) {
      let detailError = "";
      if (error) {
        if (error.response) {
          const { response } = error;
          const { data } = response;
          const { errors } = data;
          if (errors && errors.length > 0) {
            detailError = `- ${errors[0].message}`;
          }
        } else if (error.message) {
          detailError = `- ${error.message}`;
        }
      }
      commit(SET_ERROR_INFO_MUTATION, {
        message: message,
        detail: detailError
      });
    },
    [SET_SUCCESS_ACTION]({ commit }, { message }) {
      commit(SET_SUCCESS_INFO_MUTATION, {
        message: message
      });
    }
  }
};

export default module;
