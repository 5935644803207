<template>
  <div id="container">
    <div class="content">
      <button class="button is-info is-small" @click="() => archiveAllDeviceMonitoringForDevice()">
        Archiver toutes les erreurs
      </button>
      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>Date</th>
            <th>Severity</th>
            <th>Module</th>
            <th>Message</th>
            <th>Archive</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(monitoring, index) in deviceMonitoring" :key="index">
            <td>{{ formatDate(monitoring.occurred_at) }}</td>
            <td>{{ monitoring.severity }}</td>
            <td>{{ monitoring.module }}</td>
            <td>{{ monitoring.message }}</td>
            <td>
              <button class="button is-info is-small" @click="() => archiveDeviceMonitoring(monitoring.id)">
                Archiver
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatDate } from "Utils";
import { archiveDeviceMonitoring, bulkArchiveDeviceMonitoring, getDeviceMonitoring } from "Api/device";
import { SET_ERROR_ACTION } from "Stores/message";
export default {
  props: ["propDevice", "propDeviceMonitoring", "propUpdateDeviceMonitoring"],
  methods: {
    formatDate(strDate) {
      return formatDate(new Date(strDate));
    },
    getMonitoring() {
      const { apiClient } = this.$store.getters;
      this.tableLoading = true;
      getDeviceMonitoring(apiClient)({ device_instance_id: [this.device.id] })
        .then(response => {
          if (!response.data) {
            this.deviceMonitoring = [];
            this.propUpdateDeviceMonitoring(this.deviceMonitoring);
            return;
          }
          this.deviceMonitoring = response.data;
          // sort this.deviceMonitoring by occurred_at desc
          this.deviceMonitoring.sort((a, b) => {
            return new Date(b.occurred_at) - new Date(a.occurred_at);
          });
          this.propUpdateDeviceMonitoring(this.deviceMonitoring);
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError(e);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    archiveDeviceMonitoring(id) {
      archiveDeviceMonitoring(this.$store.getters.apiClient)(id)
        .then(() => {
          this.getMonitoring();
        })
        .catch(e => {
          this.setError(e);
        });
    },
    archiveAllDeviceMonitoringForDevice() {
      const monitoringIds = this.deviceMonitoring.map(item => item.id);
      const payload = {"device_monitoring_ids": monitoringIds};
      bulkArchiveDeviceMonitoring(this.$store.getters.apiClient)(payload)
        .then(() => {
          this.getMonitoring();
        })
        .catch(e => {
          this.setError(e);
        });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  mounted() {},
  data() {
    return {
      device: this.propDevice,
      deviceMonitoring: this.propDeviceMonitoring,
      tableLoading: false
    };
  }
};
</script>

<style scoped>
#container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.card {
  margin: 10px 0;
}
</style>
