<template>
  <div>
    <b-table
      :data="deviceRanges"
      :loading="tableLoading"
      :default-sort="['from_date', 'desc']"
      :paginated="deviceRanges && deviceRanges.length > 20"
      per-page="20"
      :total="deviceRanges.length"
    >
      <b-table-column v-slot="props" field="beacon_uuid" label="Beacon ID" sortable>
        {{ props.row.beacon_uuid }}
      </b-table-column>
      <b-table-column v-slot="props" field="device_session_id" label="Device Session ID" sortable>
        {{ props.row.id }}
      </b-table-column>
      <b-table-column v-slot="props" field="status" label="Statut" sortable>
        {{ props.row.status }}
      </b-table-column>
      <b-table-column v-slot="props" field="from_date" label="Date de début" sortable>
        {{ formatDate(props.row.from_date) }}
      </b-table-column>
      <b-table-column v-slot="props" field="to_date" label="Date de fin" sortable>
        {{ formatDate(props.row.to_date) }}
      </b-table-column>
      <b-table-column v-slot="props" field="action" label="Action" sortable>
        <div class="buttons">
          <b-button @click="() => selectDeviceSession(props.row)">
            <b-icon icon="map-marker" />
          </b-button>
        </div>
      </b-table-column>
      <template slot="empty">
        <b-message type="is-info">
          <p>Aucun beacons range pour la plage de date de cette device session</p>
        </b-message>
      </template>
    </b-table>
    <b-modal :active="isModalActive" :on-cancel="() => (isModalActive = false)" width="90%">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Session sur la carte</p>
        </header>
        <section class="modal-card-body">
          <div id="map-container">
            <b-loading :is-full-page="false" :active.sync="isEventPointLoading" />
            <b-message type="is-danger" v-if="mapErrorMessage !== null">
              {{ mapErrorMessage }}
            </b-message>
            <div id="map-row">
              <div id="map-legend">
                <span :style="`color:${darkerColor(r[0])}`" v-for="r in mapLegend" :key="r[0]">
                  <svg width="20px" height="20px">
                    <path d="M 0 0 H 20 V 20 H 0 Z" :fill="r[0]" :stroke="darkerColor(r[0])" />
                  </svg>
                  {{ r[1] }}
                </span>
              </div>
              <div id="event-point-map"></div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>
<script>
/* global google */
import { getDeviceSessionRelated } from "Api/deviceSession";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";
import { drawDataOnMap, darkerColor } from "Utils/map";
import { getDeviceSessionEventPoints } from "Api/eventPoint";
const deviceSessionColor = "#f94d4d";

export default {
  props: ["device_session"],
  data() {
    return {
      deviceRanges: [],
      tableLoading: false,
      isModalActive: false,
      mapLegend: [],
      mapErrorMessage: null,
      selectedDeviceSession: [],
      isEventPointLoading: false
    };
  },
  mounted() {
    this.loadDeviceRanges();
  },
  methods: {
    selectDeviceSession(session) {
      this.isModalActive = true;
      this.mapLegend = [];
      this.mapErrorMessage = null;
      this.selectedDeviceSession = session;
      this.getMapDataAndBuildMap(session);
    },
    getMapDataAndBuildMap(session) {
      const { apiClient } = this.$store.getters;
      this.isEventPointLoading = true;
      getDeviceSessionEventPoints(apiClient)(session.id)
        .then(({ data }) => {
          this.isEventPointLoading = false;
          if (data === null) {
            this.mapErrorMessage = "Aucun point pour la device session n'a été retourné";
            return;
          }
          const map = this.drawMap();

          const { device_serialnumber } = data[0];
          this.mapLegend.push([deviceSessionColor, `[${device_serialnumber}] Device session`]);
          drawDataOnMap(data, map, {
            drawMarker: true,
            drawPolyline: true,
            color: deviceSessionColor,
            weight: 3,
            opacity: 1,
            focusOn: true
          });
          this.mapLegend.push(["#f9cd4d", `Points`]);
          drawDataOnMap(data, map, {
            drawMarker: true,
            color: "#f9cd4d",
            weight: 6,
            opacity: 0.9,
            focusOn: true
          });
        })
        .catch(e => {
          this.isEventPointLoading = false;
          this.setError({
            message: "Impossible de récupérer les points de la device session <br />",
            error: e
          });
        });
    },
    drawMap() {
      return new google.maps.Map(document.getElementById("event-point-map"), {
        zoom: 3,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        tilt: 0,
        rotateControl: false
      });
    },
    toggleRow(row) {
      this.$refs.table.toggleDetails(row);
    },
    darkerColor(color) {
      return darkerColor(color);
    },
    loadDeviceRanges() {
      const { apiClient } = this.$store.getters;
      this.tableLoading = true;
      getDeviceSessionRelated(apiClient)(this.device_session)
        .then(({ data }) => {
          this.tableLoading = false;
          if (data === null || data.length === 0) {
            return;
          }
          this.deviceRanges = data.map(d => {
            return {
              ...d,
              from_date: d.first_position_date ? new Date(d.first_position_date) : null,
              to_date: d.last_movement_date ? new Date(d.last_movement_date) : null
            };
          });
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError({
            message: "Impossible de récupérer les beacon device ranges <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
#map-row {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}
#event-point-map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
</style>
