<template>
  <div class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-full">
          <div class="notification is-info" id="meeting-tile">
            <h1 class="title">Mes rendez-vous</h1>
            <div class="content">
              <b-table :data="meetings" :columns="columns"> </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline box">
        <div class="column is-full">
          <div class="notification is-warning">
            <div class="stat has-text-centered"><span>10</span> exploitations</div>
            <div class="stats-container__grid">
              <div>
                <p class="stat"><span>3</span> Ferme(s)</p>
                <p class="stat"><span>0</span> Cliente(s)</p>
              </div>
              <div>
                <p class="stat"><span>3</span> Ferme(s)</p>
                <p class="stat"><span>0</span> Cliente(s)</p>
              </div>
              <div>
                <p class="stat"><span>3</span> Ferme(s)</p>
                <p class="stat"><span>0</span> Cliente(s)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="notification is-info fix-height is-flex-center">
            <p class="stat is-multiline">
              <span> 10/10 </span>
              CLIENT(S) INACTIF(S)
            </p>
          </div>
        </div>
        <div class="column is-3">
          <div class="notification is-info fix-height is-flex-center">
            <p class="stat is-multiline">
              <span> 10/10 </span>
              KARNOTT DEFAILLANTS
            </p>
          </div>
        </div>
        <div class="column is-3">
          <div class="notification is-info fix-height is-flex-center">
            <a href="#"> </a>
            <p class="stat is-multiline">
              <span> 10/10 </span>
              KARNOTT INUTILISÉ(S)
            </p>
          </div>
        </div>
        <div class="column is-3">
          <div class="notification is-info fix-height is-flex-center">
            <div class="top-bar">
              <b-icon icon="information" size="is-small" class="info"></b-icon>
              <b-icon icon="download" size="is-small" class="download"></b-icon>
            </div>
            <p class="stat is-multiline">
              <span> 10/10 </span>
              EXPLOITATION(S) INCOMPLETE(S)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const labelTranslation = {
  label: "Label",
  email: "Email",
  cluster_name: "Exploitation",
  meeting_date: "Date de rdv"
};
export default {
  components: {},
  name: "dashboard",
  data() {
    return {
      meetings: [
        {
          label: "Formation 2",
          email: "contact@karnott.fr",
          cluster_name: "Karnott",
          meeting_date: "2019-10-10T10:00:00Z"
        },
        {
          label: "Formation",
          email: "contact@karnott.fr",
          cluster_name: "Karnott",
          meeting_date: "2019-10-01T10:00:00Z"
        }
      ]
    };
  },
  computed: {
    columns: function() {
      if (this.meetings.length > 0) {
        return Object.keys(this.meetings[0]).map(k => {
          return {
            field: k,
            label: labelTranslation[k]
          };
        });
      }
      return [];
    }
  }
};
</script>

<style>
#meeting-tile .table {
  background: #167df0 !important;
}
#meeting-tile .table * {
  border-color: white !important;
  color: white !important;
}
</style>
<style scoped>
.stat {
  font-size: 22px;
  vertical-align: middle;
}
.stat > span {
  font-size: 30px;
  font-weight: 600 !important;
  margin: 5px;
}
.stat.is-multiline {
  text-align: center;
}
.stat.is-multiline > span {
  display: block;
  text-align: center;
}
.stats-container__grid {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
}
.stats-container__grid > div {
  margin: 0 20px !important;
}
.stats-container__grid > div .stat {
  margin: 1em 0px;
}
.notification.fix-height {
  height: 250px;
}
.is-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification .top-bar {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
}
.top-bar .info {
  position: absolute;
  top: 0px;
  left: 0px;
}
.top-bar .download {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
