<template>
  <b-table
    :data="beaconDeviceRanges"
    :loading="tableLoading"
    :default-sort="['from_date', 'desc']"
    :paginated="beaconDeviceRanges && beaconDeviceRanges.length > 20"
    per-page="20"
    :total="beaconDeviceRanges.length"
  >
    <b-table-column field="id" label="Beacon range ID" v-slot="props" sortable>
      {{ props.row.id }}
    </b-table-column>
    <b-table-column field="device_serialnumber" label="Karnott" v-slot="props" sortable>
      {{ props.row.device_serialnumber }}
    </b-table-column>
    <b-table-column field="from_date" label="Date de début" v-slot="props" sortable>
      {{ formatDate(props.row.from_date) }}
    </b-table-column>
    <b-table-column field="to_date" label="Date de fin" v-slot="props" sortable>
      {{ formatDate(props.row.to_date) }}
    </b-table-column>
    <template slot="empty">
      <b-message type="is-info">
        <p>Aucuns beacons range pour la plage de date de cette device session</p>
      </b-message>
    </template>
  </b-table>
</template>

<script>
import { getBeaconDeviceRanges } from "Api/beacon";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";

export default {
  props: ["beacon", "fromDateFilter", "toDateFilter"],
  data() {
    return {
      beaconDeviceRanges: [],
      tableLoading: false
    };
  },
  mounted() {
    this.loadBeaconDeviceRanges();
  },
  methods: {
    loadBeaconDeviceRanges() {
      const { apiClient } = this.$store.getters;
      this.tableLoading = true;
      let params = {};
      if (this.fromDateFilter) {
        params.from_date = this.fromDateFilter;
      }
      if (this.toDateFilter) {
        params.to_date = this.toDateFilter;
      }
      getBeaconDeviceRanges(apiClient)(this.beacon.uuid, params)
        .then(({ data }) => {
          this.tableLoading = false;
          this.beaconDeviceRanges = [];
          if (data === null || data.length === 0) {
            return;
          }

          this.beaconDeviceRanges = data.map(d => {
            return {
              ...d,
              from_date: d.from_date ? new Date(d.from_date) : null,
              to_date: d.to_date ? new Date(d.to_date) : null
            };
          });
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError({
            message: "Impossible de récupérer les beacon device ranges <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  }
};
</script>
