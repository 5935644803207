<template>
  <div>
    <b-modal :active="isActive" :onCancel="resetDataAndCloseModal" has-modal-card>
      <div class="modal-card modal-create-ota">
        <header class="modal-card-head">Créer une OTA</header>
        <section class="modal-card-body">
          <div class="content">
            <div class="level">
              <div class="left-level">
                <b-field label="Saisir des numéros (ex: D21) de série">
                  <b-taginput
                    v-model="selectedKarnott"
                    :data="filteredKarnott"
                    autocomplete
                    :allow-new="false"
                    :allow-duplicates="false"
                    field="serialnumber"
                    icon="label"
                    placeholder="Saisir un numéro de série"
                    @typing="getFilteredSerialNumbers"
                    :clear-on-select="true"
                    ref="serialNumberInput"
                  >
                  </b-taginput>
                </b-field>
              </div>
              <b-icon icon="arrow-right" type="is-success"> </b-icon>
              <div class="right-level">
                <div class="panel">
                  <p class="panel-heading">
                    Sélectionner la nouvelle version du firmware
                  </p>
                  <div class="panel-block">
                    <div class="control">
                      <div class="field">
                        <div class="control is-expanded">
                          <div class="js-select-new-firmware select is-fullwidth">
                            <b-autocomplete
                              v-model="searchInput.firmwareNameForNew"
                              @select="selectNewFirmware"
                              :open-on-focus="true"
                              :keep-first="true"
                              :data="searchFirmwaresForNew"
                              field="fullVersion"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="field is-expanded">
                        <button class="button is-success is-fullwidth js-submit-create-ota" @click="submitForm">
                          Mettre à jour
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-field>
              <b-checkbox v-model="forceOta">Force OTA</b-checkbox>
            </b-field>
          </div>
        </section>
      </div>
    </b-modal>
    <OtaListModal
      :title="successModalTitle"
      :isActive="successModalActive"
      :closeModal="() => (successModalActive = false)"
      :otaList="successOtaList"
    />
  </div>
</template>

<script>
import OtaListModal from "Components/devices/OtaListModal";
import { getDevices } from "Api/device";
import { FETCH_FIRMWARES_VERSION_ACTION } from "Stores/devices";
import { CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION } from "Stores/ota";
import { mapActions, mapState } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

const filterFirmwaresByFullVersion = (firmwares, fullVersion) => {
  return firmwares.filter(f => f.fullVersion.indexOf(fullVersion.toLowerCase()) >= 0);
};

export default {
  mounted() {
    this.fetchFirmwaresVersion().catch(e => {
      this.setError({
        message: "Can't retrieve the firmwares.<br />Please contact the support.<br />",
        error: e
      });
    });
    this.getDevices();
  },
  computed: {
    searchFirmwaresForNew() {
      return filterFirmwaresByFullVersion(
        this.firmwaresVersionToUpdateNotSelected,
        this.searchInput.firmwareNameForNew
      );
    },
    firmwaresVersionToUpdateNotSelected() {
      return this.firmwaresVersion.filter(f => {
        return this.fromFirmwareVersions.find(f2 => f2.fullVersion === f.fullVersion) === undefined;
      });
    },
    ...mapState({ firmwaresVersion: state => state.devices.firmwaresVersion })
  },
  methods: {
    resetfirmwaresVersionToUpdateList() {
      this.fromFirmwareVersions = [];
    },
    rmFirmwareToList(firmware) {
      this.fromFirmwareVersions = this.fromFirmwareVersions.filter(f => f.fullVersion !== firmware.fullVersion);
    },
    selectNewFirmware(firmware) {
      this.toFirmwareVersion = firmware;
    },
    submitForm() {
      if (!this.toFirmwareVersion || !this.toFirmwareVersion.fullVersion) {
        this.setError({
          message: "Veuillez renseigner le nouveau firmware"
        });
        return;
      }
      this.createMultipleOtaWithDevices({
        fromDeviceIds: this.selectedKarnott.map(d => d.id),
        toFirmwareVersion: {
          major: this.toFirmwareVersion.major,
          minor: this.toFirmwareVersion.minor,
          patch: this.toFirmwareVersion.patch
        },
        forceOTA: this.forceOta
      })
        .then(data => {
          if (this.onSuccess) {
            this.onSuccess();
          }
          this.resetData();
          const count = data === null ? 0 : data.length;

          this.openSuccessModal(`${count} OTA ont été créées`, data.map(this.formatResult));
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue<br />Veuillez contacter le support<br />",
            error: e
          });
        });
    },
    openSuccessModal(message, data) {
      this.successModalTitle = message;
      this.successModalActive = true;
      this.successOtaList = data;
    },
    resetData() {
      this.resetfirmwaresVersionToUpdateList();
      this.forceOta = false;
      this.toFirmwareVersion = null;
      this.searchInput.firmwareNameToUpdate = "";
      this.searchInput.firmwareNameForNew = "";
    },
    resetDataAndCloseModal() {
      this.resetData();
      this.closeModal();
    },
    getDevices() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getDevices(apiClient)
        .then(({ data }) => {
          this.devices = data;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },
    getFilteredSerialNumbers(text) {
      let updatedInputValue = text;
      const lc = text.toLowerCase();
      let serialNumbers = lc.split(",");
      if (serialNumbers.length === 1) {
        serialNumbers = lc.split(";");
      }
      this.filteredKarnott = this.devices.filter(d => {
        const serialnumberToLowerCase = d.serialnumber.toLowerCase();
        for (const i in serialNumbers) {
          if (serialnumberToLowerCase === serialNumbers[i]) {
            const alreadyAdded = this.selectedKarnott.find(
              ({ serialnumber }) => serialnumber.toLowerCase() === serialnumberToLowerCase
            );
            if (!alreadyAdded) {
              this.selectedKarnott.push(d);
            }
            updatedInputValue = "";
            return false;
          }
        }
        return false;
      });
      if (updatedInputValue !== text) {
        setTimeout(() => {
          // update input value if user copy/past list of serial number
          this.$refs.serialNumberInput.$el.querySelectorAll("input.input")[0].value = updatedInputValue;
        }, 10);
      }
    },
    ...mapActions({
      fetchFirmwaresVersion: FETCH_FIRMWARES_VERSION_ACTION,
      createMultipleOtaWithDevices: CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION,
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  props: ["isActive", "closeModal", "onSuccess", "formatResult"],
  data() {
    return {
      successModalActive: false,
      successOtaList: [],
      successModalTitle: "",
      fromFirmwareVersions: [],
      toFirmwareVersion: null,
      forceOta: false,
      searchInput: {
        firmwareNameForNew: ""
      },
      devices: [],
      deviceSearchResults: [],
      filteredKarnott: this.devices,
      selectedKarnott: []
    };
  },
  components: { OtaListModal }
};
</script>
<style scoped>
.modal-card {
  min-height: 300px;
}
.modal-card .right-level {
  min-width: 550px;
}
.modal-card .right-level {
  min-width: 250px;
}
.panel-heading {
  margin-bottom: 0px !important;
  font-size: 18px !important;
}
.panel-block {
  position: relative;
}
.panel-block > div.delete-button {
  position: absolute;
  right: 15px;
}
</style>
<style>
.modal-create-ota .autocomplete .dropdown-content {
  max-height: 100px !important;
}
</style>
