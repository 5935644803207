<template>
  <div>
    <div class="section">
      <h1 class="title">
        Liste des OTA
        <a class="js-open-create-modal-button button is-primary" @click="() => (isModalActive = true)">
          Créer des OTA d'un firmware vers un autre
        </a>
        &nbsp;
        <a
          class="js-open-create-modal-button button is-info is-primary"
          @click="() => (ModalDeviceListisActive = true)"
        >
          Créer des OTA avec une liste de karnott
        </a>
      </h1>
      <MultipleOtaModal
        :isActive="isModalActive"
        :closeModal="() => (isModalActive = false)"
        :onSuccess="loadPage"
        :formatResult="formatResult"
      />
      <OtaModalDeviceList
        :isActive="ModalDeviceListisActive"
        :closeModal="() => (ModalDeviceListisActive = false)"
        :onSuccess="loadPage"
        :formatResult="formatResult"
      />
    </div>
    <div class="section has-background-white">
      <line-chart :data="graphData" :messages="{ empty: 'Aucune donnée' }"> </line-chart>
      <table>
        <tr>
          <td>
            <strong>Serial number:</strong>
          </td>

          <td>
            <b-input
              type="text"
              :maxlength="serialNumberLength"
              :value="serialNumber"
              class="serial_number"
              size="is-small"
              @input="onSerialNumber"
            >
            </b-input>
          </td>

          <td>
            <strong>Created since:</strong>
          </td>

          <td>
            <b-select @input="onCreatedSince" v-model="createdSince" size="is-small">
              <option v-for="option in ['None', 'day', 'week', 'month']" :value="option" :key="option">
                {{ option === "None" ? option : "1 " + option }}
              </option>
            </b-select>
          </td>

          <td>
            <b-checkbox
              @input="loadPage"
              v-model="finished"
              :disabled="activeOptionalFilters.length === 0"
              size="is-small"
            >
              Finished OTA
            </b-checkbox>
          </td>
        </tr>
      </table>

      <div class="js-ota-list">
        <b-table
          class="test"
          :data="otaList"
          :loading="loading"
          paginated
          :per-page="20"
          :default-sort-direction="'desc'"
          :default-sort="['added_at', 'desc']"
          :row-class="(row, index) => (row.is_new ? 'new-row' : '')"
        >
          <b-table-column v-slot="props" field="mac" label="MAC" sortable>
            {{ props.row.mac }}
          </b-table-column>
          <b-table-column v-slot="props" field="serial_number" label="Serial Number" sortable>
            <a class="button is-text" @click="redirectToDevice(props.row.serial_number)">
              {{ props.row.serial_number }}
            </a>
          </b-table-column>
          <b-table-column v-slot="props" field="firmware_name" label="Targetted firmware" sortable>
            {{ props.row.firmware_name }}
          </b-table-column>
          <b-table-column v-slot="props" field="firmware_name" label="From firmware" sortable>
            {{ props.row.from_firmware_name }}
          </b-table-column>
          <b-table-column v-slot="props" field="number_of_packets" label="Packets">
            {{ props.row.packets }}
          </b-table-column>
          <b-table-column v-slot="props" field="last_packet_date" label="Last packet date" sortable>
            {{ formatDate(props.row.last_packet_date) }}
          </b-table-column>
          <b-table-column v-slot="props" field="start_time" label="Start time" sortable>
            {{ formatDate(props.row.start_time) }}
          </b-table-column>
          <b-table-column v-slot="props" field="end_time" label="End time" sortable>
            {{ formatDate(props.row.end_time) }}
          </b-table-column>
          <b-table-column v-slot="props" field="added_at" label="Added at" sortable>
            {{ formatDate(props.row.added_at) }}
          </b-table-column>
          <b-table-column v-slot="props" label="Action">
            <button class="button field is-danger" :disabled="finished" @click="deleteItem(props.row.mac)">
              <b-icon icon="close"></b-icon>
              <span>Delete</span>
            </button>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import MultipleOtaModal from "Components/devices/MultipleOtaModal";
import OtaModalDeviceList from "Components/devices/OtaModalDeviceList";
import { mapActions, mapState } from "vuex";
import { FETCH_OTA_LIST_ACTION, DELETE_OTA_ACTIVE_ACTION } from "Stores/ota";
import { SET_ERROR_ACTION } from "Stores/message";
import { getOtaCountPerDay } from "Api/ota";
import { getDevicesBySerialNumber } from "Api/device";

const getOrderBy = sortField => {
  switch (sortField) {
    case "serial_number":
      return "name";
    case "firmware_name":
      return "description";
    default:
      return sortField;
  }
};

const SERIAL_NUMBER_FILTER = 1;
const CREATED_SINCE_FILTER = 2;

export default {
  components: { MultipleOtaModal, OtaModalDeviceList },
  mounted() {
    document.title = "OTA";
    this.loadPage()
      .then(() => {
        return Promise.all([
          this.createOtaCountPerDayGraph("done", "Nombre d'OTA terminées par jour"),
          this.createOtaCountPerDayGraph("started", "Nombre d'OTA démarrées par jour")
        ]);
      })
      .catch(e => this.setError({ error: e }));
  },
  methods: {
    redirectToDevice(serialNumber) {
      const { apiClient } = this.$store.getters;
      getDevicesBySerialNumber(apiClient)(serialNumber)
        .then(({ data }) => {
          if (data.length > 0) {
            const device = data[0];
            this.$router.push({ path: `/device/${device.id}` }).catch(() => {});
          }
          this.setError({
            message: `Aucun Karnott avec le numéro de série: ${serialNumber}`
          });
        })
        .catch(e => this.setError({ error: e }));
      return true;
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : "N/A";
    },
    formatResult(item) {
      return {
        ...item,
        ...{
          mac: item.mac,
          serial_number: item.serial_number,
          firmware_name: item.firmware_name || `${item.firmware_major}.${item.firmware_minor}.${item.firmware_patch}`,
          from_firmware_name: item.from_firmware_name ? item.from_firmware_name : "Unknown",
          last_packet_date: item.last_packet_date,
          packets: `${item.last_packet_number} / ${item.number_of_packets}`,
          start_time: item.start_time,
          end_time: item.end_time,
          added_at: item.added_at,
          is_new: item.isNew
        }
      };
    },

    getFilterIndex(filter) {
      return this.activeOptionalFilters.indexOf(filter);
    },
    isFilterEnabled(filter) {
      return this.getFilterIndex(filter) != -1;
    },
    enableFilter(filter) {
      this.activeOptionalFilters.push(filter);
    },
    disableFilter(filter) {
      const index = this.getFilterIndex(filter);
      this.activeOptionalFilters.splice(index, 1);
    },
    onSerialNumber(newSerialNumber) {
      if (this.isFilterEnabled(SERIAL_NUMBER_FILTER)) {
        this.disableFilter(SERIAL_NUMBER_FILTER);
        this.serialNumber = newSerialNumber;
        this.loadPage();
        return;
      }

      const shouldEnable = newSerialNumber.length === this.serialNumberLength;

      if (shouldEnable) {
        this.enableFilter(SERIAL_NUMBER_FILTER);
        this.serialNumber = newSerialNumber;
        this.loadPage();
      }
    },
    getSerialNumberFilterValue() {
      return this.isFilterEnabled(SERIAL_NUMBER_FILTER) ? this.serialNumber : "";
    },
    onCreatedSince() {
      if (this.createdSince === "None") {
        this.disableFilter(CREATED_SINCE_FILTER);
      } else if (!this.isFilterEnabled(CREATED_SINCE_FILTER)) {
        this.enableFilter(CREATED_SINCE_FILTER);
      }

      this.loadPage();
    },
    getCreatedSinceFilterValue() {
      if (!this.isFilterEnabled(CREATED_SINCE_FILTER)) {
        return "";
      }

      const value = new Date();

      switch (this.createdSince) {
        case "day":
          value.setDate(value.getDate() - 1);
          break;
        case "week":
          value.setDate(value.getDate() - 7);
          break;
        case "month":
          value.setMonth(value.getMonth() - 1);
          break;
        default:
          throw new Error(`Unsupported created since filter value: ${this.createdSince}`);
      }

      return value.toISOString();
    },
    loadPageQuery() {
      return {
        orderBy: getOrderBy(this.sortField),
        orderDirection: this.sortOrder,
        finished: this.finished,
        serial_number: this.getSerialNumberFilterValue(),
        created_since: this.getCreatedSinceFilterValue()
      };
    },
    loadPage() {
      if (this.activeOptionalFilters.length === 0) {
        this.finished = false;
      }

      this.loading = true;
      return this.fetchOtaList(this.loadPageQuery())
        .then(() => (this.loading = false))
        .catch(e => {
          this.loading = false;
          this.setError({
            message: "Can't retrieve the OTA list.<br />Please contact the support.<br />",
            error: e
          });
        });
    },
    updateGraphData(name, data) {
      for (let i in this.graphData) {
        const currentStat = this.graphData[i];
        if (currentStat.name === name) {
          currentStat.data = data;
          this.graphData[i] = currentStat;
          return;
        }
      }
      this.graphData = [{ name, data }, ...this.graphData];
    },
    createOtaCountPerDayGraph(status, label) {
      const { apiClient } = this.$store.getters;
      this.loading = true;
      getOtaCountPerDay(apiClient)(status)
        .then(({ data }) => {
          let stats = {};
          data.forEach(({ day, count }) => {
            let d = new Date(Date.parse(day));
            d.setHours(0);
            stats[d] = count;
          });
          this.updateGraphData(label, stats);
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          this.setError({
            message: "Can't retrieve the OTA statistics.<br />Please contact the support.<br />",
            error: e
          });
        });
    },
    deleteItem(mac) {
      this.loading = true;
      this.deleteOtaActive(mac)
        .then(this.loadPage)
        .catch(e => {
          this.loading = false;
          this.setError({
            message: "Can't delete the OTA with mac ${mac}.<br />Please contact the support.<br />",
            error: e
          });
        });
    },
    ...mapActions({
      fetchOtaList: FETCH_OTA_LIST_ACTION,
      deleteOtaActive: DELETE_OTA_ACTIVE_ACTION,
      setError: SET_ERROR_ACTION
    })
  },
  computed: {
    otaList() {
      return this.ota.map(this.formatResult);
    },
    ...mapState({
      ota: state => state.ota.ota
    })
  },
  data() {
    return {
      graphData: [],
      loading: false,
      finished: false,
      activeOptionalFilters: [],
      serialNumber: "AAAB ",
      serialNumberLength: 8,
      createdSince: "None",
      isModalActive: false,
      ModalDeviceListisActive: false
    };
  }
};
</script>

<style scoped>
.serial_number {
  width: 100px;
}

td {
  padding: 10px;
}
</style>
