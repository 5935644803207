<template>
  <b-modal :active="isActive" :on-cancel="closeModal" :can-cancel="canCancelOpts">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Lier une ou plusieurs exploitation(s)</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              Pour ajouter une exploitation, il est important de la sélectionner dans la liste (en cliquant sur
              l'exploitation ou en déplacant le curseur et en appuyant sur la touche 'Entrée').
            </div>
          </div>
          <b-field label="Séléctionner une exploitation" class="cluster-name" :message="fieldMessage()">
            <b-taginput
              :allow-new="false"
              :data="filteredClustersWithLabel"
              :allow-duplicates="false"
              field="label"
              icon="label"
              placeholder="Cuma ..."
              autocomplete
              v-model="selectedClusters"
              @typing="filterAction"
            >
            </b-taginput>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<style scoped>
.modal-card {
  min-height: 350px;
  width: 100%;
}
</style>
<script>
import { mapActions } from "vuex";
import { linkUserToCluster, searchClusters } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { ADMIN_USER_ROLE } from "Constants";
import { callApiWithSignal } from "Utils";

let timeout = null;
let searchClustersSignal = null;

export default {
  props: ["user", "isActive", "onLink", "onClose"],
  computed: {
    filteredClustersWithLabel() {
      return this.filteredClusters.map(c => {
        return {
          ...c,
          label: `[${c.id}] ${c.name}`
        };
      });
    },
    canCancelOpts() {
      if (this.disableEscCancelOpts) {
        return ["x", "outside"];
      }
      return ["escape", "x", "outside"];
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    fieldMessage() {
      const { selectedClusters } = this;
      return `${selectedClusters.length} exploitation(s) selectionnée(s)`;
    },
    closeModal() {
      this.selectedClusters = [];
      this.onClose();
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { user, selectedClusters } = this;
      Promise.all(selectedClusters.map(c => linkUserToCluster(apiClient)(c.id, user.id, ADMIN_USER_ROLE)))
        .then(() => {
          this.setSuccess({
            message: "Les exploitations ont bien été liés à l'utilisateur"
          });
          if (this.onLink !== undefined) {
            this.onLink();
          }
          this.closeModal();
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    filterAction(filter) {
      const { apiClient } = this.$store.getters;
      if (searchClustersSignal) {
        searchClustersSignal.cancel();
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const { apiFunc, signal } = callApiWithSignal(searchClusters, apiClient);
        searchClustersSignal = signal;
        apiFunc({ name: filter })
          .then(({ data }) => {
            this.filteredClusters = data;
          })
          .catch(e => {
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    }
  },
  data() {
    return {
      selectedClusters: [],
      filteredClusters: [],
      disableEscCancelOpts: false
    };
  }
};
</script>
