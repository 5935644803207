<template>
  <div id="menu">
    <menu>
      <menu-item v-for="menu in routes" :key="menu.menuLabel" :menu="menu" />
      <div class="right menu">
        <MenuSearch />
        <div class="item item--with-submenu">
          Actions
          <b-icon icon="menu-down"></b-icon>
          <div class="submenu">
            <router-link :to="`/password`" class="item">Password</router-link>
            <a class="item" @click="logout">Logout</a>
          </div>
        </div>
      </div>
    </menu>
  </div>
</template>

<script>
import MenuSearch from "Components/semantic-menu/MenuSearch";
import MenuItem from "Components/semantic-menu/MenuItem";
import { GET_CONFIG_ROUTES_ACTION } from "Stores/routes";
import { mapActions } from "vuex";
import { logout } from "Src/plugin";

export default {
  components: {
    MenuItem,
    MenuSearch
  },
  mounted() {
    this.getConfigRoutes().then(routes => (this.routes = routes));
  },
  name: "semantic-menu",
  data() {
    return {
      routes: []
    };
  },
  methods: {
    logout() {
      logout();
      window.location.href = "/";
    },
    ...mapActions({
      getConfigRoutes: GET_CONFIG_ROUTES_ACTION
    })
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
* {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Open Sans" !important;
}
#menu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
menu {
  padding: 20px;
  background: black;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
menu .item {
  color: white;
  margin: 0 15px;
  cursor: pointer;
}
menu .item:hover {
  color: #b0b0b0;
}
menu .right.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}
.item--with-submenu {
  display: flex;
  align-items: center;
}
.item--with-submenu:hover .submenu {
  display: flex !important;
}
.submenu:hover {
  display: flex !important;
}
.submenu {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 40px;
  padding: 20px 20px 0 20px;
  margin-left: -20px;
  background: black;
}
.submenu .item {
  padding: 10px 0px;
  color: white;
  cursor: pointer;
}
.submenu .item:hover {
  color: #b0b0b0;
}
</style>

<style>
body {
  padding-top: 80px !important;
}
</style>
