<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des Réassort
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <b-table :data="upsellJobs">
        <b-table-column v-slot="props" field="label" label="Label">
          {{ props.row.label }}
        </b-table-column>
        <b-table-column v-slot="props" field="shipping_date" label="Date d'expedition">
          {{ formatDate(props.row.meta.shipping_date) }}
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info">
            Aucun réassort
          </b-message>
        </template>
      </b-table>
    </div>
  </div>
</template>

<style scoped>
.modal-card {
  width: 100%;
}
.container {
  padding: 0 0 10px 0;
}
.card-header-title > span {
  margin: 5px;
}
</style>
<script>
import { mapActions } from "vuex";
import { getJobsByClusterId } from "Api/job";
import { SET_ERROR_ACTION } from "Stores/message";
import { FOLLOW_SHIPPING_STATE } from "Constants";
import { formatDate, sortById } from "Utils";

export default {
  props: ["cluster"],
  computed: {
    upsellJobs() {
      return this.jobs.filter(j => j.label === FOLLOW_SHIPPING_STATE);
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION
    }),
    formatDate(date) {
      if (typeof date === "string") {
        date = new Date(date);
      }
      return formatDate(date);
    },
    loadJobs(cursor = null) {
      const { apiClient } = this.$store.getters;
      getJobsByClusterId(apiClient)(this.cluster.id, 300, { cursor })
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.jobs = this.jobs.concat(data);
            this.loadJobs(data[data.length - 1].id);
            return;
          }
          this.jobs = this.jobs.sort(sortById);
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des jobs`,
            error: e
          });
        });
    }
  },
  mounted() {
    this.loadJobs();
  },
  data() {
    return {
      jobs: []
    };
  }
};
</script>
