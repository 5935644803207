<template>
  <div>
    <b-message type="is-danger" v-show="showErrorMessage()"> Aucun cluster </b-message>
    <b-table
      v-show="clusterHistory.length > 0"
      :data="clusterHistory"
      :loading="tableLoading"
      :default-sort="['from_date', 'desc']"
      paginated
      per-page="20"
      :total="clusterHistory.length"
    >
      <b-table-column v-slot="props" field="name" label="Nom" sortable>
        <span v-if="!props.row.cluster_id"> {{ props.row.name }} </span>
        <router-link
          v-if="!!props.row.cluster_id"
          :to="'/cluster/' + props.row.cluster_id"
          class="tag is-large"
          target="_blank"
        >
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="from_date" label="Date de début" sortable>
        {{ formatDate(props.row.from_date) }}
      </b-table-column>
      <b-table-column v-slot="props" field="to_date" label="Date de fin" sortable>
        {{ formatDate(props.row.to_date) }}
      </b-table-column>
      <b-table-column v-slot="props" field="state" label="Statut de liaison" sortable>
        <b-select
          :loading="changeStateLoading[props.row.id]"
          @input="updateDeviceHasClusterState(props.row)"
          v-model="props.row.state"
        >
          <option v-bind:key="option" v-for="option in DEVICE_STATES_WITH_CLUSTER" :value="option" :disabled="option === UNDEFINED_DEVICE_STATE_WITH_CLUSTER">
            {{ fmtDeviceStateWithCluster(option) }}
          </option>
        </b-select>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { getClusterHistory } from "Api/device";
import { updateDeviceHasClusterState } from "Api/deviceHasCluster";
import { getClustersByIds } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";
import { UNDEFINED_DEVICE_STATE_WITH_CLUSTER, DEVICE_STATES_WITH_CLUSTER, DEVICE_STATES_WITH_CLUSTER_LABEL } from "Constants";

export default {
  props: ["device", "onCurrentDeviceHasClusterStateChange"],
  data() {
    return {
      clusterHistory: [],
      tableLoading: false,
      dataIsLoaded: false,
      UNDEFINED_DEVICE_STATE_WITH_CLUSTER,
      DEVICE_STATES_WITH_CLUSTER,
      changeStateLoading: {}
    };
  },
  mounted() {
    this.loadHistory();
  },
  methods: {
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    updateDeviceHasClusterState(dhc) {
      const { apiClient } = this.$store.getters;
      const { id, state, to_date } = dhc;
      this.$set(this.changeStateLoading, id, true);
      updateDeviceHasClusterState(apiClient)(id, state)
        .then(() => {
          this.$set(this.changeStateLoading, id, false);
          if ((!to_date || to_date.getUTCFullYear() === 1) && !!this.onCurrentDeviceHasClusterStateChange) {
            this.onCurrentDeviceHasClusterStateChange();
          }
          this.setSuccess({
            message: "Le statut du device a été mis à jour"
          });
        })
        .catch(e => {
          this.$set(this.changeStateLoading, id, false);
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    showErrorMessage() {
      return this.clusterHistory.length === 0 && this.dataIsLoaded;
    },
    loadHistory() {
      let history;
      const { apiClient } = this.$store.getters;
      const _this = this;
      this.tableLoading = true;
      getClusterHistory(apiClient)(this.device.id)
        .then(({ data }) => {
          if (data === null) {
            return {};
          }
          const clusterIDs = data.map(d => d.cluster_id);
          history = data;
          return getClustersByIds(apiClient)(clusterIDs);
        })
        .then(response => {
          this.tableLoading = false;
          this.dataIsLoaded = true;

          if (!history) {
            return;
          }
          const { data } = response;
          let clusters = {};
          data.forEach(d => (clusters[d.id] = d));
          _this.clusterHistory = history.map(h => {
            const cluster = clusters[h.cluster_id];
            return {
              id: h.id,
              name: cluster ? cluster.name : "-",
              cluster_id: h.cluster_id,
              from_date: h.from_date ? new Date(h.from_date) : null,
              to_date: h.to_date ? new Date(h.to_date) : null,
              state: h.state
            };
          });
          return;
        })
        .catch(e => {
          this.tableLoading = false;
          this.dataIsLoaded = true;
          this.setError({
            message: "Impossible de récupérer l'historique des clusters <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  watch: {
    device() {
      this.loadHistory();
    }
  }
};
</script>
