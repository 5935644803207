<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Importer une liste de beacons</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              Votre fichier doit être au format CSV avec comme séparateur une virgule [,]. Le fichier doit comporter 1
              colonne: uuid (obligatoire)
            </div>
          </div>
          <b-field label="">
            <b-upload v-model="csvFile" :native="true">
              <a class="button is-primary">
                <b-icon icon="upload"> </b-icon>
                <span>Selectionner votre fichier CSV</span>
              </a>
            </b-upload>
          </b-field>
          <p v-if="csvFile">
            Fichier choisi: <span class="tag">{{ csvFile.name }} </span>
          </p>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { importBeacons } from "Api/beacon";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

export default {
  props: ["isActive", "onSuccess", "onClose"],
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      this.csvFile = null;
      if (this.onClose) {
        this.onClose();
      }
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { csvFile } = this;
      const reader = new FileReader();
      reader.onload = () => {
        const lines = reader.result.split("\n");
        if (lines.length === 0) {
          return;
        }

        importBeacons(apiClient)(reader.result)
          .then(() => {
            this.setSuccess({
              message: "Les beacons ont bien été importés"
            });
            if (this.onSuccess !== undefined) {
              this.onSuccess();
            }
            this.closeModal();
          })
          .catch(e => {
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      };
      reader.readAsText(csvFile, "UTF-8");
    }
  },
  data() {
    return {
      csvFile: null
    };
  }
};
</script>
