import routesConfig from "Config/routes.json";
import * as Components from "Components/semantic-menu/MenuComponents";
import DeviceDetails from "Components/devices/DeviceDetails";
import { GET_USER_ME_ACTION } from "Stores/users";
import ClusterDetails from "../../components/clusters/ClusterDetails";
import UserDetails from "../../components/users/UserDetails";
import EquipmentDetails from "../../components/equipment/EquipmentDetails";
import BeaconDetails from "../../components/beacons/BeaconDetails";
import ChangePassword from "../../components/admin/ChangePassword";

export const IS_VUE_ROUTE_ACTION = "IS_VUE_ROUTE_ACTION";
export const GET_VUE_ROUTES_ACTION = "GET_VUE_ROUTES_ACTION";
export const GET_CONFIG_ROUTES_ACTION = "GET_CONFIG_ROUTES_ACTION";
export const SET_ROUTES_MUTATION = "SET_ROUTES_MUTATION";

const flattenSubMenu = (acc, route) => {
  acc = acc.concat([route]);
  if (route.subMenu) {
    acc = acc.concat(route.subMenu);
  }
  return acc;
};

const isVueJsRoute = route => route.componentName !== undefined;
const newRoute = path => component => ({ path, component });

const resolve = componentName => {
  const component = Components[componentName];

  if (component) {
    return component;
  }

  throw new Error(`Component ${componentName} not declared as a menu component`);
};

const isAuthorized = user => {
  return route => {
    if (!route.restricted) {
      return true;
    }

    return route.restricted.indexOf(user.application_role) != -1;
  };
};

const filterRoutes = user => {
  const filterUserAuthorization = isAuthorized(user);
  return routesConfig.filter(filterUserAuthorization).map(route => {
    if (route.subMenu) {
      route.subMenu = route.subMenu.filter(filterUserAuthorization);
    }

    return route;
  });
};

export default {
  state: {},
  mutations: {
    [SET_ROUTES_MUTATION](state, routes) {
      state.routes = routes;
    }
  },
  actions: {
    [IS_VUE_ROUTE_ACTION](_, route) {
      return isVueJsRoute(route);
    },
    async [GET_VUE_ROUTES_ACTION]({ dispatch }) {
      const routes = filterRoutes(await dispatch(GET_USER_ME_ACTION))
        .reduce(flattenSubMenu, [])
        .filter(isVueJsRoute)
        .map(route => newRoute(route.menuPath)(resolve(route.componentName)))
        .concat([
          newRoute("/device/:id")(DeviceDetails),
          newRoute("/cluster/:id")(ClusterDetails),
          newRoute("/user/:id")(UserDetails),
          newRoute("/equipment/:id")(EquipmentDetails),
          newRoute("/beacon/:uuid")(BeaconDetails),
          newRoute("/password")(ChangePassword),
          { path: "/", redirect: "/search" }
        ]);
      return routes;
    },

    async [GET_CONFIG_ROUTES_ACTION]({ dispatch }) {
      return filterRoutes(await dispatch(GET_USER_ME_ACTION));
    }
  }
};
