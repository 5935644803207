<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des exploitations membres ({{ this.memberClusters ? this.memberClusters.length : 0 }})
          </h1>
          <div class="buttons has-addons">
            <a class="button is-primary is-small" @click.prevent="showLinkModal()"> Lier des exploitations </a>
            <a class="button is-info is-small" @click.prevent="showImportModal()">
              Importer des exploitations membres
            </a>
            <b-button type="is-danger is-small" :loading="isLoading" @click.prevent="unlinkChildClusters()">
              Délier toutes les exploitations membres
            </b-button>
            <b-button type="is-primary is-small" :loading="isLoading" @click.prevent="showFusionModal()">
              Fusionner des exploitations membres
            </b-button>
            <b-button type="is-info is-small" :loading="isLoading" @click.prevent="showLinkMemberToAdditionalParent()">
              Lier les membres à une seconde exploitation parente
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <ClusterTable
        :clusters="memberClusters"
        :checked.sync="clustersChecked"
        :isUnlinkActive="true"
        :onUnLink="loadClusters"
        :parentCluster="cluster"
        :showParcelsCountColumn="true"
      />
      <LinkClusterModal
        :isActive="isLinkModalActive"
        :cluster="cluster"
        :onLink="loadClusters"
        :onClose="() => (isLinkModalActive = false)"
      />
      <ImportClusterMembersModal
        :isActive="isImportModalActive"
        :cluster="cluster"
        :onLink="loadClusters"
        :onClose="() => (isImportModalActive = false)"
      />
      <LinkMemberToAdditionalParentCluster
        :isActive="isLinkMemberToAdditionalParentClusterActive"
        :onClose="() => (isLinkMemberToAdditionalParentClusterActive = false)"
        :clustersChecked="clustersChecked"
      />
    </div>
    <b-modal :active="isFusionActive" :on-cancel="closeFusionModal">
      <form @submit.prevent="showFusionModal('fusion')">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Fusionner</p>
          </header>
          <section class="modal-card-body">
            <div class="columns is-centered is-vcentered">
              <div class="column field is-one-third">
                <div
                  class="field"
                  v-for="clusterChecked in clustersChecked.filter(c => c.id !== clusterDestination)"
                  :value="clusterChecked.id"
                  :key="clusterChecked.id"
                >
                  <b-tag type="is-primary" v-if="clusterChecked.from_application === 'karnott'">
                    {{ clusterChecked.name }} ( {{ clusterChecked.from_application }} )
                  </b-tag>
                  <b-tag type="is-danger" v-if="clusterChecked.from_application !== 'karnott'">
                    {{ clusterChecked.name }} ( {{ clusterChecked.from_application }} )
                  </b-tag>
                </div>
              </div>
              <div class="column is-one-third">Fusionner les exploitations vers...</div>
              <div class="column field is-one-third">
                <b-field label="">
                  <b-select v-model="clusterDestination" placeholder="Choisir l'exploitation de destination" expanded>
                    <option
                      v-for="clusterChecked in clustersChecked"
                      :value="clusterChecked.id"
                      :key="clusterChecked.id"
                    >
                      {{ clusterChecked.name }} ( {{ clusterChecked.from_application }} )
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeFusionModal">Annuler</a>
          <button class="button is-info">
            <span> Fusionner </span>
          </button>
        </footer>
      </form>
    </b-modal>
  </div>
</template>

<style scoped>
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  margin-left: 10px;
}
table {
  padding-bottom: 20px;
}
</style>
<script>
import { mapActions } from "vuex";
import { getMemberClusters, unlinkChildClusters, manageFusionOfClusters } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import ClusterTable from "Components/clusters/clusterDetailsComponents/ClusterTable";
import LinkClusterModal from "Components/clusters/clusterDetailsComponents/LinkClusterModal";
import ImportClusterMembersModal from "Components/clusters/clusterDetailsComponents/ImportClusterMembersModal";
import LinkMemberToAdditionalParentCluster from "Components/clusters/clusterDetailsComponents/LinkMemberToAdditionalParentCluster";

export default {
  props: ["cluster"],
  components: { ClusterTable, LinkClusterModal, ImportClusterMembersModal, LinkMemberToAdditionalParentCluster },
  mounted() {
    this.loadClusters();
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    loadClusters() {
      const { apiClient } = this.$store.getters;
      const { cluster } = this;
      getMemberClusters(apiClient)(cluster.id).then(({ data }) => {
        this.memberClusters = data;
      });
    },
    showLinkModal() {
      this.isLinkModalActive = true;
    },
    showImportModal() {
      this.isImportModalActive = true;
    },
    unlinkChildClusters() {
      this.$buefy.dialog.confirm({
        message: "Êtes-vous sûr de vouloir délier toutes les exploitations membres du client ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          this.isLoading = true;
          unlinkChildClusters(apiClient)(this.cluster.id)
            .then(() => {
              this.isLoading = false;
              this.setSuccess({
                message: "Les exploitations membres ont bien été déliées"
              });
              window.location.reload();
            })
            .catch(e => {
              this.setError({
                message: "Une erreur est survenue",
                error: e
              });
            });
        }
      });
    },
    showFusionModal(action) {
      if (this.clustersChecked.length === 0) {
        alert(`Il faut selectionner au moins un cluster.`);
        return;
      }
      if (action === "fusion") {
        if (!this.clustersChecked.find(c => c.id === this.clusterDestination)) {
          alert(`Il faut selectionner au moins un cluster de destination.`);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Êtes-vous sûr de vouloir fusionner les clusters selectionnés ?",
          onConfirm: () => {
            const { apiClient } = this.$store.getters;
            const params = {
              action: "fusion_clusters",
              to_cluster_id: this.clusterDestination,
              ids: this.clustersChecked.map(c => c.id).filter(cid => cid !== this.clusterDestination)
            };
            this.isLoading = true;
            manageFusionOfClusters(apiClient)(params)
              .then(() => {
                this.isLoading = false;
                this.setSuccess({
                  message: "Les exploitations ont bien été fusionnées"
                });
                window.location.reload();
              })
              .catch(e => {
                this.setError({
                  message: "Une erreur est survenue",
                  error: e
                });
              });
          }
        });
      } else {
        this.isFusionActive = true;
      }
    },
    closeFusionModal() {
      this.manageModalAction = "";
      this.isFusionActive = false;
    },
    showLinkMemberToAdditionalParent() {
      this.isLinkMemberToAdditionalParentClusterActive = true;
    }
  },
  data() {
    return {
      memberClusters: [],
      clustersChecked: [],
      clusterDestination: {},
      isLinkModalActive: false,
      isFusionActive: false,
      isImportModalActive: false,
      isLinkMemberToAdditionalParentClusterActive: false,
      isLoading: false
    };
  }
};
</script>
