import { getDeviceHealths } from "Api/deviceHealth";

export const SET_DEVICE_HEALTHS_MUTATION = "SET_DEVICE_HEALTHS_MUTATION";

export const GET_DEVICE_HEALTHS_ACTION = "GET_DEVICE_HEALTHS_ACTION";

const module = {
  state: {
    deviceHealths: []
  },
  mutations: {
    [SET_DEVICE_HEALTHS_MUTATION](state, deviceHealths) {
      state.deviceHealths = deviceHealths || [];
    }
  },
  actions: {
    [GET_DEVICE_HEALTHS_ACTION]({ rootGetters, commit }) {
      const { apiClient } = rootGetters;
      return getDeviceHealths(apiClient).then(({ data }) => {
        data = data || [];
        commit(SET_DEVICE_HEALTHS_MUTATION, data);
        return data;
      });
    }
  }
};

export default module;
