import { getDevices, getDeviceById } from "Api/device";
import { getFirmwaresVersion, getFirmwares, updateFirmware } from "Api/firmware";

export const SET_FIRMWARES_MUTATION = "SET_FIRMWARES_MUTATION";
export const SET_FIRMWARES_VERSION_MUTATION = "SET_FIRMWARES_VERSION_MUTATION";
export const SET_DEVICES_MUTATION = "SET_DEVICES_MUTATION";

export const FETCH_FIRMWARES_ACTION = "FETCH_FIRMWARES_ACTION";
export const FETCH_FIRMWARES_VERSION_ACTION = "FETCH_FIRMWARES_VERSION_ACTION";
export const UPDATE_FIRMWARE_ACTION = "UPDATE_FIRMWARE_ACTION";
export const GET_DEVICES_ACTION = "GET_DEVICES_ACTION";
export const GET_DEVICE_BY_ID_ACTION = "GET_DEVICE_BY_ID_ACTION";

function fetchFirmwares(apiClient) {
  const req = getFirmwares(apiClient);

  return commit =>
    req.then(response => {
      const { data } = response;
      data.forEach(firmware => {
        firmware.fullVersion = getFullVersion(firmware);
        firmware.validation_date = new Date(Date.parse(firmware.validation_date));
      });
      commit(SET_FIRMWARES_MUTATION, data);
      return data;
    });
}

function fetchFirmwaresVersion(apiClient) {
  const req = getFirmwaresVersion(apiClient);

  return commit =>
    req.then(response => {
      const { data } = response;
      data.forEach(firmware => {
        firmware.fullVersion = getFullVersion(firmware);
      });
      commit(SET_FIRMWARES_VERSION_MUTATION, data);
      return data;
    });
}

let module = {
  state: {
    firmwares: [],
    firmwaresVersion: [],
    devices: []
  },
  mutations: {
    [SET_FIRMWARES_MUTATION](state, firmwares) {
      state.firmwares = firmwares || [];
    },
    [SET_FIRMWARES_VERSION_MUTATION](state, firmwaresVersion) {
      state.firmwaresVersion = firmwaresVersion || [];
    },
    [SET_DEVICES_MUTATION](state, devices) {
      state.devices = devices;
    }
  },
  actions: {
    [GET_DEVICE_BY_ID_ACTION]({ rootGetters }, id) {
      const { apiClient } = rootGetters;
      return getDeviceById(apiClient)(id);
    },
    [GET_DEVICES_ACTION]({ commit, rootGetters }) {
      const { apiClient } = rootGetters;
      return getDevices(apiClient).then(response => {
        const { data } = response;
        commit(SET_DEVICES_MUTATION, data);
        return data;
      });
    },
    [FETCH_FIRMWARES_ACTION]({ commit, rootGetters }) {
      const { apiClient } = rootGetters;
      return fetchFirmwares(apiClient)(commit);
    },
    [FETCH_FIRMWARES_VERSION_ACTION]({ commit, rootGetters }) {
      const { apiClient } = rootGetters;
      return fetchFirmwaresVersion(apiClient)(commit);
    },
    [UPDATE_FIRMWARE_ACTION]({ commit, rootGetters }, firmware) {
      const { apiClient } = rootGetters;
      return updateFirmware(apiClient)(firmware).then(() => getFirmwares(apiClient)(commit));
    }
  }
};

function getFullVersion({ major, minor, patch }) {
  return `${major}.${minor}.${patch}`;
}

export const BATTERY_HARDWARE_ID = {
  0: "ERROR",
  1: "BQ3060",
  2: "SH366006",
  3: "BQ4050",
  4: "SH366006_CAL",
  5: "BQ40z50"
};
export const BATTERY_THRESHOLDS = {
  MIN_SOH: 90,
  DEVIATION_THRESHOLD: 1.5
};

export default module;
