<template>
  <b-table
    :data="clusters || []"
    :checked-rows.sync="checkedData"
    :checkable="true"
    :per-page="perPage || 30"
    :paginated="true"
  >
    <b-table-column v-slot="props" field="id" label="ID" sortable> #{{ props.row.id }} </b-table-column>
    <b-table-column v-slot="props" field="name" label="Nom" sortable>
      <router-link :to="`/cluster/${props.row.id}`" class="button is-text"> {{ props.row.name }} </router-link>
    </b-table-column>
    <b-table-column v-slot="props" field="cluster_type" label="Type d'exploitation" sortable>
      {{ props.row.cluster_type }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="count_parcels"
      label="Nombre de parcelles"
      v-if="showParcelsCountColumn === true"
      sortable
    >
      {{ props.row.count_parcels }}
    </b-table-column>
    <b-table-column v-slot="props" field="from_application" label="Application d'origine" sortable>
      {{ props.row.from_application }}
    </b-table-column>
    <b-table-column v-slot="props" field="actions" label="Action" v-if="isUnlinkActive">
      <div class="buttons has-addons">
        <a class="button is-danger" @click.prevent="unlinkCluster(props.row)">
          <b-icon icon="link-off" size="is-small"></b-icon>
        </a>
      </div>
    </b-table-column>
    <template slot="empty">
      <b-message type="is-info"> Aucune exploitation </b-message>
    </template>
  </b-table>
</template>
<style></style>
<script>
import { unLinkClusterToCluster } from "Api/cluster";
export default {
  props: [
    "parentCluster",
    "childCluster",
    "clusters",
    "isUnlinkActive",
    "onUnLink",
    "checked",
    "showParcelsCountColumn",
    "perPage"
  ],
  methods: {
    unlinkCluster(cluster) {
      if (!this.parentCluster && !this.childCluster) {
        return;
      }

      this.$buefy.dialog.confirm({
        message: "Etes vous sûr ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          const { parentCluster, childCluster, onUnLink } = this;
          if (parentCluster) {
            unLinkClusterToCluster(apiClient)(parentCluster.id, cluster.id).then(() => {
              if (onUnLink) {
                onUnLink();
              }
            });
          }
          if (childCluster) {
            unLinkClusterToCluster(apiClient)(cluster.id, childCluster.id).then(() => {
              if (onUnLink) {
                onUnLink();
              }
            });
          }
        }
      });
    }
  },
  watch: {
    checkedData: function (val) {
      this.$emit("update:checked", val);
    }
  },
  data() {
    return {
      checkedData: []
    };
  }
};
</script>
