<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadBeaconData" @submit.prevent="loadBeaconData">
        <b-field grouped>
          <b-field label="Depuis le">
            <b-datepicker v-model="sessionFromDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
          <b-field label="Jusqu'au">
            <b-datepicker v-model="sessionToDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
          <div class="field button-field">
            <p class="control">
              <button class="button is-success">Rechercher</button>
            </p>
          </div>
        </b-field>
      </form>
    </div>
    <b-table
      v-show="beaconData.length > 0"
      :data="beaconData"
      :loading="tableLoading"
      :default-sort="['occurred_at', 'asc']"
      paginated
      per-page="20"
      :total="beaconData.length"
      :show-detail-icon="true"
      ref="table"
    >
      <b-table-column field="occurred_at" label="Date" v-slot="props" sortable>
        <template>
          {{ formatDate(props.row.occurred_at_formatted) }}
        </template>
      </b-table-column>
      <b-table-column field="device_instance_name" label="Karnott" v-slot="props" sortable>
        {{ props.row.device_instance_name }}
        <router-link v-if="props.row.device" class="button is-text" :to="`/device/${props.row.device.id}`">
          {{ props.row.device.serialnumber }}
        </router-link>
      </b-table-column>
      <b-table-column field="rssi" label="Minor" v-slot="props">
        {{ props.row.minor }}
      </b-table-column>
      <b-table-column field="rssi" label="RSSI" v-slot="props">
        {{ props.row.rssi }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { getBeaconData } from "Api/beacon";
import { formatDate, formatInputDates, formatToRFC3339 } from "Utils";
import { getDevicesByIds } from "Api/device";

export default {
  props: ["beacon"],
  data() {
    return {
      beaconData: [],
      tableLoading: false,
      sessionFromDate: null,
      sessionToDate: null
    };
  },
  mounted() {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 3);
    this.sessionFromDate = fromDate;

    this.sessionToDate = new Date();
    this.loadBeaconData();
  },
  methods: {
    truncateDatesFilter() {
      this.sessionFromDate.setHours(0);
      this.sessionFromDate.setMinutes(0);
      this.sessionFromDate.setSeconds(0);

      this.sessionToDate.setHours(23);
      this.sessionToDate.setMinutes(59);
      this.sessionToDate.setSeconds(59);
    },
    formatDate(date) {
      return formatDate(date);
    },
    loadBeaconData() {
      const { apiClient } = this.$store.getters;
      this.truncateDatesFilter();
      const { sessionFromDate, sessionToDate } = this;
      const { fromDate, toDate } = formatInputDates(sessionFromDate, sessionToDate);
      if (fromDate === null || toDate === null) {
        return;
      }
      this.tableLoading = true;
      getBeaconData(apiClient)(this.beacon.uuid, {
        from_date: formatToRFC3339(fromDate),
        to_date: formatToRFC3339(toDate)
      })
        .then(({ data }) => {
          this.tableLoading = false;
          if (data === null) {
            this.beaconData = [];
            return {};
          }

          data.forEach(d => {
            d.minor = `${d.minor} (hex: ${d.minor.toString(16)})`;
            d.occurred_at_formatted = d.occurred_at ? new Date(d.occurred_at) : null;
          });
          const deviceIdsObj = {};
          this.beaconData = data.map(d => {
            deviceIdsObj[d.device_instance_id] = true;
            return {
              ...d
            };
          });
          const deviceIds = Object.keys(deviceIdsObj);
          if (deviceIds.length === 0) {
            return { data: [] };
          }
          return getDevicesByIds(apiClient)(deviceIds);
        })
        .then(({ data: devices }) => {
          this.beaconData = this.beaconData.map(s => {
            const device = devices.find(d => d.id === s.device_instance_id);
            return {
              ...s,
              device
            };
          });
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError({
            message: "Impossible de récupérer les données<br />",
            error: e
          });
        });
    }
  },
  components: {}
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
.button-field {
  padding: 31px 0 11px;
}
</style>
