<template>
  <div v-if="equipment" class="section">
    <h1 class="title">
      {{ equipment.label }}
      <span v-if="device === null" class="tag is-large is-dark">
        Aucun karnott associé
      </span>
      <router-link v-if="device" class="tag is-large is-success" :to="'/device/' + device.id">
        {{ device.serialnumber }}
      </router-link>
    </h1>
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="column">
            <label class="label">Afficher les devices sessions filtrées par id d'equipement session </label>
            <b-field>
              <b-input v-model="equipmentSessionIdFilter"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </section>

    <section class="device-sessions">
      <div>
        <b-collapse
          class="card"
          v-for="(equipmentSessions, deviceSessionUuid) of reportsByDeviceSession"
          :key="deviceSessionUuid"
          :open="isOpen === deviceSessionUuid"
          @open="isOpen = deviceSessionUuid"
        >
          <template #trigger="props">
            <div class="card-header" role="button">
              <p class="card-header-title">
                <span
                  class="button is-danger delete-device-session"
                  @click.prevent="deleteDeviceSession(deviceSessionUuid)"
                >
                  <b-icon icon="delete"> </b-icon>
                </span>
                Device session n° {{ deviceSessionUuid }}
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <b-table :data="equipmentSessions" detailed :show-detail-icon="true" custom-detail-row>
                <b-table-column v-slot="props" field="id" label="ID Session" sortable>
                  <a :href="getEquipmentSessionLink(props.row.id)" class="button is-text is-black">
                    Equipement session n° {{ props.row.id }}
                  </a>
                </b-table-column>
                <b-table-column v-slot="props" field="device_instance_name" label="Karnott" sortable>
                  <router-link :to="`/devices/${props.row.device_instance_id}`" class="button is-text">
                    {{ props.row.device_instance_name }}
                  </router-link>
                </b-table-column>
                <b-table-column v-slot="props" field="period" label="Période" sortable>
                  {{ formatPeriod(props.row.from_date, props.row.to_date) }}
                </b-table-column>
                <b-table-column v-slot="props" field="motor_duration" label="Durée (minutes)" sortable>
                  {{ formatDuration(props.row.motor_duration) }}
                </b-table-column>
                <b-table-column v-slot="props" field="distance" label="Distance (km)" sortable>
                  {{ formatDistance(props.row.distance) }}
                </b-table-column>
                <b-table-column v-slot="props" field="area" label="Surface (ha)" sortable>
                  {{ formatArea(props.row.area) }}
                </b-table-column>
                <template v-slot:detail="props">
                  <template v-if="props.row.parcel_sessions && props.row.parcel_sessions.length > 0">
                    <tr v-for="parcelSession in props.row.parcel_sessions" :key="parcelSession.id">
                      <td></td>
                      <td>
                        <span class="tag is-warning"> Parcelle session n° {{ parcelSession.id }} </span>
                      </td>
                      <td>{{ props.row.device_instance_name.id }}</td>
                      <td>{{ formatPeriod(parcelSession.from_date, parcelSession.to_date) }}</td>
                      <td>{{ formatDuration(parcelSession.motor_duration) }}</td>
                      <td>{{ formatDistance(parcelSession.distance) }}</td>
                      <td>{{ formatArea(parcelSession.area) }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="7">
                        <b-message type="is-danger">
                          Aucune parcelles sessions
                        </b-message>
                      </td>
                    </tr>
                  </template>
                </template>
              </b-table>
            </div>
          </div>
        </b-collapse>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { getReports } from "Api/report";
import { getEquipmentById } from "Api/equipment";
import { getDeviceById } from "Api/device";
import { deleteDeviceSessionById } from "Api/deviceSession";
import { FRONT_URL } from "Constants";

export default {
  data() {
    return {
      isOpen: null,
      equipment: null,
      device: null,
      reports: [],
      equipmentSessionIdFilter: ""
    };
  },
  computed: {
    reportsByDeviceSession() {
      const data = {};
      const { equipmentSessionIdFilter, reports } = this;
      let filterRegex = null;
      if (equipmentSessionIdFilter && equipmentSessionIdFilter !== "") {
        filterRegex = new RegExp(equipmentSessionIdFilter);
      }
      reports.forEach(report => {
        report.equipment_sessions.forEach(equipmentSession => {
          const { device_session_uuid: deviceSessionUuid } = equipmentSession;
          let deviceSession = data[deviceSessionUuid] || [];
          data[deviceSessionUuid] = deviceSession.concat(equipmentSession);
        });
      });
      const filteredData = {};
      for (const deviceId in data) {
        if (filterRegex === null || data[deviceId].filter(e => filterRegex.test(e.id)).length > 0) {
          filteredData[deviceId] = data[deviceId].sort((a, b) => b.id - a.id);
        }
      }
      return filteredData;
    }
  },
  methods: {
    getEquipmentSessionLink(id) {
      if (FRONT_URL) {
        return `${FRONT_URL}/equipments/${this.equipment.id}/sessions/${id}`;
      }
      return "";
    },
    loadEquipment() {
      const { apiClient } = this.$store.getters;
      const { id } = this.$route.params;
      getEquipmentById(apiClient)(id)
        .then(({ data }) => {
          this.equipment = data;
          const { current_device_id: deviceId } = data;
          if (deviceId) {
            return getDeviceById(apiClient)(deviceId);
          } else {
            return { data: null };
          }
        })
        .then(({ data }) => {
          this.device = data;
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    loadReports() {
      const { apiClient } = this.$store.getters;
      const { id } = this.$route.params;
      getReports(apiClient)({
        withDetails: true,
        equipmentID: id
      })
        .then(({ data }) => {
          this.reports = data;
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    formatPeriod(fromDateString, toDateString) {
      const fromDate = new Date(fromDateString);
      const toDate = new Date(toDateString);
      return `Du ${fromDate.toLocaleString()} au ${toDate.toLocaleString()}`;
    },
    formatDuration(durationInSeconds) {
      return Math.round(durationInSeconds / 60);
    },
    formatDistance(distanceInMeters) {
      return Math.round(distanceInMeters / 10) / 100;
    },
    formatArea(areaInm2) {
      return Math.round(areaInm2 / 100) / 100;
    },
    deleteDeviceSession(uuid) {
      this.$buefy.dialog.confirm({
        message: "Etes vous sûr ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          deleteDeviceSessionById(apiClient)(uuid)
            .then(() => {
              this.equipmentSessionIdFilter = "";
              this.setSuccess({
                message: "La device session a bien été supprimée"
              });
              this.loadReports();
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  mounted() {
    this.loadEquipment();
    this.loadReports();
  }
};
</script>
<style scoped>
.delete-device-session {
  margin-right: 10px !important;
}
.label {
  color: #d3d3d3;
}
section.device-sessions {
  background: white;
}
</style>
