<template>
  <div>
    <b-table :loading="isLoading" :per-page="perPage" :data="beacons" paginated>
      <b-table-column field="serialnumber" label="UUID" v-slot="props" sortable>
        <router-link class="button is-text" :to="`/beacon/${props.row.uuid}`">
          {{ props.row.uuid }}
        </router-link>
      </b-table-column>
      <b-table-column field="status" label="Status" v-slot="props">
        <span>
          {{ props.row.status }}
        </span>
      </b-table-column>
      <b-table-column field="type" label="Type" v-slot="props" sortable>
        <span :class="`tag ${getBeaconTypeCssClassName(props.row.type)}`">
          {{ props.row.type }}
        </span>
      </b-table-column>
      <b-table-column field="cluster" label="Exploitation" v-slot="props" sortable>
        <span v-if="getClusterName(props.row) === '-'">
          {{ getClusterName(props.row) }}
        </span>
        <router-link
          v-if="getClusterName(props.row) !== '-'"
          :to="'/cluster/' + props.row.cluster_id"
          class="tag is-large"
          target="_blank"
        >
          {{ getClusterName(props.row) }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="current_state_with_cluster" label="Statut de liaison" sortable>
        <span>
          {{ props.row.current_state_with_cluster ? props.row.current_state_with_cluster : "-" }}
        </span>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Aucun beacon</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { getClusterName, getBeaconTypeCssClassName } from "Utils";

export default {
  props: ["clusters", "beacons", "perPage", "isLoading"],
  methods: {
    getBeaconTypeCssClassName(type) {
      return getBeaconTypeCssClassName(type);
    },
    getClusterName(beacon) {
      return getClusterName(beacon);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  }
};
</script>
