<template>
  <router-link v-if="entry.type === 'VUE_ROUTE'" v-bind:to="entry.menu.menuPath" class="item">
    {{ entry.menu.menuLabel }}
  </router-link>
  <MenuLink v-else-if="entry.type === 'RUBY_ROUTE'" :path="entry.menu.menuPath" :label="entry.menu.menuLabel" />
  <SubMenu v-else-if="entry.type === 'SUB_MENU'" :label="entry.menu.menuLabel" :subMenu="entry.menu.subMenu" />
</template>

<script>
import MenuLink from "Components/semantic-menu/MenuLink";
import SubMenu from "Components/semantic-menu/SubMenu";
import { IS_VUE_ROUTE_ACTION } from "Stores/routes";
import { mapActions } from "vuex";

export default {
  components: {
    MenuLink,
    SubMenu
  },
  props: ["menu"],
  name: "menu-item",
  mounted() {
    const m = this.menu;
    this.isVueRoute(m)
      .then(isVueRoute => (isVueRoute ? "VUE_ROUTE" : m.menuPath ? "RUBY_ROUTE" : m.subMenu ? "SUB_MENU" : "NONE"))
      .then(type => (this.entry = { type, menu: m }));
  },
  data() {
    return {
      entry: {}
    };
  },
  methods: mapActions({
    isVueRoute: IS_VUE_ROUTE_ACTION
  })
};
</script>

<style></style>
