<template>
  <LinkClusterToEntityModal
    title="Lier une exploitation au Beacon"
    successMessage="L'exploitation a bien été liée au Beacon"
    errorMessage="Impossible de lier le cluster au Beacon <br />"
    :linkAction="onSelectCluster"
    :closeModalAction="closeModalAction"
    :isActive="isActive"
    :askForEndDateForLending="false"
  />
</template>

<script>
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { linkBeaconToCluster } from "Api/beaconCluster";
import { mapActions } from "vuex";
import LinkClusterToEntityModal from "Components/clusters/LinkClusterToEntityModal";

export default {
  props: ["beacon", "isActive", "closeModalAction"],
  methods: {
    onSelectCluster(clusterId, state) {
      if (clusterId === undefined) {
        return;
      }
      const { apiClient } = this.$store.getters;
      return linkBeaconToCluster(apiClient)(this.beacon.uuid, clusterId, state);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  components: {
    LinkClusterToEntityModal
  }
};
</script>
