<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Saisir des numéros (ex: D21) de série">
        <b-taginput
          v-model="selectedKarnott"
          :data="filteredKarnott"
          autocomplete
          :allow-new="false"
          :allow-duplicates="false"
          field="serialnumber"
          icon="label"
          placeholder="Saisir un numéro de série"
          @typing="getFilteredSerialNumbers"
          :clear-on-select="true"
          ref="serialNumberInput"
        >
        </b-taginput>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-field>
        <button class="button is-info" @click="validate">
          <span>Valider</span>
        </button>
      </b-field>
    </footer>
  </div>
</template>

<script>
export default {
  name: "DeviceSelector",
  props: ["onValidate", "dataSource", "title", "preselection", "ro"],
  data() {
    return {
      filteredKarnott: this.dataSource,
      selectedKarnott: this.preselection
    };
  },
  methods: {
    validate() {
      this.onValidate(this.selectedKarnott).then(() => (this.selectedKarnott = []));
    },
    getFilteredSerialNumbers(text) {
      let updatedInputValue = text;
      const lc = text.toLowerCase();
      let serialNumbers = lc.split(",");
      if (serialNumbers.length === 1) {
        serialNumbers = lc.split(";");
      }
      this.filteredKarnott = this.dataSource.filter(d => {
        const serialnumberToLowerCase = d.serialnumber.toLowerCase();
        for (const i in serialNumbers) {
          if (serialnumberToLowerCase === serialNumbers[i]) {
            const alreadyAdded = this.selectedKarnott.find(
              ({ serialnumber }) => serialnumber.toLowerCase() === serialnumberToLowerCase
            );
            if (!alreadyAdded) {
              this.selectedKarnott.push(d);
            }
            const r = new RegExp(`${d.serialnumber},?`, "gi");
            updatedInputValue = updatedInputValue.replace(r, "");
            return false;
          }
          if (serialnumberToLowerCase.indexOf(serialNumbers[i]) >= 0) {
            return true;
          }
        }
        return false;
      });
      if (updatedInputValue !== text) {
        setTimeout(() => {
          // update input value if user copy/past list of serial number
          this.$refs.serialNumberInput.$el.querySelectorAll("input.input")[0].value = updatedInputValue;
        }, 10);
      }
    }
  }
};
</script>

<style scoped>
.modal-card-body {
  height: 300px;
}
</style>
