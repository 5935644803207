import qs from "qs";

const getDevices = client => client.get("/devices");
const getDevicesByIds = client => ids => client.get("/devices", { params: { id: ids } });
const getDevicesBySerialNumber = client => serialNumber => client.get(`/devices?serialnumber=${serialNumber}`);
const getDeviceById = client => id => client.get(`/devices/${id}`);
const getDeviceDataById = client => id => query => client.get(`/devices/${id}/data?${qs.stringify(query)}`);
const getClusterHistory = client => deviceID => client.get(`/devices/${deviceID}/cluster_history`);
const updateAssociateDate = client => (deviceID, date) =>
  client.put(`/devices/${deviceID}/update_associate_date`, {
    associate_date: date
  });
const getConfigV2ByMac = client => mac => client.get(`/devices/mac/${mac}/config_v2`);
const uploadDeviceConf = client => payload => client.put(`/devices/${payload.id}/config`, { ...payload });
const uploadDeviceConfV2 = client => id => payload =>
  client.put(`/devices/${id}/config_v2`, payload !== null ? { ...payload } : null);
const createDevices = client => payload => client.post("/devices/multi", payload);

const getDataCountByDeviceAndTcpSessionId = client => id => tcpSessionIds =>
  client.post(`/devices/${id}/data/count`, tcpSessionIds);

const getIotLogs = client => query => client.get(`/iot/logs?${qs.stringify(query)}`);

const getValidations = client => client.get("/devices/validation");
const getValidationsByDeviceId = client => id => client.get(`/devices/${id}/validation`);

const getBatteryValidations = client => client.get("/battery_validations");

const switchMaintenance = client => id => maintenance =>
  client.patch(`/devices/maintenance/${id}/${maintenance}`, { id, maintenance });

const getDeviceMonitoring =
  client =>
  (query = {}) =>
    client.get(`/device_monitoring`, { params: { ...query } });
const archiveDeviceMonitoring = client => id => client.patch(`/device_monitoring/${id}/archive`);
const bulkArchiveDeviceMonitoring = client => payload => client.post(`/device_monitoring/archive`, payload);

const changeStatus =
  client =>
  ({ deviceInstanceIds, status }) =>
    client.patch(`/devices/status/${status}/multi`, deviceInstanceIds);

const getClusterDevices =
  client =>
  (clusterId, query = {}) =>
    client.get(`/clusters/${clusterId}/devices`, { params: { ...query } });

const searchDevices =
  (client, token = null, abortController = null) =>
  query => {
    return client
      .get(`/devices`, { params: { ...query }, signal: abortController, cancelToken: token })
      .then(res => Promise.resolve(res))
      .catch(err => {
        if (err.code === "ERR_CANCELED") {
          return Promise.resolve({ data: [], status: 499 });
        }
        return Promise.reject(err);
      });
  };
const getAnalysis = client => deviceId => client.get(`/devices/${deviceId}/analysis`);

const createAnalysis = client => deviceId => analysis => client.post(`/devices/${deviceId}/analysis`, analysis);
const updateAnalysis = client => analysis => client.patch(`/devices/analysis`, analysis);

const getDiagnostic = client => (id, params) => client.get(`/devices/${id}/diagnostics`, { params });

const importKarnottDataFile =
  client =>
  (deviceId, body, testMode = false) =>
    client.post(`/devices/${deviceId}/import_data_file?test_mode=${testMode}`, body);

const updateDeviceName = client => (deviceId, name) => client.put(`/devices/${deviceId}`, { name: name });

const getHubspotTickets = client => deviceId => client.get(`/devices/${deviceId}/hubspot_tickets`);
const getDeviceInstanceStatusHistory = client => deviceId => client.get(`/devices/${deviceId}/status_history`);

const updateBatteryStatus =
  client =>
  ({ deviceInstanceId, batteryStatus }) =>
    client.patch(`/device_instances/${deviceInstanceId}/battery_status`, { battery_status: batteryStatus });

export {
  archiveDeviceMonitoring,
  bulkArchiveDeviceMonitoring,
  changeStatus,
  createAnalysis,
  createDevices,
  getAnalysis,
  getBatteryValidations,
  getClusterDevices,
  getClusterHistory,
  getConfigV2ByMac,
  getDataCountByDeviceAndTcpSessionId,
  getDeviceById,
  getDeviceDataById,
  getDeviceInstanceStatusHistory,
  getDeviceMonitoring,
  getDevices,
  getDevicesByIds,
  getDevicesBySerialNumber,
  getDiagnostic,
  getHubspotTickets,
  getIotLogs,
  getValidations,
  getValidationsByDeviceId,
  importKarnottDataFile,
  searchDevices,
  switchMaintenance,
  updateAnalysis,
  updateAssociateDate,
  updateBatteryStatus,
  updateDeviceName,
  uploadDeviceConf,
  uploadDeviceConfV2
};
