<template>
  <b-modal :active="stateName !== null" :on-cancel="closeStateModal">
    <form @submit.prevent="submitNewState">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="stateName === APPOINTMENT_PLANNED_STATE">Planifier un rendez vous</p>
          <p class="modal-card-title" v-else>Passé à l'état: {{ fmtState(stateName) }}</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Commentaire">
            <b-input type="textarea" v-model="newSelectedState.comment"></b-input>
          </b-field>

          <b-field v-for="meta in getMetaKeys(stateName)" :key="meta.label" :label="meta.label">
            <b-input
              v-if="meta.type !== 'multiple' && meta.type !== 'select'"
              :type="meta.type"
              v-model="newSelectedState.meta[meta.name]"
            ></b-input>
            <b-taginput
              v-if="meta.type === 'multiple' || meta.type === 'select'"
              :maxtags="meta.type === 'select' ? 1 : 100"
              :allow-new="false"
              :data="meta.data"
              autocomplete
              :open-on-focus="true"
              @typing="t => (meta.filterAction ? meta.filterAction(t) : false)"
              v-model="newSelectedState.meta[meta.name]"
            ></b-taginput>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeStateModal">Annuler</a>
        <button class="button" :class="cssClassByState(stateName)">
          {{ fmtActionButton(stateName) }}
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<style scoped>
.modal-card {
  width: 100%;
}
</style>
<script>
import { mapActions } from "vuex";
import { createClusterState } from "Api/clusterState";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import {
  ONBOARDING_1_STATE,
  ONBOARDING_2_STATE,
  ADOPTION_STATE,
  EXPANSION_STATE,
  ADVOCACY_STATE,
  CHURN_STATE,
  GHOST_STATE,
  TRIAL_STATE,
  NOT_CONVERTED_STATE,
  APPOINTMENT_PLANNED_STATE,
  CLUSTER_STATE_STATE_LABEL,
  CLUSTER_STATE_CSS_CLASS,
  CLUSTER_STATE_ICON_CLASS
} from "Constants";

const fmtError = (error, message) => {
  return { error, message };
};

export default {
  props: ["cluster", "adminUsers", "supportTeamUsers", "onClose", "stateName", "afterCreate"],
  computed: {
    filteredClusterUsers() {
      const { adminUsers, clusterUsersFilter } = this;
      if (clusterUsersFilter === null) {
        return adminUsers;
      }
      const r = new RegExp(clusterUsersFilter);
      return adminUsers.filter(u => r.test(u.email));
    },
    karnottUsers() {
      const { supportTeamUsers, karnottUsersFilter } = this;
      if (karnottUsersFilter === null) {
        return supportTeamUsers;
      }
      const r2 = new RegExp(karnottUsersFilter);
      return supportTeamUsers.filter(u => r2.test(u.email));
    },
    metaKeys() {
      return {
        [ONBOARDING_1_STATE]: [
          {
            name: "to_emails",
            label: "Email pour la communication",
            data: (this.filteredClusterUsers || []).map(u => u.email),
            type: "multiple"
          }
        ],
        [APPOINTMENT_PLANNED_STATE]: [
          {
            name: "meeting_date",
            type: "datetime-local",
            label: "Date de rdv"
          },
          {
            name: "trainer_email",
            type: "select",
            label: "Email du formateurs",
            data: (this.karnottUsers || []).map(u => u.email),
            filterAction: this.setKarnottUsersFilter
          },
          {
            name: "to_emails",
            type: "multiple",
            label: "Email pour la communication",
            data: (this.filteredClusterUsers || []).map(u => u.email),
            filterAction: this.setClusterUsersFilter
          }
        ],
        [ONBOARDING_2_STATE]: [
          {
            name: "to_emails",
            label: "Email pour la communication",
            type: "multiple",
            data: (this.filteredClusterUsers || []).map(u => u.email),
            filterAction: this.setClusterUsersFilter
          }
        ],
        [EXPANSION_STATE]: [
          {
            name: "to_emails",
            label: "Email pour la communication",
            type: "multiple",
            data: (this.filteredClusterUsers || []).map(u => u.email),
            filterAction: this.setClusterUsersFilter
          }
        ],
        [ADOPTION_STATE]: [
          {
            name: "to_emails",
            type: "multiple",
            label: "Email pour la communication",
            data: (this.filteredClusterUsers || []).map(u => u.email),
            filterAction: this.setClusterUsersFilter
          }
        ],
        [ADVOCACY_STATE]: [],
        [CHURN_STATE]: [],
        [GHOST_STATE]: [],
        [TRIAL_STATE]: [],
        [NOT_CONVERTED_STATE]: []
      };
    }
  },
  methods: {
    submitNewState() {
      this.newSelectedState.state = this.stateName;
      const clonedState = JSON.parse(JSON.stringify(this.newSelectedState)); // clone
      if (clonedState.meta.meeting_date) {
        // need to format date for mailjet
        const date = new Date(clonedState.meta.meeting_date);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        clonedState.meta.meeting_date = date.toISOString();
      }
      if (clonedState.meta.to_emails) {
        clonedState.meta.to_emails = clonedState.meta.to_emails.join(",");
      }
      if (clonedState.meta.trainer_email) {
        clonedState.meta.trainer_email = clonedState.meta.trainer_email.join(",");
      }
      const { apiClient } = this.$store.getters;
      createClusterState(apiClient)(this.cluster.id, clonedState.state, clonedState)
        .then(() => {
          this.setSuccess({ message: "État de l'exploitation créé" });
          this.closeStateModal();
          if (this.afterCreate) {
            this.afterCreate();
          }
          this.onNewState && this.onNewState();
        })
        .catch(e => this.setError(fmtError(e, "Erreur pour lors de la création")));
    },
    fmtState(state) {
      return CLUSTER_STATE_STATE_LABEL[state];
    },
    fmtActionButton(state) {
      return this.fmtState(state);
    },
    cssClassByState(state) {
      return CLUSTER_STATE_CSS_CLASS[state];
    },
    iconClassByState(state) {
      return CLUSTER_STATE_ICON_CLASS[state];
    },
    getMetaKeys(state) {
      return this.metaKeys[state];
    },
    setKarnottUsersFilter(text) {
      this.karnottUsersFilter = text;
    },
    setClusterUsersFilter(text) {
      this.clusterUsersFilter = text;
    },
    closeStateModal() {
      if (this.onClose) {
        this.onClose();
      }
      this.resetState();
    },
    resetState() {
      this.newSelectedState = {
        state: null,
        meta: {}
      };
      this.setKarnottUsersFilter(null);
      this.setClusterUsersFilter(null);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  data() {
    return {
      karnottUsersFilter: null,
      clusterUsersFilter: null,
      newSelectedState: {
        state: null,
        meta: {}
      },
      APPOINTMENT_PLANNED_STATE: APPOINTMENT_PLANNED_STATE
    };
  }
};
</script>
