import { getClusters } from "Api/cluster";

export const SET_CLUSTERS_MUTATION = "SET_CLUSTERS_MUTATION";

export const GET_CLUSTERS_ACTION = "GET_CLUSTERS_ACTION";

let module = {
  state: {
    clusters: []
  },
  mutations: {
    [SET_CLUSTERS_MUTATION](state, clusters) {
      state.clusters = clusters || [];
    }
  },
  actions: {
    [GET_CLUSTERS_ACTION]({ rootGetters, commit }) {
      const { apiClient } = rootGetters;
      return getClusters(apiClient).then(({ data }) => {
        data = data || [];
        commit(SET_CLUSTERS_MUTATION, data);
        return data;
      });
    }
  }
};

export default module;
