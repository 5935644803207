<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des matériels disponibles ({{ availableEquipments.length }})</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="buttons">
        <b-button type="is-danger" :loading="isLoading" @click.prevent="showManageModal('delete')">
          Supprimer
        </b-button>
        <b-button type="is-info" :loading="isLoading" @click.prevent="showManageModal('copy')"> Dupliquer </b-button>
      </div>
      <b-table :data="availableEquipments" :checked-rows.sync="checked" checkable>
        <b-table-column v-slot="props" field="id" label="ID" sortable searchable> #{{ props.row.id }} </b-table-column>
        <b-table-column v-slot="props" field="label" label="Nom" sortable searchable>
          <a target="_blank" :href="equipmentUrl(props.row.id)" class="button is-text">
            {{ props.row.label }}
          </a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="equipment_model_id"
          label="Type"
          sortable
          :custom-sort="equipmentModelSort"
        >
          {{ getEquipmentModelLabel(props.row.equipment_model_id) }}
        </b-table-column>
        <b-table-column v-slot="props" field="width" label="Largeur" sortable>
          {{ props.row.width }}
        </b-table-column>
        <b-table-column v-slot="props" field="duration_pretty" label="Temps entre deux calculs" sortable>
          {{ props.row.duration_pretty }}
        </b-table-column>
        <b-table-column v-slot="props" field="billing_method" label="Méthode de facturation" sortable>
          {{ props.row.billing_method }}
        </b-table-column>
        <b-table-column v-slot="props" field="from_application" label="From application" sortable>
          {{ props.row.from_application }}
        </b-table-column>
        <b-table-column v-slot="props" field="actions" label="Action">
          <div class="buttons has-addons">
            <a class="button is-primary" @click.prevent="openModalFor(props.row)"> Modifier </a>
            <router-link class="button is-light" :to="`/equipment/${props.row.id}`"> Voir les sessions </router-link>
          </div>
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucun matériel </b-message>
        </template>
      </b-table>
    </div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des matériels supprimés ({{ deletedEquipments.length }})</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <b-table :data="deletedEquipments" :checked-rows.sync="checked" checkable>
        <b-table-column v-slot="props" field="id" label="ID"> #{{ props.row.id }} </b-table-column>
        <b-table-column v-slot="props" field="name" label="Nom">
          <a target="_blank" :href="equipmentUrl(props.row.id)" class="button is-text">
            {{ props.row.label }}
          </a>
        </b-table-column>
        <b-table-column v-slot="props" field="type" label="Type">
          {{ getEquipmentModelLabel(props.row.equipment_model_id) }}
        </b-table-column>
        <b-table-column v-slot="props" field="width" label="Largeur">
          {{ props.row.width }}
        </b-table-column>
        <b-table-column v-slot="props" field="duration" label="Temps entre deux calculs">
          {{ props.row.duration_pretty }}
        </b-table-column>
        <b-table-column v-slot="props" field="billing_method" label="Méthode de facturation">
          {{ props.row.billing_method }}
        </b-table-column>
        <b-table-column v-slot="props" field="from_application" label="From application">
          {{ props.row.from_application }}
        </b-table-column>
        <b-table-column v-slot="props" field="actions" label="Action">
          <div class="buttons has-addons">
            <a class="button is-danger" @click.prevent="undelete(props.row)"> Annuler la suppression </a>
          </div>
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucun matériel </b-message>
        </template>
      </b-table>
    </div>
    <b-modal :active="isModalActive" :on-cancel="closeModal">
      <form @submit.prevent="submitEditForm">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Modifier l'équipment <span class="tag is-info"> {{ equipmentToEdit.label }} </span>
            </p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <b-field label="Largeur">
                  <b-input v-model="equipmentToEdit.width" />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Type">
                  <b-select v-model="equipmentToEdit.equipment_model_id">
                    <option v-for="model in equipmentModels" :key="model.id" :value="model.id">
                      {{ model.type }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column is-one-third">
                <b-field label="Duration">
                  <b-input v-model="equipmentToEdit.duration" />
                </b-field>
              </div>
              <div class="column">
                <div class="content">
                  <p>Le temps entre 2 calculs exprimé en secondes. Par exemple (bon pour copier/coller):</p>
                  <ul>
                    <li>24 heures : 86400</li>
                    <li>2 jours : 172800</li>
                    <li>7 jours : 604800</li>
                    <li>21 jours : 1814400</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column is-one-third">
                <b-field label="GPIO Default Status">
                  <b-radio v-model="equipmentToEdit.gpio_default_status" name="gpio_default_status" native-value="true">
                    1
                  </b-radio>
                  <b-radio
                    v-model="equipmentToEdit.gpio_default_status"
                    name="gpio_default_status"
                    native-value="false"
                  >
                    0
                  </b-radio>
                </b-field>
              </div>
              <div class="column">
                <div class="content">
                  <p>
                    C'est la valeur "au repos" du capteur comptage. Par exemple, une presse, au repos le capteur est
                    aligné donc à 1.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column is-one-third">
                <b-field label="GPIO Méthode de comptage">
                  <b-select v-model="equipmentToEdit.gpio_default_unit_method">
                    <option value="unit">Unité</option>
                    <option value="time">En Temps</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <div class="content">
                  <p>
                    Quoi compter ? Des unités ou de la durée pendant laquelle le contact sec est différent du GPIO
                    default Status ?
                  </p>
                </div>
              </div>
            </div>
            <hr v-if="equipmentToEdit.gpio_default_unit_method === 'unit'" />
            <div class="columns" v-if="equipmentToEdit.gpio_default_unit_method === 'unit'">
              <b-field label="GPIO Detection Range Duration">
                <div class="column">
                  <b-numberinput v-model="equipmentToEdit.minGpioDetectionRange" :min="0"></b-numberinput>
                </div>
                <div class="column">
                  <b-numberinput
                    v-model="equipmentToEdit.maxGpioDetectionRange"
                    controls-position="right"
                    :min="0"
                  ></b-numberinput>
                </div>
                <div class="column">
                  <div class="content">
                    <p>
                      C'est la durée (en s), pertinente pendant laquelle le capteur change d'état pour compter une unité
                    </p>
                  </div>
                </div>
              </b-field>
            </div>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
          <b-button class="button is-info" :loading="isLoading" native-type="submit" v-bind="submitButtonProps"
            >Modifier</b-button
          >
        </footer>
      </form>
    </b-modal>
    <b-modal :active="isManageModalActive" :on-cancel="closeManageModal">
      <form @submit.prevent="manageListedEquipmentsOfCluster">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Dupliquer vers</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Exploitation">
              <b-taginput
                :allow-new="false"
                :maxtags="1"
                :data="clusters"
                :allow-duplicates="false"
                field="name"
                icon="label"
                placeholder="Selectionner une exploitation"
                autocomplete
                @typing="filterAction"
                @add="c => (manageEquipment.cluster_id = c.id)"
                @remove="() => (manageEquipment.cluster_id = cluster.id)"
              >
              </b-taginput>
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeManageModal">Annuler</a>
          <button class="button is-info">
            <span> Dupliquer </span>
          </button>
        </footer>
      </form>
    </b-modal>
  </div>
</template>

<style scoped>
.modal-card {
  min-height: 370px;
  width: 100%;
}
.modal-card-body {
  overflow: scroll;
}
</style>
<script>
import { mapActions } from "vuex";
import { searchClusters } from "Api/cluster";
import {
  getClusterEquipment,
  manageListedEquipmentsOfCluster,
  updateEquipment,
  undeleteEquipment
} from "Api/equipment";
import { getEquipmentModels } from "Api/equipmentModel";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { FRONT_URL } from "Constants";
import { callApiWithSignal } from "Utils";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

let searchClustersTimeout = null;
let searchClustersSignal = null;
export default {
  props: ["cluster"],
  computed: {
    submitButtonProps() {
      if (this.formIsValid()) {
        return {};
      }
      return { disabled: true };
    },
    availableEquipments() {
      return (this.equipment || []).filter(e => e.status.toLowerCase() !== "deleted");
    },
    deletedEquipments() {
      return (this.equipment || []).filter(e => e.status.toLowerCase() === "deleted");
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    undelete(equipment) {
      const { apiClient } = this.$store.getters;
      undeleteEquipment(apiClient)(equipment.id)
        .then(() => {
          this.setSuccess({
            message: "Suppression annulée"
          });
          this.loadEquipment();
          this.closeModal();
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    formIsValid() {
      const { width, equipment_model_id } = this.equipmentToEdit;
      const equipmentModelIDsWithNOWidth = [
        "tractor",
        "misc",
        "remork",
        "snow_removal",
        "chenillard",
        "recolt",
        "enjambeur"
      ].map(type => {
        const models = this.equipmentModels.filter(e => e.type === type);
        if (models.length === 0) {
          return null;
        }
        return models[0].id;
      });
      if (equipmentModelIDsWithNOWidth.filter(id => id === equipment_model_id).length > 0) {
        return true;
      }
      return width > 0;
    },
    equipmentUrl(id) {
      return `${FRONT_URL}/equipments/${id}`;
    },
    getEquipmentModelLabel(equipmentModelId) {
      const models = this.equipmentModels.filter(e => e.id === equipmentModelId);
      if (models.length === 0) {
        return "-";
      }
      return models[0].type || "-";
    },
    openModalFor(equipment) {
      this.isModalActive = true;
      this.equipmentToEdit = JSON.parse(JSON.stringify(equipment)); //clone object
      this.equipmentToEdit.minGpioDetectionRange = equipment.min_gpio_detection_range;
      this.equipmentToEdit.maxGpioDetectionRange = equipment.max_gpio_detection_range;
    },
    closeModal() {
      this.isModalActive = false;
      this.equipmentToEdit = {};
    },
    submitEditForm() {
      const { equipmentToEdit } = this;
      const { apiClient } = this.$store.getters;
      if (equipmentToEdit.minGpioDetectionRange > equipmentToEdit.maxGpioDetectionRange) {
        this.setError({
          message: "La valeur min du Range de comptage ne peut pas être supérieure à la valeur max"
        });
        return;
      }
      this.isLoading = true;
      updateEquipment(apiClient)(equipmentToEdit.id, {
        width: parseFloat(`${equipmentToEdit.width}`.replace(",", ".")),
        equipment_model_id: equipmentToEdit.equipment_model_id,
        duration: equipmentToEdit.duration ? parseInt(equipmentToEdit.duration) : null,
        gpio_default_status:
          equipmentToEdit.gpio_default_status === "true" || equipmentToEdit.gpio_default_status === true,
        min_gpio_detection_range: equipmentToEdit.minGpioDetectionRange,
        max_gpio_detection_range: equipmentToEdit.maxGpioDetectionRange,
        gpio_default_unit_method: equipmentToEdit.gpio_default_unit_method
      })
        .then(() => {
          this.isLoading = false;
          this.setSuccess({
            message: "Equipement modifié"
          });
          this.loadEquipment();
          this.closeModal();
        })
        .catch(e => {
          this.isLoading = false;
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    loadEquipmentModels() {
      const { apiClient } = this.$store.getters;
      getEquipmentModels(apiClient)
        .then(({ data }) => {
          this.equipmentModels = data || [];
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des equipmentModel`,
            error: e
          });
        });
    },
    loadEquipment() {
      const { apiClient } = this.$store.getters;
      getClusterEquipment(apiClient)(this.cluster.id)
        .then(({ data }) => {
          this.equipment = (data || [])
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .map(e => {
              return {
                ...e,
                duration_pretty: e.duration ? moment.duration(e.duration, "seconds").format("d [days] hh:mm:ss") : null
              };
            });
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des equipements`,
            error: e
          });
        });
    },
    filterAction(q) {
      const { apiClient } = this.$store.getters;
      clearTimeout(searchClustersTimeout);
      if (searchClustersSignal) {
        searchClustersSignal.cancel();
      }
      searchClustersTimeout = setTimeout(() => {
        const { apiFunc, signal } = callApiWithSignal(searchClusters, apiClient);
        searchClustersSignal = signal;
        apiFunc({ name: q })
          .then(({ data }) => {
            this.clusters = data;
          })
          .catch(e => {
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    },
    showManageModal(action) {
      if (this.checked.length === 0) {
        alert(`Il faut selectionner au moins un équipment.`);
        return;
      }
      if (action === "delete") {
        this.$buefy.dialog.confirm({
          message: "Êtes-vous sûr de vouloir archiver les équipments selectionnés ?",
          onConfirm: () => {
            this.manageModalAction = "delete";
            this.manageListedEquipmentsOfCluster();
          }
        });
      } else {
        this.manageModalAction = action;
        this.isManageModalActive = true;
      }
    },
    closeManageModal() {
      this.manageModalAction = "";
      this.isManageModalActive = false;
    },
    manageListedEquipmentsOfCluster() {
      const { apiClient } = this.$store.getters;
      const equipmentIDs = this.checked.map(d => d.id);
      const { manageEquipment } = this;
      const { cluster_id } = manageEquipment;
      const params = {
        action: this.manageModalAction + "_equipments",
        to_cluster_id: cluster_id,
        ids: equipmentIDs
      };
      this.isLoading = true;
      manageListedEquipmentsOfCluster(apiClient)(params)
        .then(() => {
          this.isLoading = false;
          this.setSuccess({
            message: "Les équipments ont bien été modifiées"
          });
          window.location.reload();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    equipmentModelSort(a, b, isAsc) {
      const order = isAsc ? 1 : -1;
      return (
        (this.getEquipmentModelLabel(a.equipment_model_id).toLowerCase() <
        this.getEquipmentModelLabel(b.equipment_model_id).toLowerCase()
          ? -1
          : 1) * order
      );
    }
  },
  mounted() {
    this.loadEquipmentModels();
    this.loadEquipment();
  },
  data() {
    return {
      checked: [],
      clusters: [],
      equipment: [],
      equipmentModels: [],
      equipmentToEdit: {},
      isLoading: false,
      isManageModalActive: false,
      isModalActive: false,
      manageModalAction: "",
      manageEquipment: []
    };
  }
};
</script>
