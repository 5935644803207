<template>
  <b-modal :active="isActive" :on-cancel="closeModal" :can-cancel="canCancelOpts">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Ajouter un ou plusieurs {{ titleLabel }}</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              Pour ajouter un utilisateur, il est important de le sélectionner dans la liste (en cliquant sur l'email ou
              en déplacant le curseur et en appuyant sur la touche 'Entrée').
              <div v-if="isAdminModal()">
                Il est possible de créer de nouveaux utilisateurs :
                <ul>
                  <li>
                    1 - Il faut écrire le mail de votre utilisateur et appuyer sur 'Entrée'
                  </li>
                  <li>
                    2 - Définir le nom de ce nouvel utilisateur et appuyer sur 'Entrée'
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <b-field
            :label="fieldLabel"
            :message="fieldMessage()"
            class="emails"
            :class="isDefineFullNameActive || isDefineRole ? 'hide' : 'show'"
          >
            <b-taginput
              :allow-new="allowNewUser"
              :data="filteredUsersWithLabel"
              :allow-duplicates="false"
              :before-adding="beforeAdding"
              field="label"
              icon="label"
              :placeholder="placeholderLabel"
              autocomplete
              v-model="selectedUsers"
              @typing="filterAction"
              @add="onAdd"
              ref="emailInput"
            >
            </b-taginput>
          </b-field>
          <b-field
            class="role"
            label="Définir le role"
            :class="isDefineFullNameActive || isDefineRole ? 'show' : 'hide'"
          >
            <b-select v-model="selectedRole" @input="selectRole" expanded>
              <option v-for="role in roles" :key="role" :value="role">
                {{ role }}
              </option>
            </b-select>
          </b-field>
          <b-field class="firstname" :class="isDefineFullNameActive ? 'show' : 'hide'">
            <template slot="label">
              Définir le prénom de
              <span class="tag is-info">{{ newEmail }}</span>
            </template>
            <b-input
              v-model="newFirstName"
              ref="firstnameInput"
              @keydown.native.enter.stop.prevent="setFullname"
              @keydown.native.esc.stop.prevent="hideFullnameInput"
            />
          </b-field>
          <b-field class="lastname" :class="isDefineFullNameActive ? 'show' : 'hide'">
            <template slot="label">
              Définir le nom de
              <span class="tag is-info">{{ newEmail }}</span>
            </template>
            <b-input
              v-model="newLastName"
              ref="lastnameInput"
              @keydown.native.enter.stop.prevent="setFullname"
              @keydown.native.esc.stop.prevent="hideFullnameInput"
            />
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<style scoped>
.modal-card {
  min-height: 250px;
  width: 100%;
}
.emails,
.lastname,
.firstname,
.role {
  transition: opacity 0.3s;
}
.show {
  opacity: 1;
}
.role.hide,
.firstname.hide,
.lastname.hide {
  height: 0px;
  opacity: 0;
}
.emails.hide {
  opacity: 0.1;
}
</style>
<script>
import { mapActions } from "vuex";
import { callApiWithSignal } from "Utils";
import { searchUsers, createUserAndLinkToCluster } from "Api/user";
import { linkUserToCluster } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { ADMIN_USER_ROLE } from "Constants";

let timeout = null;
const emailReg = /.*@.*?\.\w{2,4}/;
const formatLabel = user => {
  let label = "";
  if (user.role) {
    label = `[${user.role}] `;
  }
  return `${label}${user.email} (${user.fullname})`;
};

export default {
  props: ["cluster", "titleLabel", "roles", "isActive", "onLink", "onClose", "allowNewUser"],
  computed: {
    filteredUsersWithLabel() {
      return this.filteredUsers.map(u => {
        return {
          ...u,
          label: formatLabel(u)
        };
      });
    },
    canCancelOpts() {
      if (this.disableEscCancelOpts) {
        return ["x", "outside"];
      }
      return ["escape", "x", "outside"];
    },
    fieldLabel() {
      if (this.allowNewUser) {
        return "Selectionner/Ajouter les utilisateurs";
      } else {
        return "Selectionner les utilisateurs";
      }
    },
    placeholderLabel() {
      if (this.allowNewUser) {
        return "Rechercher/Ajouter un email";
      } else {
        return "Rechercher un email";
      }
    }
  },
  methods: {
    isAdminModal() {
      return this.roles.length === 1 && this.roles[0] === ADMIN_USER_ROLE;
    },
    fieldMessage() {
      const { selectedUsers } = this;
      let message = `${selectedUsers.length} utilisateur(s) selectionné(s)`;
      if (this.isAdminModal()) {
        const newUsersCount = selectedUsers.filter(u => !u.id).length;
        message += ` (dont ${newUsersCount} nouveau(x) utilisateur(s))`;
      }
      return message;
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      this.selectedUsers = [];
      this.hideFullnameInput();
      this.onClose();
    },
    beforeAdding(u) {
      if (u.id) {
        return true;
      }
      if (!emailReg.test(u)) {
        this.setError({
          message: "Email incorrect"
        });
        return false;
      }
      return true;
    },
    onAdd(u) {
      if (u.id) {
        const { roles } = this;
        if (roles.length === 1) {
          u.role = roles[0];
          this.selectedRole = roles[0];
          return;
        }
        this.isDefineRole = true;
        this.selectedRole = u.role;
        this.newEmail = u.email;
        return;
      }
      this.selectedUsers = this.selectedUsers.map(u1 => {
        if (u1 === u) {
          return { label: u1, email: u1 };
        }
        return u1;
      });
      this.newEmail = u;
      this.isDefineFullNameActive = true;
      this.disableEscCancelOpts = true;
      this.$refs.firstnameInput.focus();
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { cluster, selectedUsers } = this;
      Promise.all(
        selectedUsers.map(u => {
          if (u.id) {
            return linkUserToCluster(apiClient)(cluster.id, u.id, u.role);
          } else {
            return createUserAndLinkToCluster(apiClient)(cluster.id, {
              mail: u.email,
              firstname: u.firstname,
              lastname: u.lastname,
              role: u.role
            });
          }
        })
      )
        .then(() => {
          if (selectedUsers.length === 0) {
            this.setError({
              message: `Aucun utilisateur n'était sélectionné.
              Si vous souhaitiez le créer, merci de valider par Entrée après avoir écrit son mail`,
              error: ""
            });
          } else {
            this.setSuccess({
              message: "Les utilisateurs ont bien été liés à l'exploitation"
            });
            if (this.onLink !== undefined) {
              this.onLink();
            }
            this.closeModal();
          }
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    filterAction(filter) {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
      }
      if (filter && filter.length >= 3) {
        const { apiClient } = this.$store.getters;
        const { selectedUsers } = this;
        timeout = setTimeout(() => {
          const { apiFunc, signal } = callApiWithSignal(searchUsers, apiClient);
          this.signal = signal;
          apiFunc(filter)
            .then(({ data }) => {
              this.filteredUsers = data.filter(d => !selectedUsers.find(u => u.id === d.id));
            })
            .catch(e => {
              if (e.code !== "ERR_CANCELED") {
                this.setError({
                  message: `Une erreur est survenue`,
                  error: e
                });
              }
            });
        }, 200);
      }
    },
    setFullname(resetInput = true) {
      const { newEmail, newFirstName, newLastName } = this;
      this.selectedUsers = this.selectedUsers.map(u => {
        if (u.email === newEmail) {
          u.fullname = `${newFirstName} ${newLastName}`;
          u.firstname = newFirstName;
          u.lastname = newLastName;
          u.label = formatLabel(u);
        }
        return u;
      });
      if (resetInput) {
        this.hideFullnameInput();
      }
    },
    hideFullnameInput() {
      this.newEmail = "";
      this.newFirstName = "";
      this.newLastName = "";
      this.$refs.emailInput.focus();
      this.isDefineFullNameActive = false;
      setTimeout(() => {
        this.disableEscCancelOpts = false;
      }, 500);
    },
    selectRole() {
      const { selectedRole, newEmail } = this;
      this.selectedUsers = this.selectedUsers.map(u => {
        if (u.email === newEmail) {
          u.role = selectedRole;
          u.label = formatLabel(u);
        }
        return u;
      });
      this.isDefineRole = false;
    }
  },
  watch: {
    newFirstName() {
      this.setFullname(false);
    },
    newLastName() {
      this.setFullname(false);
    }
  },
  data() {
    return {
      selectedUsers: [],
      filteredUsers: [],
      selectedRole: "",
      filter: null,
      newEmail: "",
      newFirstName: "",
      newLastName: "",
      isDefineFullNameActive: false,
      isDefineRole: false,
      disableEscCancelOpts: false
    };
  }
};
</script>
