<template>
  <div>
    <b-modal :active="isActive" :onCancel="resetDataAndCloseModal" has-modal-card>
      <div class="modal-card modal-create-ota">
        <header class="modal-card-head">Créer une OTA</header>
        <section class="modal-card-body">
          <div class="content">
            <div class="level">
              <div class="left-level">
                <div class="panel">
                  <p class="panel-heading">
                    Sélectionner les firmware à mettre à jour
                  </p>
                  <div class="panel-block">
                    <p class="js-select-firmware-to-update control has-icons-left">
                      <b-autocomplete
                        v-model="searchInput.firmwareNameToUpdate"
                        @select="selectFirmwareToUpdate"
                        :open-on-focus="true"
                        :clear-on-select="true"
                        :keep-first="true"
                        :data="searchfirmwaresVersionToUpdate"
                        field="fullVersion"
                      />
                      <b-icon icon="magnify"> </b-icon>
                    </p>
                  </div>
                  <div
                    class="js-firmwares-list-to-update panel-block"
                    :key="f.fullVersion"
                    v-for="f in fromFirmwareVersions"
                  >
                    {{ f.fullVersion }}
                    <div class="button delete-button is-small is-danger is-outlined" @click="rmFirmwareToList(f)">
                      <b-icon icon="delete" size="is-small"> </b-icon>
                    </div>
                  </div>
                  <div class="panel-block">
                    <button @click="resetfirmwaresVersionToUpdateList" class="button is-link is-outlined is-fullwidth">
                      Reset la liste
                    </button>
                  </div>
                </div>
              </div>
              <b-icon icon="arrow-right" type="is-success"> </b-icon>
              <div class="right-level">
                <div class="panel">
                  <p class="panel-heading">
                    Sélectionner la nouvelle version du firmware
                  </p>
                  <div class="panel-block">
                    <div class="control">
                      <div class="field">
                        <div class="control is-expanded">
                          <div class="js-select-new-firmware select is-fullwidth">
                            <b-autocomplete
                              v-model="searchInput.firmwareNameForNew"
                              @select="selectNewFirmware"
                              :open-on-focus="true"
                              :keep-first="true"
                              :data="searchFirmwaresForNew"
                              field="fullVersion"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="field is-expanded">
                        <button class="button is-success is-fullwidth js-submit-create-ota" @click="submitForm">
                          Mettre à jour
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-field label="Limiter le nombre d'OTA à créer">
              <b-numberinput v-model="limitOta"></b-numberinput>
            </b-field>
            <b-field>
              <b-checkbox v-model="forceOta">Force OTA</b-checkbox>
            </b-field>
          </div>
        </section>
      </div>
    </b-modal>
    <OtaListModal
      :title="successModalTitle"
      :isActive="successModalActive"
      :closeModal="() => (successModalActive = false)"
      :otaList="successOtaList"
    />
  </div>
</template>

<script>
import OtaListModal from "Components/devices/OtaListModal";
import { FETCH_FIRMWARES_VERSION_ACTION } from "Stores/devices";
import { CREATE_MULTIPLE_OTA_ACTION } from "Stores/ota";
import { mapActions, mapState } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

const filterFirmwaresByFullVersion = (firmwares, fullVersion) => {
  return firmwares.filter(f => f.fullVersion.indexOf(fullVersion.toLowerCase()) >= 0);
};

export default {
  mounted() {
    this.fetchFirmwaresVersion().catch(e => {
      this.setError({
        message: "Can't retrieve the firmwares.<br />Please contact the support.<br />",
        error: e
      });
    });
  },
  computed: {
    searchFirmwaresForNew() {
      return filterFirmwaresByFullVersion(
        this.firmwaresVersionToUpdateNotSelected,
        this.searchInput.firmwareNameForNew
      );
    },
    searchfirmwaresVersionToUpdate() {
      return filterFirmwaresByFullVersion(
        this.firmwaresVersionToUpdateNotSelected,
        this.searchInput.firmwareNameToUpdate
      );
    },
    firmwaresVersionToUpdateNotSelected() {
      return this.firmwaresVersion.filter(f => {
        return this.fromFirmwareVersions.find(f2 => f2.fullVersion === f.fullVersion) === undefined;
      });
    },
    ...mapState({ firmwaresVersion: state => state.devices.firmwaresVersion })
  },
  methods: {
    resetfirmwaresVersionToUpdateList() {
      this.fromFirmwareVersions = [];
    },
    rmFirmwareToList(firmware) {
      this.fromFirmwareVersions = this.fromFirmwareVersions.filter(f => f.fullVersion !== firmware.fullVersion);
    },
    selectFirmwareToUpdate(firmware) {
      if (firmware === null) {
        return;
      }
      this.fromFirmwareVersions.push(firmware);
    },
    selectNewFirmware(firmware) {
      this.toFirmwareVersion = firmware;
    },
    submitForm() {
      if (!this.toFirmwareVersion || !this.toFirmwareVersion.fullVersion) {
        this.setError({
          message: "Veuillez renseigner le nouveau firmware"
        });
        return;
      }
      if (this.fromFirmwareVersions.length === 0) {
        this.setError({
          message: "Aucun firmware à mettre à jour sélectionné"
        });
        return;
      }
      this.createMultipleOta({
        fromFirmwareVersions: this.fromFirmwareVersions.map(f => {
          return {
            major: f.major,
            minor: f.minor,
            patch: f.patch
          };
        }),
        toFirmwareVersion: {
          major: this.toFirmwareVersion.major,
          minor: this.toFirmwareVersion.minor,
          patch: this.toFirmwareVersion.patch
        },
        forceOTA: this.forceOta,
        limitOTA: this.limitOta
      })
        .then(data => {
          if (this.onSuccess) {
            this.onSuccess();
          }
          this.resetData();
          const count = data === null ? 0 : data.length;

          this.openSuccessModal(`${count} OTA ont été créées`, data.map(this.formatResult));
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue<br />Veuillez contacter le support<br />",
            error: e
          });
        });
    },
    openSuccessModal(message, data) {
      this.successModalTitle = message;
      this.successModalActive = true;
      this.successOtaList = data;
    },
    resetData() {
      this.resetfirmwaresVersionToUpdateList();
      this.forceOta = false;
      this.limitOta = null;
      this.toFirmwareVersion = null;
      this.searchInput.firmwareNameToUpdate = "";
      this.searchInput.firmwareNameForNew = "";
    },
    resetDataAndCloseModal() {
      this.resetData();
      this.closeModal();
    },
    ...mapActions({
      fetchFirmwaresVersion: FETCH_FIRMWARES_VERSION_ACTION,
      createMultipleOta: CREATE_MULTIPLE_OTA_ACTION,
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  props: ["isActive", "closeModal", "onSuccess", "formatResult"],
  data() {
    return {
      successModalActive: false,
      successOtaList: [],
      successModalTitle: "",
      fromFirmwareVersions: [],
      toFirmwareVersion: null,
      forceOta: false,
      limitOta: null,
      searchInput: {
        firmwareNameToUpdate: "",
        firmwareNameForNew: ""
      }
    };
  },
  components: { OtaListModal }
};
</script>
<style scoped>
.modal-card {
  width: 800px;
  min-height: 300px;
}
.panel-heading {
  margin-bottom: 0px !important;
  font-size: 18px !important;
}
.panel-block {
  position: relative;
}
.panel-block > div.delete-button {
  position: absolute;
  right: 15px;
}
</style>
<style>
.modal-create-ota .autocomplete .dropdown-content {
  max-height: 100px !important;
}
</style>
