<template>
  <div class="container is-fullhd">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Karnott Monitoring</h1>
          <div class="columns is-multiline" style="margin-top: 15px">
            <div class="column is-full">
              <label class="label">Filtrer par severity</label>
              <b-field>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedSeverityFilter')"
                  :native-value="ALL"
                  v-model="selectedSeverityFilter"
                  type="is-danger"
                >
                  <span> Tous </span>
                </b-checkbox-button>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedSeverityFilter')"
                  :native-value="state"
                  :key="state"
                  v-model="selectedSeverityFilter"
                  v-for="state in deviceMonitoringSeverity"
                  type="is-warning"
                >
                  <span> {{ state }}</span>
                </b-checkbox-button>
              </b-field>
              <label class="label">Filtrer par module</label>
              <b-field>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedModuleFilter')"
                  :native-value="ALL"
                  v-model="selectedModuleFilter"
                  type="is-danger"
                >
                  <span> Tous </span>
                </b-checkbox-button>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedModuleFilter')"
                  :native-value="state"
                  :key="state"
                  v-model="selectedModuleFilter"
                  v-for="state in deviceMonitoringModule"
                  type="is-warning"
                >
                  <span> {{ state }}</span>
                </b-checkbox-button>
              </b-field>
            </div>
            <div class="column is-full">
              <label class="label">Le Karnott est-il lié à une exploitation ?</label>
              <b-field>
                <b-radio-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="isLinkedToClusterFilter"
                  @input="filtersUpdated()"
                >
                  <span> Tous </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="YES"
                  v-model="isLinkedToClusterFilter"
                  @input="filtersUpdated()"
                >
                  <span> Oui </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="NO"
                  v-model="isLinkedToClusterFilter"
                  @input="filtersUpdated()"
                >
                  <span> Non </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-full">
              <label class="label">Rechercher les Karnott</label>
              <b-field>
                <b-taginput
                  :allow-new="false"
                  :data="deviceSearchResults"
                  :allow-duplicates="false"
                  field="serialnumber"
                  icon="label"
                  placeholder="AAAA A01"
                  autocomplete
                  v-model="selectedKarnott"
                  @typing="filterAction"
                  @input="filtersUpdated"
                  :loading="isSearchKarnottLoading"
                >
                  <template slot="empty" v-if="!isSearchKarnottLoading">
                    <b-message type="is-info">
                      Aucun résultat (la recherche doit comporter au moins 2 caractères et ne pas être "aa" ou "aaa")
                    </b-message>
                  </template>
                </b-taginput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section has-background-white">
      <div class="devices-monitoring-list">
        <b-collapse :open="false" class="card" v-for="(device, index) in devices" :key="index">
          <div slot="trigger" class="card-header" role="button">
            <p style="justify-content: space-between; align-items: center; display: flex" class="card-header-title">
              <span>
                <router-link target="_blank" :to="`/device/${device.id}`" class="button is-text">{{device.serialnumber}}</router-link>
                <span v-if="device.cluster_id"> ({{ device.cluster_name }}) </span>
                &nbsp;<span
                  class="tag is-success is-medium"
                  v-if="devicesMonitoring[device.id].filter(d => d.severity === 'WARNING').length > 0"
                  >{{ devicesMonitoring[device.id].filter(d => d.severity === "WARNING").length }} WARNING</span
                >
                &nbsp;<span
                  class="tag is-warning is-medium"
                  v-if="devicesMonitoring[device.id].filter(d => d.severity === 'ERROR').length > 0"
                  >{{ devicesMonitoring[device.id].filter(d => d.severity === "ERROR").length }} ERROR</span
                >
                &nbsp;<span
                  class="tag is-danger is-medium"
                  v-if="devicesMonitoring[device.id].filter(d => d.severity === 'CRITICAL').length > 0"
                  >{{ devicesMonitoring[device.id].filter(d => d.severity === "CRITICAL").length }} CRITICAL</span
                >
              </span>
              <button class="button is-info is-small" @click="() => archiveAllDeviceMonitoringForDevice(device.id)">
                Archiver toutes les erreurs
              </button>
            </p>
          </div>
          <div class="card-content">
            <div class="content">
              <table class="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Severity</th>
                    <th>Module</th>
                    <th>Message</th>
                    <th>Archive</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(monitoring, index) in devicesMonitoring[device.id]" :key="index">
                    <td>{{ formatDate(monitoring.occurred_at) }}</td>
                    <td>{{ monitoring.severity }}</td>
                    <td>{{ monitoring.module }}</td>
                    <td>{{ monitoring.message }}</td>
                    <td>
                      <button class="button is-info is-small" @click="() => archiveDeviceMonitoring(monitoring.id)">
                        Archiver
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { archiveDeviceMonitoring, bulkArchiveDeviceMonitoring, getDeviceMonitoring, searchDevices, getDevicesByIds } from "Api/device";
import { formatDate, callApiWithSignal } from "Utils";
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_USER_ME_ACTION } from "Stores/users";

const ALL = "ALL";
const YES = "YES";
const NO = "NO";
let timeout = null;

export default {
  mounted() {
    document.title = "Karnott Monitoring";
    this.getCurrentUser().then(user => (this.currentUser = user));

    const { selectedSeverityFilter, selectedModuleFilter, selectedKarnottIds, isLinkedToClusterFilter } =
      this.$route.query;
    if (selectedSeverityFilter) {
      if (typeof selectedSeverityFilter === "string") {
        this.selectedSeverityFilter = [selectedSeverityFilter];
      } else {
        this.selectedSeverityFilter = selectedSeverityFilter;
      }
    }
    if (selectedModuleFilter) {
      if (typeof selectedModuleFilter === "string") {
        this.selectedModuleFilter = [selectedModuleFilter];
      } else {
        this.selectedModuleFilter = selectedModuleFilter;
      }
    }
    if (selectedKarnottIds?.length > 0) {
      let selectedKarnott = [];
      if (typeof selectedKarnottIds === "string") {
        selectedKarnott = [{ id: selectedKarnottIds }];
      } else {
        selectedKarnott = selectedKarnottIds.map(id => ({ id }));
      }
      const { apiClient } = this.$store.getters;
      getDevicesByIds(apiClient)(selectedKarnott.map(d => d.id))
        .then(response => {
          this.selectedKarnott = response.data;
        })
        .catch(e => {
          this.setError(e);
        });

      this.selectedKarnott = selectedKarnott;
    }
    if (isLinkedToClusterFilter) {
      this.isLinkedToClusterFilter = isLinkedToClusterFilter;
    }
    this.filtersUpdated();
  },
  methods: {
    resetCheckbox(prop) {
      this[prop] = [ALL];
    },
    onChangeCheckbox(propString) {
      const propValue = this[propString];
      const arrayLen = propValue.length;
      if (arrayLen === 0) {
        this.resetCheckbox(propString);
        this.filtersUpdated();
        return;
      }
      if (propValue[arrayLen - 1] === ALL) {
        this.resetCheckbox(propString);
        this.filtersUpdated();
        return;
      }
      if (propValue.find(s => s === ALL)) {
        this[propString] = propValue.filter(s => s !== ALL);
      }
      this.filtersUpdated();
    },
    formatDate(strDate) {
      return formatDate(new Date(strDate));
    },
    filtersUpdated() {
      const { selectedSeverityFilter, selectedModuleFilter } = this;
      const query = Object.assign({}, this.$route.query);
      let params = {};
      if (selectedSeverityFilter && selectedSeverityFilter.length > 0 && !selectedSeverityFilter.includes(ALL)) {
        params.severity = selectedSeverityFilter;
      }
      query.selectedModuleFilter = selectedModuleFilter;
      if (selectedModuleFilter && selectedModuleFilter.length > 0 && !selectedModuleFilter.includes(ALL)) {
        params.module = selectedModuleFilter;
      }
      query.selectedModuleFilter = selectedModuleFilter;

      const { selectedKarnott } = this;
      if (selectedKarnott && selectedKarnott.length > 0) {
        params.device_instance_id = selectedKarnott.map(d => d.id);
      }
      query.selectedKarnottIds = selectedKarnott.map(d => d.id);

      const { isLinkedToClusterFilter } = this;
      if (isLinkedToClusterFilter && isLinkedToClusterFilter !== ALL) {
        params.is_linked_to_cluster = isLinkedToClusterFilter;
      }
      query.isLinkedToClusterFilter = isLinkedToClusterFilter;

      this.$router.push({ query }).catch(() => {});

      this.getMonitoring(params);
    },
    archiveAllDeviceMonitoringForDevice(deviceId) {
      const monitoringIds = this.devicesMonitoring[deviceId].map(item => item.id);
      const payload = {"device_monitoring_ids": monitoringIds};
      bulkArchiveDeviceMonitoring(this.$store.getters.apiClient)(payload)
        .then(() => {
          this.getMonitoring();
        })
        .catch(e => {
          this.setError(e);
        });
    },
    archiveDeviceMonitoring(id) {
      archiveDeviceMonitoring(this.$store.getters.apiClient)(id)
        .then(() => {
          this.getMonitoring();
        })
        .catch(e => {
          this.setError(e);
        });
    },
    getMonitoring(params) {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getDeviceMonitoring(apiClient)(params)
        .then(response => {
          if (!response.data) {
            this.devicesMonitoring = [];
            this.devices = [];
            return;
          }
          const deviceIds = [...new Set(response.data.map(item => item.device_instance_id))];

          // group device_monitoring by device_instance_id
          this.devicesMonitoring = response.data.reduce((acc, item) => {
            if (!acc[item.device_instance_id]) {
              acc[item.device_instance_id] = [];
            }
            acc[item.device_instance_id].push(item);
            return acc;
          }, {});

          // sort this.devicesMonitoring by occurred_at desc
          Object.keys(this.devicesMonitoring).forEach(key => {
            this.devicesMonitoring[key].sort((a, b) => (a.occurred_at < b.occurred_at ? 1 : -1));
          });

          // get devices info
          getDevicesByIds(apiClient)(deviceIds)
            .then(response => {
              this.devices = response.data;
              // sort this.devices by most critical errors
              this.devices.sort((a, b) => {
                const aCritical = this.devicesMonitoring[a.id].filter(d => d.severity === "CRITICAL").length;
                const bCritical = this.devicesMonitoring[b.id].filter(d => d.severity === "CRITICAL").length;
                return bCritical - aCritical;
              });

              // filter cluster_id with isLinkedToClusterFilter
              if (params && params.is_linked_to_cluster) {
                this.devices = this.devices.filter(d => {
                  if (params.is_linked_to_cluster === YES) {
                    return d.cluster_id;
                  }
                  if (params.is_linked_to_cluster === NO) {
                    return !d.cluster_id;
                  }
                  return true;
                });
              }
            })
            .catch(e => {
              this.setError(e);
            });
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filterAction(q) {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
        this.signal = null;
      }
      this.deviceSearchResults = [];
      this.isSearchKarnottLoading = false;
      const qLowerCase = q.toLowerCase();
      // because aa & aaa search filter return too many results
      if (q.length < 2 || qLowerCase == "aaa" || qLowerCase == "aa") {
        return;
      }
      timeout = setTimeout(() => {
        if (this.signal) {
          this.signal.cancel();
          this.signal = null;
        }
        const { apiClient } = this.$store.getters;
        const { apiFunc, signal } = callApiWithSignal(searchDevices, apiClient);
        this.signal = signal;
        this.deviceSearchResults = [];
        this.isSearchKarnottLoading = true;
        apiFunc({ serialnumber: q, without_tracks: true })
          .then(({ data }) => {
            this.isSearchKarnottLoading = false;
            // to avoid to have a too big list, limit to 20 result
            if (data && data.length > 20) {
              const dataSlice = [];
              for (let i = 0; i < 20; i++) {
                dataSlice[i] = data[i];
              }
              this.deviceSearchResults = dataSlice;
              return;
            }
            this.deviceSearchResults = data;
          })
          .catch(e => {
            this.isSearchKarnottLoading = false;
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getCurrentUser: GET_USER_ME_ACTION
    })
  },
  data() {
    return {
      ALL,
      YES,
      NO,
      deviceMonitoringSeverity: ["WARNING", "ERROR", "CRITICAL"],
      deviceMonitoringModule: ["NETWORK", "BATTERY"],
      devices: [],
      deviceSearchResults: [],
      devicesMonitoring: [],
      filteredKarnott: this.devices,
      isLinkedToClusterFilter: ALL,
      isLoading: false,
      isSearchKarnottLoading: false,
      selectedKarnott: [],
      selectedSeverityFilter: [ALL],
      selectedModuleFilter: [ALL]
    };
  }
};
</script>

<style scoped>
.card-header {
  margin: 15px 0;
}
.label {
  color: #d3d3d3;
}
</style>
