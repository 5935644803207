<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des admins ({{ this.adminUsers.length }})</h1>
          <div class="buttons has-addons">
            <a class="button is-info" @click.prevent="showAddModal()">
              Ajouter un utilisateur
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <UsersTable
        :cluster="cluster"
        :users="adminUsers"
        :cssClassByRole="() => 'is-info'"
        :onUnlink="onUnlink"
        :showIsUsingMobileAppColumn="true"
        :showEditUserRole="true"
      />
    </div>
    <LinkUserModal
      titleLabel="utilisateur"
      :cluster="cluster"
      :isActive="isModalActive"
      :roles="CLUSTER_ROLES"
      :onLink="onLink"
      :allowNewUser="true"
      :onClose="() => (isModalActive = false)"
    />
  </div>
</template>
<style scoped>
table {
  padding-bottom: 20px;
}
</style>
<script>
import { CLUSTER_ROLES } from "Constants";
import UsersTable from "Components/users/UsersTable";
import LinkUserModal from "Components/clusters/clusterDetailsComponents/LinkUserModal";

export default {
  props: ["cluster", "supportTeamUsers", "onLink", "onUnlink", "adminUsers"],
  components: { UsersTable, LinkUserModal },
  methods: {
    showAddModal() {
      this.isModalActive = true;
    }
  },
  data() {
    return {
      CLUSTER_ROLES: CLUSTER_ROLES,
      isModalActive: false
    };
  }
};
</script>
