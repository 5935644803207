<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadDiagnostic" @submit.prevent="loadDiagnostic">
        <b-field grouped>
          <b-datepicker v-model="diagnosticFromDate" placeholder="Select..." icon="calendar-today" />
          <b-datepicker v-model="diagnosticToDate" placeholder="Select..." icon="calendar-today" />
          <button :loading="isLoading" :disabled="isLoading" class="button is-success">Rechercher</button>
        </b-field>
      </form>
    </div>
    <div><p v-if="isLoading">Chargement...</p></div>
    <div v-if="this.diagnostic" :loading="isLoading" class="diagnostic-content">
      <div class="tile">
        <div class="tile is-12">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child notification is-info">
                <div class="content">
                  <p class="title">
                    Connexions : {{ this.diagnostic.connection.total }} dont
                    {{ this.diagnostic.connection.keep_alive }} Keep Alive
                  </p>
                </div>
              </article>
            </div>
            <div class="tile is-parent">
              <article
                class="tile is-child notification"
                v-bind:class="{
                  'is-success': this.diagnostic.last_firmware,
                  'is-warning': !this.diagnostic.last_firmware
                }"
              >
                <div class="content">
                  <p class="title">Firmware : {{ this.device.firmware_name }}</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="tile">
        <div class="tile is-12">
          <div class="tile">
            <div class="tile is-parent">
              <article
                class="tile is-child notification"
                v-bind:class="{
                  'is-success': ['good', 'excellent'].includes(this.diagnostic.network.status),
                  'is-warning': ['medium'].includes(this.diagnostic.network.status),
                  'is-danger': ['poor', 'bad'].includes(this.diagnostic.network.status)
                }"
              >
                <p class="title">Reseau</p>
                <vue-gauge :refid="'gauge-network'" :options="this.gaugeNetworkOptions"></vue-gauge>
                <div class="content">
                  <p>
                    Moyenne : <b>{{ this.diagnostic.network.avg }}</b>
                  </p>
                  <p>
                    Nombre accces reseau LTE / GPRS :
                    <b>{{ this.diagnostic.network.count_lte }} / {{ this.diagnostic.network.count_gprs }}</b>
                  </p>
                  <p>
                    Nombre d'erreur : <b>{{ this.diagnostic.network.error }}</b>
                  </p>
                </div>
              </article>
            </div>
            <div class="tile is-parent" v-if="this.diagnostic.network.cme_error">
              <article
                class="tile is-child notification"
                v-bind:class="{
                  'is-success': ['good', 'excellent'].includes(this.diagnostic.network.status),
                  'is-warning': ['medium'].includes(this.diagnostic.network.status),
                  'is-danger': ['poor', 'bad'].includes(this.diagnostic.network.status)
                }"
              >
                <p class="title">Erreurs de reseau</p>
                <div class="content">
                  <b-table
                    v-show="this.diagnostic.network.cme_error && this.diagnostic.network.cme_error.length > 0"
                    :data="this.diagnostic.network.cme_error"
                  >
                    <b-table-column field="value" label="Valeur" v-slot="props">
                      {{ props.row.value }}
                    </b-table-column>
                    <b-table-column field="count" label="Count" v-slot="props">
                      {{ props.row.count }}
                    </b-table-column>
                    <b-table-column field="last_occurred_at" label="Date" v-slot="props">
                      {{ new Date(Date.parse(props.row.last_occurred_at)).toLocaleString() }}
                    </b-table-column>
                  </b-table>
                </div>
              </article>
            </div>
            <div class="tile is-parent">
              <article
                class="tile is-child notification"
                v-bind:class="{
                  'is-success': ['good', 'excellent'].includes(this.diagnostic.gps.status),
                  'is-warning': ['medium'].includes(this.diagnostic.gps.status),
                  'is-danger': ['poor', 'bad'].includes(this.diagnostic.gps.status)
                }"
              >
                <p class="title">GPS {{ this.diagnostic.gps.statusLabel }}</p>
                <div class="content">
                  <p>
                    Nombre total de points : <b>{{ this.diagnostic.gps.total }}</b>
                  </p>
                  <p>
                    Nombre de points ayant au moins 11 satellites :
                    <b>{{ this.diagnostic.gps.count_with_11_sat }}</b> (avec un dop moyen de
                    <b>{{ this.diagnostic.gps.avg_dop_with_11_sat }}</b
                    >)<br />
                    Ratio d'accelerometer à true : <b>{{ this.diagnostic.gps.accelero_ratio_with_11_sat }}</b>
                  </p>
                </div>
              </article>
            </div>
            <div class="tile is-parent">
              <article
                class="tile is-child notification"
                v-bind:class="{
                  'is-success': !this.diagnostic.battery.has_problem,
                  'is-danger':
                    this.diagnostic.battery.has_problem ||
                    this.device.health.battery.status === DEVICE_BATTERY_STATUS_CRITICAL,
                  'is-warning': this.device.health.battery.status === DEVICE_BATTERY_STATUS_WARNING
                }"
              >
                <p class="title">Batterie / Temp.</p>
                <div class="content">
                  <p>
                    Statut batterie : <b>{{ this.device.health.battery.status }}</b
                    ><br />
                    Courant moyen : <b>{{ this.diagnostic.battery.avg_current }}</b
                    ><br />
                    Tension minimum : <b>{{ this.diagnostic.battery.min_voltage }}</b> <br />
                    Nombre de données : <b>{{ this.diagnostic.battery.total_data }}</b> (dont
                    {{ this.diagnostic.battery.corrupted_data }} corrompue(s))
                  </p>
                  <p>
                    température moyenne :
                    <b>{{ this.diagnostic.temperature.avg }}</b
                    ><br />
                    température Maximum : <b>{{ this.diagnostic.temperature.max }}</b> <br />
                    Nombre de données :
                    <b>{{ this.diagnostic.temperature.total_data }}</b> (dont
                    {{ this.diagnostic.temperature.corrupted_data }} corrompue(s))
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-12">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child notification is-info">
                <div class="content">
                  <p class="title">BLE</p>
                  <div class="content">
                    <p>Est-il activé : {{ this.diagnostic.ble.is_activated ? "Oui" : "Non" }}</p>
                    <p v-if="this.diagnostic.ble.is_activated">
                      avec un total de <b>{{ this.diagnostic.ble.total_beacon }}</b> captations de beacon
                    </p>
                    <p v-if="this.diagnostic.ble.details">Details :</p>
                    <b-table
                      v-show="this.diagnostic.ble.details && this.diagnostic.ble.details.length > 0"
                      :data="this.diagnostic.ble.details"
                      :default-sort="['last_occurred_at', 'desc']"
                      paginated
                      per-page="100"
                      :total="this.diagnostic.ble.details && this.diagnostic.ble.details.length"
                    >
                      <b-table-column field="beacon_uuid" label="UUID" v-slot="props" sortable>
                        {{ props.row.beacon_uuid }}
                      </b-table-column>
                      <b-table-column field="count" label="Count" v-slot="props" sortable>
                        {{ props.row.count }}
                      </b-table-column>
                      <b-table-column field="first_occurred_at" label="Premiere captation" v-slot="props" sortable>
                        {{ new Date(Date.parse(props.row.first_occurred_at)).toLocaleString() }}
                      </b-table-column>
                      <b-table-column field="last_occurred_at" label="Dernière captation" v-slot="props" sortable>
                        {{ new Date(Date.parse(props.row.last_occurred_at)).toLocaleString() }}
                      </b-table-column>
                    </b-table>
                  </div>
                </div>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification">
                <div class="content">
                  <p class="title">Etat de santé</p>
                  <p>
                    WHO_AM_I Error : <b>{{ this.diagnostic.health.count_who_am_i_error }}</b>
                  </p>
                  <b-field label="Nombre de who_am_i_error par jour">
                    <b-select placeholder="cliquez !">
                      <option v-for="(count, date) in whoAmIErrorsPerDay" :key="date" :value="count">
                        {{ date }}: {{ count }}
                      </option>
                    </b-select>
                  </b-field>
                  <p>
                    Watchdog Reboot total : <b>{{ this.diagnostic.health.total_watchdog_reboot }}</b>
                  </p>
                  <p>
                    Watchdog Reboot sur la période :
                    <b>{{
                      this.diagnostic.health.watchdog_reboot_list
                        ? this.diagnostic.health.watchdog_reboot_list.length
                        : 0
                    }}</b>
                  </p>
                  <b-field
                    label="Nombre de Watchdog Reboot  par jour"
                    v-if="
                      this.diagnostic.health.watchdog_reboot_list &&
                      this.diagnostic.health.watchdog_reboot_list.length > 0
                    "
                  >
                    <b-select placeholder="cliquez !">
                      <option v-for="(count, date) in watchdogRebootPerDay" :key="date" :value="count">
                        {{ date }}: {{ count }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDiagnostic } from "Api/device";
import { formatInputDates, formatToRFC3339 } from "Utils";
import VueGauge from "vue-gauge";
import { DEVICE_BATTERY_STATUS } from "Constants";

export default {
  components: { VueGauge },
  props: ["device"],
  data() {
    return {
      gaugeNetworkOptions: {
        arcColors: ["black", "red", "yellow", "green", "blue"],
        arcDelimiters: [10, 30, 50, 80],
        hasNeedle: true,
        needleColor: 0
      },
      isLoading: false,
      diagnostic: null,
      diagnosticFromDate: null,
      diagnosticToDate: null,
      DEVICE_BATTERY_STATUS_WARNING: DEVICE_BATTERY_STATUS.WARNING,
      DEVICE_BATTERY_STATUS_CRITICAL: DEVICE_BATTERY_STATUS.CRITICAL
    };
  },
  computed: {
    whoAmIErrorsPerDay() {
      const errors = this.diagnostic.health.who_am_i_error_list || [];
      let dateCounts = errors.reduce((counts, date) => {
        let day = new Date(date).toISOString().split("T")[0];
        if (!counts[day]) {
          counts[day] = 0;
        }
        counts[day]++;
        return counts;
      }, {});
      return dateCounts;
    },
    watchdogRebootPerDay() {
      const errors = this.diagnostic.health.watchdog_reboot_list || [];
      let dateCounts = errors.reduce((counts, date) => {
        let day = new Date(date).toISOString().split("T")[0];
        if (!counts[day]) {
          counts[day] = 0;
        }
        counts[day]++;
        return counts;
      }, {});
      return dateCounts;
    }
  },
  methods: {
    loadDiagnostic() {
      this.diagnostic = null;
      const { apiClient } = this.$store.getters;
      const { diagnosticFromDate, diagnosticToDate } = this;
      diagnosticFromDate.setHours(0, 0, 0, 0);
      diagnosticToDate.setHours(23, 59, 59, 0);
      const { fromDate, toDate } = formatInputDates(diagnosticFromDate, diagnosticToDate);
      if (fromDate === null || toDate === null) {
        return;
      }
      this.isLoading = true;
      return getDiagnostic(apiClient)(this.device.id, {
        from_date: formatToRFC3339(fromDate),
        to_date: formatToRFC3339(toDate)
      })
        .then(({ data }) => {
          this.isLoading = false;
          this.diagnostic = data;
          this.gaugeNetworkOptions.needleValue = this.diagnostic.network.avg;
          switch (this.diagnostic.gps.status) {
            case "excellent":
              this.diagnostic.gps.statusLabel = "(Excellent :D )";
              break;
            case "good":
              this.diagnostic.gps.statusLabel = "(Bon :) )";
              break;
            case "medium":
              this.diagnostic.gps.statusLabel = "(Moyen :| )";
              break;
            case "bad":
              this.diagnostic.gps.statusLabel = "(Mauvais :/ )";
              break;
            case "poor":
              this.diagnostic.gps.statusLabel = "(Mediocre :( )";
              break;
          }
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    }
  },
  mounted() {
    if (this.device !== null) {
      this.diagnosticToDate = new Date();
      if (this.device.lastinfo_date) {
        this.diagnosticToDate = new Date(Date.parse(this.device.lastinfo_date));
      }

      const diagnosticFromDate = new Date(this.diagnosticToDate);
      diagnosticFromDate.setDate(diagnosticFromDate.getDate() - 15);
      this.diagnosticFromDate = diagnosticFromDate;

      this.loadDiagnostic();
    }
  }
};
</script>
