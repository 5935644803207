<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Gestion des Karnott</h1>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-one-third">
          <b-upload v-model="dropFiles" drag-drop @input="uploadCsv" id="drop-zone">
            <section class="section">
              <div class="content">
                <p class="has-text-centered">
                  <b-icon icon="upload" size="is-large"></b-icon>
                </p>
                <p class="has-text-centered">Ajoutez votre fichier .csv</p>
                <div>
                  Le fichier ne doit pas contenir d'en tête et doit contenir 3 colonnes séparées par un ';' ou un ',' :
                  <ul>
                    <li>Colonne 1 : Numéro de série</li>
                    <li>Colonne 2 : Adresse mac</li>
                    <li>Colonne 3 : Device Model ID</li>
                  </ul>
                </div>
                <div style="margin-top: 40px">
                  <p>La liste des device model :</p>
                  <ul>
                    <li v-for="model in deviceModels" :key="model.id">{{ model.id }} - {{ model.label }}</li>
                  </ul>
                </div>
              </div>
            </section>
          </b-upload>
        </div>
        <div class="column is-two-third js-import-devices-list">
          <h2 class="subtitle">Résultat de l'upload</h2>
          <b-table
            :data="results"
            :paginated="true"
            :loading="tableLoading"
            :per-page="10"
            :default-sort="['serialnumber', 'desc']"
            :row-class="row => row.uploadStatus === UPLOAD_ERROR && 'is-danger'"
          >
            <b-table-column v-slot="props" field="id" label="ID" sortable> #{{ props.row.id }} </b-table-column>
            <b-table-column v-slot="props" field="serialnumber" label="Numéro de série" sortable>
              {{ props.row.serialnumber }}
            </b-table-column>
            <b-table-column v-slot="props" field="noolitic_mac" label="Mac" sortable>
              {{ props.row.noolitic_mac }}
            </b-table-column>
            <b-table-column v-slot="props" field="device_model_id" label="Device Model" sortable>
              {{ props.row.device_model_id }}
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Processus d'import non démarré</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createDevices } from "Api/device";
import { mapActions } from "vuex";
import { getDeviceModels } from "Api/deviceModel";
import { SET_ERROR_ACTION } from "Stores/message";

const convertCsvToArray = csvContent => {
  let rows = csvContent.split("\n");
  return rows
    .filter(r => r !== "")
    .map(row => {
      return row
        .split(";")
        .concat(row.split(","))
        .filter(column => column !== "" && column !== row);
    })
    .filter(row => row.length === 3);
};
const getFileContentReader = file => {
  const reader = new FileReader();
  reader.readAsText(file);
  return reader;
};
const UPLOAD_ERROR = "ERROR";

export default {
  data() {
    return {
      dropFiles: [],
      results: [],
      deviceModels: [],
      tableLoading: false,
      UPLOAD_ERROR
    };
  },
  methods: {
    loadDeviceModels() {
      const { apiClient } = this.$store.getters;
      getDeviceModels(apiClient)
        .then(({ data }) => {
          this.deviceModels = data || [];
          this.deviceModels = this.deviceModels.filter(deviceModel => deviceModel.label !== "Virtuel");
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des deviceModel`,
            error: e
          });
        });
    },
    uploadCsv(file) {
      if (!file.name.endsWith(".csv")) {
        this.setError({
          message: "Seuls les fichiers CSV sont autorisés"
        });
        return;
      }

      this.tableLoading = true;

      const waitFileIsProcessed = new Promise((resolve, reject) => {
        const reader = getFileContentReader(file);
        reader.onload = () => {
          const rows = convertCsvToArray(reader.result);
          const devices = rows.map(row => {
            const colA = row[0];
            const colB = row[1];
            const colC = parseInt(row[2]);

            return { serialnumber: colA, noolitic_mac: colB, device_model_id: colC };
          });

          this.saveDevices(devices)
            .then(() => resolve(devices.length))
            .catch(reject);
        };
      });
      waitFileIsProcessed
        .then(count => {
          this.tableLoading = false;
          const message = `${count} boitiers ont été pris en compte`;
          this.$buefy.snackbar.open({
            duration: 3000,
            position: "is-bottom-right",
            message
          });
          this.dropFiles = [];
        })
        .catch(e => {
          let message = e.message;

          if (e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
            message = e.response.data.errors[0].message;

            if (message.includes("device_instance_serialnumber_key")) {
              message = "Un ou plusieurs numéros de série existent déjà. Veuillez contrôler le contenu du fichier.";
            } else if (message.includes("mac_uindex")) {
              message = "Une ou plusieurs adresses MAC existent déjà. Veuillez contrôler le contenu du fichier.";
            }
          }

          this.setError({ message: message });
          this.tableLoading = false;
        });
    },
    saveDevices(payload) {
      const { apiClient } = this.$store.getters;
      const createDevicesApi = createDevices(apiClient);
      return createDevicesApi(payload).then(() => (this.results = payload));
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  mounted() {
    document.title = "Créer des boîtiers";
    this.loadDeviceModels();
  }
};
</script>

<style scoped>
h1 {
  color: white;
  text-shadow: 3px 1px 20px rgba(0, 0, 0, 1);
}
</style>
