import Vue from "vue";
import Vuex from "vuex";
import devices from "Stores/devices";
import clusters from "Stores/clusters";
import users from "Stores/users";
import deviceData from "Stores/device-data";
import deviceHealths from "Stores/deviceHealths";
import message from "Stores/message";
import ota from "Stores/ota";
import routes from "Stores/routes";
import axios from "axios";
import { SaveLastUrlAndRedirectToSignIn, RemoveLastUrlAndRedirectToHome } from "Src/plugin";

const { VUE_APP_API_URL, VUE_APP_RAILS_API_URL } = process.env;

const debug = process.env.NODE_ENV !== "production";

const setHTTPInterceptor = instance => {
  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        SaveLastUrlAndRedirectToSignIn();
        return Promise.reject(401);
      } else if (error.response && error.response.status === 403) {
        RemoveLastUrlAndRedirectToHome();
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    hostname: window.location.hostname,
    accessToken: window.localStorage.getItem("accessToken")
  },
  getters: {
    getHostname(state) {
      return state.hostname;
    },
    apiClient(state) {
      let client = axios.create({
        baseURL: VUE_APP_API_URL,
        headers: {
          Authorization: `Bearer ${state.accessToken}`
        }
      });
      setHTTPInterceptor(client);
      return client;
    },
    railsClient() {
      let client = axios.create({
        baseURL: VUE_APP_RAILS_API_URL
      });
      setHTTPInterceptor(client);
      return client;
    }
  },
  modules: {
    clusters,
    devices,
    users,
    deviceData,
    ota,
    routes,
    message,
    deviceHealths
  },
  strict: debug
});

export default store;
