<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Lier un équipement au beacon</p>
        </header>
        <section class="modal-card-body" v-if="!equipments || equipments.length === 0">
          <b-message type="is-danger">
            Impossible d'associer un équipement au beacon car l'exploitation liée au beacon n'a aucun matériel à son
            actif
          </b-message>
        </section>
        <section class="modal-card-body" v-if="equipments && equipments.length > 0">
          <div class="message is-info">
            <div class="message-body">
              Attention, une nouvelle liaison entre un équipement et un beacon risque d'effacer les précédentes liaisons
              si la plage de date croise celle d'ancienne liaison
            </div>
          </div>
          <b-field label="Choisir un équipement">
            <b-autocomplete
              v-model="selectedEquipmentName"
              :keep-first="true"
              :open-on-focus="true"
              :data="filteredEquipments"
              field="label"
              @select="e => (selectedEquipment = e)"
            />
          </b-field>
          <b-field label="Date de début">
            <b-datetimepicker v-model="fromDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <b-field label="Date de fin">
            <b-datetimepicker
              v-model="toDate"
              placeholder="Type or select a date..."
              icon="calendar-today"
              :locale="'fr-FR'"
              editable
            >
            </b-datetimepicker>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot" v-if="equipments && equipments.length > 0">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<script>
import { linkEquipmentToBeacon } from "Api/equipmentHasDevice";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";

export default {
  props: ["beacon", "equipments", "isActive", "onCloseModal", "onSuccess"],
  data() {
    return {
      selectedEquipmentName: "",
      selectedEquipment: null,
      fromDate: null,
      toDate: null
    };
  },
  computed: {
    filteredEquipments() {
      return this.equipments.filter(
        e =>
          e.status != "DELETED" &&
          (this.selectedEquipmentName == "" || e.label.toLowerCase().includes(this.selectedEquipmentName.toLowerCase()))
      );
    }
  },
  methods: {
    closeModal() {
      if (this.onCloseModal) {
        this.onCloseModal();
      }
    },
    submit() {
      const { fromDate, toDate } = this.formatInputDates();
      const { beacon, selectedEquipment } = this;
      if (fromDate === null || selectedEquipment === null || !selectedEquipment.id) {
        return;
      }

      const formattedFromDate = fromDate.toJSON().replace(/\d{2}\.\d{3}Z/, "00Z");
      let formattedToDate = null;
      if (toDate) {
        formattedToDate = toDate.toJSON().replace(/\d{2}\.\d{3}Z/, "00Z");
      }
      const { apiClient } = this.$store.getters;
      linkEquipmentToBeacon(apiClient)(beacon.uuid, {
        equipment_id: selectedEquipment.id,
        from_date: formattedFromDate,
        to_date: formattedToDate
      })
        .then(() => {
          this.onSuccess && this.onSuccess();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue <br />",
            error: e
          });
        });
    },
    formatInputDates() {
      let { fromDate, toDate } = this;
      if (fromDate !== null && !(fromDate instanceof Date)) {
        fromDate = new Date(fromDate);
      }
      if (toDate !== null && !(toDate instanceof Date)) {
        toDate = new Date(toDate);
      }
      return { fromDate, toDate };
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  watch: {
    isActive() {
      const now = new Date();
      this.fromDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    }
  }
};
</script>
