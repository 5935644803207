<template>
  <div class="container is-fullhd">
    <div class="container title-container">
      <h1 class="title">
        Liste des exploitations
        <button class="button is-success" @click="showCreateClusterModal">
          <span class="icon is-small"> <i class="mdi mdi-plus mdi-12px"></i></span>
        </button>
      </h1>
    </div>
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Filtrer
          </h1>

          <div class="columns is-multiline">
            <div class="column is-half">
              <label class="label">Rechercher par nom</label>
              <b-field>
                <b-input v-model="searchParams.name" @input="filtersUpdated(500)"></b-input>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <label class="label">Filtrer par démo</label>
              <b-field>
                <b-radio-button
                  v-for="(label, value) in demoLabelsMapping"
                  :key="value"
                  v-model="searchParams.demo"
                  @input="filtersUpdated()"
                  :native-value="value"
                  type="is-info"
                >
                  <b-icon v-if="searchParams.demo === value" icon="check"></b-icon>
                  <span> {{ label }} </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-full">
              <label class="label">Filtrer par type</label>
              <b-field>
                <b-radio-button
                  v-for="(label, type) in clusterTypeLabelsMapping"
                  :key="type"
                  v-model="searchParams.clusterType"
                  :native-value="type"
                  type="is-info"
                  @input="filtersUpdated()"
                >
                  <b-icon v-if="searchParams.clusterType === type" icon="check"></b-icon>
                  <span> {{ label }} </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-full">
              <label class="label">Filtrer par état</label>
              <div class="field filter-by-state">
                <div class="field-body">
                  <div class="field has-addons">
                    <b-radio-button
                      v-for="(label, state) in stateLabelsMapping"
                      :key="state"
                      v-model="searchParams.state"
                      :native-value="state"
                      type="is-info"
                      @input="filtersUpdated()"
                    >
                      <b-icon v-if="searchParams.state === state" icon="check"></b-icon>
                      <span> {{ label }} </span>
                    </b-radio-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-full">
              <b-button
                type="is-success"
                size="is-medium"
                class="is-fullwidth"
                @click="filtersUpdated()"
                :loading="isLoading"
              >
                Rechercher
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="clusters">
      <b-table :loading="isLoading" :per-page="100" :data="clusters" paginated>
        <b-table-column field="name" label="Nom" v-slot="props" sortable>
          <router-link :to="`/cluster/${props.row.id}`"> {{ props.row.name }} </router-link>
        </b-table-column>
        <b-table-column field="cluster_type" label="Type" v-slot="props" sortable>
          <span :class="clusterTypeClassTag(props.row.cluster_type)">
            {{ clusterTypeLabelsMapping[props.row.cluster_type] }}
          </span>
        </b-table-column>
        <b-table-column field="current_state" label="Etat" v-slot="props" sortable>
          <span :class="stateClassTag(props.row.current_state)">
            {{ stateLabelsMapping[props.row.current_state] }}
          </span>
        </b-table-column>
        <b-table-column field="last_state_date" label="Date du dernier état" v-slot="props" sortable>
          <span>
            {{ props.row.last_state_date_string }}
          </span>
        </b-table-column>
      </b-table>
    </section>

    <b-modal :active.sync="isCreateClusterModalActive">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Ajouter une exploitation</p>
        </header>
        <section class="modal-card-body">
          <CreateForm :afterSubmit="afterCreateAction" />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { searchClusters } from "Api/cluster";
import CreateForm from "Components/clusters/Form";
import { CLUSTER_TYPE_LABELS_MAPPING, CLUSTER_STATE_CSS_CLASS, CLUSTER_STATE_STATE_LABEL_AS_FILTER } from "Constants";
let searchTimeout = null;

export default {
  components: { CreateForm },
  data() {
    return {
      clusters: [],
      isCreateClusterModalActive: false,
      isLoading: false,
      clusterTypeLabelsMapping: {
        ALL: "Tous",
        ...CLUSTER_TYPE_LABELS_MAPPING
      },
      demoLabelsMapping: {
        ALL: "Tous",
        true: "Démo",
        false: "Non démo"
      },
      stateLabelsMapping: CLUSTER_STATE_STATE_LABEL_AS_FILTER,
      searchParams: {
        name: "",
        state: "ALL",
        clusterType: "ALL",
        demo: "ALL"
      }
    };
  },
  methods: {
    afterCreateAction(newCluster) {
      this.isCreateClusterModalActive = false;
      this.clusters = [newCluster].concat(this.clusters);
    },
    showCreateClusterModal() {
      this.isCreateClusterModalActive = true;
    },
    filtersUpdated(timeout = 0) {
      const { name, demo, state, clusterType } = this.searchParams;
      const params = {
        name: name === "" ? null : name,
        is_demo: demo === "ALL" ? null : demo,
        state: state === "ALL" ? null : state,
        cluster_type: clusterType === "ALL" ? null : clusterType
      };

      if (searchTimeout !== null) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => this.searchClusters(params), timeout);
    },
    searchClusters(params) {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      searchClusters(apiClient)(params)
        .then(({ data }) => {
          data.forEach(d => {
            d.last_state_date_string = "-";
            if (d.last_state_date) {
              d.last_state_date = new Date(Date.parse(d.last_state_date));
              d.last_state_date_string = d.last_state_date.toLocaleDateString();
            }
          });
          this.clusters = data;
          this.isLoading = false;
        })
        .catch(e => this.setError(e));
    },
    clusterTypeClassTag(clusterType) {
      const defaultClass = ["tag"];

      if (!clusterType) {
        return defaultClass.join(" ");
      }

      const mapClusterTypeClass = {
        ETA: "is-info",
        CUMA: "is-danger",
        FARM: "is-warning",
        CONCESSIONNAIRE: "is-primary"
      };

      defaultClass.push(mapClusterTypeClass[clusterType.toUpperCase()] || "is-dark");
      return defaultClass.join(" ");
    },
    stateClassTag(state) {
      const defaultClass = ["tag"];
      if (!state) {
        return defaultClass.join(" ");
      }

      defaultClass.push(CLUSTER_STATE_CSS_CLASS[state.toUpperCase()] || "is-dark");
      return defaultClass.join(" ");
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  mounted() {
    document.title = "Liste des exploitations";
  }
};
</script>
<style scoped>
.title-container {
  margin-top: 50px;
  margin-bottom: 20px;
}
.title-container h1 {
  color: white;
}
.hero {
  background: rgba(54, 54, 54, 0.95) !important;
}
.clusters {
  background: white;
}
.field:not(:last-child) {
  margin-right: 20px;
}
td > span > a {
  display: block;
  color: #3273dc;
}

.label {
  color: #d3d3d3;
}
.filter-by-state .has-addons {
  flex-wrap: wrap;
}
.filter-by-state .has-addons .control {
  margin-top: 5px;
}
</style>
