<template>
  <div id="container">
    <b-collapse :open="false" class="card" v-for="(ticket, index) in sortHubspotTickets" :key="index">
      <div slot="trigger" class="card-header" role="button">
        <p style="justify-content: space-between; align-items: center; display: flex" class="card-header-title">
          <span>
            [{{
              ticket.properties.createdate ? ticket.properties.createdate.toLocaleString() : "[Pas de date définie]"
            }}]
            {{
              ticket.properties.subject === null || ticket.properties.subject === ""
                ? "[Pas de sujet défini]"
                : ticket.properties.subject
            }}
          </span>
          <a
            class="button is-info is-small"
            target="_blank"
            :href="`https://app.hubspot.com/contacts/4904226/record/0-5/${ticket.id}`"
          >
            Voir le ticket
          </a>
        </p>
      </div>
      <div class="card-content">
        <div class="content">
          {{
            ticket.properties.content === null || ticket.properties.content === ""
              ? "[Pas de contenu défini]"
              : ticket.properties.content
          }}
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: ["hubspotTickets"],
  mounted() {
    this.sortHubspotTickets = this.hubspotTickets
      .map(a => {
        return {
          id: a.id,
          properties: {
            subject: a.properties.subject,
            content: a.properties.content,
            createdate: a.properties.createdate ? new Date(a.properties.createdate) : null
          }
        };
      })
      .sort((a, b) => {
        return new Date(b.properties.createdate) - new Date(a.properties.createdate);
      });
  },
  data() {
    return {
      sortHubspotTickets: [],
      tableLoading: false
    };
  }
};
</script>

<style scoped>
#container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.card {
  margin: 10px 0;
}
</style>
