<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des Chauffeurs
          </h1>
          <div class="buttons has-addons">
            <a class="button" @click.prevent="() => (isModalActive = true)">
              Lier un chauffeur
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <b-table
        :data="usersHistory"
        :loading="tableLoading"
        :default-sort="['from_date', 'desc']"
        paginated
        per-page="20"
        :total="usersHistory.length"
      >
        <b-table-column field="name" label="Nom" v-slot="props" sortable>
          {{ getUserName(props.row.user_id) }}
        </b-table-column>
        <b-table-column field="email" label="Email" v-slot="props" sortable>
          {{ getUserEmail(props.row.user_id) }}
        </b-table-column>
        <b-table-column field="from_date" label="Date de début" v-slot="props" sortable>
          {{ formatDate(props.row.from_date) }}
        </b-table-column>
        <b-table-column field="to_date" label="Date de fin" v-slot="props" sortable>
          {{ formatDate(props.row.to_date) }}
        </b-table-column>
        <b-table-column field="actions" label="Actions" v-slot="props">
          <div v-if="formatDate(props.row.to_date) === '-'">
            <b-button type="is-danger" @click="() => unlinkCurrentUser()">
              <b-icon icon="link-off" size="is-small"></b-icon>
            </b-button>
          </div>
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info">
            Aucun chauffeur n'a été lié à ce beacon
          </b-message>
        </template>
      </b-table>
    </div>
    <LinkUserModal
      :isActive="isModalActive"
      :users="clusterUsers"
      :beacon="beacon"
      :onCloseModal="() => (isModalActive = false)"
      :onSuccess="onLinkUserSuccess"
    />
  </div>
</template>

<script>
import { getBeaconUsersByBeaconUuid, unLinkBeaconToUser } from "Api/beaconUser";
import { getUsersByIds } from "Api/user";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";
import LinkUserModal from "Components/beacons/LinkUserToBeaconModal";

export default {
  props: ["beacon", "clusterUsers"],
  components: { LinkUserModal },
  data() {
    return {
      usersHistory: [],
      usersList: {},
      tableLoading: false,
      isModalActive: false
    };
  },
  mounted() {
    this.loadHistory();
  },
  methods: {
    onLinkUserSuccess() {
      this.loadHistory();
      this.isModalActive = false;
    },
    unlinkCurrentUser() {
      const { apiClient } = this.$store.getters;
      this.tableLoading = true;
      unLinkBeaconToUser(apiClient)(this.beacon.uuid).then(() => {
        this.tableLoading = false;
        this.loadHistory();
      });
    },
    loadHistory() {
      const { apiClient } = this.$store.getters;
      this.tableLoading = true;
      getBeaconUsersByBeaconUuid(apiClient)(this.beacon.uuid)
        .then(({ data }) => {
          this.usersHistory = (data || []).map(h => {
            return {
              ...h,
              from_date: h.from_date ? new Date(h.from_date) : null,
              to_date: h.to_date ? new Date(h.to_date) : null
            };
          });
        })
        .catch(e => {
          this.setError({
            message: "Impossible de récupérer l'historique des chauffeurs <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    getUserName(id) {
      return (this.usersList[id] && this.usersList[id].fullname) || "-";
    },
    getUserEmail(id) {
      return (this.usersList[id] && this.usersList[id].email) || "-";
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  watch: {
    beacon() {
      this.loadHistory();
    },
    usersHistory() {
      const { apiClient } = this.$store.getters;
      const userIds = this.usersHistory.map(h => h.user_id);
      getUsersByIds(apiClient)(userIds)
        .then(({ data }) => {
          this.tableLoading = false;
          this.usersList = data.reduce((acc, e) => {
            acc[e.id] = e;
            return acc;
          }, {});
        })
        .catch(e => {
          this.setError({
            message: "Impossible de récupérer les informations des utilisateurs de l'historique <br />",
            error: e
          });
        });
    }
  }
};
</script>
