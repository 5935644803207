<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadUserSessions" @submit.prevent="loadUserSessions">
        <b-field grouped>
          <b-field label="Depuis le">
            <b-datepicker v-model="sessionFromDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
          <b-field label="Jusqu'au">
            <b-datepicker v-model="sessionToDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
        </b-field>
        <button class="button is-success">
          Rechercher
        </button>
      </form>
    </div>
    <b-table
      v-show="sessions.length > 0"
      :data="sessions"
      :loading="tableLoading"
      :default-sort="['from_date_date', 'asc']"
      paginated
      per-page="20"
      :total="sessions.length"
      detailed
      :show-detail-icon="true"
      ref="table"
    >
      <template slot="empty">
        <b-message type="is-info">
          Aucune session
        </b-message>
      </template>
      <b-table-column v-slot="props" field="id" label="ID" sortable>
        <template>
          <a @click="toggleRow(props.row)"> {{ props.row.id }} </a>
        </template>
      </b-table-column>
      <b-table-column v-slot="props" field="beacon_uuid" label="Beacon" sortable>
        <router-link class="button is-text" :to="`/beacon/${props.row.beacon_uuid}`">
          {{ formatDeviceName(props.row) }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="motor_duration" label="Temps moteur (min)" sortable>
        {{ formatDuration(props.row.motor_duration) }}
      </b-table-column>
      <b-table-column v-slot="props" field="distance" label="Distance (m)" sortable>
        {{ props.row.distance }}
      </b-table-column>
      <b-table-column v-slot="props" field="from_date_formatted" label="Date de début" sortable>
        {{ formatDate(props.row.from_date_formatted) }}
      </b-table-column>
      <b-table-column v-slot="props" field="to_date_formatted" label="Date de fin" sortable>
        {{ formatDate(props.row.to_date_formatted) }}
      </b-table-column>
      <b-table-column v-slot="props" field="device_session_uuid" label="Device session" sortable>
        {{ props.row.device_session_uuid }}
      </b-table-column>
      <b-table-column v-slot="props" field="device_session_status" label="Status de la device session" sortable>
        {{ props.row.device_session_status }}
      </b-table-column>

      <b-table-column v-slot="props" field="action" label="Action" sortable>
        <b-button @click="() => selectSession(props.row)">
          <b-icon icon="map-marker" />
        </b-button>
      </b-table-column>
      <template #detail="props">
        <b-table
          :data="props.row.related"
          :default-sort="['from_date', 'desc']"
          :paginated="props.row.related && props.row.related.length > 20"
          per-page="20"
          :total="props.row.related.length"
        >
          <b-table-column v-slot="props" field="id" label="Session ID" sortable>
            {{ props.row.id }}
          </b-table-column>
          <b-table-column v-slot="props" field="device_instance_name" label="Device" sortable>
            <router-link class="button is-text" :to="getDeviceLink(props.row)">
              {{ formatDeviceName(props.row) }}
            </router-link>
          </b-table-column>
          <b-table-column v-slot="props" field="motor_duration" label="Temps moteur (minutes)" sortable>
            {{ formatDuration(props.row.motor_duration) }}
          </b-table-column>
          <b-table-column v-slot="props" field="distance" label="Distance" sortable>
            {{ props.row.distance }}
          </b-table-column>
          <b-table-column v-slot="props" field="from_date_formatted" label="Date de début" sortable>
            {{ formatDate(props.row.from_date_formatted) }}
          </b-table-column>
          <b-table-column v-slot="props" field="to_date_formatted" label="Date de fin" sortable>
            {{ formatDate(props.row.to_date_formatted) }}
          </b-table-column>
          <b-table-column v-slot="props" field="device_session_uuid" label="Device session" sortable>
            {{ props.row.device_session_uuid }}
          </b-table-column>
          <b-table-column v-slot="props" field="device_session_status" label="Status de la device session" sortable>
            {{ props.row.device_session_status }}
          </b-table-column>

          <template slot="empty">
            <b-message type="is-info">
              <p>Aucunes sessions lié à la session de l'utilisateur</p>
            </b-message>
          </template>
        </b-table>
      </template>
    </b-table>

    <b-modal :active="isModalActive" :on-cancel="() => (isModalActive = false)" width="90%">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Détails de l'équipement session</p>
        </header>
        <section class="modal-card-body">
          <div class="columns is-multiline is-gapless">
            <div class="column field is-half">
              <label class="label">Beacon uuid</label>
              <span>
                <router-link class="button is-text" :to="getDeviceLink(selectedSession)">
                  {{ formatDeviceName(selectedSession) }}
                </router-link>
              </span>
            </div>
            <div class="column field is-half">
              <label class="label">Exploitation</label>
              <span>
                <router-link class="button is-text" :to="`/cluster/${selectedSession.cluster_id}`">
                  {{ selectedSession.cluster ? selectedSession.cluster.name : selectedSession.cluster_id }}
                </router-link>
              </span>
            </div>
            <div class="column field is-half">
              <label class="label">Karnott à côté du beacon</label>
              <span>
                <router-link class="button is-text" :to="`/device/${selectedSession.device_instance_id}`">
                  {{ selectedSession.device ? selectedSession.device.name : selectedSession.device_instance_id }}
                </router-link>
              </span>
            </div>
            <div class="column field is-half">
              <label class="label">Device session</label>
              <pre>
                <b> ID : </b> <span> {{ selectedSession.device_session_uuid }} </span>
                <b> Statut : </b> <span> {{ selectedSession.device_session_status }} </span>
              </pre>
            </div>
            <div class="column field is-half">
              <label class="label">Temps moteur (en minutes)</label>
              <pre>
                <b> Total : </b> <span> {{ formatDuration(selectedSession.motor_duration) }} </span> 
                <b> Hors parcelle : </b> <span> {{ formatDuration(selectedSession.out_parcel_motor_duration) }} </span>
              </pre>
            </div>
            <div class="column field is-half">
              <label class="label">Temps en mouvement (en minutes)</label>
              <pre>
                <b> Total : </b> <span> {{ formatDuration(selectedSession.movement_duration) }} </span> 
              </pre>
            </div>
            <div class="column field is-half">
              <label class="label">Période</label>
              <pre>
                <span> Du {{ formatDate(selectedSession.from_date_formatted) }} au {{ formatDate(selectedSession.to_date_formatted) }} </span>
              </pre>
            </div>
            <div class="column field is-half">
              <label class="label">Durée de la session (en minutes)</label>
              <pre>
                <b> Total : </b> <span> {{ sessionDuration(selectedSession) }} </span>
                <b> Hors parcelle : </b> <span> {{ formatDuration(selectedSession.out_parcel_duration) }} </span>
              </pre>
            </div>
            <div class="column field is-half">
              <label class="label">Distance (en mètres)</label>
              <pre>
                <b> Total : </b> <span> {{ selectedSession.distance }} </span> 
                <b> Hors parcelle : </b> <span> {{ selectedSession.out_parcel_distance }} </span>
              </pre>
            </div>
            <div class="column field is-half">
              <label class="label">Unit</label>
              <pre>
                <b> Total : </b> <span> {{ selectedSession.unit }} </span>
              </pre>
            </div>
            <div class="column field">
              <label class="label">Sessions liées</label>
              <div class="box">
                <b-table
                  :data="selectedSession.related"
                  :default-sort="['from_date', 'desc']"
                  :paginated="selectedSession.related && selectedSession.related.length > 20"
                  per-page="20"
                  :total="selectedSession.related && selectedSession.related.length"
                >
                  <b-table-column v-slot="props" field="id" label="Session ID" sortable>
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column v-slot="props" field="device_instance_name" label="Device" sortable>
                    <router-link class="button is-text" :to="getDeviceLink(props.row)">
                      {{ formatDeviceName(props.row) }}
                    </router-link>
                  </b-table-column>
                  <b-table-column v-slot="props" field="motor_duration" label="Temps moteur (minutes)" sortable>
                    {{ formatDuration(props.row.motor_duration) }}
                  </b-table-column>
                  <b-table-column v-slot="props" field="distance" label="Distance" sortable>
                    {{ props.row.distance }}
                  </b-table-column>
                  <b-table-column v-slot="props" field="from_date_formatted" label="Date de début" sortable>
                    {{ formatDate(props.row.from_date_formatted) }}
                  </b-table-column>
                  <b-table-column v-slot="props" field="to_date_formatted" label="Date de fin" sortable>
                    {{ formatDate(props.row.to_date_formatted) }}
                  </b-table-column>
                  <b-table-column v-slot="props" field="device_session_uuid" label="Device session" sortable>
                    {{ props.row.device_session_uuid }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="device_session_status"
                    label="Status de la device session"
                    sortable
                  >
                    {{ props.row.device_session_status }}
                  </b-table-column>

                  <template slot="empty">
                    <b-message type="is-info">
                      <p>Aucunes sessions lié à la session de l'utilisateur</p>
                    </b-message>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
          <div id="map-container">
            <b-loading :is-full-page="false" :active.sync="isEventPointLoading" />
            <b-message type="is-danger" v-if="mapErrorMessage !== null">
              {{ mapErrorMessage }}
            </b-message>
            <div id="map-row">
              <div id="event-point-map"></div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* global google */
import { getUserSessions } from "Api/user";
import { getClustersByIds } from "Api/cluster";
import { getDevicesByIds } from "Api/device";
import { getSessionPoints } from "Api/report";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate, formatInputDates } from "Utils";
import { drawDataOnMap } from "Utils/map";

export default {
  props: ["user"],
  data() {
    return {
      sessions: [],
      tableLoading: false,
      sessionFromDate: null,
      sessionToDate: null,
      selectedSession: {},
      isEventPointLoading: false,
      mapErrorMessage: null,
      isModalActive: false
    };
  },
  mounted() {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 5);
    this.sessionFromDate = fromDate;
    this.sessionToDate = new Date();
  },
  methods: {
    selectSession(session) {
      this.isModalActive = true;
      this.selectedSession = session;
      this.isEventPointLoading = true;
      const { apiClient } = this.$store.getters;
      getSessionPoints(apiClient)(session.id)
        .then(({ data }) => {
          this.isEventPointLoading = false;
          if (data === null) {
            this.mapErrorMessage = "Aucun point pour cette session";
            return;
          }
          const map = new google.maps.Map(document.getElementById("event-point-map"), {
            zoom: 3,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            tilt: 0,
            rotateControl: false
          });
          drawDataOnMap(
            data,
            map,
            {
              drawPolyline: true,
              drawMarker: true,
              color: "#f9cd4d",
              polilyneColor: "#4df9a3",
              focusOn: true
            },
            this.user
          );
        })
        .catch(e => {
          this.isEventPointLoading = false;
          this.setError({
            message: "Impossible de récupérer les points de la session <br />",
            error: e
          });
        });
    },
    truncateDatesFilter() {
      this.sessionFromDate.setHours(0);
      this.sessionFromDate.setMinutes(0);
      this.sessionFromDate.setSeconds(0);

      this.sessionToDate.setHours(23);
      this.sessionToDate.setMinutes(59);
      this.sessionToDate.setSeconds(59);
    },
    loadUserSessions() {
      const { apiClient } = this.$store.getters;
      this.truncateDatesFilter();
      const { sessionFromDate, sessionToDate } = this;
      const { fromDate, toDate } = formatInputDates(sessionFromDate, sessionToDate);
      if (fromDate === null || toDate === null) {
        return;
      }
      this.tableLoading = true;
      getUserSessions(apiClient)(this.user.id, { fromDate, toDate, withTracks: true })
        .then(({ data }) => {
          this.tableLoading = false;
          let clusterP = { data: [] };
          let deviceP = { data: [] };
          if (data === null || data.length === 0) {
            this.sessions = [];
            return Promise.all([clusterP, deviceP]);
          }
          const sessions = data[0].sessions;

          const clusterIdsObj = {};
          const deviceIdsObj = {};
          this.sessions = sessions.map(d => {
            clusterIdsObj[d.cluster_id] = true;
            deviceIdsObj[d.device_instance_id] = true;
            return {
              ...d,
              from_date_formatted: d.from_date ? new Date(d.from_date) : null,
              to_date_formatted: d.to_date ? new Date(d.to_date) : null,
              related: (d.related || []).map(r => {
                clusterIdsObj[r.cluster_id] = true;
                deviceIdsObj[r.device_instance_id] = true;
                return {
                  ...r,
                  from_date_formatted: r.from_date ? new Date(r.from_date) : null,
                  to_date_formatted: r.to_date ? new Date(r.to_date) : null
                };
              })
            };
          });
          const clusterIds = Object.keys(clusterIdsObj);
          if (clusterIds.length > 0) {
            clusterP = getClustersByIds(apiClient)(clusterIds);
          }
          const deviceIds = Object.keys(deviceIdsObj);
          if (deviceIds.length > 0) {
            deviceP = getDevicesByIds(apiClient)(deviceIds);
          }

          return Promise.all([clusterP, deviceP]);
        })
        .then(([{ data: clusters }, { data: devices }]) => {
          if ((!clusters || clusters.length === 0) && (!devices || devices.length === 0)) {
            return;
          }

          this.sessions = this.sessions.map(s => {
            const cluster = clusters.find(c => c.id === s.cluster_id);
            const device = devices.find(d => d.id === s.device_instance_id);
            return {
              ...s,
              cluster,
              device,
              related: s.related.map(r => {
                const cluster = clusters.find(c => c.id === r.cluster_id);
                const device = devices.find(d => d.id === s.device_instance_id);
                return {
                  ...r,
                  cluster,
                  device
                };
              })
            };
          });
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError({
            message: "Impossible de récupérer les sessions <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    toggleRow(row) {
      this.$refs.table.toggleDetails(row);
    },
    getDeviceLink(session) {
      if (!!session.beacon_uuid && session.beacon_uuid !== "") {
        return `/beacon/${session.beacon_uuid}`;
      }
      return `/device/${session.device_instance_id}`;
    },
    formatDeviceName(session) {
      if (!!session.beacon_uuid && session.beacon_uuid !== "") {
        return session.device_instance_name.slice(-4);
      }
      return session.device_instance_name;
    },
    formatDuration(durationInSec, outputFormat = "m") {
      const durationInMinutes = durationInSec / 60;
      if (outputFormat === "m") {
        return parseInt(durationInMinutes);
      }
      // format in hours
      return durationInMinutes / 60;
    },
    sessionDuration(session) {
      if (!session.to_date_formatted || !session.from_date_formatted) {
        return 0;
      }
      return parseInt(
        (Math.floor(session.to_date_formatted / 1000) - Math.floor(session.from_date_formatted / 1000)) / 60
      );
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
#map-row {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}
#event-point-map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
pre {
  margin: 10px 10px 10px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
