<template>
  <div class="container is-fullhd">
    <div class="container title-container">
      <h1 class="title">
        Liste des utilisateurs

        <div class="buttons">
          <button class="button is-success" @click="showModal()">
            <span class="icon is-small"> <i class="mdi mdi-plus mdi-12px"></i></span>
          </button>
          <button class="button is-info" @click="exportUserList()">
            <span class="icon is-small"> <i class="mdi mdi-download mdi-12px"></i></span>
          </button>
        </div>
      </h1>
    </div>
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Rechercher un utilisateur
          </h1>
          <div class="columns is-multiline">
            <div class="column is-half">
              <label class="label">Rechercher par nom</label>
              <b-field>
                <b-input
                  v-model="searchParams.email"
                  @input="filtersUpdated(300)"
                  placeholder="contact@karnott.fr"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-full">
              <b-button
                type="is-success"
                size="is-medium"
                class="is-fullwidth"
                @click="filtersUpdated()"
                :loading="isLoading"
              >
                Rechercher
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="user-table">
      <UsersTable :users="users" :isLoading="isLoading" :cssClassByRole="cssClassByRole" roleField="application_role" />
    </div>

    <b-modal :active.sync="isModalActive">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Ajouter un utilisateur</p>
        </header>
        <section class="modal-card-body">
          <CreateForm :user="newUser" :afterSubmit="submitCreateUserForm" />
        </section>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { searchUsers, getUsers, exportUser } from "Api/user";
import { ADMIN_USER_ROLE, CARE_USER_ROLE, SALE_USER_ROLE, USER_ROLE } from "Constants";
import { cssClassByUserRole } from "Utils";
import UsersTable from "Components/users/UsersTable";
import CreateForm from "Components/users/Form";

let searchTimeout = null;
export default {
  components: { UsersTable, CreateForm },
  data() {
    return {
      users: [],
      searchParams: {
        email: ""
      },
      isLoading: false,
      newUser: {
        email: "",
        firstname: "",
        lastname: "",
        application_role: USER_ROLE
      },
      isModalActive: false,
      ROLES: [ADMIN_USER_ROLE, CARE_USER_ROLE, SALE_USER_ROLE, USER_ROLE]
    };
  },
  mounted() {
    document.title = "Liste utilisateurs";
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    showModal() {
      this.isModalActive = true;
    },
    submitCreateUserForm(user) {
      this.users = [user, ...this.users];
      this.isModalActive = false;
      this.setSuccess({
        message: "L'utilisateur a été créé"
      });
      this.newUser = {
        email: "",
        firstname: "",
        lastname: "",
        application_role: USER_ROLE
      };
    },
    cssClassByRole(role) {
      return cssClassByUserRole(role);
    },
    searchUsers(email) {
      const { apiClient } = this.$store.getters;
      let promise = null;
      this.isLoading = true;
      if (email && email !== "") {
        promise = searchUsers(apiClient)(email);
      } else {
        promise = getUsers(apiClient);
      }
      promise
        .then(({ data }) => {
          this.users = data;
          this.isLoading = false;
        })
        .catch(e => {
          this.setError(e);
          this.isLoading = false;
        });
    },
    filtersUpdated(timeout = 0) {
      const { email } = this.searchParams;
      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => this.searchUsers(email), timeout);
    },
    exportUserList() {
      const { apiClient } = this.$store.getters;
      exportUser(apiClient)().then(response => {
        const link = document.createElement("a");
        const headers =
          "Email;Prénom;Nom;Numéro de Téléphone;Exploitation;Type de l'exploitation;Exploitation parente;Type de l'exploitation parente;Nombre de parcelles;Utilisateur de l'application mobile ?;Accepte les communications?\n";
        link.href = "data:text/csv;charset=utf-8," + headers + encodeURIComponent(response.data);
        const date = new Date();
        link.download = `users_${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}.csv`;
        link.click();
      });
    }
  }
};
</script>

<style scoped>
.title-container {
  margin-top: 50px;
  margin-bottom: 20px;
}
.title-container h1 {
  color: white;
}
.title-container h1 .buttons {
  display: inline;
}
.hero {
  background: rgba(54, 54, 54, 0.95) !important;
}
#user-table {
  padding: 10px;
  background: white;
}
</style>
