<template>
  <div class="item item--with-submenu">
    {{ label }}
    <b-icon icon="menu-down"></b-icon>
    <div class="submenu">
      <template v-for="e in entries">
        <router-link v-if="e.type === 'VUE_ROUTE'" v-bind:to="e.menu.menuPath" class="item" :key="e.menu.menuLabel">
          {{ e.menu.menuLabel }}
        </router-link>
        <MenuLink
          v-else-if="e.type === 'RUBY_ROUTE'"
          :path="e.menu.menuPath"
          :label="e.menu.menuLabel"
          :key="e.menu.menuLabel"
        />
      </template>
    </div>
  </div>
</template>

<script>
import MenuLink from "Components/semantic-menu/MenuLink";
import { IS_VUE_ROUTE_ACTION } from "Stores/routes";
import { mapActions } from "vuex";

export default {
  components: {
    MenuLink
  },
  props: ["label", "subMenu"],
  name: "sub-menu",
  mounted() {
    Promise.all(
      this.subMenu.map(m =>
        this.isVueRoute(m)
          .then(isVueRoute => (isVueRoute ? "VUE_ROUTE" : m.menuPath ? "RUBY_ROUTE" : "NONE"))
          .then(type => ({ type, menu: m }))
      )
    ).then(entries => {
      this.entries = entries;
    });
  },
  data() {
    return {
      entries: []
    };
  },
  methods: mapActions({
    isVueRoute: IS_VUE_ROUTE_ACTION
  })
};
</script>

<style scoped>
.item--with-submenu {
  display: flex;
  align-items: center;
}
.item--with-submenu:hover .submenu {
  display: flex !important;
}
.submenu:hover {
  display: flex !important;
}
.submenu {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 40px;
  padding: 20px 20px 0 20px;
  margin-left: -20px;
  background: black;
}
.submenu .item {
  padding: 10px 0px;
  color: white;
  cursor: pointer;
}
.submenu .item:hover {
  color: #b0b0b0;
}
</style>
