<template>
  <b-modal :active="isActive" :onCancel="closeModal" has-modal-card>
    <div class="modal-card js-update-associate-date-modal">
      <header class="modal-card-head">Modifier la date d'association</header>
      <section class="modal-card-body">
        <form @submit="onSubmit">
          <b-field label="Modifier la date d'association">
            <b-datepicker
              v-model="date"
              placeholder="Select..."
              icon="calendar-today"
              ref="associateDate"
              @input="() => setFocus('associateDate')"
            />
          </b-field>
          <b-field label="Choisir l'heure">
            <b-clockpicker
              rounded
              v-model="time"
              placeholder="13:37"
              icon="clock"
              :hour-format="format"
            ></b-clockpicker>
          </b-field>
          <button class="button is-success">
            Modifier
          </button>
        </form>
      </section>
    </div>
  </b-modal>
</template>

<script>
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { updateAssociateDate } from "Api/device";
import { mapActions } from "vuex";

export default {
  props: ["device", "currentDate", "isActive", "closeModalAction"],
  data() {
    return {
      date: null,
      time: null,
      format: 24
    };
  },
  watch: {
    isActive() {
      this.date = this.currentDate;
    }
  },
  methods: {
    setFocus(ref) {
      // need to set focus on datetimepicker
      // when value is changed
      this.$refs[ref].focus();
    },
    closeModal() {
      this.closeModalAction(null);
    },
    onSubmit(e) {
      e.preventDefault();
      this.updateDate();
    },
    updateDate() {
      let { date } = this;
      if (!date) {
        return;
      }
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      if (this.time) {
        date.setHours(this.time.getHours());
        date.setMinutes(this.time.getMinutes());
      }
      const formattedDate = date.toJSON().replace(/\d{2}\.\d{3}Z/, "00Z");
      const { apiClient } = this.$store.getters;
      updateAssociateDate(apiClient)(this.device.id, formattedDate)
        .then(({ data }) => {
          this.closeModalAction(new Date(data.associate_date));
          this.setSuccess({
            message: "L'exploitation a bien été liée au Karnott"
          });
          this.date = null;
        })
        .catch(e => {
          this.setError({
            message: "Impossible de lier le cluster au Karnott <br />",
            error: e
          });
        });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  }
};
</script>
<style>
.js-update-associate-date-modal {
  overflow: visible !important;
}
.js-update-associate-date-modal > section {
  overflow: visible;
}
</style>
