<template>
  <b-modal :active="isModalActive" :on-cancel="closeModal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Historique du Karnott [{{ device && device.serialnumber }}]</p>
      </header>
      <section class="modal-card-body">
        <b-table :loading="isModalLoading" :data="deviceHistory">
          <b-table-column v-slot="props" field="clusterName" label="Exploitation" sortable>
            <router-link :to="`/cluster/${props.row.cluster_id}`"> {{ props.row.clusterName }} </router-link>
          </b-table-column>
          <b-table-column v-slot="props" field="from_date" label="Date de début" sortable>
            <span>
              {{ formatDate(props.row.from_date) }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" field="to_date" label="Date de fin" sortable>
            <span>
              {{ formatDate(props.row.to_date) }}
            </span>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Aucun historique</p>
              </div>
            </section>
          </template>
        </b-table>
      </section>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { getDeviceHasClusterHistory } from "Api/deviceHasCluster";
import { formatDate } from "Utils";

export default {
  props: ["clusters", "device", "isDeviceOk", "isModalActive", "onClose"],
  data() {
    return {
      deviceHistory: [],
      isModalLoading: false
    };
  },
  watch: {
    device: function() {
      if (this.device !== null && this.isModalActive) {
        this.showHistory();
      }
    }
  },
  methods: {
    setCurrentDeviceHistory(data) {
      this.deviceHistory = (data || []).map(d => {
        const cluster = this.clusters.find(c => c.id === d.cluster_id);
        d.clusterName = cluster && cluster.name;
        d.from_date = new Date(d.from_date);
        d.to_date = new Date(d.to_date);
        return d;
      });
    },
    showHistory() {
      const { apiClient } = this.$store.getters;
      this.isModalLoading = true;
      getDeviceHasClusterHistory(apiClient)(this.device.id)
        .then(({ data }) => {
          this.isModalLoading = false;
          this.setCurrentDeviceHistory(data);
        })
        .catch(e => {
          this.isModalLoading = false;
          this.setError(e);
        });
    },
    formatDate(date) {
      if (!date) {
        return "-";
      }
      return formatDate(date);
    },
    closeModal() {
      this.deviceHistory = [];
      if (this.onClose) {
        this.onClose();
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  }
};
</script>
