export const getBeacons = client => params => client.get("/beacons", { params });
export const getBeaconByUuid = client => uuid => client.get(`/beacons/${uuid}`);
export const updateBeaconAssociateDate = client => (uuid, associateDate) =>
  client.put(`/beacons/${uuid}/update_associate_date`, { associate_date: associateDate });
export const importBeacons = client => csv =>
  client.post(`/beacons/import_csv`, csv, { headers: { "Content-Type": "text/csv;charset=utf-8" } });
export const getBeaconEventPoints = client => (uuid, params) => client.get(`/beacons/${uuid}/event_points`, { params });
export const getBeaconDeviceSessions = client => (uuid, params) =>
  client.get(`/beacons/${uuid}/device_sessions`, { params });
export const getBeaconDeviceRanges = client => (uuid, params) =>
  client.get(`/beacons/${uuid}/beacon_device_ranges`, { params });
export const getBeaconData = client => (uuid, params) => client.get(`/beacons/${uuid}/data`, { params });
