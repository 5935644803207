<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Lier une ou plusieurs exploitations</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              Pour ajouter une exploitation, il est important de la sélectionner dans la liste (en cliquant sur le nom
              de l'exploitation ou en déplacant le curseur et en appuyant sur la touche 'Entrée').
            </div>
          </div>
          <b-field :message="fieldMessage()" label="Selectionner les exploitations">
            <b-taginput
              :allow-new="false"
              :data="clustersWithLabel"
              :allow-duplicates="false"
              field="label"
              icon="label"
              placeholder="Selectionner/Ajouter une exploitation"
              autocomplete
              v-model="selectedClusters"
              @typing="filterAction"
              @add="onAdd"
            >
            </b-taginput>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<style scoped>
.modal-card {
  min-height: 250px;
  width: 100%;
}
.emails,
.fullname {
  transition: opacity 0.3s;
}
.show {
  opacity: 1;
}
.fullname.hide {
  opacity: 0;
}
.emails.hide {
  opacity: 0.1;
}
</style>
<script>
import { mapActions } from "vuex";
import { searchClusters, linkClusterToCluster } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { FARM_CLUSTER_TYPE } from "Constants";
import { callApiWithSignal } from "Utils";

let timeout = null;
let searchClustersSignal = null;

export default {
  props: ["cluster", "isActive", "onLink", "onClose"],
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    fieldMessage() {
      const { selectedClusters } = this;
      return `${selectedClusters.length} exploitation(s) selectionnée(s)`;
    },
    closeModal() {
      this.selectedClusters = [];
      this.onClose();
    },
    onAdd(c) {
      if (c.id) {
        return;
      }
      this.selectedClusters = this.selectedClusters.map(c1 => {
        if (c1 === c) {
          return { name: c1, cluster_type: FARM_CLUSTER_TYPE };
        }
        return c1;
      });
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { cluster, selectedClusters } = this;
      Promise.all(
        selectedClusters.map(c => {
          if (c.id) {
            return linkClusterToCluster(apiClient)(c.id, cluster.id);
          }
        })
      )
        .then(() => {
          this.setSuccess({
            message: "Les exploitations ont bien été liées"
          });
          if (this.onLink !== undefined) {
            this.onLink();
          }
          this.closeModal();
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    filterAction(filter) {
      const { apiClient } = this.$store.getters;
      if (searchClustersSignal) {
        searchClustersSignal.cancel();
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const { apiFunc, signal } = callApiWithSignal(searchClusters, apiClient);
        searchClustersSignal = signal;
        apiFunc({ name: filter })
          .then(({ data }) => {
            this.clusters = data;
          })
          .catch(e => {
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    }
  },
  computed: {
    clustersWithLabel() {
      return this.clusters.map(c => {
        return {
          ...c,
          label: `[${c.id}] ${c.name}`
        };
      });
    }
  },
  data() {
    return {
      clusters: [],
      selectedClusters: []
    };
  }
};
</script>
