<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadDeviceSessions" @submit.prevent="loadDeviceSessions">
        <b-field grouped>
          <b-field label="Depuis le">
            <b-datepicker v-model="fromDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
          <b-field label="Jusqu'au">
            <b-datepicker v-model="toDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
        </b-field>
        <button class="button is-success">Rechercher</button>
        <b-button type="is-info" icon-left="speedometer" @click="showGenerateSessionModal" class="generate">
          Générer une session
        </b-button>
      </form>
    </div>
    <b-table
      v-show="deviceSessions.length > 0"
      :data="deviceSessions"
      :loading="tableLoading"
      :default-sort="['first_position_date', 'asc']"
      paginated
      per-page="20"
      :total="deviceSessions.length"
      ref="table"
      detailed
      :show-detail-icon="true"
    >
      <b-table-column v-slot="props" field="id" label="ID" sortable>
        <template>
          <a @click="toggleRow(props.row)"> {{ props.row.id }} </a>
        </template>
      </b-table-column>
      <b-table-column v-slot="props" field="status" label="Statut" sortable>
        {{ props.row.status }}
      </b-table-column>
      <b-table-column v-slot="props" field="first_position_date" label="Date de début" sortable>
        {{ formatDate(props.row.first_position_date_formatted) }}
      </b-table-column>
      <b-table-column v-slot="props" field="last_movement_date" label="Date de fin" sortable>
        {{ formatDate(props.row.last_movement_date_formatted) }}
      </b-table-column>
      <b-table-column v-slot="props" field="count_equipment_session" label="Sessions" sortable>
        <span class="tag" v-bind:class="{ 'is-success': props.row.count_equipment_session > 0 }">
          {{ props.row.count_equipment_session }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="action" label="Action" sortable>
        <div class="buttons">
          <b-button @click="() => selectDeviceSession(props.row)">
            <b-icon icon="map-marker" />
          </b-button>
          <b-button @click="() => copyDeviceSessionToBeacon(props.row)">
            <b-icon icon="content-copy" />
          </b-button>
        </div>
      </b-table-column>
      <template v-slot:detail="props">
        <DeviceRangeTable :device_session="props.row.id" />
      </template>
    </b-table>

    <b-modal :active="isModalActive" :on-cancel="() => (isModalActive = false)" width="90%">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Session sur la carte</p>
        </header>
        <section class="modal-card-body">
          <div id="map-container">
            <b-loading :is-full-page="false" :active.sync="isEventPointLoading" />
            <b-message type="is-danger" v-if="mapErrorMessage !== null">
              {{ mapErrorMessage }}
            </b-message>
            <div id="map-row">
              <div id="map-legend">
                <span :style="`color:${r[0]}`" v-for="r in mapLegend" :key="r[0]">
                  <svg width="20px" height="20px">
                    <path d="M 0 0 H 20 V 20 H 0 Z" :fill="r[0]" :stroke="darkerColor(r[0])" />
                  </svg>
                  {{ r[1] }}
                </span>
              </div>
              <div id="event-point-map"></div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <CopySessionToBeaconModal
      :isModalActive="isCopyModalActive"
      :device="device"
      :deviceSession="selectedDeviceSessionToCopy"
      :onClose="() => (isCopyModalActive = false)"
    />

    <GenerateSessionModal
      :isActive="isGenerateSessionModalActive"
      :closeModalAction="closeGenerateSessionModal"
      :device="device"
    />
  </div>
</template>

<script>
/* global google */
import { getDeviceSessions } from "Api/deviceSession";
import { getDeviceSessionEventPoints } from "Api/eventPoint";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate, formatInputDates, dateToLocaleString } from "Utils";
import { drawDataOnMap, darkerColor, gpsColors } from "Utils/map";
import CopySessionToBeaconModal from "Components/devices/CopySessionToBeaconModal";
import DeviceRangeTable from "Components/devices/DeviceRangeTable";
import GenerateSessionModal from "Components/devices/GenerateSessionModal";
const deviceSessionColor = "#f94d4d";

const MANAGED_QUERY_PARAMS = ["fromDate", "toDate"];
export default {
  props: ["device"],
  data() {
    return {
      deviceSessions: [],
      tableLoading: false,
      isModalActive: false,
      selectedDeviceSession: {},
      map: null,
      path: null,
      markers: [],
      eventPoints: [],
      mapLegend: [],
      mapErrorMessage: null,
      isEventPointLoading: false,
      fromDate: null,
      toDate: null,
      selectedDeviceSessionToCopy: null,
      isCopyModalActive: false,
      isGenerateSessionModalActive: false
    };
  },
  mounted() {
    const fromDate = new Date();
    this.syncFromQueryParams(MANAGED_QUERY_PARAMS);
    if (this.fromDate === null) {
      fromDate.setDate(fromDate.getDate() - 5);
      this.fromDate = fromDate;
    }

    if (this.toDate === null) {
      this.toDate = new Date();
    }
    this.loadDeviceSessions();
  },
  methods: {
    selectDeviceSession(session) {
      this.isModalActive = true;
      this.mapLegend = [];
      this.mapErrorMessage = null;
      this.selectedDeviceSession = session;
      this.getMapDataAndBuildMap(session);
    },
    truncateDatesFilter() {
      this.fromDate.setHours(0);
      this.fromDate.setMinutes(0);
      this.fromDate.setSeconds(0);

      this.toDate.setHours(23);
      this.toDate.setMinutes(59);
      this.toDate.setSeconds(59);
    },
    loadDeviceSessions() {
      const { apiClient } = this.$store.getters;
      this.truncateDatesFilter();
      this.updateUrl();
      const { fromDate: fd, toDate: td } = this;
      const { fromDate, toDate } = formatInputDates(fd, td);
      if (fromDate === null || toDate === null) {
        return;
      }
      this.tableLoading = true;
      getDeviceSessions(apiClient)(this.device.id, { from_date: fromDate, to_date: toDate })
        .then(({ data }) => {
          this.tableLoading = false;
          if (data === null) {
            this.deviceSessions = [];
            return {};
          }

          this.deviceSessions = data.map(d => {
            return {
              ...d,
              first_position_date_formatted: d.first_position_date ? new Date(d.first_position_date) : null,
              last_movement_date_formatted: d.last_movement_date ? new Date(d.last_movement_date) : null
            };
          });
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError({
            message: "Impossible de récupérer les device sessions <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    getMapDataAndBuildMap(session) {
      const { apiClient } = this.$store.getters;
      this.isEventPointLoading = true;
      getDeviceSessionEventPoints(apiClient)(session.id)
        .then(({ data }) => {
          this.isEventPointLoading = false;
          if (data === null) {
            this.mapErrorMessage = "Aucun points pour la device session n'a été retourné";
            return;
          }
          const map = this.drawMap();

          const { device_serialnumber } = data[0];
          this.mapLegend.push([deviceSessionColor, `[${device_serialnumber}] Device session`]);
          drawDataOnMap(data, map, {
            drawMarker: true,
            drawPolyline: true,
            color: deviceSessionColor,
            weight: 3,
            opacity: 1,
            focusOn: true
          });
          this.mapLegend.push([gpsColors.goodGPSReceptionColor, `Bonne réception GPS >= 12 satellites`]);
          this.mapLegend.push([gpsColors.averageGPSReceptionColor, `Moyenne réception GPS >= 8 satellites`]);
          this.mapLegend.push([gpsColors.badGPSReceptionColor, `Mauvaise réception GPS < 8 satellites`]);
          drawDataOnMap(data, map, {
            drawMarker: true,
            color: "#f9cd4d",
            weight: 6,
            opacity: 0.9,
            focusOn: true
          });
        })
        .catch(e => {
          this.isEventPointLoading = false;
          this.setError({
            message: "Impossible de récupérer les points de la device session <br />",
            error: e
          });
        });
    },
    darkerColor(color) {
      return darkerColor(color);
    },
    drawMap() {
      return new google.maps.Map(document.getElementById("event-point-map"), {
        zoom: 3,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        tilt: 0,
        rotateControl: false
      });
    },
    toggleRow(row) {
      this.$refs.table.toggleDetails(row);
    },
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      MANAGED_QUERY_PARAMS.forEach(prop => {
        const p = this[prop];
        if (p instanceof Date) {
          query[prop] = dateToLocaleString(p);
        } else {
          query[prop] = p;
        }
      });

      if (this.device) {
        this.$router.push({ path: this.device.id.toString(), query }).catch(() => {});
      }
    },
    syncFromQueryParams(props) {
      props.forEach(prop => {
        let value = this.$route.query[prop];
        if (value && (prop === "toDate" || prop === "fromDate")) {
          value = new Date(value);
        }

        if (value) {
          this[prop] = value;
        }
      });
    },
    copyDeviceSessionToBeacon(deviceSession) {
      this.isCopyModalActive = true;
      this.selectedDeviceSessionToCopy = deviceSession;
    },
    showGenerateSessionModal() {
      this.isGenerateSessionModalActive = true;
    },
    closeGenerateSessionModal() {
      this.isGenerateSessionModalActive = false;
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  components: {
    CopySessionToBeaconModal,
    DeviceRangeTable,
    GenerateSessionModal
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
#map-row {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}
#event-point-map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
.generate {
  margin-left: 20px;
}
</style>
