<template>
  <div v-if="user" class="section">
    <section class="hero is-primary">
      <div class="hero-body">
        <h2 class="title" id="title-name">
          <span class="tag" :class="`${cssClassByRole()} is-large`">
            {{ user.application_role }}
          </span>
          {{ user.fullname }}
          <div class="buttons">
            <a class="button is-info" @click.prevent="showEditModal()">
              <b-icon icon="pencil" />
            </a>
            <a class="button is-warning" @click.prevent="connectAs()">
              <b-icon icon="eye" size="is-small"></b-icon>
            </a>
            <a class="button is-danger" @click.prevent="resetPassword()">
              <b-icon icon="restore" size="is-small"></b-icon>
            </a>
          </div>
        </h2>
        <div class="columns is-multiline is-gapless">
          <div class="column field is-one-third">
            <label class="label">Email</label>
            <span>{{ user.email }}</span>
          </div>
          <div class="column field is-one-third">
            <label class="label">Téléphone</label>
            <span>{{ user.phonenumber }}</span>
          </div>
          <div class="column field is-one-third">
            <label class="label">Utilise l'application mobile</label>
            <span>{{ isUsingMobileAppText() }}</span>
          </div>

          <div v-show="user.application_role === SALE_USER_ROLE" class="column field is-half">
            <label class="label">Adresse (metadata seulement éditable pour les commerciaux)</label>
            <span>{{ (userMetadata && userMetadata.address) || "Aucune adresse définie" }}</span>
          </div>
        </div>
      </div>
      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth">
          <div class="container">
            <ul>
              <li @click="showTab(ConnectionsTab)" :class="cssClassForTab(ConnectionsTab)">
                <a>Connexions</a>
              </li>
              <li @click="showTab(ClustersTab)" :class="cssClassForTab(ClustersTab)">
                <a>
                  Exploitations liées à l'utilisateur
                  <button
                    @click="showLinkModal"
                    class="button is-info is-small show-link-cluster-modal"
                    v-show="isTabActive(ClustersTab)"
                  >
                    <b-icon icon="plus" size="is-small" />
                  </button>
                </a>
              </li>
              <li @click="showTab(PendingClustersTab)" :class="cssClassForTab(PendingClustersTab)">
                <a>Exploitations en attente liées à l'utilisateur</a>
              </li>
              <li @click="showTab(EmailsTab)" :class="cssClassForTab(EmailsTab)">
                <a>Email envoyés</a>
              </li>
              <li @click="showTab(SessionsTabs)" :class="cssClassForTab(SessionsTabs)">
                <a>Sessions</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
    <section>
      <div class="has-background-white">
        <div id="connections" v-show="isTabActive(ConnectionsTab)">
          <b-table :loading="isLoading" :data="connections" :per-page="10" paginated>
            <template slot="empty">
              <b-message type="is-info"> Aucune connexion </b-message>
            </template>
            <b-table-column v-slot="props" field="client" label="ID Client" sortable>
              {{ props.row.client }}
            </b-table-column>
            <b-table-column v-slot="props" field="client_extra" label="Nom Client" sortable>
              {{ props.row.client_extra }}
            </b-table-column>
            <b-table-column v-slot="props" field="scope" label="Scope" sortable>
              {{ props.row.scope }}
            </b-table-column>
            <b-table-column v-slot="props" field="created_at_date" label="Créé le" sortable>
              {{ props.row.created_at }}
            </b-table-column>
          </b-table>
        </div>

        <div id="clusters" v-show="isTabActive(ClustersTab)">
          <b-table :loading="isLoading" :data="clusters" :per-page="10" paginated>
            <template slot="empty">
              <b-message type="is-info"> Aucune exploitation liée à l'utilisateur </b-message>
            </template>
            <b-table-column v-slot="props" field="id" label="ID" sortable>
              <router-link class="button is-text" :to="`/cluster/${props.row.id}`"> {{ props.row.id }} </router-link>
            </b-table-column>
            <b-table-column v-slot="props" field="name" label="Nom" sortable>
              <router-link class="button is-text" :to="`/cluster/${props.row.id}`">
                {{ props.row.name }}
              </router-link>
            </b-table-column>
            <b-table-column v-slot="props" field="name" label="Role" sortable>
              {{ props.row.role }}
            </b-table-column>
            <b-table-column v-slot="props" field="cluster_type" label="Type" sortable>
              {{ props.row.cluster_type }}
            </b-table-column>
            <b-table-column v-slot="props" label="Actions">
              <a class="button is-danger" @click.prevent="unlinkCluster(props.row.id)">
                <b-icon icon="link-off" size="is-small"></b-icon>
              </a>
            </b-table-column>
          </b-table>
          <LinkClusterModal
            :onLink="refreshClustersBelongsToUser"
            :onClose="closeLinkModal"
            :user="user"
            :isActive="isLinkModalActive"
          />
        </div>
        <div id="pending-clusters" v-show="isTabActive(PendingClustersTab)">
          <b-table :loading="isLoading" :data="pendingClusters" :per-page="10" paginated>
            <template slot="empty">
              <b-message type="is-info"> Aucune exploitation en attente </b-message>
            </template>
            <b-table-column v-slot="props" field="id" label="ID de l'exploitation" sortable>
              {{ props.row.cluster_id }}
            </b-table-column>
            <b-table-column v-slot="props" field="name" label="Nom de l'exploitation" sortable>
              {{ props.row.cluster_name }}
            </b-table-column>
            <b-table-column v-slot="props" label="Actions">
              <a class="button is-danger" @click.prevent="unlinkPendingCluster(props.row.cluster_id)">
                <b-icon icon="link-off" size="is-small"></b-icon>
              </a>
            </b-table-column>
          </b-table>
        </div>
        <div id="emails" v-show="isTabActive(EmailsTab)">
          <b-table :loading="isLoading" :data="emails" :per-page="10" paginated>
            <template slot="empty">
              <b-message type="is-info"> Aucun email envoyé </b-message>
            </template>
            <b-table-column v-slot="props" field="subject" label="Sujet" sortable>
              {{ props.row.subject }}
            </b-table-column>
            <b-table-column v-slot="props" field="status" label="Statut" sortable>
              <span class="tag" :class="cssClassByEmailStatus(props.row.status)">
                {{ props.row.status }}
              </span>
            </b-table-column>
            <b-table-column v-slot="props" field="arrived_at_date" label="Envoyé le" sortable>
              {{ props.row.arrived_at }}
            </b-table-column>
          </b-table>
        </div>
        <div id="sessions" v-show="isTabActive(SessionsTabs)">
          <SessionsTabItem :user="user" />
        </div>
      </div>
    </section>
    <b-modal :active.sync="isUpdateUserModalActive">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Modifier l'utilisateur</p>
        </header>
        <section class="modal-card-body">
          <UpdateForm :userId="user.id" :userMetadata="copyUserMetadata" :user="copyUser" :afterSubmit="afterUpdate" />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SALE_USER_ROLE } from "Constants";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { getUserById, getUserEmails, generateOauthToken, resetUserPassword, getUserMetadataById } from "Api/user";
import { unlinkUserToCluster, getClustersBelongsToUser, getPendingClustersBelongsToUser } from "Api/cluster";
import { getUserConnections } from "Api/oauth_access";
import { isUsingMobileApp, cssClassByUserRole, formatDate, openFrontTabWithOauthAccess } from "Utils";
import UpdateForm from "Components/users/Form";
import LinkClusterModal from "Components/users/LinkClusterModal";
import SessionsTabItem from "Components/users/SessionsTabItem";

export default {
  components: { UpdateForm, LinkClusterModal, SessionsTabItem },
  data() {
    return {
      EmailsTab: "emails",
      ClustersTab: "clusters",
      PendingClustersTab: "pendingClusters",
      ConnectionsTab: "connections",
      SessionsTabs: "sessions",
      user: null,
      userMetadata: null,
      connections: [],
      isLoading: false,
      clusters: [],
      pendingClusters: [],
      emails: [],
      currentTab: "connections",
      isUpdateUserModalActive: false,
      isLinkModalActive: false,
      SALE_USER_ROLE: SALE_USER_ROLE
    };
  },
  computed: {
    copyUser() {
      return JSON.parse(JSON.stringify(this.user));
    },
    copyUserMetadata() {
      return JSON.parse(JSON.stringify(this.userMetadata));
    }
  },
  mounted() {
    const { apiClient } = this.$store.getters;
    const userId = this.$route.params.id;
    getUserMetadataById(apiClient)(userId).then(({ data, status }) => {
      if (status !== 200 || data === null) {
        this.userMetadata = {
          user_id: null,
          address: null
        };
        return;
      }
      this.userMetadata = data;
    });
    getUserById(apiClient)(userId).then(({ data }) => {
      this.user = data;
      document.title = data.fullname;
      getUserConnections(apiClient)(userId)
        .then(({ data: connections }) => {
          this.connections = (connections || []).map(d => {
            d.created_at_date = new Date(d.created_at);
            d.created_at = formatDate(d.created_at_date);
            return d;
          });
        })
        .catch(e => {
          this.setError(e);
        });
      getClustersBelongsToUser(apiClient)(userId)
        .then(({ data: clusters }) => {
          this.clusters = clusters || [];
          // Petit hack. Pour chaque cluster, le role devrait être rempli, mais ne l'est pas à travers cette API.
          this.clusters.map(c => {
            if (this.user && this.user.cluster_roles) {
              c.role = this.user.cluster_roles.filter(cr => cr.cluster_id === c.id)[0].role;
            }
          });
        })
        .catch(e => {
          this.setError(e);
        });
      getPendingClustersBelongsToUser(apiClient)(userId)
        .then(({ data: pendingClusters }) => {
          this.pendingClusters = pendingClusters || [];
        })
        .catch(e => {
          this.setError(e);
        });
      getUserEmails(apiClient)(userId)
        .then(({ data: emails }) => {
          this.emails = emails.map(d => {
            d.arrived_at_date = new Date(d.arrived_at);
            d.arrived_at = formatDate(d.arrived_at_date);
            return d;
          });
        })
        .catch(e => {
          const error = e.response.data.errors && e.response.data.errors[0];
          if (error && error.domain === "Emails" && error.reason === "GetMailjetContact") {
            e = {
              message:
                "Impossible de récupérer les mails de l'utilisateur car l'utilisateur n'existe pas dans la base de données de mailjet. Il est possible qu'aucun mail n'est été envoyé pour le moment"
            };
          }
          this.setError(e);
        });
    });
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    afterUpdate(user, userMetadata) {
      this.user = user;
      this.userMetadata = userMetadata;
      this.setSuccess({ message: "L'utilisateur a bien été modifié" });
      this.isUpdateUserModalActive = false;
    },
    showEditModal() {
      this.isUpdateUserModalActive = true;
    },
    cssClassByEmailStatus(status) {
      return {
        spam: "is-danger",
        blocked: "is-danger",
        sent: "is-warning",
        opened: "is-success",
        clicked: "is-dark"
      }[status];
    },
    cssClassByRole() {
      return cssClassByUserRole(this.user.application_role);
    },
    showTab(tab) {
      this.currentTab = tab;
    },
    isTabActive(tab) {
      return tab === this.currentTab;
    },
    cssClassForTab(tab) {
      return { "is-active": this.isTabActive(tab) };
    },
    isUsingMobileAppText() {
      if (this.connections && isUsingMobileApp(this.connections)) {
        return "Oui";
      }
      return "Non";
    },
    resetPassword() {
      this.$buefy.dialog.confirm({
        message: "Générer un nouveau mot de passe ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          resetUserPassword(apiClient)(this.user.id)
            .then(() => {
              this.setSuccess({
                message: "Mot de passe généré avec succès"
              });
            })
            .catch(e => {
              this.setError({
                message: "Impossible de générer un nouveau mot de passe",
                error: e
              });
            });
        }
      });
    },
    connectAs() {
      const { apiClient } = this.$store.getters;
      generateOauthToken(apiClient)(this.user.id)
        .then(({ data }) => {
          const token = data["access_token"];
          if (token === undefined || token === "") {
            this.setError({
              message: "Le token généré est incorrect"
            });
            return;
          }
          openFrontTabWithOauthAccess(token);
        })
        .catch(e => {
          this.setError({
            message: "Impossible de récupérer un token",
            error: e
          });
        });
    },
    unlinkCluster(clusterId) {
      this.defaultUnLinkCluster(clusterId, () => {
        this.refreshClustersBelongsToUser();
      });
    },
    unlinkPendingCluster(clusterId) {
      this.defaultUnLinkCluster(clusterId, () => {
        const { apiClient } = this.$store.getters;
        getPendingClustersBelongsToUser(apiClient)(this.user.id).then(({ data }) => {
          this.pendingClusters = data;
        });
      });
    },
    defaultUnLinkCluster(clusterId, afterUnlink = null) {
      this.$buefy.dialog.confirm({
        message: "L'utilisateur ne sera plus lié à l'exploitation. Voulez vous continuer ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          unlinkUserToCluster(apiClient)(clusterId, this.user.id)
            .then(() => {
              this.setSuccess({
                message: "L'utilisateur n'est plus lié à l'exploitation"
              });
              if (afterUnlink) {
                afterUnlink();
              }
            })
            .catch(e => {
              this.setError({
                message: "Impossible de délié l'utilisateur à l'exploitation",
                error: e
              });
            });
        }
      });
    },
    refreshClustersBelongsToUser() {
      const { apiClient } = this.$store.getters;
      getClustersBelongsToUser(apiClient)(this.user.id).then(({ data }) => {
        this.clusters = data;
      });
    },
    closeLinkModal() {
      this.isLinkModalActive = false;
    },
    showLinkModal() {
      this.isLinkModalActive = true;
    }
  }
};
</script>

<style scoped>
button.show-link-cluster-modal {
  margin-left: 15px;
}
#title-name {
  padding: 0;
}
#title-name .tag {
  margin: 0 10px 0 0;
}
.hero .column .label {
  padding-top: 10px;
  padding-bottom: 0.1em;
  margin-bottom: 0;
  color: #d3d3d3;
}
</style>
