<template>
  <div class="custom-container">
    <div class="title-container">
      <h1 class="title">Statut de validation des boitiers</h1>
    </div>
    <section class="hero is-dark">
      <div class="hero-body">
        <div>
          <b-field>
            <b-field>
              <button class="button is-danger" @click="resetFilter">
                <span>Réinitialiser les filtres</span>
              </button>
            </b-field>
          </b-field>
          <b-field>
            <label class="label">Etat :&nbsp;</label>
            <b-field>
              <b-radio-button v-model="selectedFilter" native-value="all" size="is-small" type="is-success">
                <span>Karnott en cours de validation ({{ numberOfKarnottInValidation }})</span>
              </b-radio-button>
              <b-radio-button v-model="selectedFilter" native-value="ok" size="is-small" type="is-success">
                <span>Karnott valides ({{ numberOfValidKarnott }})</span>
              </b-radio-button>
              <b-radio-button v-model="selectedFilter" native-value="ko" size="is-small" type="is-success">
                <span>Karnott invalides ({{ numberOfInvalidKarnott }})</span>
              </b-radio-button>
              <b-radio-button v-model="selectedFilter" native-value="analyze" size="is-small" type="is-success">
                <span>Karnott à analyser ({{ numberOfKarnottToAnalyze }})</span>
              </b-radio-button>
            </b-field>
          </b-field>
          <b-field>
            <b-field>
              <label class="label">Firm.&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedFirmwareFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedFirmwareFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedFirmwareFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;GPS&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedLocationFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedLocationFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedLocationFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;PO&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedPulloutFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedPulloutFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedPulloutFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;Connec.&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedConnectionFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedConnectionFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedConnectionFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;GPIO&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedGpioFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedGpioFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedGpioFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;Bat.&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedBatteryFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedBatteryFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedBatteryFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;BLE.&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedBluetoothFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedBluetoothFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedBluetoothFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;WR&nbsp;</label>
              <b-field>
                <b-radio-button
                  v-model="selectedWatchdogRebootFilter"
                  native-value="n/a"
                  size="is-small"
                  type="is-success"
                >
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button
                  v-model="selectedWatchdogRebootFilter"
                  native-value="ok"
                  size="is-small"
                  type="is-success"
                >
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button
                  v-model="selectedWatchdogRebootFilter"
                  native-value="ko"
                  size="is-small"
                  type="is-success"
                >
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field>
              <label class="label">&nbsp;Log&nbsp;</label>
              <b-field>
                <b-radio-button v-model="selectedLogsFilter" native-value="n/a" size="is-small" type="is-success">
                  <span>N/A</span>
                </b-radio-button>
                <b-radio-button v-model="selectedLogsFilter" native-value="ok" size="is-small" type="is-success">
                  <span>OK</span>
                </b-radio-button>
                <b-radio-button v-model="selectedLogsFilter" native-value="ko" size="is-small" type="is-success">
                  <span>KO</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <br />
            <label class="label">BLE&nbsp;</label>
            <b-field>
              <b-radio-button v-model="selectedBleVersionFilter" native-value="n/a" size="is-small" type="is-success">
                <span>N/A</span>
              </b-radio-button>
              <b-radio-button v-model="selectedBleVersionFilter" native-value="ok" size="is-small" type="is-success">
                <span>OK</span>
              </b-radio-button>
              <b-radio-button v-model="selectedBleVersionFilter" native-value="ko" size="is-small" type="is-success">
                <span>KO</span>
              </b-radio-button>
            </b-field>
            <label class="label">HD&nbsp;</label>
            <b-field>
              <b-radio-button
                v-model="selectedHardwareVersionFilter"
                native-value="n/a"
                size="is-small"
                type="is-success"
              >
                <span>N/A</span>
              </b-radio-button>
              <b-radio-button
                v-model="selectedHardwareVersionFilter"
                native-value="ok"
                size="is-small"
                type="is-success"
              >
                <span>OK</span>
              </b-radio-button>
              <b-radio-button
                v-model="selectedHardwareVersionFilter"
                native-value="ko"
                size="is-small"
                type="is-success"
              >
                <span>KO</span>
              </b-radio-button>
            </b-field>
          </b-field>
          <b-field>
            <b-tooltip
              label="Les Karnott montés (MOUNTED peuvent passer en statut disponible (AVAILABLE) ou à analyser (TO_ANALYZE)"
              type="is-light"
              position="is-bottom"
            >
              <label class="label">Passer les status des Karnott sélectionnés en :&nbsp;</label>
              <button class="button is-success is-small" @click="showValidateDevicesModal">Disponible</button>
              &nbsp;
              <button class="button is-danger is-small" @click="showToAnalyzeDevicesModal">A analyser</button>
              &nbsp;
              <button
                v-if="currentUser && !currentUser.isHardware()"
                class="button is-warning is-small"
                @click="() => (isMountDeviceModalActive = true)"
              >
                À valider
              </button>
            </b-tooltip>
          </b-field>
        </div>
      </div>
    </section>
    <section class="validations has-background-white">
      <div class="table-title">{{ this.filteredValidations.length }} karnott affiché(s)</div>
      <b-table
        :loading="isLoading"
        :per-page="300"
        :data="this.filteredValidations"
        :checked-rows.sync="checked"
        paginated
        checkable
      >
        <b-table-column v-slot="props" field="serialnumber" label="Serial Number" sortable>
          <router-link :to="`/device/${props.row.device_instance_id}`">{{ props.row.serialnumber }}</router-link>
        </b-table-column>
        <b-table-column v-slot="props" field="hardware_version" label="HardwareID" centered>
          <b-tag :type="selectType(props.row.hardware_version)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 1 }
              }"
            >
              {{ selectLabel(props.row.hardware_version) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="ble_version" label="Version Ble" centered>
          <b-tag :type="selectType(props.row.ble_version)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 1 }
              }"
            >
              {{ selectLabel(props.row.ble_version) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="firmware" label="Firmware" sortable centered>
          <b-tag :type="selectType(props.row.firmware)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 1 }
              }"
            >
              {{ selectLabel(props.row.firmware) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="location" label="Location" sortable centered>
          <b-tag :type="selectType(props.row.location)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 2 }
              }"
            >
              {{ selectLabel(props.row.location) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="pullout" label="PullOut" sortable centered>
          <b-tag :type="selectType(props.row.pullout)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 4 }
              }"
            >
              {{ selectLabel(props.row.pullout) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="connection" label="Connection" sortable centered>
          <b-tag :type="selectType(props.row.connection)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 0 }
              }"
            >
              {{ selectLabel(props.row.connection) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="gpio" label="GPIO" sortable centered>
          <b-tag :type="selectType(props.row.gpio)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 10 }
              }"
            >
              {{ selectLabel(props.row.gpio) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="battery" label="Battery" sortable centered>
          <b-tag :type="selectType(props.row.battery)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 5 }
              }"
            >
              {{ selectLabel(props.row.battery) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="bluetooth" label="Bluetooth" sortable centered>
          <b-tag :type="selectType(props.row.bluetooth)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 3 }
              }"
            >
              {{ selectLabel(props.row.bluetooth) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="watchdog_reboot" label="WatchdogReboot" sortable centered>
          <b-tag :type="selectType(props.row.watchdog_reboot)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 3 }
              }"
            >
              {{ selectLabel(props.row.watchdog_reboot) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="logs" label="Logs" sortable centered>
          <b-tag :type="selectType(props.row.logs)" size="is-medium">
            <router-link
              :to="{
                path: `device/${props.row.device_instance_id}`,
                query: { currentTab: 'DeviceDataTab', selectedDataTypeIndex: 8 }
              }"
            >
              {{ selectLabel(props.row.logs) }}
            </router-link>
          </b-tag>
        </b-table-column>
        <b-table-column v-slot="props" field="nb_hours_current_status" label="Nombre heures" sortable centered>
          <span>
            {{ props.row.nb_hours_current_status ? props.row.nb_hours_current_status : "N/A" }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props" field="added_at" label="Date de màj">
          <span>
            {{ props.row.added_at ? props.row.added_at : "N/A" }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props" label="Actions">
          <span
            v-if="props.row.status === 'PRE_VALIDATED'"
            class="button is-success"
            @click="addDeviceToValidateWithConfirm(props.row)"
          >
            Valider
          </span>
          <b-message type="is-dark" size="is-small" v-else> Aucune action disponible </b-message>
        </b-table-column>
      </b-table>
    </section>

    <b-modal
      v-if="currentUser && !currentUser.isHardware()"
      :active.sync="isMountDeviceModalActive"
      class="mount-device-modal"
    >
      <DeviceSelector
        title="Passer des Karnott en état à valider"
        :data-source="
          this.devices.filter(({ status }) => status === 'UNMOUNTED' || status === 'AVAILABLE' || status === 'IN_USE')
        "
        :on-validate="addDevicesToMount"
        :preselection="[]"
      >
      </DeviceSelector>
    </b-modal>

    <b-modal :active.sync="isValidateDeviceModalActive" class="mount-device-modal">
      <DeviceSelector
        title="Passer des Karnott en état validé"
        :data-source="this.devices.filter(({ status }) => status === 'MOUNTED' || status === 'PRE_VALIDATED')"
        :on-validate="addDevicesToValidate"
        :preselection="this.checked"
      >
      </DeviceSelector>
    </b-modal>

    <b-modal :active.sync="isToAnalyzeDeviceModalActive" class="mount-device-modal">
      <DeviceSelector
        title="Passer des Karnott en état à analyser"
        :data-source="this.devices.filter(({ status }) => status === 'MOUNTED' || status === 'PRE_VALIDATED')"
        :on-validate="addDevicesToAnalyze"
        :preselection="this.checked"
      >
      </DeviceSelector>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_USER_ME_ACTION } from "Stores/users";
import { getValidations, getDevices } from "Api/device";
import { changeStatus } from "Api/device";
import DeviceSelector from "./DeviceSelector";

const MANAGED_QUERY_PARAMS = [
  "selectedFirmwareFilter",
  "selectedLocationFilter",
  "selectedPulloutFilter",
  "selectedConnectionFilter",
  "selectedGpioFilter",
  "selectedBatteryFilter",
  "selectedBluetoothFilter",
  "selectedWatchdogRebootFilter",
  "selectedLogsFilter",
  "selectedFilter"
];

const ALLOWED_STATUS_CHANGE = {
  UNMOUNTED: ["MOUNTED"],
  MOUNTED: ["AVAILABLE", "TO_ANALYZE"],
  TO_ANALYZE: ["AVAILABLE"],
  IN_USE: ["MOUNTED"],
  AVAILABLE: ["MOUNTED"],
  PRE_VALIDATED: ["AVAILABLE", "TO_ANALYZE"]
};

export default {
  components: { DeviceSelector },
  data() {
    return {
      data: [],
      devices: [],
      currentUser: null,
      validations: null,
      checked: [],
      isLoading: false,
      selectedFilter: "all",
      selectedFirmwareFilter: null,
      selectedLocationFilter: null,
      selectedPulloutFilter: null,
      selectedConnectionFilter: null,
      selectedGpioFilter: null,
      selectedBatteryFilter: null,
      selectedBluetoothFilter: null,
      selectedWatchdogRebootFilter: null,
      selectedLogsFilter: null,
      selectedHardwareVersionFilter: null,
      selectedBleVersionFilter: null,
      isMountDeviceModalActive: false,
      isValidateDeviceModalActive: false,
      isToAnalyzeDeviceModalActive: false
    };
  },
  computed: {
    numberOfUnmountedKarnott() {
      return this.data.filter(({ status }) => status === "UNMOUNTED").length;
    },
    numberOfKarnottInValidation() {
      return this.validations ? this.validations.filter(this.validationFilter("all")).length : 0;
    },
    numberOfValidKarnott() {
      return this.validations ? this.validations.filter(this.validationFilter("ok")).length : 0;
    },
    numberOfInvalidKarnott() {
      return this.validations ? this.validations.filter(this.validationFilter("ko")).length : 0;
    },
    numberOfKarnottToAnalyze() {
      return this.validations ? this.validations.filter(this.validationFilter("analyze")).length : 0;
    },
    filteredValidations() {
      if (this.validations === null) {
        return [];
      }

      this.updateUrl();
      return this.validations
        .filter(this.validationFilter(this.selectedFilter))
        .filter(this.filterIndicator("firmware", "selectedFirmwareFilter"))
        .filter(this.filterIndicator("location", "selectedLocationFilter"))
        .filter(this.filterIndicator("pullout", "selectedPulloutFilter"))
        .filter(this.filterIndicator("connection", "selectedConnectionFilter"))
        .filter(this.filterIndicator("gpio", "selectedGpioFilter"))
        .filter(this.filterIndicator("battery", "selectedBatteryFilter"))
        .filter(this.filterIndicator("bluetooth", "selectedBluetoothFilter"))
        .filter(this.filterIndicator("watchdog_reboot", "selectedWatchdogRebootFilter"))
        .filter(this.filterIndicator("logs", "selectedLogsFilter"))
        .filter(this.filterIndicator("temperatures", "selectedTemperatureFilter"))
        .filter(this.filterIndicator("hardware_version", "selectedHardwareVersionFilter"))
        .filter(this.filterIndicator("ble_version", "selectedBleVersionFilter"));
    }
  },
  methods: {
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      MANAGED_QUERY_PARAMS.forEach(prop => (query[prop] = this[prop]));
      this.$router.push({ path: "validations", query }).catch(() => {});
    },
    syncFromQueryParams() {
      MANAGED_QUERY_PARAMS.forEach(field => {
        const value = this.$route.query[field];

        if (value && value !== "" && value !== "null") {
          this[field] = value;
        }
      });
    },
    resetFilter() {
      this.selectedFilter = "all";
      this.selectedFirmwareFilter = null;
      this.selectedLocationFilter = null;
      this.selectedPulloutFilter = null;
      this.selectedConnectionFilter = null;
      this.selectedGpioFilter = null;
      this.selectedBatteryFilter = null;
      this.selectedBluetoothFilter = null;
      this.selectedWatchdogRebootFilter = null;
      this.selectedLogsFilter = null;
      this.selectedHardwareVersionFilter = null;
      this.selectedBleVersionFilter = null;
    },
    filterIndicator(forField, filterName) {
      return v => {
        const filter = this[filterName];
        if (filter === "n/a") {
          return v[forField] === null;
        }

        if (filter === "ok") {
          return v[forField] === true;
        }

        if (filter === "ko") {
          return v[forField] === false;
        }
        return true;
      };
    },
    validationFilter(filter) {
      switch (filter) {
        case "all":
          return ({ status }) => status !== "TO_ANALYZE";
        case "analyze":
          return ({ status }) => status === "TO_ANALYZE";
        case "ok":
          return v =>
            v.status !== "TO_ANALYZE" &&
            v.firmware &&
            v.location &&
            v.pullout &&
            v.connection &&
            v.gpio &&
            v.battery &&
            v.bluetooth &&
            v.watchdog_reboot &&
            v.logs &&
            v.hardware_version &&
            v.ble_version;
        case "ko":
          return v =>
            v.status !== "TO_ANALYZE" &&
            ((v.firmware !== null && !v.firmware) ||
              !v.location ||
              !v.pullout ||
              !v.connection ||
              !v.gpio ||
              !v.battery ||
              !v.bluetooth ||
              !v.watchdog_reboot ||
              !v.logs ||
              !v.hardware_version ||
              !v.ble_version);
      }
    },
    setDevicesWithNewStatus(karnottList) {
      return withStatus => {
        const list = karnottList
          .filter(({ status }) => {
            if (status === withStatus) {
              return false;
            }

            const allowedStatus = ALLOWED_STATUS_CHANGE[status];

            if (!status) {
              alert(`Not allowed status change detected for: ${status}. Contact the support.`);
              return false;
            }

            const allowed = allowedStatus.indexOf(withStatus) !== -1;

            if (!allowed) {
              alert(`Cannot change status from ${status} to ${withStatus}. Contact the support.`);
            }

            return allowed;
          })
          .map(({ device_instance_id }) => device_instance_id);

        const { apiClient } = this.$store.getters;
        this.isLoading = true;
        let params = {
          deviceInstanceIds: list,
          status: withStatus
        };

        return changeStatus(apiClient)(params).finally(() => (this.isLoading = true));
      };
    },
    addDevicesToValidate(karnottToValidate) {
      return this.setDevicesWithNewStatus(karnottToValidate)("AVAILABLE")
        .then(() => {
          this.getValidations();
          this.getDevices();
        })
        .catch(this.setError)
        .finally(() => {
          this.isValidateDeviceModalActive = false;
          this.checked = [];
        });
    },
    addDevicesToAnalyze(karnottToAnalyze) {
      return this.setDevicesWithNewStatus(karnottToAnalyze)("TO_ANALYZE")
        .then(() => {
          this.getValidations();
          this.getDevices();
        })
        .catch(this.setError)
        .finally(() => (this.isToAnalyzeDeviceModalActive = false));
    },
    addDevicesToMount(karnottToMount) {
      return this.setDevicesWithNewStatus(karnottToMount)("MOUNTED")
        .then(() => {
          this.getValidations();
          this.getDevices();
        })
        .catch(this.setError)
        .finally(() => (this.isMountDeviceModalActive = false));
    },
    addDeviceToValidateWithConfirm(device) {
      this.$buefy.dialog.confirm({
        message: "Etes vous sur ?",
        onConfirm: () => {
          this.addDevicesToValidate([device]);
        }
      });
    },
    selectType(flag) {
      return flag === null ? "" : flag ? "is-success" : "is-danger";
    },
    selectLabel(flag) {
      return flag === null ? "N/A" : flag ? "OK" : "KO";
    },
    showValidateDevicesModal() {
      this.isValidateDeviceModalActive = true;
    },
    showToAnalyzeDevicesModal() {
      this.isToAnalyzeDeviceModalActive = true;
    },
    showMountDevicesModal() {
      this.isMountDeviceModalActive = true;
    },
    getDevices() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getDevices(apiClient)
        .then(({ data }) => {
          data.forEach(d => (d.device_instance_id = d.id));
          this.devices = data;
          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },
    getValidations() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getValidations(apiClient)
        .then(({ data }) => {
          data.forEach(d => {
            if (d.added_at) {
              d.added_at = new Date(Date.parse(d.added_at)).toLocaleString();
            }
          });
          this.data = data;
          this.validations = data.filter(
            ({ status }) => status === "MOUNTED" || status === "PRE_VALIDATED" || status === "TO_ANALYZE"
          );
          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getCurrentUser: GET_USER_ME_ACTION
    })
  },
  mounted() {
    document.title = "Validation";
    this.getCurrentUser().then(user => (this.currentUser = user));
    this.syncFromQueryParams();
    this.getValidations();
    this.getDevices();
  }
};
</script>
<style scoped>
td > span > a {
  display: block;
  color: #3273dc;
}
.validations {
  padding: 10px;
}
.label {
  color: #d3d3d3;
}
.title-container h1 {
  color: white;
}
.table-title {
  font-weight: bold;
  font-size: 20px;
  background-color: white;
  width: 100%;
  padding: 10px;
  margin: 0;
}
.custom-container {
  max-width: 95%;
  margin: 0 auto;
}
</style>
