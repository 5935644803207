<template>
  <b-table :data="analysis" :loading="isLoading">
    <b-table-column v-slot="props" field="from" label="Du">
      {{ new Date(props.row.start_at).toLocaleString() }}
    </b-table-column>
    <b-table-column v-slot="props" field="to" label="Au">
      {{ new Date(props.row.end_at).toLocaleString() }}
    </b-table-column>
    <b-table-column v-slot="props" field="categories" label="Catégories">
      <b-taglist>
        <b-tag type="is-info" v-for="(category, index) in props.row.categories" :key="`category-${index}`">
          {{ category }}
        </b-tag>
      </b-taglist>
    </b-table-column>
    <b-table-column v-slot="props" field="comment" label="Commentaire">
      {{ props.row.comment }}
    </b-table-column>
    <b-table-column v-slot="props" field="see_data" label="Voir les données" centered>
      <b-button @click="showSessions(props.row)">
        <b-icon icon="details"></b-icon>
      </b-button>
    </b-table-column>
    <b-table-column v-slot="props" field="update_data" label="Mettre à jour" centered>
      <b-button @click="() => showUpdateAnalysisModal(props.row)">
        <b-icon icon="pencil" />
      </b-button>
      <CreateOrUpdateAnalysisModal
        :isActive="props.row.isUpdateAnalysisModalActive"
        :closeModal="closeModal"
        :analysisPublisher="analysisPublisher(props.row)"
      ></CreateOrUpdateAnalysisModal>
    </b-table-column>
  </b-table>
</template>

<script>
import CreateOrUpdateAnalysisModal from "Components/devices/CreateOrUpdateAnalysisModal";
import { formatToRFC3339 } from "Utils";
import { getAnalysis } from "Api/device";
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";

export default {
  components: { CreateOrUpdateAnalysisModal },
  props: ["device"],
  data() {
    return {
      analysis: [],
      isLoading: false
    };
  },
  methods: {
    closeModal(refresh) {
      if (refresh) {
        const copy = [...this.analysis];
        this.loadAnalysis().then(() =>
          copy.forEach(({ analysisUpdater }, i) => (this.analysis[i].analysisUpdater = analysisUpdater))
        );
      } else {
        const analysis = [...this.analysis];
        analysis.forEach(a => (a.isUpdateAnalysisModalActive = false));
        this.analysis = analysis;
      }
    },
    analysisPublisher(row) {
      return callback => (row.analysisUpdater = callback);
    },
    showUpdateAnalysisModal(row) {
      const analysis = [...this.analysis];
      analysis
        .filter(a => a.id == row.id)
        .forEach(a => {
          a.isUpdateAnalysisModalActive = true;
          if (a.analysisUpdater) {
            a.analysisUpdater(
              row.id,
              row.comment,
              row.categories,
              formatToRFC3339(new Date(row.start_at), false),
              formatToRFC3339(new Date(row.end_at), false)
            );
          }
        });

      this.analysis = analysis;
    },
    showSessions(row) {
      const query = {
        fromDateOption: "DATE",
        toDateOption: "DATE",
        currentTab: "DeviceDataTab",
        fromDate: formatToRFC3339(new Date(row.start_at), false),
        toDate: formatToRFC3339(new Date(row.end_at), false)
      };

      this.$router.replace({
        path: this.device.id.toString(),
        query
      });

      location.reload();
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    }),
    loadAnalysis() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      return getAnalysis(apiClient)(this.device.id)
        .then(({ data }) => {
          this.analysis = data;
          this.analysis.forEach(a => {
            a.analysisUpdater = null;
            a.isUpdateAnalysisModalActive = false;
          });
          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    }
  },
  mounted() {
    if (this.device !== null) {
      this.loadAnalysis();
    }
  }
};
</script>
