<template>
  <div class="container is-fullhd">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des {{ beaconsWithCluster.length }} Beacons
            <div class="buttons">
              <button class="button is-info" @click="() => (this.isImportModalActive = true)">
                Importer une liste de beacon
              </button>
            </div>
          </h1>
        </div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <label class="label">Rechercher par UUID (Entrez 2 caractères minimum pour lancer la recherche) </label>
            <b-field>
              <b-input v-model="searchParams.uuid" @input="filtersUpdated(500)"></b-input>
            </b-field>
          </div>
          <div class="column is-half">
            <label class="label">Filtrer par type</label>
            <b-field>
              <b-checkbox-button
                @input="onChangeType()"
                :native-value="ALL"
                v-model="selectedTypeFilter"
                type="is-danger"
              >
                <span> Tous </span>
              </b-checkbox-button>
              <b-checkbox-button
                @input="onChangeType()"
                :native-value="type"
                :key="type"
                v-model="selectedTypeFilter"
                v-for="type in BEACON_TYPES"
                type="is-warning"
              >
                <span> {{ type }}</span>
              </b-checkbox-button>
            </b-field>
          </div>
          <div class="column is-half">
            <label class="label">Filtrer par statut de liaison avec une exploitation courante</label>
            <b-field>
              <b-checkbox-button
                @input="onChangeState()"
                :native-value="ALL"
                v-model="selectedCurrentStateWithClusterFilter"
                type="is-danger"
              >
                <span> Tous </span>
              </b-checkbox-button>
              <b-checkbox-button
                @input="onChangeState()"
                :native-value="state"
                :key="state"
                v-model="selectedCurrentStateWithClusterFilter"
                v-for="state in DEVICE_STATES_WITH_CLUSTER"
                type="is-warning"
              >
                <span> {{ fmtDeviceStateWithCluster(state) }}</span>
              </b-checkbox-button>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <label class="label">Le Beacon est-il lié à un matériel ?</label>
            <b-field>
              <b-radio-button
                type="is-danger"
                :native-value="ALL"
                v-model="isLinkedToEquipmentFilter"
                @input="filtersUpdated()"
              >
                <span> Tous </span>
              </b-radio-button>
              <b-radio-button
                type="is-warning"
                :native-value="YES"
                v-model="isLinkedToEquipmentFilter"
                @input="filtersUpdated()"
              >
                <span> Oui </span>
              </b-radio-button>
              <b-radio-button
                type="is-warning"
                :native-value="NO"
                v-model="isLinkedToEquipmentFilter"
                @input="filtersUpdated()"
              >
                <span> Non </span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <label class="label">Le Beacon est-il lié à une exploitation ?</label>
            <b-field>
              <b-radio-button
                type="is-danger"
                :native-value="ALL"
                v-model="isLinkedToClusterFilter"
                @input="filtersUpdated()"
              >
                <span> Tous </span>
              </b-radio-button>
              <b-radio-button
                type="is-warning"
                :native-value="YES"
                v-model="isLinkedToClusterFilter"
                @input="filtersUpdated()"
              >
                <span> Oui </span>
              </b-radio-button>
              <b-radio-button
                type="is-warning"
                :native-value="NO"
                v-model="isLinkedToClusterFilter"
                @input="filtersUpdated()"
              >
                <span> Non </span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column is-full">
            <b-button
              type="is-success"
              size="is-medium"
              class="is-fullwidth"
              @click="filtersUpdated()"
              :loading="isLoading > 0"
            >
              Rechercher
            </b-button>
          </div>
        </div>
      </div>
    </section>
    <section class="beacons">
      <BeaconsTable :beacons="beaconsWithCluster" :perPage="100" :isLoading="isLoading > 0" />
    </section>
    <ImportBeaconsModal :isActive="isImportModalActive" :onSuccess="getBeacons" :onClose="onCloseImportModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { searchClusters } from "Api/cluster";
import { getBeacons } from "Api/beacon";
import { formatDate } from "Utils";
import BeaconsTable from "Components/beacons/BeaconsTable";
import ImportBeaconsModal from "Components/beacons/ImportBeaconsModal";
import { BEACON_TYPES, DEVICE_STATES_WITH_CLUSTER, DEVICE_STATES_WITH_CLUSTER_LABEL } from "Constants";

const ALL = "ALL";
const YES = "YES";
const NO = "NO";

var searchTimeout = null;
export default {
  components: { BeaconsTable, ImportBeaconsModal },
  data() {
    return {
      beacons: [],
      clusters: [],
      isLoading: 0,
      isImportModalActive: false,
      searchParams: {},
      selectedTypeFilter: [ALL],
      selectedCurrentStateWithClusterFilter: [ALL],
      isLinkedToEquipmentFilter: ALL,
      isLinkedToClusterFilter: ALL,
      BEACON_TYPES: BEACON_TYPES,
      ALL: ALL,
      YES: YES,
      NO: NO,
      DEVICE_STATES_WITH_CLUSTER
    };
  },
  methods: {
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    onChangeState() {
      const { selectedCurrentStateWithClusterFilter } = this;
      const arrayLen = selectedCurrentStateWithClusterFilter.length;
      if (arrayLen === 0 || selectedCurrentStateWithClusterFilter[arrayLen - 1] === ALL) {
        this.selectedCurrentStateWithClusterFilter = [ALL];
        this.filtersUpdated();
        return;
      }
      this.selectedCurrentStateWithClusterFilter = selectedCurrentStateWithClusterFilter.filter(s => s !== ALL);
      this.filtersUpdated();
    },

    onChangeType() {
      const { selectedTypeFilter } = this;
      const arrayLen = selectedTypeFilter.length;
      if (arrayLen === 0 || selectedTypeFilter[arrayLen - 1] === ALL) {
        this.selectedTypeFilter = [ALL];
        this.filtersUpdated();
        return;
      }
      this.selectedTypeFilter = selectedTypeFilter.filter(s => s !== ALL);
      this.filtersUpdated();
    },
    onCloseImportModal() {
      this.isImportModalActive = false;
    },
    getBeacons(params = {}) {
      const { apiClient } = this.$store.getters;
      this.isLoading++;
      return getBeacons(apiClient)(params)
        .then(({ data }) => {
          this.isLoading--;
          if (data === null) {
            return;
          }
          this.beacons = data.map(d => {
            if (d.lastinfo_date) {
              const date = new Date(Date.parse(d.lastinfo_date));
              d.lastinfo_date_string = date.toLocaleDateString();
              d.lastinfo_date = date;
            }
            return d;
          });
        })
        .catch(this.setError);
    },
    formatDate(date) {
      if (!date) {
        return "-";
      }
      return formatDate(date);
    },
    fetchClusters() {
      const { apiClient } = this.$store.getters;
      this.isLoading++;
      return searchClusters(apiClient)({ is_demo: false })
        .then(({ data }) => {
          this.clusters = data;
          this.isLoading--;
        })
        .catch(this.setError);
    },
    filtersUpdated(timeout = 0) {
      const {
        selectedTypeFilter,
        searchParams,
        isLinkedToEquipmentFilter,
        isLinkedToClusterFilter,
        selectedCurrentStateWithClusterFilter
      } = this;
      const { uuid } = searchParams;
      if (!uuid || (uuid !== "" && uuid.length <= 2)) {
        return;
      }
      const params = {
        uuid: uuid === "" ? null : uuid,
        type: selectedTypeFilter.find(f => f === ALL) ? null : selectedTypeFilter,
        is_linked_to_equipment: isLinkedToEquipmentFilter === ALL ? null : isLinkedToEquipmentFilter === YES,
        current_state_with_cluster: selectedCurrentStateWithClusterFilter.find(f => f === ALL)
          ? null
          : selectedCurrentStateWithClusterFilter,
        is_linked_to_cluster: isLinkedToClusterFilter === ALL ? null : isLinkedToClusterFilter === YES
      };

      if (searchTimeout !== null) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => this.getBeacons(params), timeout);
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  computed: {
    clustersById() {
      const result = {};
      if (!this.clusters) {
        return result;
      }
      this.clusters.forEach(c => (result[c.id] = c));
      return result;
    },
    beaconsWithCluster() {
      return this.beacons.map(b => {
        if (b.cluster_id) {
          b.cluster = this.clustersById[b.cluster_id];
        }
        return b;
      });
    }
  },
  mounted() {
    document.title = "Liste des Beacons";
    this.fetchClusters();
  }
};
</script>
<style scoped>
.title-container {
  margin-top: 50px;
  margin-bottom: 20px;
}
.hero {
  background: rgba(54, 54, 54, 0.95) !important;
}
.hero h1.title {
  margin-bottom: 20px;
}
.beacons {
  background: white;
}
.field:not(:last-child) {
  margin-right: 20px;
}
td > span > a {
  display: block;
  color: #3273dc;
}

.label {
  color: #d3d3d3;
}
</style>
