<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Importer des cultures</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              Les parcelles concernées peuvent être :
              <ul>
                <li>- celles de l'exploitation</li>
                <li>- celles des exploitations membres de celle-ci</li>
              </ul>
              <br />
              Votre fichier doit être au format CSV avec comme séparateur une virgule [,].
              <br />
              Le fichier doit comporter 5 colonnes:
              <ul>
                <li><b> Colonne 1: </b> Parcelle (obligatoire - correspond au nom de la parcelle)</li>
                <li><b> Colonne 2: </b> Exploitation (obligatoire)</li>
                <li><b> Colonne 3: </b> Culture initiale (obligatoire)</li>
                <li><b> Colonne 4: </b> Libellé (optionnel - correspond au nom personnalisé de la culture)</li>
                <li><b> Colonne 5: </b> Libellé code (optionnel)</li>
              </ul>
              <br />
              Attention, le nom de la parcelle et le nom de l'exploitation doivent être identiques à la donnée
              sauvegardée en base de données.
              <br />
              Exemple:
              <pre>Parcelle,Exploitation,Culture Initiale,Libellé<br />Parcelle 2,Ma Ferme,Vin,Merlot Noir</pre>
            </div>
          </div>
          <b-field label="">
            <b-upload v-model="csvFile" :native="true">
              <a class="button is-primary">
                <b-icon icon="upload"> </b-icon>
                <span>Selectionner votre fichier CSV</span>
              </a>
            </b-upload>
          </b-field>
          <p v-if="csvFile">
            Fichier choisi: <span class="tag">{{ csvFile.name }} </span>
          </p>
          <b-field label="Choisir le séparateur">
            <b-select v-model="csvSeparator">
              <option value=","> , </option>
              <option value=";"> ; </option>
            </b-select>
          </b-field>
          <div class="message is-success" v-if="successMessage !== null">
            <div class="message-header">
              <p>{{ successMessage }}</p>
              <button class="delete" @click="successMessage = null"></button>
            </div>
          </div>
          <div class="message is-danger" v-if="errorMessages !== null">
            <div class="message-header">
              <p>Liste des erreurs de l'import</p>
              <button class="delete" @click="errorMessages = null"></button>
            </div>
            <div class="message-body">
              <b-table :data="errorMessages" paginated :per-page="10" :row-class="() => 'has-text-danger'">
                <b-table-column label="" id="message" v-slot="props">
                  {{ props.row.message }}
                </b-table-column>
              </b-table>
            </div>
          </div>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<script>
import { mapActions } from "vuex";
import { importCrops } from "Api/crop";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

export default {
  props: ["cluster", "isActive", "onLink", "onClose"],
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      this.csvFile = null;
      this.errorMessages = null;
      this.successMessage = null;
      this.onClose();
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { cluster, csvFile, csvSeparator } = this;
      const reader = new FileReader();
      reader.onload = () => {
        const lines = reader.result.split("\n");
        if (lines.length === 0) {
          return;
        }

        importCrops(apiClient)(cluster.id, reader.result, csvSeparator)
          .then(({ data }) => {
            const { error_messages, success_messages } = data;
            if (error_messages.length === 0) {
              this.setSuccess({
                message: "Les cultures ont bien été créées et liées"
              });
              if (this.onLink !== undefined) {
                this.onLink();
              }
              this.closeModal();
              return;
            }

            if (success_messages && success_messages.length == 1) {
              this.successMessage = `1 parcelle a été liée à une culture`;
            }
            if (success_messages && success_messages.length > 1) {
              this.successMessage = `${success_messages.length} parcelles ont été liées à une culture`;
            }
            this.errorMessages = error_messages.map(message => {
              return { message };
            });
          })
          .catch(e => {
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      };
      reader.readAsText(csvFile, "UTF-8");
    }
  },
  data() {
    return {
      csvFile: null,
      csvSeparator: ",",
      errorMessages: null,
      successMessage: null
    };
  }
};
</script>
