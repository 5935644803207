<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <div class="modal-card">
      <header class="modal-card-head">Changer le statut des Karnott sélectionnés</header>
      <section class="modal-card-body">
        <div class="panel">
          <p class="panel-heading">
            Sélectionner le nouveau status
          </p>
          <div class="panel-block">
            <div class="control">
              <div class="field">
                <div class="control is-expanded">
                  <b-message type="is-info">
                    Boitiers sélectionnés:
                    <ul>
                      <li :key="device.id" v-for="device in devices">{{ device.serialnumber }}</li>
                    </ul>
                  </b-message>
                  <div class="js-select-new-firmware select is-fullwidth">
                    <b-select v-model="newStatusSelected" expanded>
                      <option :key="status" v-for="status in DEVICE_STATUS" :value="status"> {{ status }} </option>
                    </b-select>
                  </div>
                </div>
              </div>
              <div class="field is-expanded">
                <button class="button is-success is-fullwidth js-submit-create-ota" @click="submitChangeStatusForm">
                  Mettre à jour les boitiers
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>
<style scoped>
ul {
  list-style: inherit;
  margin-left: 30px;
}
</style>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { changeStatus } from "Api/device";
import { DEVICE_STATUS } from "Constants";
export default {
  props: ["devices", "onClose", "isActive", "onSubmitSuccess"],
  data() {
    return {
      newStatusSelected: null,
      DEVICE_STATUS
    };
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      const { onClose } = this;
      this.newStatusSelected = null;
      onClose && onClose();
    },
    submitChangeStatusForm() {
      const { devices, newStatusSelected, setError, setSuccess, closeModal, onSubmitSuccess } = this;
      if (devices.length === 0) {
        setError({
          message: "Aucun boitier selectionné"
        });
        return;
      }
      if (newStatusSelected === null) {
        setError({
          message: "Aucun nouveau statut selectionné"
        });
        return;
      }
      const { apiClient } = this.$store.getters;
      changeStatus(apiClient)({
        status: newStatusSelected,
        deviceInstanceIds: devices.map(d => d.id)
      }).then(() => {
        setSuccess({
          message: `${devices.length} boitiers ${
            devices.length === 1 ? "a" : "ont"
          } été mis à jour avec le statut [${newStatusSelected}]`
        });
        onSubmitSuccess && onSubmitSuccess();
        closeModal();
      });
    }
  }
};
</script>
