<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des Parcelles ({{ this.parcels.length }})</h1>
          <div class="buttons">
            <b-button type="is-success" :loading="isLoading" @click.prevent="downloadParcelsOfCluster()">
              <b-icon icon="download" />
            </b-button>
            <b-button type="is-danger" :loading="isLoading" @click.prevent="deleteParcelsOfCluster()">
              <b-icon icon="delete" />
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="buttons">
        <b-button type="is-danger" :loading="isLoading" @click.prevent="showManageModal('delete')">
          Supprimer
        </b-button>
        <b-button type="is-warning" :loading="isLoading" @click.prevent="() => (isCloseParcelModalActive = true)">
          Clôturer
        </b-button>
        <b-button type="is-info" :loading="isLoading" @click.prevent="showManageModal('move')"> Déplacer </b-button>
        <b-button type="is-info" :loading="isLoading" @click.prevent="showManageModal('copy')"> Dupliquer </b-button>
        <b-button type="is-info" :loading="isLoading" @click.prevent="showImportCropModal()">
          Importer des cultures
        </b-button>
      </div>
      <b-table :data="parcelsWithCrop" :checked-rows.sync="checked" checkable>
        <b-table-column v-slot="props" field="id" label="ID" sortable searchable> #{{ props.row.id }} </b-table-column>
        <b-table-column v-slot="props" field="name" label="Nom" sortable searchable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column v-slot="props" field="area" label="Surface (en ha)" sortable>
          {{ props.row.area }}
        </b-table-column>
        <b-table-column v-slot="props" field="category" label="Type" sortable>
          {{ props.row.category }}
        </b-table-column>
        <b-table-column v-slot="props" field="from_date" label="Début" sortable>
          {{ props.row.fromDateString }}
        </b-table-column>
        <b-table-column v-slot="props" field="to_date" label="Fin" sortable>
          {{ props.row.toDateString }}
        </b-table-column>
        <b-table-column v-slot="props" field="crop" label="Culture" sortable>
          {{ props.row.crop || "-" }}
        </b-table-column>
        <b-table-column v-slot="props" field="actions" label="Edition">
          <div class="buttons has-addons">
            <a class="button is-primary" @click.prevent="showModal(props.row)">
              <b-icon icon="pencil" size="is-small"></b-icon>
            </a>
          </div>
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info"> Aucune parcelle </b-message>
        </template>
      </b-table>
    </div>
    <b-modal :active="isModalActive" :on-cancel="closeModal">
      <form @submit.prevent="submitEditParcelForm">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Modifier la parcelle <span class="tag is-info"> {{ parcelToEdit.name }} </span>
            </p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nom">
              <b-input v-model="parcelToEdit.name" type="text" />
            </b-field>
            <b-field label="Choisir le type de parcelle">
              <b-select v-model="parcelToEdit.category">
                <option value="field">Parcelle</option>
                <option value="farmhouse">Corps de ferme</option>
              </b-select>
            </b-field>
            <b-field label="Exploitation">
              <b-taginput
                :allow-new="false"
                :maxtags="1"
                :data="clusters"
                :allow-duplicates="false"
                :value="defaultParcelCluster"
                field="explicitName"
                icon="label"
                placeholder="Selectionner une exploitation"
                autocomplete
                @typing="filterAction"
                @add="c => (parcelToEdit.cluster_id = c.id)"
                @remove="() => (parcelToEdit.cluster_id = cluster.id)"
              >
              </b-taginput>
            </b-field>
            <b-field label="Géometrie">
              <div id="map" ref="map"></div>
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
          <button class="button is-info">Modifier</button>
        </footer>
      </form>
    </b-modal>

    <b-modal :active="isCloseParcelModalActive" :on-cancel="closeCloseParcelModal" width="70%">
      <form @submit.prevent="submitCloseParcel" v-if="!!checked && checked.length > 0">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Clôturer les parcelles sélectionnées</p>
          </header>
          <section class="modal-card-body">
            <b-message type="is-warning">
              Attention, la clôture d'une parcelle entraine la suppression des différents prêts de parcelles après la
              date de clôture définie
              <br />
              Apres la date de clôture, la parcelle ne sera plus prise en compte pour les calculs.
            </b-message>
            <b-field
              label="Définir la date de cloture (Si aucune date n'est définie, la valeur sélectionnée sera `maintenant`)"
            >
              <b-datepicker v-model="closeParcelToDate" placeholder="Select..." icon="calendar-today" />
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeCloseParcelModal">Annuler</a>
          <button class="button is-info">Clôturer</button>
        </footer>
      </form>
      <div class="modal-card" v-else>
        <header class="modal-card-head">
          <p class="modal-card-title">Clôturer les parcelles sélectionnées</p>
        </header>
        <section class="modal-card-body">
          <b-message type="is-danger"> Aucune parcelle sélectionnée </b-message>
        </section>
      </div>
    </b-modal>

    <b-modal :active="isManageModalActive" :on-cancel="closeManageModal">
      <form @submit.prevent="manageListedParcelsOfCluster">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title" v-if="this.manageModalAction == 'move'">Deplacer vers</p>
            <p class="modal-card-title" v-if="this.manageModalAction == 'copy'">Dupliquer vers</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Exploitation">
              <b-taginput
                :allow-new="false"
                :maxtags="1"
                :data="clusters"
                :allow-duplicates="false"
                field="explicitName"
                icon="label"
                placeholder="Selectionner une exploitation"
                autocomplete
                @typing="filterAction"
                @add="c => (manageParcel.cluster_id = c.id)"
                @remove="() => (manageParcel.cluster_id = cluster.id)"
              >
              </b-taginput>
            </b-field>
            <b-field v-if="this.manageModalAction == 'copy'" label="Définir la date de cloture">
              <b-datepicker v-model="copyParcelAtDate" placeholder="Select..." icon="calendar-today" />
            </b-field>
            <p>
              Si une date est définie, alors les parcelles dupliquées predront fin à date et les nouvelles parcelles
              predront effet à date
            </p>
            <p>Si aucune date n'est definie, on vient juste dupliquer les parcelles</p>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeManageModal">Annuler</a>
          <button class="button is-info">
            <span v-if="this.manageModalAction == 'move'"> Deplacer </span>
            <span v-if="this.manageModalAction == 'copy'"> Dupliquer </span>
          </button>
        </footer>
      </form>
    </b-modal>
    <ImportCropsModal
      :isActive="isImportCropsModalActive"
      :cluster="cluster"
      :onClose="() => (isImportCropsModalActive = false)"
      :onLink="loadParcels"
    />
  </div>
</template>

<style scoped>
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.modal-card {
  min-height: 470px;
  width: 100%;
}
#map {
  height: 300px;
  width: 100%;
}
</style>
<script>
/* global google */
import { mapActions } from "vuex";
import { searchClusters } from "Api/cluster";
import {
  deleteParcelsOfCluster,
  downloadParcelsOfCluster,
  manageListedParcelsOfCluster,
  getClusterParcels,
  getParcel,
  updateParcel,
  closeParcels
} from "Api/parcel";
import { getCropsList } from "Api/crop";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { loadGmapScript, sortById, callApiWithSignal, formatToRFC3339, formatDate } from "Utils";
import ImportCropsModal from "Components/clusters/clusterDetailsComponents/ImportCropsModal";

const formatCoordinates = coords => {
  return coords.map(c => {
    return { lng: c[0], lat: c[1] };
  });
};

// convert google maps coords to geojson coords
const convertToGeoJson = _coords => {
  const paths = _coords.getPaths();
  const finalCoords = [];
  paths.forEach(path => {
    let coordsArray = path.getArray();
    coordsArray = [...coordsArray, coordsArray[0]];
    const coords = coordsArray
      .map(point => ({ latitude: point.lat(), longitude: point.lng() }))
      .map(item => [item.longitude, item.latitude]);
    finalCoords.push(coords);
  });

  return {
    type: "Polygon",
    crs: { type: "name", properties: { name: "EPSG:4326" } },
    coordinates: finalCoords
  };
};

let searchClustersTimeout = null;
let searchClustersSignal = null;
export default {
  components: {
    ImportCropsModal
  },
  props: ["cluster"],
  computed: {
    defaultParcelCluster() {
      return [this.cluster];
    },
    parcelsWithCrop() {
      const { parcels, crops } = this;
      return parcels.map(parcel => {
        parcel.from_date = parcel.from_date ? new Date(parcel.from_date) : null;
        parcel.fromDateString = parcel.from_date ? formatDate(parcel.from_date) : "-";
        parcel.to_date = parcel.to_date ? new Date(parcel.to_date) : null;
        parcel.toDateString = parcel.to_date ? formatDate(parcel.to_date) : "-";
        return { ...parcel, crop: (crops[parcel.current_crop_id] || {}).name };
      });
    }
  },
  watch: {
    parcelsWithCrop() {
      // need to update checked
      // if the parcelsWithCrop is updated
      this.checked = this.checked.map(checkedParcel =>
        this.parcelsWithCrop.find(parcelWithCrop => parcelWithCrop.id === checkedParcel.id)
      );
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    formatDate(date) {
      return formatDate(date);
    },
    submitEditParcelForm() {
      const { parcelToEdit } = this;
      const { name, geometry_area, cluster_id, category } = parcelToEdit;
      const { apiClient } = this.$store.getters;
      updateParcel(apiClient)(parcelToEdit.id, {
        name,
        geometry_area,
        cluster_id,
        category
      })
        .then(() => {
          this.closeModal();
          this.loadParcels();
          this.setSuccess({
            message: "La parcelle a bien été mis à jour"
          });
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue`,
            error: e
          });
        });
    },
    closeModal() {
      this.isModalActive = false;
      this.parcelToEdit = {};
    },
    showModal(parcel) {
      this.isModalActive = true;
      let parcelId = { ...parcel }.id;
      const { apiClient } = this.$store.getters;
      getParcel(apiClient)(parcelId, { precision: 16 })
        .then(r => {
          this.parcelToEdit = r.data;
          this.showGMap()
            .then(this.showParcelPolygon)
            .catch(e => {
              this.setError({
                error: e
              });
            });
        })
        .catch(e => {
          this.setError({
            error: e
          });
        });
    },
    showGMap() {
      return new Promise((resolve, reject) => {
        let count = 0;
        const interval = setInterval(() => {
          if (count > 5) {
            clearInterval(interval);
            reject("Impossible de générer la carte Gmap");
            return;
          }

          count += 1;
          const { map } = this.$refs;
          if (map === null || !google) {
            return;
          }
          this.gmap = new google.maps.Map(map, {
            zoom: 3,
            mapTypeId: "hybrid"
          });

          clearInterval(interval);
          resolve();
        }, 100);
      });
    },
    showParcelPolygon() {
      const { parcelToEdit } = this;
      const { geometry_area } = parcelToEdit;
      const { coordinates } = JSON.parse(geometry_area);
      if (!coordinates) {
        return;
      }
      //const paths = formatCoordinates(coords);
      const paths = coordinates.map(sub_coords => formatCoordinates(sub_coords));
      const polygon = new google.maps.Polygon({
        paths: paths,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        clickable: true,
        draggable: true
      });
      polygon.setEditable(true);
      polygon.addListener("mouseout", () => {
        this.parcelToEdit.geometry_area = JSON.stringify(convertToGeoJson(polygon));
      });
      polygon.setMap(this.gmap);

      google.maps.event.addListener(polygon, "rightclick", e => {
        if (e.vertex === undefined) {
          return;
        }

        polygon.getPaths().getAt(e.path).removeAt(e.vertex);
      });

      const bounds = new google.maps.LatLngBounds();
      const points = polygon.getPath().getArray();
      for (var n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
      }
      this.gmap.fitBounds(bounds);
    },
    filterAction(q) {
      const { apiClient } = this.$store.getters;
      if (searchClustersSignal) {
        searchClustersSignal.cancel();
      }
      clearTimeout(searchClustersTimeout);
      searchClustersTimeout = setTimeout(() => {
        const { apiFunc, signal } = callApiWithSignal(searchClusters, apiClient);
        searchClustersSignal = signal;
        apiFunc({ name: q })
          .then(({ data }) => {
            this.clusters = data.map(c => ({ ...c, explicitName: `[#${c.id}] ${c.name}` }));
          })
          .catch(e => {
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      }, 200);
    },
    loadParcels() {
      const { apiClient } = this.$store.getters;
      getClusterParcels(apiClient)(this.cluster.id)
        .then(({ data }) => {
          this.parcels = (data || []).sort(sortById);
          const cropsId = this.parcels.map(parcel => parcel.current_crop_id).filter(e => e);
          if (cropsId.length === 0) return;
          let uniqueCropsId = [...new Set(cropsId)];
          getCropsList(apiClient)({ id: uniqueCropsId })
            .then(({ data }) => {
              const crops = {};
              data.forEach(crop => (crops[crop.id] = crop));
              this.crops = crops;
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue lors du chargement des cultures`,
                error: e
              });
            });
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des parcelles`,
            error: e
          });
        });
    },
    deleteParcelsOfCluster() {
      this.$buefy.dialog.confirm({
        message: "Êtes-vous sûr de vouloir archiver toutes les parcelles du client ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          this.isLoading = true;
          deleteParcelsOfCluster(apiClient)(this.cluster.id)
            .then(() => {
              this.isLoading = false;
              this.setSuccess({
                message: "Les parcelles ont bien été archivées"
              });
              window.location.reload();
            })
            .catch(e => {
              this.setError({
                message: "Une erreur est survenue",
                error: e
              });
            });
        }
      });
    },
    downloadParcelsOfCluster() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      downloadParcelsOfCluster(apiClient)(this.cluster.id)
        .then(response => {
          this.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/zip" }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.cluster.id + "_export_parcels.zip");
          document.body.appendChild(link);
          link.click();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    showManageModal(action) {
      if (this.checked.length === 0) {
        alert(`Il faut selectionner au moins une parcelle.`);
        return;
      }
      if (action === "delete") {
        this.$buefy.dialog.confirm({
          message: "Êtes-vous sûr de vouloir archiver les parcelles selectionnés ?",
          onConfirm: () => {
            this.manageModalAction = "delete";
            this.manageListedParcelsOfCluster();
          }
        });
      } else {
        this.manageModalAction = action;
        this.isManageModalActive = true;
      }
    },
    closeManageModal() {
      this.manageModalAction = "";
      this.copyParcelAtDate = null;
      this.isManageModalActive = false;
    },
    manageListedParcelsOfCluster() {
      const { apiClient } = this.$store.getters;
      const parcelIDs = this.checked.map(d => d.id);
      const { manageParcel } = this;
      const { cluster_id } = manageParcel;

      const params = {
        action: this.manageModalAction + "_fields",
        to_cluster_id: cluster_id,
        at_date: this.copyParcelAtDate ? formatToRFC3339(this.copyParcelAtDate) : null,
        ids: parcelIDs
      };
      this.isLoading = true;
      manageListedParcelsOfCluster(apiClient)(params)
        .then(() => {
          this.isLoading = false;
          this.setSuccess({
            message: "Les parcelles ont bien été modifiées"
          });
          window.location.reload();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    closeCloseParcelModal() {
      this.isCloseParcelModalActive = false;
      this.closeParcelToDate = null;
      this.isLoading = false;
    },
    submitCloseParcel() {
      const { apiClient } = this.$store.getters;
      const parcelIDs = this.checked.map(d => d.id);
      let toDate = this.closeParcelToDate;
      if (toDate && toDate != "") {
        toDate = formatToRFC3339(toDate);
      }
      this.isLoading = true;
      closeParcels(apiClient)(parcelIDs, toDate)
        .then(({ data }) => {
          this.isLoading = false;
          this.setSuccess({
            message: "Les parcelles ont bien été cloturées"
          });
          this.parcels = this.parcels.map(p => {
            const newParcel = data.find(newParcel => newParcel.id === p.id);
            return newParcel || p;
          });
          this.closeCloseParcelModal();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    showImportCropModal() {
      this.isImportCropsModalActive = true;
    }
  },
  mounted() {
    loadGmapScript();
    this.loadParcels();
  },
  data() {
    return {
      parcels: [],
      checked: [],
      clusters: [],
      parcelToEdit: [],
      manageParcel: [],
      isLoading: false,
      isModalActive: false,
      isManageModalActive: false,
      manageModalAction: "",
      gmap: null,
      isImportCropsModalActive: false,
      crops: {},
      closeParcelToDate: null,
      copyParcelAtDate: null,
      isCloseParcelModalActive: false
    };
  }
};
</script>
