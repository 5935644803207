<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des équipements
          </h1>
          <div class="buttons has-addons">
            <a class="button" @click.prevent="() => (isModalActive = true)">
              Lier un équipement
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <b-table
        :data="equipmentHistory"
        :loading="tableLoading"
        :default-sort="['from_date', 'desc']"
        paginated
        per-page="20"
        :total="equipmentHistory.length"
      >
        <b-table-column field="name" label="Nom" v-slot="props" sortable>
          {{ getEquipmentName(props.row.equipment_instance_id) }}
        </b-table-column>
        <b-table-column field="name" label="Exploitation" v-slot="props" sortable>
          {{ getClusterName(props.row.equipment_instance_id) }}
        </b-table-column>
        <b-table-column field="from_date" label="Date de début" v-slot="props" sortable>
          {{ formatDate(props.row.from_date) }}
        </b-table-column>
        <b-table-column field="to_date" label="Date de fin" v-slot="props" sortable>
          {{ formatDate(props.row.to_date) }}
        </b-table-column>
        <template slot="empty">
          <b-message type="is-info">
            Aucun équipement n'a été lié à ce beacon
          </b-message>
        </template>
      </b-table>
    </div>
    <LinkEquipmentModal
      :isActive="isModalActive"
      :equipments="clusterEquipments"
      :beacon="beacon"
      :onCloseModal="() => (isModalActive = false)"
      :onSuccess="onLinkEquipmentSuccess"
    />
  </div>
</template>

<script>
import { getEquipmentHasBeaconHistory } from "Api/equipmentHasDevice";
import { getEquipmentByIds } from "Api/equipment";
import { getClustersByIds } from "Api/cluster";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";
import LinkEquipmentModal from "./LinkEquipmentModal";

export default {
  props: ["beacon", "clusterEquipments"],
  components: { LinkEquipmentModal },
  data() {
    return {
      equipmentHistory: [],
      equipmentList: {},
      clusterList: {},
      tableLoading: false,
      isModalActive: false
    };
  },
  mounted() {
    this.loadHistory();
  },
  methods: {
    onLinkEquipmentSuccess() {
      this.loadHistory();
      this.isModalActive = false;
    },
    loadHistory() {
      const { apiClient } = this.$store.getters;
      this.tableLoading = true;
      getEquipmentHasBeaconHistory(apiClient)(this.beacon.uuid)
        .then(({ data }) => {
          this.equipmentHistory = (data || []).map(h => {
            return {
              ...h,
              from_date: h.from_date ? new Date(h.from_date) : null,
              to_date: h.to_date ? new Date(h.to_date) : null
            };
          });
        })
        .catch(e => {
          this.setError({
            message: "Impossible de récupérer l'historique des équipements <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    getEquipmentName(id) {
      return (this.equipmentList[id] && this.equipmentList[id].label) || "-";
    },
    getClusterName(equipmentId) {
      const { equipmentList, clusterList } = this;
      const equipment = equipmentList[equipmentId];
      if (!equipment) {
        return "-";
      }
      const { cluster_id } = equipment;
      return (cluster_id && clusterList[cluster_id] && this.clusterList[cluster_id].name) || "-";
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  watch: {
    beacon() {
      this.loadHistory();
    },
    equipmentHistory() {
      const { apiClient } = this.$store.getters;
      const equipmentIds = this.equipmentHistory.map(e => e.equipment_instance_id);
      getEquipmentByIds(apiClient)(equipmentIds)
        .then(({ data }) => {
          this.tableLoading = false;
          this.equipmentList = data.reduce((acc, e) => {
            acc[e.id] = e;
            return acc;
          }, {});
          const clusterIds = data.map(d => d.cluster_id);
          if (clusterIds.length === 0 || equipmentIds.length === 0) {
            return { data: [] };
          }
          return getClustersByIds(apiClient)(clusterIds);
        })
        .then(({ data }) => {
          this.clusterList = data.reduce((acc, c) => {
            acc[c.id] = c;
            return acc;
          }, {});
        })
        .catch(e => {
          this.setError({
            message: "Impossible de récupérer les informations des équipements et clusters de l'historique <br />",
            error: e
          });
        });
    }
  }
};
</script>
