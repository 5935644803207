<template>
  <div>
    <div class="section">
      <h1 class="title">Gestion des firmwares</h1>
    </div>
    <div class="section has-background-white">
      <div class="firmwares-list">
        <h1 class="subtitle">Firmwares existant</h1>
        <b-field>
          <b-radio-button
            @input="onChangeVersion()"
            :native-value="ALL"
            v-model="selectedVersionFilter"
            type="is-danger"
          >
            <span> Tous </span>
          </b-radio-button>
          <b-radio-button
            @input="onChangeVersion()"
            :native-value="model.id"
            :key="model.id"
            v-model="selectedVersionFilter"
            v-for="model in deviceModels"
            type="is-warning"
          >
            <span> {{ model.label }}</span>
          </b-radio-button>
        </b-field>
        <b-table
          :data="firmwaresFiltered"
          :paginated="true"
          :per-page="20"
          :default-sort="['id', 'desc']"
          :row-class="row => (row.isNew ? 'new-row' : '')"
        >
          <b-table-column v-slot="props" field="id" label="ID" sortable> #{{ props.row.id }} </b-table-column>
          <b-table-column v-slot="props" field="description" label="Description" sortable>
            {{ props.row.description }}
          </b-table-column>
          <b-table-column v-slot="props" field="devicesNumber" label="Nb Karnott">
            {{ props.row.devices_number }}
          </b-table-column>
          <b-table-column v-slot="props" field="fullVersion" label="Version" sortable numeric>
            {{ props.row.fullVersion }}
          </b-table-column>
          <b-table-column v-slot="props" field="bank" label="Bank" sortable numeric>
            {{ props.row.bank }}
          </b-table-column>
          <b-table-column v-slot="props" field="mtu" label="MTU">
            {{ props.row.mtu }}
          </b-table-column>
          <b-table-column v-slot="props" field="stage" label="Stage" sortable>
            {{ props.row.stage }}
          </b-table-column>
          <b-table-column v-slot="props" field="crc" label="CRC">
            {{ props.row.crc }}
          </b-table-column>
          <b-table-column v-slot="props" field="size" label="SIZE (Ko)">
            {{ props.row.size / 1000 }}
          </b-table-column>
          <b-table-column v-slot="props" field="added_at" label="Date d'ajout">
            {{ props.row.validation_date.toLocaleString() }}
          </b-table-column>
          <b-table-column v-slot="props" label="Action">
            <div class="buttons">
              <button class="button is-success" @click="downloadFirmware(props.row)">
                <b-icon icon="download"></b-icon>
              </button>
            </div>
          </b-table-column>
          <b-table-column v-slot="props" label="Used for validation" v-if="currentUser && !currentUser.isHardware()">
            <b-switch
              v-model="props.row.is_valid"
              @input="value => toggleFirmwareValidation({ id: props.row.id, is_valid: value })"
            ></b-switch>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getDeviceModels } from "Api/deviceModel";
import { FETCH_FIRMWARES_ACTION, UPDATE_FIRMWARE_ACTION } from "Stores/devices";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { GET_USER_ME_ACTION } from "Stores/users";
import { downloadFirmware } from "Api/firmware";

const ALL = "ALL";

export default {
  mounted() {
    document.title = "Firmwares";
    Promise.all([this.getCurrentUser(), this.fetchFirmwares()])
      .then(res => (this.currentUser = res[0]))
      .catch(e => {
        this.setError({
          message: "Can't retrieve the firmwares and/or current user.<br />Please contact the support.<br />",
          error: e
        });
      });
      this.loadDeviceModels();
      this.fetchFirmwares().then((data) => {
        this.firmwares = data;
        this.firmwaresFiltered = this.firmwares;
      });
  },
  methods: {
    toggleFirmwareValidation(firmware) {
      this.updateFirmware(firmware);
    },
    downloadFirmware(firmware) {
      const { apiClient } = this.$store.getters;
      downloadFirmware(apiClient, firmware.id).then(({ data }) => {
        const url = URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = firmware.description.replace(".", "-") + ".bin";
        a.click();
      });
    },
    loadDeviceModels() {
      const { apiClient } = this.$store.getters;
      getDeviceModels(apiClient)
        .then(({ data }) => {
          this.deviceModels = data || [];
          this.deviceModels = this.deviceModels.filter(deviceModel => deviceModel.label !== "Virtuel");
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des deviceModel`,
            error: e
          });
        });
    },
    onChangeVersion() {
      const { selectedVersionFilter } = this;
      if (selectedVersionFilter === ALL) {
        this.firmwaresFiltered = this.firmwares;
        return;
      }
      this.firmwaresFiltered = this.firmwares.filter(firmware => {
        return firmware.device_models.some(deviceModel => deviceModel.id === selectedVersionFilter);
      });
      console.log(this.firmwaresFiltered.length);
    },
    ...mapActions({
      fetchFirmwares: FETCH_FIRMWARES_ACTION,
      updateFirmware: UPDATE_FIRMWARE_ACTION,
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION,
      getCurrentUser: GET_USER_ME_ACTION
    })
  },
  data() {
    return {
      ALL,
      currentUser: null,
      deviceModels: [{}],
      selectedVersionFilter: ALL,
      firmwares: [],
      firmwaresFiltered: [],
    };
  }
};
</script>

<style>
tr.new-row {
  animation: 5s linear 0s background-color;
}
@keyframes background-color {
  from {
    background: #7957d5;
  }
  to {
    background: white;
  }
}
</style>
