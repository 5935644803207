<template>
  <div class="section">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Changer son mot de passe</h1>
          <form @submit.prevent="onSubmit">
            <div class="columns">
              <div class="column is-one-third">
                <label class="label">Ancien mot de passe</label>
                <b-field>
                  <b-input v-model="oldPassword" type="password" password-reveal />
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-third">
                <label class="label">Nouveau mot de passe</label>
                <b-field>
                  <b-input v-model="newPassword" type="password" password-reveal />
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-third">
                <label class="label">Confirmation du mot de passe</label>
                <b-field>
                  <b-input v-model="confirmNewPassword" type="password" password-reveal />
                </b-field>
              </div>
            </div>
            <button class="button is-info" v-bind="submitButtonProps" :loading="isLoading">Modifier</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
label {
  color: white !important;
}
</style>

<script>
import { getUserMe, changePassword } from "Api/user";
import { mapActions } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "../../store/modules/message";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    };
  },
  computed: {
    submitButtonProps() {
      if (this.formIsValid()) {
        return {};
      }
      return { disabled: true };
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    onSubmit() {
      const { apiClient } = this.$store.getters;
      const params = {
        current_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.confirmNewPassword
      };
      this.isLoading = true;
      getUserMe(apiClient)
        .then(answer => {
          changePassword(apiClient)(answer.data.id, params)
            .then(() => {
              this.isLoading = false;
              this.newPassword = "";
              this.oldPassword = "";
              this.confirmNewPassword = "";
              this.setSuccess({
                message: "Le mot de passe a bien été modifié"
              });
            })
            .catch(e => {
              this.isLoading = false;
              this.setError({
                message: "Une erreur est survenue",
                error: e
              });
            });
        })
        .catch(e => {
          this.isLoading = false;
          this.setError({
            message: "Une erreur est survenue",
            error: e
          });
        });
    },
    formIsValid() {
      return (
        this.oldPassword && this.newPassword && this.confirmNewPassword && this.newPassword === this.confirmNewPassword
      );
    }
  }
};
</script>
