import { getOtaList, deleteOtaActive, createMultipleOta, createMultipleOtaWithDevices, createOta } from "Api/ota";

export const SET_OTA_MUTATION = "SET_OTA_MUTATION";

export const FETCH_OTA_LIST_ACTION = "FETCH_OTA_LIST_ACTION";
export const DELETE_OTA_ACTIVE_ACTION = "DELETE_OTA_ACTIVE_ACTION";
export const CREATE_MULTIPLE_OTA_ACTION = "CREATE_MULTIPLE_OTA_ACTION";
export const CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION = "CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION";
export const CREATE_OTA_ACTION = "CREATE_OTA_ACTION";

let module = {
  state: {
    ota: []
  },
  mutations: {
    [SET_OTA_MUTATION](state, ota) {
      state.ota = ota || [];
    }
  },
  actions: {
    [CREATE_OTA_ACTION]({ rootGetters }, { deviceInstanceID, major, minor, patch }) {
      const { apiClient } = rootGetters;
      return createOta(apiClient)({ deviceInstanceID, major, minor, patch });
    },
    [CREATE_MULTIPLE_OTA_ACTION]({ rootGetters }, { fromFirmwareVersions, toFirmwareVersion, forceOTA, limitOTA }) {
      const { apiClient } = rootGetters;
      return createMultipleOta(apiClient)({
        fromFirmwareVersions,
        toFirmwareVersion,
        forceOTA,
        limitOTA
      }).then(({ data }) => {
        data = data || [];
        return data;
      });
    },
    [CREATE_MULTIPLE_OTA_WITH_DEVICES_ACTION]({ rootGetters }, { fromDeviceIds, toFirmwareVersion, forceOTA }) {
      const { apiClient } = rootGetters;
      return createMultipleOtaWithDevices(apiClient)({
        fromDeviceIds,
        toFirmwareVersion,
        forceOTA
      }).then(({ data }) => {
        data = data || [];
        return data;
      });
    },
    [FETCH_OTA_LIST_ACTION]({ commit, rootGetters }, query) {
      const { apiClient } = rootGetters;
      return getOtaList(apiClient)(query).then(({ data }) => {
        commit(SET_OTA_MUTATION, data);
        return data;
      });
    },
    [DELETE_OTA_ACTIVE_ACTION]({ rootGetters }, mac) {
      const { apiClient } = rootGetters;
      return deleteOtaActive(apiClient)(mac);
    }
  }
};

export default module;
