<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    showSuccessDialog() {
      let msg = `${this.success.message}`;

      this.$buefy.dialog.alert({
        title: "Succès !",
        message: msg,
        type: "is-success",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa"
      });
    },
    showErrorDialog() {
      let msg = `${this.error.message}`;

      if (this.error.detail !== "") {
        msg += `<b/>Detailed error: <br/>${this.error.detail}`;
      }

      this.$buefy.dialog.alert({
        title: "Error",
        message: msg,
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa"
      });
    }
  },
  computed: mapState({
    error: state => state.message.error,
    success: state => state.message.success
  }),
  watch: {
    success() {
      this.showSuccessDialog();
    },
    error() {
      this.showErrorDialog();
    }
  }
};
</script>

<style></style>
