<template>
  <div>
    <b-table
      :loading="isLoading"
      :data="users"
      :per-page="10"
      paginated
      default-sort="roleField"
      default-sort-direction="asc"
    >
      <b-table-column v-slot="props" field="fullname" label="Nom" sortable>
        <router-link :to="getLink(props.row)" class="button is-text">
          {{ props.row.fullname }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="email" label="Email" sortable>
        <router-link :to="getLink(props.row)" class="button is-text">
          {{ props.row.email }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" :field="roleField" label="Role" sortable>
        <span class="tag" :class="cssClassByRole(props.row[roleField])">
          {{ props.row[roleField] }}
        </span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="is_using_mobile_app"
        label="Utilise l'application mobile ?"
        v-if="showIsUsingMobileAppColumn === true"
      >
        <span class="label">
          {{ isUsingMobileAppText(props.row) }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="" label="Action">
        <div class="buttons has-addons">
          <a class="button is-dark" @click.prevent="resetPassword(props.row)">
            <b-icon icon="restore" size="is-small"></b-icon>
          </a>
          <a class="button is-danger" @click.prevent="unlinkUser(props.row)" v-if="cluster">
            <b-icon icon="delete" size="is-small"></b-icon>
          </a>
          <a class="button is-info" @click.prevent="connectAs(props.row)">
            <b-icon icon="eye" size="is-small"></b-icon>
          </a>
          <a class="button is-success" @click.prevent="openModalFor(props.row)" v-if="showEditUserRole === true">
            <b-icon icon="pencil" size="is-small"></b-icon>
          </a>
        </div>
      </b-table-column>
    </b-table>
    <b-modal :active="isModalActive" :on-cancel="closeModal">
      <form @submit.prevent="submitEditForm">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Modifier l'utilisateur <span class="tag is-info"> {{ userToEdit.fullname }} </span>
            </p>
          </header>
          <section class="modal-card-body">
            <b-select v-model="userToEdit.role">
              <option v-for="role in roles" :key="role" :value="role">
                {{ role }}
              </option>
            </b-select>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
          <button class="button is-info">Modifier</button>
        </footer>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { CLUSTER_ROLES } from "Constants";
import { resetUserPassword, generateOauthToken } from "Api/user";
import { unlinkUserToCluster, patchUserToCluster } from "Api/cluster";
import { getUserConnections } from "Api/oauth_access";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { isUsingMobileApp, openFrontTabWithOauthAccess } from "Utils";

export default {
  props: {
    cluster: Object,
    users: Array,
    cssClassByRole: Function,
    onUnlink: Function,
    showIsUsingMobileAppColumn: Boolean,
    showEditUserRole: Boolean,
    isLoading: {
      type: Boolean,
      default: false
    },
    roleField: {
      type: String,
      default: "role"
    }
  },
  mounted() {
    this.updateConnections();
  },
  watch: {
    users() {
      this.updateConnections();
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    updateConnections() {
      if (!this.showIsUsingMobileAppColumn) {
        return;
      }
      const { apiClient } = this.$store.getters;
      this.users.forEach(user => {
        if (!this.connections[user.id]) {
          getUserConnections(apiClient)(user.id).then(({ data }) => {
            this.connections = {
              [user.id]: data || [],
              ...this.connections
            };
          });
        }
      });
    },
    isUsingMobileAppText(user) {
      const { connections } = this;
      if (connections && connections[user.id] && isUsingMobileApp(connections[user.id])) {
        return "Oui";
      }
      return "Non";
    },
    getLink(user) {
      return `/user/${user.id}`;
    },
    connectAs(user) {
      const { apiClient } = this.$store.getters;
      generateOauthToken(apiClient)(user.id)
        .then(({ data }) => {
          const token = data["access_token"];
          if (token === undefined || token === "") {
            this.setError({
              message: "Le token généré est incorrect"
            });
            return;
          }
          openFrontTabWithOauthAccess(token);
        })
        .catch(e => {
          this.setError({
            message: "Impossible de récupérer un token",
            error: e
          });
        });
    },
    resetPassword(user) {
      this.$buefy.dialog.confirm({
        message: "Générer un nouveau mot de passe ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          resetUserPassword(apiClient)(user.id)
            .then(() => {
              this.setSuccess({
                message: "Mot de passe généré avec succès"
              });
            })
            .catch(e => {
              this.setError({
                message: "Impossible de générer un nouveau mot de passe",
                error: e
              });
            });
        }
      });
    },
    unlinkUser(user) {
      this.$buefy.dialog.confirm({
        message: "L'utilisateur ne sera plus lié à l'exploitation. Voulez vous continuer ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          unlinkUserToCluster(apiClient)(this.cluster.id, user.id)
            .then(() => {
              this.setSuccess({
                message: "L'utilisateur n'est plus lié à l'exploitation"
              });
              this.onUnlink();
            })
            .catch(e => {
              this.setError({
                message: "Impossible de délié l'utilisateur à l'exploitation",
                error: e
              });
            });
        }
      });
    },
    openModalFor(user) {
      this.isModalActive = true;
      this.userToEdit = JSON.parse(JSON.stringify(user)); //clone object
    },
    closeModal() {
      this.isModalActive = false;
      this.userToEdit = {};
    },
    submitEditForm() {
      const { userToEdit } = this;
      const { apiClient } = this.$store.getters;
      patchUserToCluster(apiClient)(this.cluster.id, userToEdit.id, userToEdit.role)
        .then(() => {
          this.setSuccess({
            message: "Utilisateur modifié"
          });
          this.onUnlink();
          this.closeModal();
        })
        .catch(e => {
          this.setError({
            message: "Impossible de modifier l'utilisateur",
            error: e
          });
        });
    }
  },
  data() {
    return {
      connections: {},
      isModalActive: false,
      userToEdit: {},
      roles: CLUSTER_ROLES
    };
  }
};
</script>
