<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadDeviceSessions" @submit.prevent="loadDeviceSessions">
        <b-field grouped>
          <b-field label="Depuis le">
            <b-datepicker v-model="sessionFromDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
          <b-field label="Jusqu'au">
            <b-datepicker v-model="sessionToDate" placeholder="Select..." icon="calendar-today" />
          </b-field>
          <div class="field button-field">
            <p class="control">
              <button class="button is-success">Rechercher</button>
            </p>
          </div>
        </b-field>
      </form>
    </div>
    <b-table
      v-show="deviceSessions.length > 0"
      :data="deviceSessions"
      :loading="tableLoading"
      :default-sort="['first_position_date', 'asc']"
      paginated
      per-page="20"
      :total="deviceSessions.length"
      detailed
      :show-detail-icon="true"
      ref="table"
    >
      <b-table-column field="id" label="ID" v-slot="props" sortable>
        <template>
          <a @click="toggleRow(props.row)"> {{ props.row.id }} </a>
        </template>
      </b-table-column>
      <b-table-column field="device_instance_name" label="Karnott" v-slot="props" sortable>
        <router-link v-if="props.row.device" class="button is-text" :to="getKarnottLink(props.row)">
          {{ formatKarnottName(props.row) }}
        </router-link>
        <span v-if="!props.row.device"> - </span>
      </b-table-column>
      <b-table-column field="status" label="Statut" v-slot="props" sortable>
        {{ props.row.status }}
      </b-table-column>
      <b-table-column field="first_position_date" label="Date de début" v-slot="props" sortable>
        {{ formatDate(props.row.first_position_date_formatted) }}
      </b-table-column>
      <b-table-column field="last_movement_date" label="Date de fin" v-slot="props" sortable>
        {{ formatDate(props.row.last_movement_date_formatted) }}
      </b-table-column>
      <b-table-column v-slot="props" field="count_equipment_session" label="Sessions" sortable>
        <span class="tag" v-bind:class="{ 'is-success': props.row.count_equipment_session > 0 }">
          {{ props.row.count_equipment_session }}
        </span>
      </b-table-column>
      <b-table-column field="action" label="Action" v-slot="props" sortable>
        <b-button @click="() => selectDeviceSession(props.row)">
          <b-icon icon="map-marker" />
        </b-button>
      </b-table-column>
      <template v-slot:detail="props">
        <BeaconDeviceRangesTable
          :beacon="beacon"
          :fromDateFilter="props.row.first_position_date"
          :toDateFilter="props.row.last_movement_date"
        />
      </template>
    </b-table>

    <b-modal :active="isModalActive" :on-cancel="() => (isModalActive = false)" width="90%">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Liste des beacon range générés</p>
        </header>
        <section class="modal-card-body">
          <div class="message">
            <div class="message-body">
              Les beacon range affichés sont filtrés sur la plage de date de la device session
              <b> [{{ selectedDeviceSession.id }}] </b> :
              {{ formatDate(selectedDeviceSession.first_position_date_formatted) }}
              -
              {{ formatDate(selectedDeviceSession.last_movement_date_formatted) }}
            </div>
          </div>
          <BeaconDeviceRangesTable
            :beacon="beacon"
            :fromDateFilter="selectedDeviceSession.first_position_date"
            :toDateFilter="selectedDeviceSession.last_movement_date"
          />
          <div id="map-container">
            <b-loading :is-full-page="false" :active.sync="isEventPointLoading" />
            <b-message type="is-danger" v-if="mapErrorMessage !== null">
              {{ mapErrorMessage }}
            </b-message>
            <div id="map-row">
              <div id="map-legend">
                <span :style="`color:${darkerColor(r[0])}`" v-for="r in mapLegend" :key="r[0]">
                  <svg width="20px" height="20px">
                    <path d="M 0 0 H 20 V 20 H 0 Z" :fill="r[0]" :stroke="darkerColor(r[0])" />
                  </svg>
                  {{ r[1] }}
                </span>
              </div>
              <div id="event-point-map"></div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* global google */
import { getBeaconDeviceSessions } from "Api/beacon";
import { getDeviceSessionEventPoints, getAllBeaconEventPointsForDeviceSession } from "Api/eventPoint";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate, formatInputDates, formatToRFC3339 } from "Utils";
import { drawDataOnMap, darkerColor, markerColors, markerStrokeWeight, deviceSessionColor } from "Utils/map";
import { getDevicesByIds } from "Api/device";
import BeaconDeviceRangesTable from "Components/beacons/BeaconDeviceRangeTable";

export default {
  props: ["beacon"],
  data() {
    return {
      deviceSessions: [],
      tableLoading: false,
      isModalActive: false,
      selectedDeviceSession: {},
      map: null,
      path: null,
      markers: [],
      eventPoints: [],
      mapLegend: [],
      mapErrorMessage: null,
      isEventPointLoading: false,
      sessionFromDate: null,
      sessionToDate: null
    };
  },
  mounted() {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 3);
    this.sessionFromDate = fromDate;

    this.sessionToDate = new Date();
    this.loadDeviceSessions();
  },
  methods: {
    selectDeviceSession(session) {
      this.isModalActive = true;
      this.mapLegend = [];
      this.mapErrorMessage = null;
      this.selectedDeviceSession = session;
      this.getMapDataAndBuildMap(session);
    },
    truncateDatesFilter() {
      this.sessionFromDate.setHours(0);
      this.sessionFromDate.setMinutes(0);
      this.sessionFromDate.setSeconds(0);

      this.sessionToDate.setHours(23);
      this.sessionToDate.setMinutes(59);
      this.sessionToDate.setSeconds(59);
    },
    loadDeviceSessions() {
      const { apiClient } = this.$store.getters;
      this.truncateDatesFilter();
      const { sessionFromDate, sessionToDate } = this;
      const { fromDate, toDate } = formatInputDates(sessionFromDate, sessionToDate);
      if (fromDate === null || toDate === null) {
        return;
      }
      this.tableLoading = true;
      getBeaconDeviceSessions(apiClient)(this.beacon.uuid, {
        from_date: formatToRFC3339(fromDate),
        to_date: formatToRFC3339(toDate)
      })
        .then(({ data }) => {
          this.tableLoading = false;
          if (data === null) {
            this.deviceSessions = [];
            return {};
          }

          const deviceIdsObj = {};
          this.deviceSessions = data.map(d => {
            deviceIdsObj[d.device_instance_id] = true;
            return {
              ...d,
              first_position_date_formatted: d.first_position_date ? new Date(d.first_position_date) : null,
              last_movement_date_formatted: d.last_movement_date ? new Date(d.last_movement_date) : null
            };
          });
          const deviceIds = Object.keys(deviceIdsObj);
          if (deviceIds.length === 0) {
            return { data: [] };
          }
          return getDevicesByIds(apiClient)(deviceIds);
        })
        .then(({ data: devices }) => {
          this.deviceSessions = this.deviceSessions.map(s => {
            const device = devices.find(d => d.id === s.device_instance_id);
            return {
              ...s,
              device
            };
          });
        })
        .catch(e => {
          this.tableLoading = false;
          this.setError({
            message: "Impossible de récupérer les device sessions <br />",
            error: e
          });
        });
    },
    formatDate(date) {
      return formatDate(date);
    },
    darkerColor(color) {
      return darkerColor(color);
    },
    getMapDataAndBuildMap(session) {
      const { apiClient } = this.$store.getters;
      const params = {
        from_date: session.first_position_date,
        to_date: session.last_movement_date
      };

      this.isEventPointLoading = true;
      Promise.all([
        getDeviceSessionEventPoints(apiClient)(session.id),
        getAllBeaconEventPointsForDeviceSession(apiClient)(session.id, params)
      ])
        .then(([{ data: data1 }, { data: data2 }]) => {
          const errorsMessage = [];
          if (data1 === null) {
            errorsMessage.push("Aucun points pour la device session n'a été retourné");
          }
          if (data2 === null) {
            errorsMessage.push("Aucun points pour le beacon n'a été retourné");
          }
          if (errorsMessage.length > 0) {
            this.mapErrorMessage = errorsMessage.join(". ");
          }
          if (data1 === null && data2 === null) {
            this.isEventPointLoading = false;
            return {};
          }
          const map = this.drawMap();

          // need to draw marker before polyline to avoid error
          if (data2 !== null && data2.length > 0) {
            const eventPointsGroupByKarnott = data2.reduce((newData, value) => {
              (newData[value.device_serialnumber] = newData[value.device_serialnumber] || []).push(value);
              return newData;
            }, {});
            let i = 0;
            for (let serialnumber in eventPointsGroupByKarnott) {
              const points = eventPointsGroupByKarnott[serialnumber];
              const color = markerColors[i] || "#0e0e0e";
              this.mapLegend.push([color, serialnumber]);
              drawDataOnMap(
                points,
                map,
                {
                  drawMarker: true,
                  color,
                  weight: markerStrokeWeight,
                  opacity: 0.9,
                  focusOn: true
                },
                this.beacon
              );
              i++;
            }
          }

          if (data1 !== null && data1.length > 0) {
            const { device_serialnumber } = data1[0];
            this.mapLegend.push([deviceSessionColor, `[${device_serialnumber}] Device session`]);
            drawDataOnMap(data1, map, {
              drawPolyline: true,
              color: deviceSessionColor,
              weight: 8,
              opacity: 0.8,
              focusOn: true
            });
          }

          this.isEventPointLoading = false;
        })
        .catch(e => {
          this.isEventPointLoading = false;
          this.setError({
            message: "Impossible de récupérer les points de la device session <br />",
            error: e
          });
        });
    },
    drawMap() {
      return new google.maps.Map(document.getElementById("event-point-map"), {
        zoom: 3,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        tilt: 0,
        rotateControl: false
      });
    },
    toggleRow(row) {
      this.$refs.table.toggleDetails(row);
    },
    getKarnottLink(session) {
      if (!session.device) {
        return null;
      }
      return `/device/${session.device_instance_id}`;
    },
    formatKarnottName(session) {
      if (!session.device) {
        return "-";
      }
      return session.device.serialnumber;
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  components: {
    BeaconDeviceRangesTable
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
#map-row {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}
#event-point-map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
.button-field {
  padding: 31px 0 11px;
}
</style>
