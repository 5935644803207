<template>
  <div id="container">
    <b-table
      v-show="history.length > 0"
      :data="history"
      :default-sort="['from_date', 'desc']"
      paginated
      per-page="50"
      :total="history.length"
      :row-class="row => getClassFromDevice(row)"
    >
      <b-table-column v-slot="props" field="occurred_at" label="Date" sortable>
        {{ formatDate(props.row.occurred_at) }}
      </b-table-column>
      <b-table-column v-slot="props" field="status" label="Status" sortable>
        {{ props.row.status }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { formatDate } from "Utils";
import { DEVICE_STOCK_LENDING_STATUS, DEVICE_TO_ANALYZE_STATUS } from "Constants";
export default {
  props: ["statusHistory"],
  mounted() {
    this.history = this.statusHistory.map(h => {
      h.occurred_at = h.occurred_at ? new Date(h.occurred_at) : null;
      return h;
    });
  },
  data() {
    return {
      history: [],
      tableLoading: false
    };
  },
  methods: {
    getClassFromDevice(r) {
      const status = r.status;
      switch (status) {
        case DEVICE_STOCK_LENDING_STATUS:
          return "is-info";
        case DEVICE_TO_ANALYZE_STATUS:
          return "is-warning";
        default:
          return "";
      }
    },
    formatDate(date) {
      return formatDate(date);
    }
  }
};
</script>

<style scoped>
#container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.card {
  margin: 10px 0;
}
</style>
