<template>
  <b-modal :active="isModalActive" :on-cancel="closeModal" width="90%">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Copier la session et lier cette session à un beacon</p>
      </header>
      <section class="modal-card-body" v-if="device.cluster_id">
        <b-message type="is-danger">
          Attention, la copie d'une device_session vers un Beacon supprimera la/les device_session de ce beacon sur la
          plage de date de la device_session à copier
        </b-message>
        <b-field label="Choisir un beacon">
          <b-taginput
            :allow-new="false"
            :allow-duplicates="false"
            :maxtags="1"
            :open-on-focus="true"
            :data="filteredBeacons"
            field="selectLabel"
            v-model="selectedBeacons"
            autocomplete
            @typing="filterBeacons"
          />
        </b-field>
        <b-button type="is-danger" @click="submit">
          Copier
        </b-button>
      </section>
      <section class="modal-card-body" v-else>
        <b-message type="is-danger">
          Pour pouvoir copier une device_session, il faut que le Karnott soit lié à une exploitation
        </b-message>
      </section>
    </div>
  </b-modal>
</template>

<script>
import { getBeacons } from "Api/beacon";
import { getBeaconClustersByClusterID } from "Api/beaconCluster";
import { getClusterEquipment } from "Api/equipment";
import { getClusterUsers } from "Api/user";
import { copyDeviceSessionToBeacon } from "Api/deviceSession";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { mapActions } from "vuex";
import { formatDate } from "Utils";

export default {
  props: ["device", "deviceSession", "isModalActive", "onClose"],
  data() {
    return {
      beacons: [],
      searchText: "",
      selectedBeacons: []
    };
  },
  mounted() {
    this.loadBeacons();
    this.loadEquipments();
    this.loadUsers();
  },
  computed: {
    usersById() {
      const o = {};
      this.users.forEach(u => (o[u.id] = u));
      return o;
    },
    equipmentsById() {
      const o = {};
      this.equipments.forEach(e => (o[e.id] = e));
      return o;
    },
    beaconsWithLabel() {
      const { equipmentsById, usersById, beacons } = this;
      return beacons.map(b => {
        const e = equipmentsById[b.current_equipment_instance_id];
        const u = usersById[b.current_user_id];
        b.selectLabel = b.readable_uuid;
        if (e) {
          b.selectLabel = `${b.selectLabel} (${e.label})`;
        }
        if (u) {
          b.selectLabel = `${b.selectLabel} (${u.fullname})`;
        }
        if (b.selectLabel === b.readable_uuid) {
          b.selectLabel = `${b.selectLabel} (Aucun équipement/chauffeur)`;
        }
        return b;
      });
    },
    filteredBeacons() {
      if (!this.searchText) {
        return this.beaconsWithLabel;
      }
      return this.beaconsWithLabel.filter(
        b => b.uuid.toLowerCase().includes(this.searchText) || b.selectLabel.toLowerCase().includes(this.searchText)
      );
    }
  },
  methods: {
    submit() {
      const { deviceSession, selectedBeacons } = this;
      if (selectedBeacons.length === 0) {
        return;
      }
      const { first_position_date_formatted, last_movement_date_formatted } = deviceSession;
      const fromDate = formatDate(first_position_date_formatted);
      const toDate = formatDate(last_movement_date_formatted);
      const beaconLabel = selectedBeacons[0].selectLabel;
      const beaconUuid = selectedBeacons[0].uuid;
      const message = `Êtes vous sûr de vouloir écraser les données du beacon: <strong> ${beaconLabel} </strong> du ${fromDate} au ${toDate}`;
      this.$buefy.dialog.confirm({
        message,
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          copyDeviceSessionToBeacon(apiClient)(deviceSession.id, beaconUuid)
            .then(() => {
              this.setSuccess({
                message: `La session a bien été copiée et liée au beacon ${beaconLabel}`
              });
              this.closeModal();
            })
            .catch(e => {
              this.setError({
                message: "Une erreur est survenue",
                error: e
              });
            });
        }
      });
    },
    filterBeacons(searchText) {
      this.searchText = searchText.toLowerCase();
    },
    loadBeacons() {
      const { device } = this;
      if (!device || !device.cluster_id) {
        return;
      }
      const { apiClient } = this.$store.getters;
      getBeaconClustersByClusterID(apiClient)(device.cluster_id)
        .then(({ data }) => {
          if (!data || data.length === 0) {
            return { data: [] };
          }
          const beaconUuids = data.map(b => b.beacon_uuid);
          return getBeacons(apiClient)({ uuids: beaconUuids });
        })
        .then(({ data }) => (this.beacons = data));
    },
    loadEquipments() {
      const { device } = this;
      if (!device || !device.cluster_id) {
        return;
      }
      const { apiClient } = this.$store.getters;
      getClusterEquipment(apiClient)(device.cluster_id).then(({ data }) => {
        this.equipments = data;
      });
    },
    loadUsers() {
      const { device } = this;
      if (!device || !device.cluster_id) {
        return;
      }
      const { apiClient } = this.$store.getters;
      getClusterUsers(apiClient)(device.cluster_id).then(({ data }) => {
        this.users = data;
      });
    },
    closeModal() {
      this.selectedBeacons = [];
      if (this.onClose) {
        this.onClose();
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  }
};
</script>
<style scoped>
.modal-card {
  height: 500px;
}
</style>
