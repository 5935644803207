export const getEventPoints = client => ({ deviceID, fromDate, toDate }) => {
  let params = {};
  if (fromDate) {
    params["from_date"] = fromDate;
  }
  if (toDate) {
    params["to_date"] = toDate;
  }
  return client.get(`/devices/${deviceID}/event_points`, { params });
};

export const getDeviceSessionEventPoints = client => id => client.get(`/device_sessions/${id}/event_points`);
export const getAllBeaconEventPointsForDeviceSession = client => id =>
  client.get(`/device_sessions/${id}/beacon_event_points`);
