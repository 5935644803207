<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Equipe support
          </h1>
          <div class="buttons has-addons">
            <a class="button" :class="cssClassByRole(CARE_USER_ROLE)" @click.prevent="showCareModal()">
              Ajouter un chouchouteur
            </a>
            <a class="button" :class="cssClassByRole(SALE_USER_ROLE)" @click.prevent="showSaleModal()">
              Ajouter un commercial
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <UsersTable :cluster="cluster" :users="supportTeamUsers" :cssClassByRole="cssClassByRole" :onUnlink="onUnlink" />
    </div>
    <LinkUserModal
      titleLabel="Chouchouteur"
      :cluster="cluster"
      :isActive="isLinkCareModalActive"
      :roles="[CARE_USER_ROLE]"
      :onLink="onLink"
      :onClose="() => (isLinkCareModalActive = false)"
    />
    <LinkUserModal
      titleLabel="Commercial"
      :cluster="cluster"
      :isActive="isLinkSaleModalActive"
      :roles="[SALE_USER_ROLE]"
      :onLink="onLink"
      :onClose="() => (isLinkSaleModalActive = false)"
    />
  </div>
</template>
<style scoped>
.hero h1.title {
  display: inline-block;
  margin-bottom: 0;
}

.hero .buttons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
table {
  padding-bottom: 20px;
}
</style>
<script>
import { CARE_USER_ROLE, SALE_USER_ROLE } from "Constants";
import UsersTable from "Components/users/UsersTable";
import LinkUserModal from "Components/clusters/clusterDetailsComponents/LinkUserModal";

export default {
  props: ["cluster", "supportTeamUsers", "onLink", "onUnlink"],
  components: { UsersTable, LinkUserModal },
  methods: {
    cssClassByRole(role) {
      return {
        [CARE_USER_ROLE]: "is-dark",
        [SALE_USER_ROLE]: "is-info"
      }[role];
    },
    showCareModal() {
      this.isLinkCareModalActive = true;
    },
    showSaleModal() {
      this.isLinkSaleModalActive = true;
    }
  },
  data() {
    return {
      CARE_USER_ROLE: CARE_USER_ROLE,
      SALE_USER_ROLE: SALE_USER_ROLE,
      isLinkCareModalActive: false,
      isLinkSaleModalActive: false
    };
  }
};
</script>
