export const getClusterById = client => id => client.get(`/clusters/${id}`);
export const getClusters = client => client.get(`/clusters`);
export const getClustersByIds = client => ids => client.get(`/clusters`, { params: { id: ids } });
export const searchClusters = (client, token = null) => params =>
  client.get(`/clusters`, { params, cancelToken: token });
export const createCluster = client => params => client.post(`/clusters`, { ...params });
export const updateCluster = client => (id, params) => client.put(`/clusters/${id}`, { ...params });
export const deleteCluster = client => id => client.delete(`/clusters/${id}`);
export const unlinkUserToCluster = client => (clusterId, userId) =>
  client.delete(`/clusters/${clusterId}/users/${userId}`);
export const linkUserToCluster = client => (clusterId, userId, role) =>
  client.post(`/clusters/${clusterId}/users/${userId}`, { role: role });
export const patchUserToCluster = client => (clusterId, userId, role) =>
  client.patch(`/clusters/${clusterId}/users/${userId}`, { role: role });
export const getClusterHierarchy = client => clusterId => client.get(`/clusters/${clusterId}/hierarchy`);
export const unlinkChildClusters = client => clusterId => client.delete(`/clusters/${clusterId}/child`);
export const getParentClusters = client => clusterId => {
  return getClusterHierarchy(client)(clusterId).then(({ data }) => {
    const clusterIds = data && data.filter(c => c.child_id === clusterId).map(c => c.parent_id);
    if (clusterIds && clusterIds.length > 0) {
      return getClustersByIds(client)(clusterIds);
    } else {
      return { data: [] };
    }
  });
};
export const getMemberClusters = client => clusterId => client.get(`/clusters/${clusterId}/members`);
export const importMemberClusters = client => (clusterId, body, csvSeparator = ",") =>
  client.post(`/clusters/${clusterId}/members`, body, {
    headers: { "Content-Type": "text/csv;charset=utf-8" },
    params: { csv_separator: csvSeparator }
  });
export const linkClusterToCluster = client => (parentId, childId) =>
  client.post(`/clusters/${parentId}/hierarchy/${childId}`);
export const unLinkClusterToCluster = client => (parentId, childId) =>
  client.delete(`/clusters/${parentId}/hierarchy/${childId}`);
export const getClustersBelongsToUser = client => userId => client.get(`/users/${userId}/clusters`);
export const getPendingClustersBelongsToUser = client => userId => client.get(`/users/${userId}/pending`);
export const manageFusionOfClusters = client => params => client.post(`/clusters/management`, { ...params });
export const updateHubspotId = client => clusterId => hubspot_id =>
  client.patch(`/clusters/${clusterId}/hubspot_id`, { hubspot_id });
export const getHubspotId = client => clusterId => client.get(`/clusters/${clusterId}/hubspot_id`);
export const linkClusterMembersToParent = client => (parentClusterID, memberIds) =>
  client.post(`/clusters/${parentClusterID}/link_members`, memberIds);
