<template>
  <div class="container is-fullhd">
    <div class="section">
      <h1 class="title">Statut de validation des batteries</h1>
    </div>
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <form @keyup.enter="getValidations" @submit.prevent="getValidations">
            <b-field grouped>
              <b-field label="Depuis le">
                <b-datepicker v-model="formFromDate" placeholder="Select..." icon="calendar-today" />
              </b-field>
              <b-field label="Jusqu'au">
                <b-datepicker v-model="formToDate" placeholder="Select..." icon="calendar-today" />
              </b-field>
            </b-field>
            <button class="button is-success">Filter</button>
          </form>
        </div>
      </div>
    </section>
    <section class="validations has-background-white">
      <b-table :loading="isLoading" :per-page="300" :data="batteryValidationsComputed">
        <b-table-column v-slot="props" field="serialnumber" label="Serial Number" sortable>
          device : {{ props.row.device_instance_serial_number }}<br />
          battery ID : {{ props.row.battery_serial_number }}<br /><br />
          battery HW ID: {{ props.row.battery_hardware_id }}<br /><br />
          dernier status: <br />
          {{ props.row.textualData.last_state }}<br />
          <br />
          Shunt estimé : {{ props.row.textualData.estimated_shunt }}<br />
          <p :class="validationColor(props.row.isSohOK)">SOH estimé : {{ props.row.textualData.estimated_soh }}</p>
          <p :class="validationColor(props.row.isCellOk1)">
            RI cellule 1 : {{ props.row.textualData.estimated_internal_resistance_cell1 }}
          </p>
          <p :class="validationColor(props.row.isCellOk2)">
            RI cellule 2 : {{ props.row.textualData.estimated_internal_resistance_cell2 }}
          </p>
          <p :class="validationColor(props.row.isCellOk3)">
            RI cellule 3 : {{ props.row.textualData.estimated_internal_resistance_cell3 }}
          </p>
          Capacité estimée : {{ props.row.textualData.battery_capacity_estimated }}<br />
          Capacité théorique : {{ props.row.textualData.battery_capacity_design }}<br />
          <p :class="batteryValidationColor(props.row.isBatteryValid)">
            Batterie validée : {{ batteryValidationText(props.row.isBatteryValid) }}
          </p>
          <br />
        </b-table-column>
        <b-table-column v-slot="props" field="chartData" label="Data">
          <div v-for="c in props.row.chartData" :key="c.name">
            <line-chart :data="c" :messages="{ empty: 'Aucune donnée' }"> </line-chart>
          </div>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_USER_ME_ACTION } from "Stores/users";
import { getBatteryValidations } from "Api/device";
import { stringToDate } from "Utils";
import { BATTERY_HARDWARE_ID } from "Stores/devices";
import { BATTERY_THRESHOLDS } from "Stores/devices";
export default {
  components: {},
  data() {
    return {
      currentUser: null,
      isLoading: false,
      data: [],
      batteryValidations: [],

      formFromDate: null,
      formToDate: null
    };
  },
  computed: {
    batteryValidationsComputed() {
      return (this.batteryValidations || []).map(bValidation => {
        const cells = [
          bValidation.textualData.estimated_internal_resistance_cell1,
          bValidation.textualData.estimated_internal_resistance_cell2,
          bValidation.textualData.estimated_internal_resistance_cell3
        ];
        const soh = bValidation.textualData.estimated_soh;
        let sum = 0;
        cells.forEach(cell => {
          sum += cell;
        });
        const average = sum / cells.length;
        let varianceSum = 0;
        for (let i = 0; i < cells.length; i++) {
          varianceSum += Math.pow(cells[i] - average, 2);
        }
        const variance = varianceSum / cells.length;
        const standardDeviation = Math.sqrt(variance);
        const minLimit = average - BATTERY_THRESHOLDS.DEVIATION_THRESHOLD * standardDeviation;
        const maxLimit = average + BATTERY_THRESHOLDS.DEVIATION_THRESHOLD * standardDeviation;

        const isPropertiesOk = {
          isCellOk1: cells[0] === "N/A" ? null : cells[0] > minLimit && cells[0] < maxLimit,
          isCellOk2: cells[1] === "N/A" ? null : cells[1] > minLimit && cells[1] < maxLimit,
          isCellOk3: cells[2] === "N/A" ? null : cells[2] > minLimit && cells[2] < maxLimit,
          isSohOK: soh === "N/A" ? null : soh >= BATTERY_THRESHOLDS.MIN_SOH
        };

        const hasNullValue = Object.values(isPropertiesOk).some(val => val === null);
        const isBatteryValid = hasNullValue ? null : Object.values(isPropertiesOk).every(val => val === true);
        return {
          ...bValidation,
          ...isPropertiesOk,
          isBatteryValid
        };
      });
    }
  },
  methods: {
    updateUrl() {},
    syncFromQueryParams() {},
    resetFilter() {},
    getDevices() {},
    validationColor(isPropertyOk) {
      if (isPropertyOk === null) {
        return "black";
      } else {
        return isPropertyOk ? "green" : "red";
      }
    },
    batteryValidationColor(isBatteryValid) {
      if (isBatteryValid === null) {
        return "";
      } else {
        return isBatteryValid ? "validate" : "invalidate";
      }
    },
    batteryValidationText(isBatteryValid) {
      if (isBatteryValid === null) {
        return "N/A";
      } else {
        return isBatteryValid ? "OUI" : "NON";
      }
    },
    getValidations() {
      const { apiClient } = this.$store.getters;
      this.isLoading = true;
      getBatteryValidations(apiClient)
        .then(({ data }) => {
          this.isLoading = false;
          data.forEach(d => {
            if (d.created_at) {
              d.created_at = stringToDate(d.created_at);
            }
            if (d.occurred_at) {
              d.occurred_at_rfc = d.occurred_at;
              d.occurred_at = stringToDate(d.occurred_at);
            }
            d.battery_info_voltage = d.battery_info_voltage / 1000;
            d.estimated_voltage = d.estimated_voltage / 1000;
          });

          // filter on date
          let { formFromDate, formToDate } = this;
          formFromDate.setHours(0, 0, 0, 0);
          formToDate.setHours(23, 59, 59, 0);
          const fromDate = stringToDate(formFromDate);
          const toDate = stringToDate(formToDate);
          if (fromDate === null || toDate === null) {
            return;
          }

          const filteredData = data.filter(d => d.occurred_at >= fromDate && d.occurred_at < toDate);

          const validationGroupByKarnott = filteredData.reduce((d, value) => {
            const key = `${value.device_instance_serial_number}-${value.battery_serial_number}`;
            if (d.some(p => p.key === key)) {
              const index = d.map(p => p.key).indexOf(key);
              d[index].data.push(value);
            } else {
              d.push({
                key: key,
                device_instance_serial_number: value.device_instance_serial_number,
                battery_serial_number: value.battery_serial_number,
                battery_hardware_id: BATTERY_HARDWARE_ID[value.battery_hardware_id]
                  ? BATTERY_HARDWARE_ID[value.battery_hardware_id]
                  : value.battery_hardware_id,
                data: [value],
                chartData: [],
                textualData: {}
              });
            }
            return d;
          }, []);

          validationGroupByKarnott.forEach(d => {
            const sortedDatas = d.data.sort((a, b) => a.occurred_at_rfc < b.occurred_at_rfc);

            let clearedDatas = [];

            clearedDatas = sortedDatas.filter(f => f.estimated_soh);

            d.textualData.estimated_soh =
              clearedDatas && clearedDatas.length > 0 ? clearedDatas[0].estimated_soh : "N/A";

            clearedDatas = sortedDatas.filter(f => f.estimated_internal_resistance_cell1);
            d.textualData.estimated_internal_resistance_cell1 =
              clearedDatas && clearedDatas.length > 0 ? clearedDatas[0].estimated_internal_resistance_cell1 : "N/A";

            clearedDatas = null;

            clearedDatas = sortedDatas.filter(f => f.estimated_internal_resistance_cell2);
            d.textualData.estimated_internal_resistance_cell2 =
              clearedDatas && clearedDatas.length > 0 ? clearedDatas[0].estimated_internal_resistance_cell2 : "N/A";

            clearedDatas = null;

            clearedDatas = sortedDatas.filter(f => f.estimated_internal_resistance_cell3);
            d.textualData.estimated_internal_resistance_cell3 =
              clearedDatas && clearedDatas.length > 0 ? clearedDatas[0].estimated_internal_resistance_cell3 : "N/A";

            clearedDatas = null;

            clearedDatas = sortedDatas.filter(f => f.battery_capacity_estimated);
            d.textualData.battery_capacity_estimated =
              clearedDatas && clearedDatas.length > 0 ? clearedDatas[0].battery_capacity_estimated : "N/A";

            clearedDatas = null;

            //datas that we always have

            d.textualData.last_state = `${sortedDatas[0].state} le ${new Date(
              Date.parse(sortedDatas[0].occurred_at)
            ).toLocaleString()}`;

            d.textualData.battery_capacity_design = sortedDatas[0].battery_capacity_design;

            d.textualData.estimated_shunt = sortedDatas[0].estimated_shunt;

            let retval = [];
            retval.push([
              {
                name: "battery_info_current",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_current;
                  return acc;
                }, {})
              },
              {
                name: "estimated_current",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.estimated_current;
                  return acc;
                }, {})
              }
            ]);
            retval.push([
              {
                name: "battery_info_voltage",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_voltage;
                  return acc;
                }, {})
              },
              {
                name: "estimated_voltage",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.estimated_voltage;
                  return acc;
                }, {})
              }
            ]);
            retval.push([
              {
                name: "battery_info_soh",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_soh;
                  return acc;
                }, {})
              },
              {
                name: "estimated_soc",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.estimated_soc;
                  return acc;
                }, {})
              },
              {
                name: "battery_info_soc",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_soc;
                  return acc;
                }, {})
              }
            ]);
            retval.push([
              {
                name: "battery_info_voltage_cell1",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_voltage_cell1;
                  return acc;
                }, {})
              },
              {
                name: "battery_info_voltage_cell2",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_voltage_cell2;
                  return acc;
                }, {})
              },
              {
                name: "battery_info_voltage_cell3",
                data: d.data.reduce((acc, value) => {
                  acc[value.occurred_at] = value.battery_info_voltage_cell3;
                  return acc;
                }, {})
              }
            ]);
            d.chartData = retval;
          });

          this.batteryValidations = validationGroupByKarnott;
        })
        .catch(e => {
          this.isLoading = false;
          this.setError(e);
        });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getCurrentUser: GET_USER_ME_ACTION
    })
  },
  mounted() {
    document.title = "BatteryValidation";
    this.getCurrentUser().then(user => (this.currentUser = user));
    const fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);
    fromDate.setDate(fromDate.getDate() - 3);
    this.formFromDate = fromDate;
    const toDate = new Date();
    toDate.setHours(23, 59, 59, 0);
    this.formToDate = toDate;
    this.getValidations();
  }
};
</script>

<style scoped>
.label {
  color: white;
}

.red {
  color: red;
}
.black {
  color: black;
}

.green {
  color: green;
}

.validate {
  background-color: rgb(28, 146, 28);
  border-radius: 5px;
  color: white;
}

.invalidate {
  background-color: rgb(209, 32, 32);
  border-radius: 5px;
  color: white;
}
</style>
