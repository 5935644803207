<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Lier un chauffeur au beacon</p>
        </header>
        <section class="modal-card-body" v-if="!users || users.length === 0">
          <b-message type="is-danger">
            Impossible d'associer un chauffeur au beacon car l'exploitation liée au beacon n'a aucun utilisateur
          </b-message>
        </section>
        <section class="modal-card-body" v-if="users && users.length > 0">
          <div class="message is-info">
            <div class="message-body">
              Attention, une nouvelle liaison entre un chauffeur et un beacon risque d'effacer les précédentes liaisons
              si la plage de date croise celle d'ancienne liaison
            </div>
          </div>
          <b-field label="Choisir un chauffeur">
            <b-autocomplete
              v-model="selectedUserEmail"
              :keep-first="true"
              :open-on-focus="true"
              :data="filteredUsers"
              field="email"
              @select="u => (selectedUser = u)"
            />
          </b-field>
          <b-field label="Date de début">
            <b-datetimepicker v-model="fromDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <b-field label="Date de fin">
            <b-datetimepicker v-model="toDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot" v-if="users && users.length > 0">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
  </b-modal>
</template>
<script>
import { linkBeaconToUser } from "Api/beaconUser";
import { SET_ERROR_ACTION } from "Stores/message";
import { mapActions } from "vuex";

export default {
  props: ["beacon", "users", "isActive", "onCloseModal", "onSuccess"],
  data() {
    return {
      selectedUserEmail: "",
      selectedUser: null,
      fromDate: null,
      toDate: null
    };
  },
  methods: {
    closeModal() {
      if (this.onCloseModal) {
        this.onCloseModal();
      }
    },
    submit() {
      const { fromDate, toDate } = this.formatInputDates();
      const { beacon, selectedUser } = this;
      if (fromDate === null || selectedUser === null || !selectedUser.id) {
        return;
      }

      const formattedFromDate = fromDate.toJSON().replace(/\d{2}\.\d{3}Z/, "00Z");
      let formattedToDate = null;
      if (toDate) {
        formattedToDate = toDate.toJSON().replace(/\d{2}\.\d{3}Z/, "00Z");
      }
      const { apiClient } = this.$store.getters;
      linkBeaconToUser(apiClient)(beacon.uuid, {
        user_id: selectedUser.id,
        from_date: formattedFromDate,
        to_date: formattedToDate
      })
        .then(() => {
          this.onSuccess && this.onSuccess();
        })
        .catch(e => {
          this.setError({
            message: "Une erreur est survenue <br />",
            error: e
          });
        });
    },
    formatInputDates() {
      let { fromDate, toDate } = this;
      if (fromDate !== null && !(fromDate instanceof Date)) {
        fromDate = new Date(fromDate);
      }
      if (toDate !== null && !(toDate instanceof Date)) {
        toDate = new Date(toDate);
      }
      return { fromDate, toDate };
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  },
  watch: {
    isActive() {
      const now = new Date();
      this.fromDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    }
  },
  computed: {
    filteredUsers() {
      const { users, selectedUserEmail } = this;
      const emailToLowerCase = selectedUserEmail.toLowerCase();
      return users.filter(u => u.email.toLowerCase().indexOf(emailToLowerCase) >= 0);
    }
  }
};
</script>
