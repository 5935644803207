<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Onboarding</h1>
        </div>
      </div>
    </div>
    <div class="hero is-dark is-bold">
      <div class="hero-body">
        <div class="container">
          <h2 class="subtitle">Etape suivante</h2>
          <div class="next-step tabs is-toggle is-fullwidth">
            <ul>
              <li
                :key="state"
                v-for="state in getNextStates(cluster.current_state)"
                @click="showModalAndselectState(state)"
              >
                <a :class="cssClassByState(state)">
                  <b-icon :icon="iconClassByState(state)" size="is-small"></b-icon>
                  <span>{{ fmtState(state) }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div>
        <div class="message" :class="cssClassByState(state.state)" :key="state.id" v-for="state in formattedStates">
          <header class="message-header">
            <p class="message-title">
              <b-icon :icon="iconClassByState(state.state)" />
              <span class="message-title__text"> {{ fmtState(state.state) }} </span>
            </p>
            <div class="buttons has-addons">
              <a
                class="button is-inverted is-outlined"
                :class="cssClassByState(state.state)"
                @click="() => (stateToUpdate = state)"
              >
                <b-icon icon="pencil" size="is-small"></b-icon>
              </a>
              <a
                class="button is-inverted is-outlined"
                :class="cssClassByState(state.state)"
                @click="confirmDeleteState(state.id)"
              >
                <b-icon icon="delete" size="is-small"></b-icon>
              </a>
            </div>
          </header>
          <div class="message-body">
            <div class="content comment">
              <strong>
                Crée le {{ formatDate(state.created_at) }} par {{ (state.author && state.author.email) || "-" }}
              </strong>
              <div class="message" :class="cssClassByState(state.state)">
                <div class="message-body">
                  {{ fmtComment(state.comment) }}
                </div>
              </div>
              <div v-if="state.meta && state.meta.meeting_date">
                <strong> Rendez vous le :</strong>
                <br />
                <span class="tag is-large" :class="cssClassByState(state.state)">
                  {{ fmtMeetingDate(state.meta.meeting_date) }}
                </span>
                <br />
                <strong> Formateur(s)/Formatrice(s) : </strong>
                <br />
                <span class="tag" :class="cssClassByState(state.state)">
                  {{ state.meta.trainer_email }}
                </span>
              </div>
            </div>
            <div class="content jobs">
              <h5>Communication</h5>
              <b-table v-show="getClusterStateJobs(state.id).length > 0" :data="getClusterStateJobs(state.id)">
                <b-table-column v-slot="props" field="label" label="label">
                  {{ fmtJobLabel(props.row.label) }}
                </b-table-column>
                <b-table-column v-slot="props" field="status" label="Status">
                  <label class="label">
                    {{ fmtJobStatus(props.row.status) }}
                  </label>
                </b-table-column>
                <b-table-column v-slot="props" field="email" label="email">
                  {{ props.row.email }}
                </b-table-column>
                <b-table-column v-slot="props" field="delayed_at" label="Envoyé le">
                  {{ props.row.delayed_at }}
                </b-table-column>
              </b-table>
              <div v-show="getClusterStateJobs(state.id).length === 0" class="message is-primary">
                Aucune communication
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewClusterStateModal
      :stateName="newSelectedState"
      :onClose="closeNewStateModal"
      :cluster="cluster"
      :adminUsers="adminUsers"
      :supportTeamUsers="supportTeamUsers"
      :afterCreate="afterCreate"
    />
    <b-modal :active="stateToUpdate.id !== null" :on-cancel="closeEditStateModal">
      <form @submit.prevent="submitEditState">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Modifier l'état: {{ fmtState(stateToUpdate.state) }}</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Commentaire">
              <b-input type="textarea" v-model="stateToUpdate.comment"></b-input>
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click.prevent="closeEditStateModal">Annuler</a>
          <button class="button" :class="cssClassByState(stateToUpdate.state)">
            {{ fmtActionButton(stateToUpdate.state) }}
          </button>
        </footer>
      </form>
    </b-modal>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.container > div {
  width: 100%;
  margin: 10px 0;
  padding: 0px;
}
.message-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message-title__text {
  margin-top: -3px;
  margin-left: 10px;
}
.message-body {
  display: flex;
}
.message-body > div.comment {
  width: 45%;
  padding: 0 20px 0 0;
}
.message-body > div.jobs {
  width: 55%;
  padding: 0 20px 0 0;
}
.message-body > div.comment .message {
  margin: 10px 0;
  box-shadow: 2px 2px 5px #e0e0e0;
}
.next-step li {
  margin-left: 0px !important;
}
.next-step a {
  font-size: 14px;
}
.next-step a span.icon {
  opacity: 0.3;
  transition: 0.3s opacity;
}
.next-step a:hover span.icon {
  opacity: 1;
}
.modal-card {
  width: 100%;
}
</style>
<script>
import { mapActions } from "vuex";
import { getClusterStateByClusterId, updateClusterState, deleteClusterState } from "Api/clusterState";
import { getUsersByIds } from "Api/user";
import { getJobsByClusterId } from "Api/job";
import { formatDate } from "Utils";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import {
  JOB_STATUS_LABEL,
  JOB_LABEL_LABEL,
  CLUSTER_STATE_STATE_LABEL_AS_FILTER,
  CLUSTER_STATE_STATE_LABEL,
  CLUSTER_STATE_CSS_CLASS,
  CLUSTER_STATE_ICON_CLASS
} from "Constants";
import NewClusterStateModal from "Components/clusters/clusterDetailsComponents/NewClusterStateModal";

const fmtError = (error, message) => {
  return { error, message };
};

export default {
  components: {
    NewClusterStateModal
  },
  props: ["cluster", "adminUsers", "supportTeamUsers", "onNewState", "onDeleteState"],
  mounted() {
    this.loadClusterStates();
  },
  computed: {
    formattedStates() {
      return this.states.map(s => {
        return {
          ...s,
          created_at: !s.created_at ? null : new Date(s.created_at)
        };
      });
    },
    formattedJobs() {
      return this.jobs.map(j => {
        return {
          ...j,
          delayed_at: !j.delayed_at ? "-" : formatDate(new Date(j.delayed_at)),
          email: j.meta && j.meta.to && j.meta.to.map(m => m.email).join(", ")
        };
      });
    }
  },
  methods: {
    loadJobs(cursor = null) {
      const { apiClient } = this.$store.getters;
      getJobsByClusterId(apiClient)(this.cluster.id, 300, { cursor })
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.jobs = this.jobs.concat(data);
            this.loadJobs(data[data.length - 1].id);
            return;
          }
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des jobs`,
            error: e
          });
        });
    },
    loadClusterStates() {
      const { apiClient } = this.$store.getters;
      this.loadJobs();
      getClusterStateByClusterId(apiClient)(this.cluster.id)
        .then(({ data }) => {
          this.states = data || [];
        })
        .then(() => {
          // get users linked to clusterState with author_id to show author.email
          if (this.states.length === 0) {
            return { data: [] };
          }

          const userIds = this.states.map(s => s.author_id);
          return getUsersByIds(apiClient)(userIds);
        })
        .then(({ data }) => {
          if (data.length === 0) {
            return;
          }
          this.states = this.states.map(state => {
            state.author = data.find(u => u.id === state.author_id) || {};
            return state;
          });
        })
        .catch(e => this.setError(fmtError(e, "Erreur pour récupérer les états")));
    },
    showModalAndselectState(state) {
      this.newSelectedState = state;
    },
    confirmDeleteState(id) {
      this.$buefy.dialog.confirm({
        message: "Etes vous sur ?",
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          deleteClusterState(apiClient)(id)
            .then(() => {
              this.setSuccess({ message: "État de l'exploitation supprimé" });
              this.loadClusterStates();
              this.onDeleteState();
            })
            .catch(e => this.setError(fmtError(e, "Erreur pour supprimer l'état")));
        }
      });
    },
    submitEditState() {
      const { stateToUpdate } = this;
      const { apiClient } = this.$store.getters;
      const p = {
        comment: stateToUpdate.comment
      };
      updateClusterState(apiClient)(stateToUpdate.id, p)
        .then(() => {
          this.setSuccess({ message: "État de l'exploitation modifié" });
          this.closeEditStateModal();
          this.loadClusterStates();
        })
        .catch(e => {
          this.setError(fmtError(e, "Erreur pour modifier l'état"));
        });
    },
    getClusterStateJobs(id) {
      return this.formattedJobs.filter(j => j.meta && j.meta.cluster_state_id === id);
    },
    fmtJobStatus(status) {
      return JOB_STATUS_LABEL[status];
    },
    fmtJobLabel(label) {
      return JOB_LABEL_LABEL[label];
    },
    fmtComment(comment) {
      return !comment || comment === "" ? "Aucun commentaire" : comment;
    },
    fmtMeetingDate(_date) {
      const date = new Date(_date);
      // need to format date because of mailjet
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return this.fmtDate(date);
    },
    fmtDate(date) {
      return new Date(date).toLocaleString();
    },
    fmtState(state) {
      return CLUSTER_STATE_STATE_LABEL[state];
    },
    fmtActionButton(state) {
      return this.fmtState(state);
    },
    cssClassByState(state) {
      return CLUSTER_STATE_CSS_CLASS[state];
    },
    iconClassByState(state) {
      return CLUSTER_STATE_ICON_CLASS[state];
    },
    formatDate(date) {
      return date === null ? "-" : formatDate(date);
    },
    getNextStates(state) {
      return Object.keys(CLUSTER_STATE_STATE_LABEL_AS_FILTER).filter(s => s !== state);
    },
    closeEditStateModal() {
      this.stateToUpdate = {
        id: null
      };
    },
    closeNewStateModal() {
      this.newSelectedState = null;
    },
    afterCreate() {
      this.loadClusterStates();
      if (this.onNewState) {
        this.onNewState();
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  },
  data() {
    return {
      states: [],
      karnottUsersFilter: null,
      clusterUsersFilter: null,
      newSelectedState: null,
      stateToUpdate: {
        id: null
      },
      jobs: [],
      jobsColumns: [
        { field: "label", label: "label" },
        { field: "status", label: "status" },
        { field: "email", label: "email" },
        { field: "delayed_at", label: "Envoyé le" }
      ]
    };
  }
};
</script>
