<template>
  <b-modal :active="isActive" :onCancel="closeModal" has-modal-card>
    <div class="modal-card js-ota-list-modal">
      <header class="modal-card-head">{{ title }}</header>
      <section class="modal-card-body">
        <b-table :data="otaList" paginated :per-page="20" :default-sort="['added_at', 'desc']">
          <b-table-column v-slot="props" field="mac" label="MAC" sortable>
            {{ props.row.mac }}
          </b-table-column>
          <b-table-column v-slot="props" field="serial_number" label="Serial Number" sortable>
            {{ props.row.serial_number }}
          </b-table-column>
          <b-table-column v-slot="props" field="firmware_name" label="Targetted firmware" sortable>
            {{ props.row.firmware_name }}
          </b-table-column>
          <b-table-column v-slot="props" field="firmware_name" label="From firmware" sortable>
            {{ props.row.from_firmware_name }}
          </b-table-column>
        </b-table>
      </section>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["title", "otaList", "closeModal", "isActive"]
};
</script>
<style scoped>
.modal-card {
  width: 900px;
}
</style>
