<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Lier les exploitations membres sélectionnées à une exploitation parente suplémentaire
          </p>
        </header>
        <section class="modal-card-body">
          <b-message type="is-warning" v-if="clustersChecked.length === 0">
            Il faut d'abord sélectionner les exploitations membres à lier
          </b-message>
          <b-message type="is-info" v-if="clustersChecked.length > 0">
            Le lien avec cette exploitation parente ne sera pas supprimé
          </b-message>
          <div class="columns is-centered is-vcentered" v-if="clustersChecked.length > 0">
            <div class="column field is-one-third">
              <div
                class="field"
                v-for="clusterChecked in clustersChecked.filter(c => c.id !== parentCluster.id)"
                :value="clusterChecked.id"
                :key="clusterChecked.id"
              >
                <b-tag type="is-primary"> {{ clusterChecked.name }} ( {{ clusterChecked.from_application }} ) </b-tag>
              </div>
            </div>
            <div class="column is-one-third">
              Lier ces exploitations à une exploitation supplémentaire
            </div>
            <div class="column field is-one-third">
              <b-field label="Séléctionner une exploitation parente" class="cluster-name">
                <b-taginput
                  :allow-new="false"
                  :data="parentClustersWithLabel"
                  :allow-duplicates="false"
                  maxtags="1"
                  field="label"
                  icon="label"
                  placeholder="Cuma ..."
                  autocomplete
                  v-model="parentClustersSelected"
                  @typing="searchClusterParent"
                  scrollable="true"
                >
                </b-taginput>
              </b-field>
            </div>
          </div>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <b-button type="is-info" :loading="isLoading" native-type="submit" v-if="clustersChecked.length > 0">
          Valider
        </b-button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { searchClusters, linkClusterMembersToParent } from "Api/cluster";
import { callApiWithSignal } from "Utils";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

let searchTimeout = null;
let searchClustersSignal = null;
export default {
  props: ["isActive", "onClose", "clustersChecked"],
  computed: {
    parentClustersWithLabel() {
      return this.parentClustersResult.map(c => {
        return {
          ...c,
          label: `[${c.id}] ${c.name}`
        };
      });
    },
    parentCluster() {
      if (this.parentClustersSelected && this.parentClustersSelected.length === 1) {
        return this.parentClustersSelected[0];
      }
      return {};
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      this.parentClustersResult = [];
      this.parentClustersSelected = [];
      this.onClose && this.onClose();
    },
    submit() {
      const { parentCluster, closeModal, clustersChecked, $store, $buefy, setSuccess, setError } = this;
      if (!parentCluster.id) {
        alert("Il faut sélectionner l'exploitation parente");
        return;
      }
      $buefy.dialog.confirm({
        message: `Êtes-vous sûr de vouloir lier les exploitations selectionnées à  [${parentCluster.name}]?`,
        onConfirm: () => {
          this.isLoading = true;
          const { apiClient } = $store.getters;
          const memberIds = clustersChecked.map(c => c.id).filter(cid => cid !== parentCluster.id);
          linkClusterMembersToParent(apiClient)(parentCluster.id, memberIds)
            .then(() => {
              this.isLoading = false;
              setSuccess({
                message: "Les exploitations ont bien été liées"
              });
              closeModal();
            })
            .catch(e => {
              this.isLoading = false;
              setError({
                message: "Une erreur est survenue",
                error: e
              });
            });
        }
      });
    },
    searchClusterParent(searchParam) {
      if (!searchParam || searchParam === "") {
        return;
      }
      if (searchClustersSignal) {
        searchClustersSignal.cancel();
      }
      clearTimeout(searchTimeout);
      this.isLoading = true;
      searchTimeout = setTimeout(() => {
        const { apiClient } = this.$store.getters;
        const { apiFunc, signal } = callApiWithSignal(searchClusters, apiClient);
        searchClustersSignal = signal;
        apiFunc({ name: searchParam })
          .then(({ data }) => {
            this.isLoading = false;
            this.parentClustersResult = data;
          })
          .catch(e => {
            if (e.code === "ERR_CANCELED") {
              return;
            }
            this.isLoading = false;
            this.setError({
              message: "Une erreur est survenue pour récupérer la liste des exploitations",
              error: e
            });
          });
      }, 200);
    }
  },
  data() {
    return {
      isLoading: false,
      parentClustersResult: [],
      parentClustersSelected: []
    };
  }
};
</script>
