<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <Loader :status="loaderStatus" />
    <form @submit.prevent="submit">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Importer les données du Karnott [{{ device && device.serialnumber }}]</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              La première ligne de votre fichier doit contenir l'adresse MAC du Karnott.
              <br />
              Les lignes suivantes doivent contenir les frame du Karnott. Sur une même ligne, il peut y avoir plusieurs
              frames et elles doivent être séparées d'un point-virgule <code> ; </code>
              <br />
              Exemple :
              <pre> 4e4f0000000009e4 <br /> 00ff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000; <br /> 00ff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
              </pre>
              Le <code> mode Test </code> permet de tester l'import d'un fichier sans insérer la donnée dans la base de
              donnée.
              <br />
              Si votre fichier contient beaucoup de frame (/lignes), il est normal que le temps de chargement soit long
            </div>
          </div>
          <b-field label="">
            <b-upload v-model="dataFile" :native="true">
              <a class="button is-primary">
                <b-icon icon="upload"> </b-icon>
                <span>Selectionner votre fichier de données</span>
              </a>
            </b-upload>
          </b-field>
          <p v-if="dataFile">
            Fichier choisi: <span class="tag">{{ dataFile.name }} </span>
          </p>
          <b-field label="Mode Test ?">
            <b-switch v-model="testMode">
              {{ testMode ? "Oui" : "Non" }}
            </b-switch>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Importer
        </button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { importKarnottDataFile } from "Api/device";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import Loader from "Components/Loader";

export default {
  props: ["device", "isActive", "onSuccess", "onClose"],
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      this.dataFile = null;
      this.onClose();
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { device, dataFile, testMode } = this;
      const reader = new FileReader();
      reader.onload = () => {
        this.loaderStatus = "show";
        importKarnottDataFile(apiClient)(device.id, reader.result, testMode)
          .then(({ data }) => {
            this.loaderStatus = "hide";
            const { count_rows, error_rows, tcp_session_id } = data;
            let message = `Les données ont bien été importées : <br />
            - ${count_rows} ligne de données dont ${(error_rows && error_rows.length) || 0} erreurs <br /> 
            - Session TCP généré: <b> ${tcp_session_id} </b>
            `;
            this.setSuccess({
              message
            });
            if (this.onSuccess !== undefined) {
              this.onSuccess();
            }
            this.closeModal();
          })
          .catch(e => {
            this.loaderStatus = "hide";
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      };
      reader.readAsText(dataFile, "UTF-8");
    }
  },
  data() {
    return {
      dataFile: null,
      testMode: true,
      loaderStatus: "hide"
    };
  },
  components: { Loader }
};
</script>
