import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Buefy from "buefy";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import store from "./store";
import { Auth } from "./plugin";
import { GET_VUE_ROUTES_ACTION } from "Stores/routes";
import { saveAccessToken, containAccessToken } from "Src/plugin";

if (containAccessToken(window.location)) {
  const href = saveAccessToken(window.location);
  if (href !== null) {
    window.location.href = href;
  }
}

const date = new Date();

if (date.getDate() === 4 && date.getMonth() + 1 === 5) {
  document.querySelector("link[rel='icon']").href = "may4.ico";
}

let redirectUrl = window.localStorage.getItem("fromUrl");
window.localStorage.removeItem("fromUrl");
if (redirectUrl !== null) {
  window.location.href = redirectUrl;
}

Chartkick.options = {
  colors: ["#20d160", "#4a4a4a"]
};
Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueRouter);
Vue.use(Auth);
Vue.use(Chartkick.use(Chart));

store
  .dispatch(GET_VUE_ROUTES_ACTION)
  .then(vueRoutes =>
    new Vue({
      render: h => h(App),
      store: store,
      router: new VueRouter({ routes: vueRoutes })
    }).$mount("#app")
  )
  .catch(e => {
    if (e !== 401) alert(e);
  });
