<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <form @submit.prevent="submit" v-show="view === FORM_VIEW">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Importer des exploitations membres</p>
        </header>
        <section class="modal-card-body">
          <div class="message is-info">
            <div class="message-body">
              Votre fichier doit être au format CSV avec comme séparateur une virgule [,].
              <br />
              Le fichier doit comporter 5 colonnes:
              <ul>
                <li><b> Colonne 1: </b> Nom (obligatoire)</li>
                <li><b> Colonne 2: </b> adresse (optionnelle)</li>
                <li><b> Colonne 3: </b> téléphone (optionnel)</li>
                <li><b> Colonne 4: </b> adresse email (optionnelle)</li>
                <li><b> Colonne 5: </b> siren (optionnel)</li>
              </ul>
              <br />
              Attention, pour que l'adresse email soit bien prise en compte même si aucune adresse (ou numéro de
              téléphone) n'est définie il faut tout de même définir ces champs. Exemple:
              <pre>Ferme 1,,,contact@karnott.fr,siren 12<br />Ferme 2,mon adresse,mon téléphone,contact+2@karnott.fr,siren 123</pre>
              <br />
              Si l'exploitation existe déjà alors seul l'utilisateur avec l'adresse email sera créé et associé à
              l'exploitation.
            </div>
          </div>
          <b-field label="">
            <b-upload v-model="csvFile" :native="true">
              <a class="button is-primary">
                <b-icon icon="upload"> </b-icon>
                <span>Selectionner votre fichier CSV</span>
              </a>
            </b-upload>
          </b-field>
          <p v-if="csvFile">
            Fichier choisi: <span class="tag">{{ csvFile.name }} </span>
          </p>
          <b-field label="Choisir le séparateur">
            <b-select v-model="csvSeparator">
              <option value=","> , </option>
              <option value=";"> ; </option>
            </b-select>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot" v-show="view === FORM_VIEW">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info">
          Ajouter
        </button>
      </footer>
    </form>
    <div v-show="view === RESULT_VIEW">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Importer des exploitations membres</p>
        </header>
        <section class="modal-card-body">
          <h2 class="title">Résultat de l'import</h2>
          <div class="message is-info">
            <div class="message-header">{{ createdClusters.length }} exploitation(s) créée(s):</div>
            <div class="message-body" v-show="createdClusters.length > 0">
              <ul>
                <li v-for="c in createdClusters" :key="c.id">{{ c.name }}</li>
              </ul>
            </div>
          </div>

          <div class="message is-info">
            <div class="message-header">{{ updatedClusters.length }} exploitation(s) modifiée(s):</div>
            <div class="message-body" v-show="updatedClusters.length > 0">
              <ul>
                <li v-for="c in updatedClusters" :key="c.id">- {{ c.name }}</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <footer class="modal-card-foot">
        <a class="button is-primary" @click.prevent="closeModal">Fermer</a>
        <a class="is-primary" @click.prevent="() => setFormView()">Importer un nouveau fichier</a>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import { mapActions } from "vuex";
import { importMemberClusters } from "Api/cluster";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

export default {
  props: ["cluster", "isActive", "onLink", "onClose"],
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      this.setFormView();
      this.onClose();
    },
    setFormView() {
      this.view = this.FORM_VIEW;
      this.resetVar();
    },
    resetVar() {
      this.csvFile = null;
      this.updatedClusters = [];
      this.createdClusters = [];
    },
    submit() {
      const { apiClient } = this.$store.getters;
      const { cluster, csvFile, csvSeparator } = this;
      const reader = new FileReader();
      reader.onload = () => {
        const lines = reader.result.split("\n");
        if (lines.length === 0) {
          return;
        }

        importMemberClusters(apiClient)(cluster.id, reader.result, csvSeparator)
          .then(({ data }) => {
            this.view = this.RESULT_VIEW;
            const { created_clusters, updated_clusters } = data;
            if (created_clusters) {
              this.createdClusters = created_clusters;
            }
            if (updated_clusters) {
              this.updatedClusters = updated_clusters;
            }
            if (this.onLink !== undefined) {
              this.onLink();
            }
          })
          .catch(e => {
            this.setError({
              message: `Une erreur est survenue`,
              error: e
            });
          });
      };
      reader.readAsText(csvFile, "UTF-8");
    }
  },
  data() {
    return {
      csvFile: null,
      csvSeparator: ",",
      FORM_VIEW: "form",
      RESULT_VIEW: "result",
      view: "form",
      createdClusters: [],
      updatedClusters: []
    };
  }
};
</script>
