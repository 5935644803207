/* global google */
export const drawDataOnMap = (dataFromApi, map, params, isBeaconVue = false) => {
  const { color, polilyneColor, weight, opacity, icon, drawMarker, drawPolyline, focusOn } = params;
  let markers = [];
  let eventPoints = [];
  if (!dataFromApi) {
    return;
  }
  const iconWidth = weight || 7;
  let defaultIcon = getSvgUrl({
    color: color || "#FFF000",
    opacity: opacity || 1,
    stroke: darkerColor(color || "#FFF000"),
    width: iconWidth
  });
  let satelliteIcons = [];
  if (!isBeaconVue) {
    satelliteIcons = [
      { maxSatellites: 8, color: gpsColors.badGPSReceptionColor },
      { maxSatellites: 12, color: gpsColors.averageGPSReceptionColor },
      { maxSatellites: Number.MAX_SAFE_INTEGER, color: gpsColors.goodGPSReceptionColor }
    ].map(range => {
      return {
        maxSatellites: range.maxSatellites,
        icon: getSvgUrl({
          color: range.color,
          opacity: opacity || 1,
          stroke: darkerColor(range.color),
          width: iconWidth
        })
      };
    });
  }
  let defaultInfoWindow = null;

  dataFromApi.forEach(e => {
    e = formatLocation(e);
    let selectedIcon = icon || defaultIcon;
    if (!isBeaconVue) {
      selectedIcon = satelliteIcons.find(iconRange => e.satellites < iconRange.maxSatellites).icon;
    }

    const eventPoint = new google.maps.LatLng(e.latitude, e.longitude);
    eventPoints.push(eventPoint);

    if (drawMarker) {
      const m = new google.maps.Marker({
        position: eventPoint,
        map,
        icon: selectedIcon
      });

      const date = e.occurred_at ? new Date(e.occurred_at).toLocaleString() : null;
      m.addListener("click", () => {
        if (defaultInfoWindow != null) {
          defaultInfoWindow.close();
        }
        defaultInfoWindow = eventPointInfoWindow(date, e.dop, e.satellites, e.device_serialnumber);
        defaultInfoWindow.open(map, m);
      });
      markers.push(m);
    }
  });

  const path = new google.maps.Polyline({
    path: eventPoints,
    geodesic: true,
    strokeColor: polilyneColor || color || "#FF0000",
    strokeOpacity: opacity || 1,
    strokeWeight: weight || 2
  });
  if (focusOn) {
    zoomToObject(path, map);
  }
  if (drawPolyline) {
    path.setMap(map);
  }

  return { eventPoints, markers, path };
};

export const formatLocation = e => {
  if (e.location_date) {
    return {
      ...e,
      occurred_at: e.location_date,
      latitude: e.location_latitude,
      longitude: e.location_longitude
    };
  }
  return e;
};

export const zoomToObject = (obj, map) => {
  if (obj === undefined || obj.getPath() === undefined) {
    return;
  }

  const bounds = new google.maps.LatLngBounds();

  if (bounds === undefined) {
    return;
  }
  obj
    .getPath()
    .getArray()
    .forEach(i => {
      if (i === undefined) {
        return;
      }
      bounds.extend(i);
    });
  map.fitBounds(bounds);
};

export const eventPointInfoWindow = (date, dop, satellites, serialNumber = null) => {
  let content = `${date} - dop ${dop} - sat ${satellites}`;
  if (serialNumber) {
    content = `[${serialNumber}] - ${content}`;
  }
  return new google.maps.InfoWindow({ content: content, disableAutoPan: true });
};

export const getSvgUrl = params => {
  const { width, color, opacity, stroke } = params;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${width}" viewBox="0 0 ${width} ${width}">
    <rect x="0" y="0" width="100%" height="100%" fill="${color || "#FFF000"}" opacity="${opacity ||
    1}" stroke="${stroke || "#FFF000"}" />
  </svg>`;
  return URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
};

export const darkerColor = color => {
  return color.replaceAll(/./g, c => {
    if (c === "#" || c === "a") {
      return c;
    }
    return String.fromCharCode(c.charCodeAt(0) - 2);
  });
};

export const deviceSessionColor = "#f94d4d";

export const markerColors = [
  "#f94d4d",
  "#f9cd4d",
  "#76f94d",
  "#4df9a3",
  "#4de0f9",
  "#4da9f9",
  "#4d78f9",
  "#36ca8b",
  "#36a7ca"
];
export const markerStrokeWeight = 8;

export const gpsColors = {
  badGPSReceptionColor: "#126de9",
  averageGPSReceptionColor: "#fe9741",
  goodGPSReceptionColor: "#55df2d"
};
