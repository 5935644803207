<template>
  <div class="search">
    <b-autocomplete
      rounded
      size="is-small"
      :data="data"
      icon="magnify"
      :loading="isLoading"
      @typing="getData"
      @select="selectEntity"
    >
      <template v-slot="props">
        <p class="search-result">
          <span class="tag" :class="props.option.typeClass" v-if="props.option.type"> {{ props.option.type }} </span>
          <span class="tag" :class="props.option.stateClass" v-if="props.option.state"> {{ props.option.state }} </span>
          <span class="tag" :class="props.option.cssClassSubType" v-if="props.option.subType">
            {{ props.option.subType }}
          </span>
          <span> {{ props.option.name }} </span>
        </p>
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import {
  callApiWithSignal,
  getBeaconTypeCssClassName,
  getClusterCurrentStateColor,
  getClusterTypeColor,
  sortClusters
} from "Utils";
import { search } from "Api/search";

var timeout = null;
export default {
  name: "menu-search",
  data() {
    return {
      isLoading: false,
      signal: null,
      data: []
    };
  },
  methods: {
    getData(value) {
      clearTimeout(timeout);
      if (this.signal) {
        this.signal.cancel();
      }
      this.data = [];
      const qLowerCase = value.toLowerCase();
      // because aa & aaa search filter return too many results
      if (qLowerCase.length < 2 || qLowerCase == "aaa" || qLowerCase == "aa") {
        return;
      }
      timeout = setTimeout(() => {
        const { apiClient } = this.$store.getters;
        this.isLoading = true;
        const { apiFunc, signal } = callApiWithSignal(search, apiClient);
        this.signal = signal;
        apiFunc({ q: value })
          .then(({ data }) => {
            const { clusters, devices, users, beacons } = data;
            this.data = [];
            clusters.sort(sortClusters).forEach(c =>
              this.data.push({
                type: c.current_state ? c.cluster_type.slice(0, 4) : undefined,
                state: c.current_state ? c.current_state.slice(0, 4) : undefined,
                id: c.id,
                name: c.name,
                typeClass: getClusterTypeColor(c.cluster_type),
                stateClass: getClusterCurrentStateColor(c.current_state)
              })
            );
            users.forEach(u =>
              this.data.push({ type: "Utilisateur", id: u.id, name: u.email, typeClass: "is-warning" })
            );
            devices
              .sort((a, b) => a.serialnumber.localeCompare(b.serialnumber))
              .forEach(d => this.data.push({ type: "Karnott", id: d.id, name: d.serialnumber, typeClass: "is-dark" }));
            beacons
              .sort((a, b) => a.uuid.localeCompare(b.uuid))
              .forEach(b =>
                this.data.push({
                  type: "Beacon",
                  subType: b.type,
                  id: b.uuid,
                  name: b.uuid,
                  typeClass: "is-success",
                  cssClassSubType: getBeaconTypeCssClassName(b.type)
                })
              );
            this.isLoading = false;
          })
          .catch(e => {
            this.isLoading = false;
            if (e.code !== "ERR_CANCELED") {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            }
          });
      }, 300);
    },
    selectEntity(value) {
      const { type, id } = value;
      let path = null;
      path = `/cluster/${id}`;
      if (type === "Utilisateur") {
        path = `/user/${id}`;
      }
      if (type === "Karnott") {
        path = `/device/${id}`;
      }
      if (type === "Beacon") {
        path = `/beacon/${id}`;
      }
      this.$router.push({ path }).catch(() => {});
    }
  }
};
</script>

<style scoped>
.search {
  width: 300px;
}
.search-result {
  padding: 5px;
}
.search-result * {
  font-size: 12px;
}
.search-result .tag {
  font-size: 10px;
}
.search-result .tag:not(:last-child) {
  margin-right: 2px;
}
</style>
