<template>
  <div :class="`button is-warning ${cssClass}`" @click.prevent="unlinkKarnott2DaysLater()">
    <b-icon icon="link-off" size="is-small"></b-icon>
    <span> +2 </span>
  </div>
</template>

<script>
import { unlinkDeviceToClusterWithDate } from "Api/deviceHasCluster";
import { formatToRFC3339, formatDate } from "Utils";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import { mapActions } from "vuex";

export default {
  props: ["device", "cluster", "onSuccess", "cssClass"],
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    formatDate(date) {
      if (typeof date === "string") {
        date = new Date(date);
      }
      return formatDate(date);
    },
    unlinkKarnott2DaysLater() {
      const { cluster, onSuccess, device } = this;
      var message = "Le karnott sera désassocié dans 2 jours. Etes vous sur ?";
      if (device.associate_to_date) {
        message = `Le karnott a déjà une date de désassociation au ${this.formatDate(
          device.associate_to_date
        )}. Etes vous sur de la redefinir à dans 2 jours ?`;
      }
      let twoDaysLater = new Date();
      twoDaysLater.setDate(twoDaysLater.getDate() + 2);
      this.$buefy.dialog.confirm({
        message,
        onConfirm: () => {
          const { apiClient } = this.$store.getters;
          unlinkDeviceToClusterWithDate(apiClient)(device.id, cluster.id, formatToRFC3339(twoDaysLater))
            .then(() => {
              this.setSuccess({
                message: `L'association avec le karnott [${device.name}] expirera dans 2 jours`
              });
              onSuccess && onSuccess();
            })
            .catch(e => {
              this.setError({
                message: `Une erreur est survenue`,
                error: e
              });
            });
        }
      });
    }
  }
};
</script>
