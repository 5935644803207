<template>
  <div>
    <b-table
      :loading="isLoading"
      :per-page="perPage"
      :data="devices"
      paginated
      :row-class="row => getClassFromDevice(row)"
    >
      <b-table-column v-slot="props" field="cluster_state" label="Etat exploitation" sortable>
        <span class="tag">
          {{ props.row.cluster_id ? props.row.cluster_state : "-" }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="serialnumber" label="Numéro de série" sortable>
        <router-link class="button is-text" :to="`/device/${props.row.id}`">
          {{ props.row.serialnumber }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="batterie" label="Bat." sortable>
        <b-icon
          :icon="getBatteryIcon(props.row.external_battery_level, props.row.charging_state)"
          size="is-small"
        ></b-icon>
      </b-table-column>
      <b-table-column v-slot="props" field="firmware_name" label="Firmware" sortable>
        <span>
          {{ props.row.firmware_name }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="hardware_version" label="Version hardware" sortable>
        <span>
          {{ props.row.hardware_version }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="ble_version" label="Version BLE" sortable>
        <span>
          {{ props.row.ble_version ? props.row.ble_version : "-" }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="status" label="Statut" sortable>
        <span>
          {{ props.row.status }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="cluster" label="Exploitation" sortable>
        <span v-if="getClusterName(props.row) === '-'">
          {{ getClusterName(props.row) }}
        </span>
        <router-link
          v-if="getClusterName(props.row) !== '-'"
          :to="'/cluster/' + props.row.cluster_id"
          class="tag is-large"
          target="_blank"
        >
          {{ getClusterName(props.row) }}
        </router-link>
        <span v-if="getClusterName(props.row) !== '-'">
          ({{ props.row.cluster_id ? props.row.cluster_state : "-" }})</span
        >
      </b-table-column>
      <b-table-column v-slot="props" field="last_connection_date" label="Dernière connexion" sortable>
        <span>
          {{ props.row.last_connection_date_string }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="current_state_with_cluster" label="Statut de liaison" sortable>
        <span>
          {{
            props.row.current_state_with_cluster ? fmtDeviceStateWithCluster(props.row.current_state_with_cluster) : "-"
          }}
        </span>
      </b-table-column>
      <b-table-column v-slot="props" field="actions" label="Actions" sortable>
        <a class="button is-info" @click="showHistory(props.row)"> Voir l'historique </a>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Aucun karnott</p>
          </div>
        </section>
      </template>
    </b-table>

    <DeviceHistoryModal
      :device="selectedDeviceForHistory"
      :isModalActive="isModalActive"
      :onClose="onClose"
      :clusters="clusters"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { getClusterName } from "Utils";
import DeviceHistoryModal from "Components/devices/DeviceHistoryModal";
import { DEVICE_HEALTH_CRITICAL, DEVICE_HEALTH_DOWN, DEVICE_STATES_WITH_CLUSTER_LABEL } from "Constants";

export default {
  components: { DeviceHistoryModal },
  props: ["clusters", "devices", "isDeviceOk", "perPage", "isLoading"],
  data() {
    return {
      selectedDeviceForHistory: null,
      isModalActive: false
    };
  },
  methods: {
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    getBatteryIcon(external_battery_level, charging_state) {
      if (!external_battery_level) {
        return "battery-off-outline";
      }
      if (charging_state === "CHARGED") {
        return "power-plug-battery";
      }
      if (charging_state === "CHARGING") {
        return `battery-charging-${Math.ceil(external_battery_level / 10) * 10}`;
      }
      if (external_battery_level > 90) {
        return `battery`;
      }
      return `battery-${Math.ceil(external_battery_level / 10) * 10}`;
    },
    onClose() {
      this.selectedDeviceForHistory = null;
      this.isModalActive = false;
    },
    showHistory(device) {
      this.selectedDeviceForHistory = device;
      this.isModalActive = true;
    },
    getClusterName(device) {
      return getClusterName(device);
    },
    getClassFromDevice(device) {
      const deviceHealth = device.health && device.health.status;
      switch (deviceHealth) {
        case DEVICE_HEALTH_DOWN:
          return "is-custom-danger";
        case DEVICE_HEALTH_CRITICAL:
          return "is-danger";
        default:
          return "";
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION
    })
  }
};
</script>
<style>
tr.is-custom-danger {
  background-color: #ffb88d;
}
</style>
