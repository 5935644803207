<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading"> </b-loading>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des jobs
          </h1>
        </div>
      </div>
      <div class="hero is-dark is-bold">
        <div class="hero-body">
          <div class="container">
            <h2 class="subtitle">Filtre</h2>
            <div class="next-step tabs is-toggle is-fullwidth">
              <ul>
                <li
                  :key="filter"
                  v-for="filter in filters"
                  @click="() => (typeFilter = filter)"
                  :class="typeFilter === filter && 'is-active'"
                >
                  <a class="is-dark">
                    <span>{{ filter }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container container--data">
      <b-collapse :open="false" class="card" v-for="job in filteredJobs" :key="job.id">
        <div slot="trigger" class="card-header" role="button">
          <p class="card-header-title">
            <span> #{{ job.id }} </span>
            <span class="tag is-warning"> {{ job.type }} </span>
            <span class="tag is-primary"> {{ job.label }} </span>
            <span class="tag is-info"> {{ job.status }} </span>
            <span class="tag"> {{ job.created_at.toLocaleString() }} </span>
            <span v-for="email in getEmails(job)" class="tag is-success" :key="email"> {{ email }} </span>
            <span v-for="serialNumber in getDevices(job)" class="tag is-warning" :key="serialNumber">
              {{ serialNumber }}
            </span>
            <span v-for="equipment in getEquipment(job)" class="tag is-warning" :key="equipment">
              {{ equipment }}
            </span>
          </p>
        </div>
        <div class="card-content">
          <div class="content">
            <pre>{{ job }}</pre>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<style scoped>
.modal-card {
  width: 100%;
}
.container {
  padding: 0px 0 10px 0;
}
.container--data {
  padding-top: 20px;
}
.card-header-title > span {
  margin: 5px;
}
</style>
<script>
import { mapActions } from "vuex";
import { getJobsByClusterId } from "Api/job";
import { SET_ERROR_ACTION } from "Stores/message";

const loadAllJobs = (getJobsFunc, clusterId) => {
  return new Promise((resolve, reject) => {
    let jobsData = [];
    const loadJobs = cursor => {
      getJobsFunc(clusterId, 100, { cursor })
        .then(({ data }) => {
          if (data && data.length > 0) {
            jobsData = jobsData.concat(data);
            loadJobs(data[data.length - 1].id);
            return;
          }
          resolve(jobsData.sort((a, b) => (a.id < b.id ? 1 : -1)));
        })
        .catch(e => {
          reject(e);
        });
    };
    loadJobs(null);
  });
};

export default {
  props: ["cluster"],
  watch: {
    typeFilter: function() {
      const { typeFilter } = this;
      if (typeFilter === "TOUS") {
        this.filteredJobs = this.jobs;
        this.isLoading = false;
        return;
      }
      this.filteredJobs = this.jobs.filter(job => job.type === typeFilter);
    },
    jobs: function() {
      this.filteredJobs = this.jobs;
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION
    }),
    loadJobs() {
      this.isLoading = true;
      const { apiClient } = this.$store.getters;
      loadAllJobs(getJobsByClusterId(apiClient), this.cluster.id)
        .then(jobs => {
          this.jobs = jobs;
          this.isLoading = false;
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des jobs`,
            error: e
          });
        });
    },
    getEmails(job) {
      if (!job.meta.to) {
        return [];
      }
      return job.meta.to.map(e => e.email);
    },
    getDevices(job) {
      if (!job.meta || !job.meta.mailjet_vars || !job.meta.mailjet_vars.karnottName) {
        return [];
      }
      return [job.meta.mailjet_vars.karnottName];
    },
    getEquipment(job) {
      if (!job.meta || !job.meta.mailjet_vars || !job.meta.mailjet_vars.equipment) {
        return [];
      }
      return [job.meta.mailjet_vars.equipment];
    }
  },
  mounted() {
    this.loadJobs();
  },
  data() {
    return {
      jobs: [],
      typeFilter: "TOUS",
      filters: ["TOUS", "NOTIFICATION", "MAIL"],
      filteredJobs: [],
      isLoading: false
    };
  }
};
</script>
