const { VUE_APP_OAUTH_URL, VUE_APP_CLIENT_ID } = process.env;

const Auth = {
  install: Vue => {
    Vue.prototype.$auth = new Vue({
      created() {
        if (this.getAccessToken() === null) {
          SaveLastUrlAndRedirectToSignIn();
        }

        return;
      },
      methods: {
        getAccessToken() {
          return window.localStorage.getItem("accessToken");
        }
      }
    });
  }
};
const SaveLastUrlAndRedirectToSignIn = () => {
  window.localStorage.setItem("fromUrl", window.location.href);
  const url = `${VUE_APP_OAUTH_URL}/authorize?response_type=token&client_id=${VUE_APP_CLIENT_ID}`;
  window.location.href = url;
};

const RemoveLastUrlAndRedirectToHome = () => {
  window.localStorage.removeItem("fromUrl");
  window.location.href = "/";
};

export { Auth, SaveLastUrlAndRedirectToSignIn, RemoveLastUrlAndRedirectToHome };

export const containAccessToken = urlLocation => {
  return /access_token=/.test(urlLocation.hash);
};

export const saveAccessToken = urlLocation => {
  const hash = urlLocation.hash;
  if (hash === "" || hash === undefined) {
    return null;
  }
  const hashValues = hash.replace("#", "").split("&");
  let accessToken = 0;
  for (let v of hashValues) {
    let keyValue = v.split("=");
    if (keyValue[0] === "access_token") {
      accessToken = keyValue[1];
    }
  }
  if (accessToken === 0) {
    return null;
  }
  window.localStorage.setItem("accessToken", accessToken);
  let redirectUrl = window.localStorage.getItem("fromUrl");
  if (redirectUrl === null) {
    redirectUrl = "/";
  }
  window.localStorage.removeItem("fromUrl");
  return redirectUrl;
};
export const logout = () => {
  window.localStorage.removeItem("accessToken");
};
