<template>
  <div>
    <div class="box">
      <form @keyup.enter="loadData" @submit.prevent="loadData">
        <b-message>
          Au chargement de la page, seule la dernière position du boitier est affichée pour un soucis de performance :)
        </b-message>
        <b-field grouped>
          <b-field label="Depuis le">
            <b-datetimepicker v-model="fromDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <b-field label="Jusqu'au">
            <b-datetimepicker v-model="toDate" placeholder="Type or select a date..." icon="calendar-today" editable>
            </b-datetimepicker>
          </b-field>
          <div class="field button-field">
            <p class="control">
              <button class="button is-success">Mettre à jour</button>
            </p>
          </div>
          <div class="legend-wrapper">
            <div class="legend-line">
              <b-tag type="is-primary" :style="`background-color: ${goodColor};`"></b-tag>
              Bonne réception satellite (supérieure ou égale à 12 captés)
            </div>
            <div class="legend-line">
              <b-tag type="is-warning" :style="`background-color: ${averageColor};`"></b-tag>
              Réception satellite moyenne (supérieure à 8)
            </div>
            <div class="legend-line">
              <b-tag type="is-danger" :style="`background-color: ${badColor};`"></b-tag>
              Mauvaise réception satellite (inférieure ou égale à 8)
            </div>
          </div>
        </b-field>
        <b-field>
          <p class="control">
            <button
              @click.prevent="
                setTodayDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              Aujourd'hui
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                setYesterdayDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              Hier
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                setTwoDaysAgoDate();
                loadData();
              "
              class="button is-outlined is-dark"
            >
              12 dernières heures
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                slideDate(24);
                loadData();
              "
              class="button is-outlined is-dark"
            >
              +24 heures
            </button>
          </p>
          <p class="control">
            <button
              @click.prevent="
                slideDate(-24);
                loadData();
              "
              class="button is-outlined is-dark"
            >
              -24 heures
            </button>
          </p>
        </b-field>
      </form>
    </div>
    <div id="map-container">
      <b-loading :is-full-page="false" :active="isLoadingEventPoints"> </b-loading>
      <div id="map-legend" v-if="mapData.length && !device">
        <span
          :style="`color:${darkerColor(r.color)};${r.displayed ? '' : 'text-decoration:line-through'};`"
          v-for="r in mapData"
          :key="r.color"
        >
          <svg width="20px" height="20px" @click="toggleSerialNumber(r.serialNumber)">
            <path d="M 0 0 H 20 V 20 H 0 Z" :fill="r.color" :stroke="darkerColor(r.color)" />
          </svg>
          {{ r.serialNumber }}
        </span>
      </div>
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
/// Below line disables no-undef google Maps related warnings
/// (because Google Maps is loaded in a script tag rather than
/// in the bundle.).
/* global google */
import { SET_ERROR_ACTION } from "Stores/message";
import { GET_LOCATION_BY_DEVICE_INSTANCE_ID } from "Stores/device-data";
import { getBeaconEventPoints } from "Api/beacon";
import { mapActions } from "vuex";
import { dateToLocaleString, stringToDate, formatInputDates, formatToRFC3339 } from "Utils";
import { drawDataOnMap, darkerColor, markerColors, deviceSessionColor, gpsColors } from "Utils/map";
const getTwoDaysAgoInterval = () => {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(fromDate.getHours() - 12);
  return {
    fromDate,
    toDate
  };
};
const getYesterdayInterval = () => {
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 1);
  fromDate.setHours(0);
  fromDate.setMinutes(0);

  let toDate = new Date();
  toDate.setDate(toDate.getDate() - 1);
  toDate.setHours(23);
  toDate.setMinutes(59);
  return {
    fromDate,
    toDate
  };
};
const getTodayInterval = () => {
  let fromDate = new Date();
  let toDate = new Date();
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  return {
    fromDate,
    toDate
  };
};

const MANAGED_QUERY_PARAMS = [
  { field: "fromDate", fmt: v => stringToDate(v) },
  { field: "toDate", fmt: v => stringToDate(v) }
];

export default {
  props: ["device", "beacon"],
  computed: {
    tool() {
      if (this.device) {
        return this.device;
      }
      if (this.beacon) {
        return this.beacon;
      }
      return null;
    }
  },
  mounted() {
    this.syncFromQueryParams(MANAGED_QUERY_PARAMS);
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      tilt: 0,
      rotateControl: false
    });
    const { location_latitude, location_longitude, location_date } = this.tool;
    if (location_latitude && location_longitude) {
      this.resetMap([
        {
          latitude: location_latitude,
          longitude: location_longitude,
          occurred_at: location_date,
          dop: null,
          satellites: null,
          device_serialnumber: this.device ? this.device.serialnumber : "device unknown"
        }
      ]);
    }
  },
  methods: {
    darkerColor(color) {
      return darkerColor(color);
    },
    setTodayDate() {
      const { fromDate, toDate } = getTodayInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    setYesterdayDate() {
      const { fromDate, toDate } = getYesterdayInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    setTwoDaysAgoDate() {
      const { fromDate, toDate } = getTwoDaysAgoInterval();
      this.fromDate = fromDate;
      this.toDate = toDate;
    },
    slideDate(hours) {
      const { fromDate, toDate } = this.formatInputDates();
      const ms = hours * 60 * 60 * 1000;
      this.fromDate = new Date(fromDate.getTime() + ms);
      this.toDate = new Date(toDate.getTime() + ms);
    },
    getToolEventPoints(fromDate, toDate) {
      if (this.device) {
        return this.getEventPoints({
          id: this.device.id,
          filterBy: "occurred_at",
          params: {
            from: fromDate,
            to: toDate
          }
        });
      }
      if (this.beacon) {
        const { apiClient } = this.$store.getters;
        return getBeaconEventPoints(apiClient)(this.beacon.uuid, {
          from_date: fromDate,
          to_date: toDate
        });
      }
      return null;
    },
    formatInputDates() {
      let { fromDate, toDate } = this;
      return formatInputDates(fromDate, toDate);
    },
    loadData() {
      const { fromDate, toDate } = this.formatInputDates();
      if (fromDate === null || toDate === null) {
        return;
      }

      this.updateUrl();
      this.isLoadingEventPoints = true;
      const p = this.getToolEventPoints(formatToRFC3339(fromDate), formatToRFC3339(toDate));
      if (p !== null) {
        p.then(({ data }) => {
          this.resetMap(data);
          this.isLoadingEventPoints = false;
        }).catch(e => {
          this.isLoadingEventPoints = false;
          this.setError({
            message: "Impossible de récupérer les points GPS <br />",
            error: e
          });
        });
      }
    },
    resetMap(dataFromApi) {
      this.mapData.forEach(md => {
        md.markers.forEach(m => m.setMap(null));
        md.polyline.setMap(null);
      });
      this.mapData = [];
      this.locationsGroupedBySerialNumber = new Map();
      dataFromApi.forEach(e => {
        if (!e.device_serialnumber && this.device) {
          e.device_serialnumber = this.device.serialnumber;
        }
        if (!this.locationsGroupedBySerialNumber.has(e.device_serialnumber)) {
          this.locationsGroupedBySerialNumber.set(e.device_serialnumber, []);
          if (e.device_serialnumber) {
            const color = markerColors[this.locationsGroupedBySerialNumber.size - 1] || "#0e0e0e";
            this.mapData.push({
              color,
              serialNumber: e.device_serialnumber,
              markers: [],
              polyline: null,
              displayed: true
            });
          }
        }
        this.locationsGroupedBySerialNumber.get(e.device_serialnumber).push(e);
      });

      this.locationsGroupedBySerialNumber.forEach((points, serialNumber) => {
        let color = deviceSessionColor;
        if (this.mapData.length && this.mapData.find(md => md.serialNumber === serialNumber)) {
          color = this.mapData.find(md => md.serialNumber === serialNumber).color;
        }
        const { markers, path } = drawDataOnMap(
          points,
          this.map,
          {
            drawMarker: true,
            drawPolyline: true,
            color: color,
            opacity: 0.9,
            focusOn: false
          },
          this.beacon
        );
        if (this.mapData.length && this.mapData.find(md => md.serialNumber === serialNumber)) {
          this.mapData.find(md => md.serialNumber === serialNumber).markers = this.mapData
            .find(md => md.serialNumber === serialNumber)
            .markers.concat(markers);
          this.mapData.find(md => md.serialNumber === serialNumber).polyline = path;
        }
      });
      this.fitMapData();
    },
    toggleSerialNumber(serialNumber) {
      const toggleMapData = this.mapData.find(md => md.serialNumber === serialNumber);
      let mapContent = this.map;
      if (toggleMapData.displayed) {
        mapContent = null;
      }
      toggleMapData.markers.forEach(m => m.setMap(mapContent));
      toggleMapData.polyline.setMap(mapContent);
      toggleMapData.displayed = !toggleMapData.displayed;
      this.fitMapData();
    },
    fitMapData() {
      if (this.mapData.length) {
        const bounds = new google.maps.LatLngBounds();
        this.mapData
          .filter(md => md.displayed)
          .forEach(md => {
            md.polyline
              .getPath()
              .getArray()
              .forEach(i => {
                if (i === undefined) {
                  return;
                }
                bounds.extend(i);
              });
          });
        this.map.fitBounds(bounds);
      }
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      getEventPoints: GET_LOCATION_BY_DEVICE_INSTANCE_ID
    }),
    updateUrl() {
      const query = Object.assign({}, this.$route.query);
      MANAGED_QUERY_PARAMS.map(prop => (prop.field ? prop.field : prop)).forEach(prop => {
        const value = prop === "fromDate" || prop === "toDate" ? dateToLocaleString(this[prop]) : this[prop];
        return (query[prop] = value);
      });

      if (this.device) {
        this.$router.push({ path: this.device.id.toString(), query }).catch(()=>{});
      }

      if (this.beacon) {
        //this.$router.push({ path: this.beacon.uuid, query });
      }
    },
    syncFromQueryParams(props) {
      props.forEach(prop => {
        const field = prop.field ? prop.field : prop;
        const value = this.$route.query[field];

        if (value) {
          this[field] = prop.fmt ? prop.fmt(value) : value;
        }
      });
    }
  },
  data() {
    const { fromDate, toDate } = getTwoDaysAgoInterval();
    return {
      fromDate: fromDate,
      toDate: toDate,
      mapData: [],
      map: null,
      defaultInfoWindow: null,
      isLoadingEventPoints: false,
      locationsGroupedBySerialNumber: new Map(),
      goodColor: gpsColors.goodGPSReceptionColor,
      averageColor: gpsColors.averageGPSReceptionColor,
      badColor: gpsColors.badGPSReceptionColor
    };
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px;
}
#map {
  width: 90%;
  height: 600px;
}
#map-legend {
  width: 200px;
}
#map-legend > span {
  display: flex;
  align-items: center;
  margin: 10px;
}
#map-legend > span > svg {
  margin-right: 10px;
}
.button-field {
  padding: 31px 0 11px;
}
.legend-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-line {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
</style>
