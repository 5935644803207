<template>
  <router-link :to="path" class="item">
    {{ label }}
  </router-link>
</template>

<script>
export default {
  props: ["path", "label"],
  name: "menu-link"
};
</script>
