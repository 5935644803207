<template>
  <b-modal :active="isActive" :on-cancel="closeModal">
    <div class="modal-card">
      <header class="modal-card-head">Désassocier les Karnott sélectionnés</header>
      <section class="modal-card-body">
        <div class="panel is-danger">
          <p class="panel-heading">
            Attention ! Les karnott suivant seront désassociés du compte :
          </p>
          <div class="panel-block">
            <div class="control">
              <div class="field">
                <div class="control is-expanded">
                  <b-message type="is-info">
                    Boitiers sélectionnés:
                    <ul>
                      <li :key="device.id" v-for="device in devices">{{ device.serialnumber }}</li>
                    </ul>
                  </b-message>
                </div>
              </div>
              <div class="field is-expanded">
                <button class="button is-success is-fullwidth" @click="unlinkSelectedKarnott">
                  Désassocier les boitiers
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>
<style scoped>
ul {
  list-style: inherit;
  margin-left: 30px;
}
</style>

<script>
import { mapActions } from "vuex";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";

export default {
  props: ["devices", "onClose", "isActive", "unlinkKarnott"],
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    closeModal() {
      const { onClose } = this;
      onClose && onClose();
    },
    unlinkSelectedKarnott() {
      const { devices, closeModal, unlinkKarnott } = this;
      unlinkKarnott(devices);
      closeModal();
    }
  }
};
</script>
