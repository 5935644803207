import { getUserMe } from "Api/user";
import { HARDWARE_USER_ROLE, ADMIN_USER_ROLE } from "Constants";

export const SET_USER_ME_MUTATION = "SET_USER_ME_MUTATION";
export const GET_USER_ME_ACTION = "GET_USER_ME_ACTION";

function isHardware({ application_role }) {
  return () => application_role === HARDWARE_USER_ROLE;
}

export function isAdmin({ application_role }) {
  return () => application_role === ADMIN_USER_ROLE;
}

export default {
  state: {
    me: null
  },
  mutations: {
    [SET_USER_ME_MUTATION](state, user) {
      if (user) {
        user.isHardware = isHardware(user);
        user.isAdmin = isAdmin(user);
      }

      state.me = user;
    }
  },
  actions: {
    [GET_USER_ME_ACTION]({ rootGetters, commit, state }) {
      if (state.me != null) {
        return state.me;
      }

      const { apiClient } = rootGetters;
      return getUserMe(apiClient).then(({ data }) => {
        commit(SET_USER_ME_MUTATION, data);
        return data;
      });
    }
  }
};
