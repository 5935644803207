<template>
  <div>
    <b-modal :active="isActive" :onCancel="closeModalAction">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Générer une session</p>
        </header>
        <section class="modal-card-body" v-if="device">
          <div class="message is-info">
            <div class="message-body" type="is-danger">
              Cette action forcera la valeur de l'accéléromètre à "true" et générera une session sur la plage de date
              choisie.
            </div>
          </div>
          <b-field label="Début de la session">
            <b-datetimepicker
              v-model="fromDate"
              placeholder="Type or select a date..."
              icon="calendar-today"
              editable
              horizontal-time-picker
            >
            </b-datetimepicker>
          </b-field>
          <b-field label="Fin de la session">
            <b-datetimepicker
              v-model="toDate"
              placeholder="Type or select a date..."
              icon="calendar-today"
              editable
              horizontal-time-picker
            >
            </b-datetimepicker>
          </b-field>
        </section>
      </div>
      <footer class="modal-card-foot" v-if="device">
        <a class="button is-primary" @click.prevent="closeModal">Annuler</a>
        <button class="button is-info" @click="submit">
          Générer
        </button>
      </footer>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { generateSession } from "Api/deviceSession";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
const fmtError = error => message => ({ error, message });
export default {
  props: ["isActive", "closeModalAction", "device"],
  data() {
    return {
      toDate: new Date(),
      fromDate: new Date()
    };
  },
  methods: {
    closeModal() {
      if (this.closeModalAction) {
        this.closeModalAction();
      }
    },
    submit() {
      const { apiClient } = this.$store.getters;
      generateSession(apiClient)(this.device.id, this.fromDate, this.toDate)
        .then(({ data }) => {
          this.closeModal();
          this.setSuccess({
            message: `Les acceleromètres ont bien été mis à true. <br />
                    Et la device_session a été créée:
                    <ul style="list-style: inherit">
                      <li>
                      id: <b> ${data.id} </b> 
                      </li>
                      <li>
                        date début: <b>${data.first_position_date}</b>
                      </li> 
                      <li>
                        date fin: <b>${data.last_movement_date}</b>
                      </li>
                    </ul>`
          });
        })
        .catch(e => {
          this.setError(fmtError(e)("Une erreur est survenue lors du reset <br />"));
        });
    },
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    })
  }
};
</script>
<style scoped>
.modal-card {
  width: 800px;
  min-height: 600px;
}
</style>
