<template>
  <div class="container is-fullhd">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Liste des Karnott ({{ devices.length }} Karnott)
            <span class="buttons">
              <button class="button is-info" @click="showAvailableAndHealthReadyDevices">
                Lister des karnott disponible (et prêt)
              </button>
              <button class="button is-info" @click="exportCsv">Exporter la liste (filtrée) en csv</button>
            </span>
          </h1>

          <div class="columns is-multiline">
            <div class="column is-full flex-wrap">
              <label class="label">Filtrer par état</label>
              <div class="field filter-by-state">
                <div class="field-body">
                  <div class="field has-addons">
                    <b-checkbox-button
                      @input="onChangeCheckbox('selectedStatusFilter')"
                      :native-value="ALL"
                      v-model="selectedStatusFilter"
                      type="is-danger"
                    >
                      <span> Tous </span>
                    </b-checkbox-button>
                    <b-checkbox-button
                      @input="onChangeCheckbox('selectedStatusFilter')"
                      :native-value="state"
                      :key="state"
                      v-model="selectedStatusFilter"
                      v-for="state in DEVICE_STATUS"
                      type="is-warning"
                    >
                      <span> {{ state }}</span>
                    </b-checkbox-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-one-half">
              <label class="label">Filtrer par statut de liaison avec une exploitation courante</label>
              <div class="field filter-by-state">
                <div class="field-body">
                  <div class="field has-addons">
                    <b-checkbox-button
                      @input="onChangeCheckbox('selectedCurrentStateWithClusterFilter')"
                      :native-value="ALL"
                      v-model="selectedCurrentStateWithClusterFilter"
                      type="is-danger"
                    >
                      <span> Tous </span>
                    </b-checkbox-button>
                    <b-checkbox-button
                      @input="onChangeCheckbox('selectedCurrentStateWithClusterFilter')"
                      :native-value="state"
                      :key="state"
                      v-model="selectedCurrentStateWithClusterFilter"
                      v-for="state in DEVICE_STATES_WITH_CLUSTER"
                      type="is-warning"
                      size="small"
                    >
                      <span> {{ fmtDeviceStateWithCluster(state) }}</span>
                    </b-checkbox-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-one-half flex-wrap">
              <label class="label">Filtrer par statut d'exploitation</label>
              <div class="field filter-by-state">
                <div class="field-body">
                  <div class="field has-addons">
                    <b-checkbox-button
                      @input="onChangeCheckbox('selectedCurrentClusterStateWithClusterFilter')"
                      :native-value="ALL"
                      v-model="selectedCurrentClusterStateWithClusterFilter"
                      type="is-danger"
                    >
                      <span> Tous </span>
                    </b-checkbox-button>
                    <b-checkbox-button
                      @input="onChangeCheckbox('selectedCurrentClusterStateWithClusterFilter')"
                      :native-value="state"
                      :key="state"
                      v-model="selectedCurrentClusterStateWithClusterFilter"
                      v-for="(label, state) in CLUSTER_STATE_STATE_LABEL_AS_FILTER"
                      type="is-warning"
                    >
                      <span> {{ label }}</span>
                    </b-checkbox-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-one-quarter">
              <label class="label">Le Karnott est-il lié à un matériel ?</label>
              <b-field>
                <b-radio-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="isLinkedToEquipmentFilter"
                  @input="filtersUpdated()"
                >
                  <span> Tous </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="YES"
                  v-model="isLinkedToEquipmentFilter"
                  @input="filtersUpdated()"
                >
                  <span> Oui </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="NO"
                  v-model="isLinkedToEquipmentFilter"
                  @input="filtersUpdated()"
                >
                  <span> Non </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <label class="label">Le Karnott est-il lié à une exploitation ?</label>
              <b-field>
                <b-radio-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="isLinkedToClusterFilter"
                  @input="filtersUpdated()"
                >
                  <span> Tous </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="YES"
                  v-model="isLinkedToClusterFilter"
                  @input="filtersUpdated()"
                >
                  <span> Oui </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="NO"
                  v-model="isLinkedToClusterFilter"
                  @input="filtersUpdated()"
                >
                  <span> Non </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-one-half">
              <label class="label">Filtrer par type d'exploitation</label>
              <b-field>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedClusterTypeFilter')"
                  :native-value="ALL"
                  v-model="selectedClusterTypeFilter"
                  type="is-danger"
                >
                  <span> Tous </span>
                </b-checkbox-button>
                <b-checkbox-button
                  @input="onChangeCheckbox('selectedClusterTypeFilter')"
                  :native-value="type"
                  :key="type"
                  v-model="selectedClusterTypeFilter"
                  v-for="type in CLUSTER_TYPES"
                  type="is-warning"
                >
                  <span> {{ type }}</span>
                </b-checkbox-button>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-one-third">
              <label class="label">
                Le Karnott fonctionne t'il ?
                <b-tooltip
                  label="Un karnott est DOWN s'il ne s'est pas connecté depuis plus d'un jour OU si sa batterie interne est inférieur à sa batterie externe ET si sa batterie interne est inférieur à 75%. Critique si DOWN et associé à un matériel"
                  :animated="true"
                  position="is-top"
                  multilined
                >
                  <span class="tag is-info">
                    <b-icon icon="help" size="is-small" />
                  </span>
                </b-tooltip>
              </label>
              <b-field>
                <b-radio-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="deviceHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> Tous </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="UP"
                  v-model="deviceHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> UP </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="DOWN"
                  v-model="deviceHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> DOWN </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="CRITICAL"
                  v-model="deviceHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> CRITICAL </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-one-third">
              <label class="label">
                La batterie est-elle OK ?
                <b-tooltip
                  label="La batterie est DOWN si la batterie interne est inférieur à sa batterie externe ET si sa batterie interne est inférieur à 75%."
                  :animated="true"
                  position="is-top"
                  multilined
                >
                  <span class="tag is-info">
                    <b-icon icon="help" size="is-small" />
                  </span>
                </b-tooltip>
              </label>
              <b-field>
                <b-radio-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="batteryHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> Tous </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="UP"
                  v-model="batteryHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> UP </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="DOWN"
                  v-model="batteryHealthFilter"
                  @input="filtersUpdated()"
                >
                  <span> DOWN </span>
                </b-radio-button>
              </b-field>
            </div>
            <div class="column is-one-third">
              <label class="label"> Le statut de la batterie </label>
              <b-field>
                <b-radio-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="batteryChargingStateFilter"
                  @input="filtersUpdated()"
                >
                  <span> Tous </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="DEVICE_CHARGING_STATE_DISCHARGING"
                  v-model="batteryChargingStateFilter"
                  @input="filtersUpdated()"
                >
                  <span> DISCHARGING </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="DEVICE_CHARGING_STATE_CHARGING"
                  v-model="batteryChargingStateFilter"
                  @input="filtersUpdated()"
                >
                  <span> CHARGING </span>
                </b-radio-button>
                <b-radio-button
                  type="is-warning"
                  :native-value="DEVICE_CHARGING_STATE_CHARGED"
                  v-model="batteryChargingStateFilter"
                  @input="filtersUpdated()"
                >
                  <span> CHARGED </span>
                </b-radio-button>
              </b-field>
              <label class="label"> Niveau de batterie actuel : {{ batteryLevel }} </label>
              <b-field>
                <vue-slider
                  v-model="batteryLevel"
                  :lazy="true"
                  :marks="batteryLevelMarks"
                  @drag-end="filtersUpdated()"
                ></vue-slider>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-one-third">
              <label class="label"> Device Model ? </label>
              <b-field>
                <b-checkbox-button
                  type="is-danger"
                  :native-value="ALL"
                  v-model="deviceModelFilter"
                  @input="onChangeCheckbox('deviceModelFilter')"
                >
                  <span> Tous </span>
                </b-checkbox-button>
                <b-checkbox-button
                  @input="onChangeCheckbox('deviceModelFilter')"
                  :native-value="model.id"
                  :key="model.id"
                  v-model="deviceModelFilter"
                  v-for="model in deviceModels"
                  type="is-warning"
                >
                  <span> {{ model.label }}</span>
                </b-checkbox-button>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-one-third">
              <label class="label">Rechercher par firmware</label>
              <b-select placeholder="Select..." v-model="firmwareIdFilter" @input="filtersUpdated()" multiple>
                <option :value="ALL">Tous</option>
                <option v-for="option in firmwares" :value="option.id" :key="option.id">
                  {{ option.description }}
                </option>
              </b-select>
            </div>
            <div class="column is-one-third">
              <label class="label">Rechercher par hardware ID</label>
              <b-select placeholder="Select..." v-model="hardwareFilter" @input="filtersUpdated()">
                <option :value="ALL">Tous</option>
                <option v-for="h in hardwares" :value="h" :key="h">
                  {{ h }}
                </option>
              </b-select>
            </div>
            <div class="column is-full">
              <b-button
                type="is-success"
                size="is-medium"
                class="is-fullwidth"
                @click="filtersUpdated()"
                :loading="isLoading"
              >
                Rechercher
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot">
        <nav class="tabs is-boxed is-fullwidth is-centered">
          <ul>
            <li @click="showTab(DevicesListTab)" :class="isTabActive(DevicesListTab)">
              <a>Liste</a>
            </li>
            <li @click="showTab(DevicesMapTab)" :class="isTabActive(DevicesMapTab)">
              <a>Carte</a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <section class="devices">
      <DevicesTable
        :clusters="clusters"
        :devices="devices"
        :perPage="100"
        :isDeviceOk="isDeviceOk"
        :isLoading="isLoading"
        v-if="currentTab === DevicesListTab"
      />
    </section>

    <section class="devices">
      <DevicesMap :devices="devices" v-if="currentTab === DevicesMapTab" />
    </section>

    <b-modal :active="isModalActive" :on-cancel="closeModal">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Karnott disponibles et prêts</p>
          <div class="buttons">
            <b-tooltip label="Copier !" :active="copyTooltipActive" :animated="true" position="is-bottom">
              <button class="button is-info" @click="copySerialNumbers(availableAndHealthReadyDevices)">
                Copier les numéros de séries
              </button>
            </b-tooltip>
          </div>
        </header>
        <section class="modal-card-body">
          <DevicesTable
            :clusters="clusters"
            :devices="availableAndHealthReadyDevices"
            :perPage="100"
            :isLoading="false"
            :isDeviceOk="isDeviceOk"
          />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SET_ERROR_ACTION } from "Stores/message";
import { FETCH_FIRMWARES_ACTION } from "Stores/devices";
import { searchDevices } from "Api/device";
import { getDeviceModels } from "Api/deviceModel";
import {
  DEVICE_STATUS,
  DEVICE_AVAILABLE_STATUS,
  DEVICE_IN_USE_STATUS,
  CLUSTER_TYPES,
  DEVICE_HEALTH_UP,
  DEVICE_HEALTH_DOWN,
  DEVICE_HEALTH_CRITICAL,
  DEVICE_STATES_WITH_CLUSTER,
  DEVICE_STATES_WITH_CLUSTER_LABEL,
  CLUSTER_STATE_STATE_LABEL_AS_FILTER
} from "Constants";
import { formatDate, getClusterName, isDeviceOk } from "Utils";
import DevicesTable from "Components/devices/DevicesTable";
import DevicesMap from "Components/devices/DevicesMap";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

const ALL = "ALL";
const YES = "YES";
const NO = "NO";
const TRACTOR = 4;
const OUTIL = 5;
const OUTIL_V2 = 6;
const DevicesListTab = "DevicesListTab";
const DevicesMapTab = "DevicesMapTab";

let searchTimeout = null;

export default {
  components: { DevicesTable, DevicesMap, VueSlider },
  data() {
    return {
      abortController: new AbortController(),
      devices: [],
      clusters: [],
      isLoading: false,
      defaultSearchParams: {
        firmwareVersion: null,
        is_demo: false,
        withoutTracks: true
      },
      DEVICE_STATUS,
      ALL,
      YES,
      NO,
      TRACTOR,
      OUTIL,
      OUTIL_V2,
      UP: DEVICE_HEALTH_UP,
      DOWN: DEVICE_HEALTH_DOWN,
      DEVICE_CHARGING_STATE_DISCHARGING: "DISCHARGING",
      DEVICE_CHARGING_STATE_CHARGING: "CHARGING",
      DEVICE_CHARGING_STATE_CHARGED: "CHARGED",
      CRITICAL: DEVICE_HEALTH_CRITICAL,
      CLUSTER_TYPES,
      CLUSTER_STATE_STATE_LABEL_AS_FILTER,
      DevicesListTab: DevicesListTab,
      DevicesMapTab: DevicesMapTab,
      deviceModels: [{}],
      currentTab: DevicesListTab,
      selectedStatusFilter: [ALL],
      selectedClusterTypeFilter: [ALL],
      selectedCurrentClusterStateWithClusterFilter: [ALL],
      selectedCurrentStateWithClusterFilter: [ALL],
      isLinkedToClusterFilter: ALL,
      isLinkedToEquipmentFilter: ALL,
      deviceModelFilter: [ALL],
      hardwareFilter: ALL,
      firmwareIdFilter: [ALL],
      batteryHealthFilter: ALL,
      batteryChargingStateFilter: ALL,
      deviceHealthFilter: ALL,
      batteryLevel: [0, 100],
      batteryLevelMarks: [0, 100],
      availableAndHealthReadyDevices: [],
      isModalActive: false,
      copyTooltipActive: false,
      hardwares: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      DEVICE_STATES_WITH_CLUSTER
    };
  },
  methods: {
    isTabActive(title) {
      return {
        "is-active": title === this.currentTab
      };
    },
    showTab(title) {
      this.currentTab = title;
    },
    fmtDeviceStateWithCluster(state) {
      return DEVICE_STATES_WITH_CLUSTER_LABEL[state];
    },
    exportCsv() {
      const { devices } = this;
      let csvContent = ["Numéro de série", "Firmware", "Statut", "Exploitation", "Dernière connexion", "État"].join(
        ","
      );
      csvContent += "\n";
      csvContent += devices
        .map(d =>
          [
            d.serialnumber,
            d.firmware_name,
            d.status,
            getClusterName(d),
            d.last_connection_date_string,
            d.health.status
          ].join(",")
        )
        .join("\n");
      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      // Create a link element
      const a = document.createElement("a");
      // Use URL.createObjectURL to create a URL representing the Blob
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = "export-karnott.csv";
      a.click();
      // Release the reference to the blob URL
      URL.revokeObjectURL(url);
    },
    closeModal() {
      this.availableAndHealthReadyDevices = [];
      this.isModalActive = false;
    },
    copySerialNumbers(devices) {
      this.copyTooltipActive = true;
      const serialNumbers = devices.map(d => d.serialnumber).join(",");
      const textarea = document.createElement("textarea");
      textarea.value = serialNumbers;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => (this.copyTooltipActive = false), 1000);
    },
    showAvailableAndHealthReadyDevices() {
      this.$buefy.dialog.prompt({
        message: "Combien de karnott souhaitez vous ?",
        confirmText: "Valider",
        cancelText: "Annuler",
        inputAttrs: {
          type: "number",
          value: 1
        },
        onConfirm: count => {
          this.availableAndHealthReadyDevices = this.getAvailableAndHealthReadyDevices(parseInt(count));
          this.isModalActive = true;
        },
        onCancel: () => {
          this.availableAndHealthReadyDevices = [];
          this.isModalActive = false;
        }
      });
    },
    getAvailableAndHealthReadyDevices(maxCount = 1) {
      let count = 0;
      const okDevices = [];
      const { devices, isDeviceOk } = this;
      for (let i = 0; i < devices.length; i++) {
        if (count === maxCount) {
          break;
        }
        const d = devices[i];
        if (
          !d.cluster_id &&
          isDeviceOk(d) &&
          (d.status === DEVICE_AVAILABLE_STATUS || d.status === DEVICE_IN_USE_STATUS)
        ) {
          okDevices.push(d);
          count++;
        }
      }
      return okDevices;
    },
    filtersUpdated(timeout = 0) {
      if (searchTimeout !== null) {
        clearTimeout(searchTimeout);
      }

      const {
        selectedStatusFilter,
        selectedClusterTypeFilter,
        isLinkedToClusterFilter,
        isLinkedToEquipmentFilter,
        deviceHealthFilter,
        batteryHealthFilter,
        batteryChargingStateFilter,
        deviceModelFilter,
        hardwareFilter,
        firmwareIdFilter,
        defaultSearchParams,
        batteryLevel,
        selectedCurrentStateWithClusterFilter,
        selectedCurrentClusterStateWithClusterFilter
      } = this;
      let params = {};
      for (let key in defaultSearchParams) {
        params[key] = defaultSearchParams[key];
      }
      if (!firmwareIdFilter.includes(ALL)) {
        params.firmware_id = firmwareIdFilter;
      }
      if (isLinkedToEquipmentFilter && isLinkedToEquipmentFilter !== ALL) {
        params.is_linked_to_equipment = isLinkedToEquipmentFilter === YES;
      }
      if (isLinkedToClusterFilter && isLinkedToClusterFilter !== ALL) {
        params.is_linked_to_cluster = isLinkedToClusterFilter === YES;
      }
      if (deviceHealthFilter && deviceHealthFilter !== ALL) {
        params.device_health = deviceHealthFilter;
      }
      if (batteryHealthFilter && batteryHealthFilter !== ALL) {
        params.battery_health = batteryHealthFilter;
      }
      if (batteryChargingStateFilter && batteryChargingStateFilter !== ALL) {
        params.charging_state = batteryChargingStateFilter;
      }
      if (batteryLevel[0] !== 0) {
        params.min_battery_level = batteryLevel[0];
      }
      if (batteryLevel[1] !== 100) {
        params.max_battery_level = batteryLevel[1];
      }

      if (deviceModelFilter && deviceModelFilter.length > 0 && !deviceModelFilter.includes(ALL)) {
        params.device_model_id = deviceModelFilter;
      }

      if (selectedStatusFilter && selectedStatusFilter.length > 0 && !selectedStatusFilter.includes(ALL)) {
        params.status = selectedStatusFilter;
      }
      if (selectedCurrentStateWithClusterFilter?.length && !selectedCurrentStateWithClusterFilter.includes(ALL)) {
        params.current_state_with_cluster = selectedCurrentStateWithClusterFilter;
      }
      if (
        selectedCurrentClusterStateWithClusterFilter?.length &&
        !selectedCurrentClusterStateWithClusterFilter.includes(ALL)
      ) {
        params.cluster_state = selectedCurrentClusterStateWithClusterFilter;
      }

      if (
        selectedClusterTypeFilter &&
        selectedClusterTypeFilter.length > 0 &&
        !selectedClusterTypeFilter.includes(ALL)
      ) {
        params.cluster_type = selectedClusterTypeFilter;
      }

      if (hardwareFilter && hardwareFilter !== ALL) {
        params.hardware_version = hardwareFilter;
      }

      console.log(selectedCurrentClusterStateWithClusterFilter);
      const query = Object.assign({}, this.$route.query);
      query.firmwareId = firmwareIdFilter;
      query.isLinkedToClusterFilter = isLinkedToClusterFilter;
      query.isLinkedToEquipmentFilter = isLinkedToEquipmentFilter;
      query.deviceHealthFilter = deviceHealthFilter;
      query.batteryChargingStateFilter = batteryChargingStateFilter;
      query.deviceModelFilter = deviceModelFilter;
      query.selectedStatusFilter = selectedStatusFilter;
      query.selectedClusterTypeFilter = selectedClusterTypeFilter;
      query.selectedCurrentClusterStateWithClusterFilter = selectedCurrentClusterStateWithClusterFilter;
      this.$router.push({ query }).catch(() => {});

      searchTimeout = setTimeout(() => this.searchDevices(params), timeout);
    },
    searchDevices(params) {
      const { apiClient } = this.$store.getters;
      if (this.isLoading) {
        // si on est deja en train de faire une requete, on l'interrompt
        this.abortController.abort();
        this.abortController = new AbortController();
      }
      this.isLoading = true;
      if (params === null) {
        params = {};
      }
      return searchDevices(
        apiClient,
        null,
        this.abortController.signal
      )(params)
        .then(({ data }) => {
          this.devices = data.map(d => {
            if (d.last_connection_date) {
              const date = new Date(Date.parse(d.last_connection_date));
              d.last_connection_date_string = date.toLocaleDateString();
              d.last_connection_date = date;
            }
            return d;
          });

          this.isLoading = false;
        })
        .catch(this.setError);
    },
    loadDeviceModels() {
      const { apiClient } = this.$store.getters;
      getDeviceModels(apiClient)
        .then(({ data }) => {
          this.deviceModels = data || [];
        })
        .catch(e => {
          this.setError({
            message: `Une erreur est survenue lors du chargement des deviceModel`,
            error: e
          });
        });
    },
    onChangeCheckbox(propString) {
      const propValue = this[propString];
      const arrayLen = propValue.length;
      if (arrayLen === 0) {
        this.resetCheckbox(propString);
        this.filtersUpdated();
        return;
      }
      if (propValue[arrayLen - 1] === ALL) {
        this.resetCheckbox(propString);
        this.filtersUpdated();
        return;
      }
      if (propValue.find(s => s === ALL)) {
        this[propString] = propValue.filter(s => s !== ALL);
      }
      this.filtersUpdated();
    },
    resetCheckbox(prop) {
      this[prop] = [ALL];
    },
    isDeviceOk(device) {
      isDeviceOk(device);
    },
    formatDate(date) {
      if (!date) {
        return "-";
      }
      return formatDate(date);
    },
    ...mapActions({
      fetchFirmwares: FETCH_FIRMWARES_ACTION,
      setError: SET_ERROR_ACTION
    })
  },
  computed: {
    ...mapState({
      firmwares: state => {
        const retval = [].concat(state.devices.firmwares);
        retval.sort((a, b) => b.id - a.id);
        return retval;
      }
    })
  },
  mounted() {
    document.title = "Liste des Karnott";
    this.loadDeviceModels();
    const {
      deviceHealthFilter,
      batteryChargingStateFilter,
      deviceModelFilter,
      selectedStatusFilter,
      selectedClusterTypeFilter,
      isLinkedToClusterFilter,
      isLinkedToEquipmentFilter,
      firmwareId,
      selectedCurrentClusterStateWithClusterFilter
    } = this.$route.query;
    var hasFilter = false;
    if (
      deviceHealthFilter ||
      deviceModelFilter ||
      selectedStatusFilter ||
      selectedClusterTypeFilter ||
      isLinkedToClusterFilter ||
      isLinkedToEquipmentFilter ||
      firmwareId ||
      batteryChargingStateFilter
    ) {
      hasFilter = true;
    }
    if (deviceHealthFilter) {
      this.deviceHealthFilter = deviceHealthFilter;
    }
    if (batteryChargingStateFilter) {
      this.batteryChargingStateFilter = batteryChargingStateFilter;
    }
    if (deviceModelFilter) {
      if (typeof deviceModelFilter === "string") {
        this.deviceModelFilter = [deviceModelFilter];
      } else {
        this.deviceModelFilter = deviceModelFilter;
      }
    }
    if (selectedStatusFilter) {
      if (typeof selectedStatusFilter === "string") {
        this.selectedStatusFilter = [selectedStatusFilter];
      } else {
        this.selectedStatusFilter = selectedStatusFilter;
      }
    }
    if (selectedClusterTypeFilter) {
      if (typeof selectedClusterTypeFilter === "string") {
        this.selectedClusterTypeFilter = [selectedClusterTypeFilter];
      } else {
        this.selectedClusterTypeFilter = selectedClusterTypeFilter;
      }
    }
    if (selectedCurrentClusterStateWithClusterFilter) {
      if (typeof selectedCurrentClusterStateWithClusterFilter === "string") {
        this.selectedCurrentClusterStateWithClusterFilter = [selectedCurrentClusterStateWithClusterFilter];
      } else {
        this.selectedCurrentClusterStateWithClusterFilter = selectedCurrentClusterStateWithClusterFilter;
      }
    }
    if (isLinkedToClusterFilter) {
      this.isLinkedToClusterFilter = isLinkedToClusterFilter;
    }
    if (isLinkedToEquipmentFilter) {
      this.isLinkedToEquipmentFilter = isLinkedToEquipmentFilter;
    }
    if (firmwareId) {
      if (typeof firmwareId === "string") {
        this.firmwareIdFilter = [firmwareId];
      } else {
        this.firmwareIdFilter = firmwareId;
      }
    }
    Promise.all([this.fetchFirmwares()]);
    if (hasFilter) {
      this.filtersUpdated();
    }
  }
};
</script>
<style scoped>
.hero {
  background: rgba(54, 54, 54, 0.95) !important;
}
.hero h1.title {
  margin-bottom: 20px;
}
.devices {
  background: white;
}
td > span > a {
  display: block;
  color: #3273dc;
}

.label {
  color: #d3d3d3;
}

.flex-wrap .has-addons {
  flex-wrap: wrap;
}
</style>
