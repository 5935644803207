import { getDeviceDataById, getDataCountByDeviceAndTcpSessionId, getIotLogs } from "Api/device";
import { getOtaByMac } from "Api/ota";

export const GET_COUNTS_BY_DEVICE_AND_SESSION_ID = "GET_COUNTS_BY_DEVICE_AND_SESSION_ID";
export const GET_BEACONS_BY_DEVICE_INSTANCE_ID = "GET_BEACONS_BY_DEVICE_INSTANCE_ID";
export const GET_DATA_ERROR_BY_DEVICE_INSTANCE_ID = "GET_DATA_ERROR_BY_DEVICE_INSTANCE_ID";
export const GET_BATTERY_LEVEL_BY_DEVICE_INSTANCE_ID = "GET_BATTERY_LEVEL_BY_DEVICE_INSTANCE_ID";
export const GET_PULLOUT_BY_DEVICE_INSTANCE_ID = "GET_PULLOUT_BY_DEVICE_INSTANCE_ID";
export const GET_TEMPERATURE_LEVEL_BY_DEVICE_INSTANCE_ID = "GET_TEMPERATURE_LEVEL_BY_DEVICE_INSTANCE_ID";
export const GET_LOG_BY_DEVICE_INSTANCE_ID = "GET_LOG_BY_DEVICE_INSTANCE_ID";
export const GET_LOG_V2_BY_DEVICE_INSTANCE_ID = "GET_LOG_V2_BY_DEVICE_INSTANCE_ID";
export const GET_IOT_LOG = "GET_IOT_LOG";
export const GET_GPIO_TIMER_BY_DEVICE_INSTANCE_ID = "GET_GPIO_TIMER_BY_DEVICE_INSTANCE_ID";
export const GET_DEVICE_EVENT_BY_DEVICE_INSTANCE_ID = "GET_DEVICE_EVENT_BY_DEVICE_INSTANCE_ID";
export const GET_LOCATION_BY_DEVICE_INSTANCE_ID = "GET_LOCATION_BY_DEVICE_INSTANCE_ID";
export const GET_OTA_BY_MAC = "GET_OTA_BY_MAC";
const withApiClient = fetch => type => ({ id, filterBy, params }) => fetch(id)({ type, filterBy, ...params });
const withWithRootGetters = ({ apiClient }) => withApiClient(getDeviceDataById(apiClient));
const getDeviceDataByIdAction = type => ({ rootGetters }, query) => withWithRootGetters(rootGetters)(type)(query);

const getDeviceDataCount = ({ apiClient }) => {
  const fetch = getDataCountByDeviceAndTcpSessionId(apiClient);
  return ({ id, tcpSessionIds }) => fetch(id)(tcpSessionIds);
};

let module = {
  actions: {
    [GET_COUNTS_BY_DEVICE_AND_SESSION_ID]: ({ rootGetters }, query) => getDeviceDataCount(rootGetters)(query),
    [GET_LOCATION_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("location"),
    [GET_BEACONS_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("beacon"),
    [GET_DATA_ERROR_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("data_error"),
    [GET_BATTERY_LEVEL_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("battery_level"),
    [GET_PULLOUT_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("pullout"),
    [GET_TEMPERATURE_LEVEL_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("temperature"),
    [GET_LOG_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("log"),
    [GET_LOG_V2_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("log_v2"),
    [GET_IOT_LOG]: ({ rootGetters }, query) => getIotLogs(rootGetters.apiClient)(query),
    [GET_GPIO_TIMER_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("gpio_timer"),
    [GET_DEVICE_EVENT_BY_DEVICE_INSTANCE_ID]: getDeviceDataByIdAction("device_event"),
    [GET_OTA_BY_MAC]: ({ rootGetters }, query) => getOtaByMac(rootGetters.apiClient)(query)
  }
};

export const LOG_DEFINITION = {
  0: {
    label: "NO_MODULE",
    value: {
      0: "NO_VAL"
    }
  },
  1: {
    label: "GPRS_ID",
    value: {
      1: "NO_ANTENA",
      2: "COUPURE_GPRS",
      3: "NEED_START",
      4: "GPRS_NOK",
      5: "SOCKET_ERROR_STOP",
      6: "SOCKET_ERROR_RETRY",
      7: "CCID_FAILURE",
      8: "AT_CSGT_RESET_GPRS",
      9: "AT_CRITICAL_ERROR",
      10: "AT_CCID_FAILURE",
      11: "AT_COPS_3_1_FAILURE",
      12: "AT_UDCONF_20_FAILURE",
      13: "BOUYG_CO",
      14: "ORAN_CO",
      15: "SFR_CO",
      16: "AT_COPS_0_FAILURE",
      17: "AT_COPS_QUESTION_FAILURE",
      18: "MAX_CREG_ATTEINT",
      19: "AT_CGREG_QUESTION_FAILURE",
      20: "CGATT_GPRS_registration_FAIL",
      21: "AT_CSQ_FAILURE",
      22: "AT_UPSD_FAILURE",
      23: "CHECK_IP_FAILURE",
      24: "DNS_FAILURE",
      25: "CPOL_PHASE_FAILURE",
      26: "RX_CALLBACK_RORR_ERROR",
      27: "AT_UDOPN_0_FAILURE",
      28: "CRSM_READ_28542",
      29: "CRSM_READ_28531",
      30: "CRSM_ERASE_28542",
      31: "CRSM_ERASE_28531",
      32: "ERASE_SIM",
      33: "ERASE_CO",
      34: "AUTO_CO",
      35: "ERROR_ANTENNE_NOT_DISCONNECTED",
      36: "NETWORK_NOT_FOUND",
      37: "AT_CGREG_2_FAILURE",
      38: "AT_CGREG_2_OK",
      39: "NOT_HAPPENED",
      40: "GPRS_RETRY",
      41: "ANTENNE_DISCONNECTED",
      42: "AT_CCID_OK",
      43: "SOCKET_OK",
      44: "ERROR_CO",
      45: "AT_COPS_0_NO_NETWORK_SERVICE",
      46: "ROGERS_CO",
      47: "STOP_GPRS_ASK",
      48: "GPRS_STOPPED_PROPERLY",
      49: "SOCKET_START",
      50: "UPSD_1_OK",
      51: "UPSD_2_OK",
      52: "UPSD_3_OK",
      53: "UPSD_7_OK",
      54: "UPSDA_3_OK",
      55: "UPSND_OK",
      56: "NO_ANSWER_RECEIVED",
      57: "ERROR_NO_FRAME_RECEIVED",
      58: "ERROR_WRONG_FRAME_RECEIVED",
      59: "USOCR_OK",
      60: "USOCO_OK",
      61: "TRAME_NOK",
      62: "CONNEXION_OK",
      63: "UPSDA_3_NOT_OK",
      64: "UPSND_NOT_OK",
      65: "UDNSRN_NOT_OK",
      66: "CGACT_NOT_OK",
      67: "CGACT_OK",
      68: "UPSDA_0_OK",
      69: "UPSDA_0_NOT_OK",
      70: "UPSDA_2_NOT_OK",
      71: "UPSDA_1_NOT_OK",
      72: "ERROR_INIT_GPRS",
      73: "IMP_TO_START"
    }
  },
  2: {
    label: "ACCELERO_ID",
    value: {
      1: "WHOAMI_ERROR",
      2: "ARRET_VEHICULE",
      3: "WAKE_BY_ACCELERO",
      4: "NO_ACCELERO_WAKE",
      5: "IMPOSSIBLE_TO_STANDBY",
      6: "STOP_TAKE_GPS_NO_MOVE",
      7: "RESTART_TAKE_GPS_MOVE_DETECTED",
      8: "ACCELERO_ERROR_DIFFERENT_INTERRUPT_GPIO",
      9: "ACCELERO_NOT_RESPONDING_I2C_DIFF_GPIO",
      10: "ACCELERO_NOT_RESPONDING_IN_GPS_INFO",
      11: "INIT",
      12: "RST_IMP",
      13: "WHOAMI_OK",
      14: "I2CINIT_ERROR",
      100: "INIT_VARIABLE_ACCELERO_ERROR_VALUE",
      199: "MAX_CMPT_ACCELERO_ERROR",
      200: "ACCELERO_ERROR_BEFORE_IMPOSSIBLE_STDBY"
    }
  },
  3: {
    label: "I2C_ID"
  },
  4: {
    label: "GPS_ID",
    value: {
      1: "GET_GPS_INFO_NOT_CALLED",
      2: "I2C_ERROR_GPS_RESET",
      3: "FIRSTPOINT"
    }
  },
  5: {
    label: "PO_ID",
    value: {
      3: "WAKE_BY_PO"
    }
  },
  6: {
    label: "GPIO_ID",
    value: {
      3: "WAKE_BY_GPIO"
    }
  },
  7: {
    label: "POWERPLUG_ID",
    value: {
      3: "WAKE_BY_POWERPLUG",
      4: "PLUG_ON",
      5: "PLUG_OFF",
      6: "POWERED_BY_PLUG"
    }
  },
  8: {
    label: "BATTERY_ID",
    value: {
      1: "DECHARGE_PROFONDE",
      2: "WHEREISMYBATT",
      3: "DECHARGE_PRO_BUT_PERCENT_GOOD",
      4: "DECHARGE_IMP_TO_READ_SOC_StartBrain",
      5: "CANTREAD",
      6: "UTC_UTD_UPTODATE",
      7: "UTC_UTD_UPDATING_IMPOSSIBLE",
      8: "BATTERY_LOW",
      9: "DSNV_UPTODATE",
      10: "DSNV_UPDATING_IMPOSSIBLE",
      11: "RE_COV_UPTODATE",
      12: "RE_COV_UPDATING_IMPOSSIBLE",
      13: "OTC_OTD_UPTODATE",
      14: "OTC_OTD_UPDATING_IMPOSSIBLE",
      36: "BQ3060",
      45: "BQ4050",
      66: "SH660"
    }
  },
  9: {
    label: "CONNECT_AGRI_ID",
    value: {
      1: "BACK_STDBY",
      2: "DEBUG_TIMER_EXPIRED",
      3: "PROCESS_POLL_CA",
      4: "DEBUG_TIMER_SECOURS_EXPIRED",
      5: "INITIALIZE_ALREADY_STOPPED",
      6: "RESTART_EMPTY_MEMORY_ASK",
      7: "RESTART_GPRS_SESSION",
      8: "I2C_PROBLEM",
      9: "I2C_PROBLEM_SOLVED",
      10: "I2C_PROBLEM_NOT_SOLVED"
    }
  },
  10: {
    label: "RTC_ST_ID",
    value: {
      1: "WAKE_BY_RTC"
    }
  },
  11: {
    label: "LAC_ID"
  },
  12: {
    label: "LAC_ID2"
  },
  13: {
    label: "CI_ID"
  },
  14: {
    label: "CI_ID2"
  },
  16: {
    label: "MNC",
    value: {
      20: "Bouygues Telecom",
      21: "Bouygues Telecom",
      88: "Bouygues Telecom",
      29: "Orange",
      91: "Orange",
      2: "Orange",
      1: "Orange",
      13: "SFR",
      11: "SFR",
      10: "SFR",
      9: "SFR"
    }
  },
  17: {
    label: "MCC",
    value: {
      206: "BELGIUM",
      208: "FRANCE",
      302: "CANADA",
      617: "MAURITIUS"
    }
  },
  18: {
    label: "TIME_TO_RECEIVE_DNS"
  },
  19: {
    label: "MEMORY_ID",
    value: {
      1: "DATA_EMPTY",
      2: "INIT_FLASH_ERROR",
      3: "WRITE_FLASH_ERROR",
      4: "VERIF_FLASH_ERROR",
      5: "INIT_ERASE_BLOC_FLASH_ERROR",
      6: "ERASE_SEC_FLASH_ERROR",
      7: "EEPROM_FULL",
      8: "EEPROM_DATA",
      9: "FLASH_DATA",
      10: "MEMORY_FORCE_CONNECTION",
      11: "ERROR_NB_PAGE",
      12: "WRITE_PTR_TURN_1",
      13: "WRITE_PTR_TURN_0"
    }
  },
  20: {
    label: "PROCESS_START",
    value: {
      255: "CANNOTSTART"
    }
  },
  21: {
    label: "GPRS_CME_ERR"
  },
  22: {
    label: "SET_READ_CMD_ID"
  },
  23: {
    label: "BLE_ID",
    value: {
      1: "START_BLE",
      2: "STOP_BLE",
      3: "TENTATIVE_RESET_BLE",
      4: "NO_TAG_AROUND_US",
      5: "NO_TAG_RECEIVED",
      6: "TAG_RECEIVED",
      7: "ERROR_INIT"
    }
  },
  24: {
    label: "BATTERY_ID_CURRENT_POS"
  },
  25: {
    label: "BATTERY_ID_CURRENT_NEG"
  },
  26: {
    label: "BATTERY_ID_VOLTAGE"
  },
  27: {
    label: "LTE_ID",
    value: {
      1: "NO_ANTENA",
      2: "COUPURE_GPRS",
      3: "NEED_START",
      4: "GPRS_NOK",
      5: "SOCKET_ERROR_STOP",
      6: "SOCKET_ERROR_RETRY",
      7: "CCID_FAILURE",
      8: "AT_CSGT_RESET_GPRS",
      9: "AT_CRITICAL_ERROR",
      10: "AT_CCID_FAILURE",
      11: "AT_COPS_3_1_FAILURE",
      12: "AT_UDCONF_20_FAILURE",
      13: "BOUYG_CO",
      14: "ORAN_CO",
      15: "SFR_CO",
      16: "AT_COPS_0_FAILURE",
      17: "AT_COPS_QUESTION_FAILURE",
      18: "MAX_CREG_ATTEINT",
      19: "AT_CGREG_QUESTION_FAILURE",
      20: "CGATT_GPRS_registration_FAIL",
      21: "AT_CSQ_FAILURE",
      22: "AT_UPSD_FAILURE",
      23: "CHECK_IP_FAILURE",
      24: "DNS_FAILURE",
      25: "CPOL_PHASE_FAILURE",
      26: "RX_CALLBACK_RORR_ERROR",
      27: "AT_UDOPN_0_FAILURE",
      28: "CRSM_READ_28542",
      29: "CRSM_READ_28531",
      30: "CRSM_ERASE_28542",
      31: "CRSM_ERASE_28531",
      32: "ERASE_SIM",
      33: "ERASE_CO",
      34: "AUTO_CO",
      35: "ERROR_ANTENNE_NOT_DISCONNECTED",
      36: "NETWORK_NOT_FOUND",
      37: "AT_CGREG_2_FAILURE",
      38: "AT_CGREG_2_OK",
      39: "NOT_HAPPENED",
      40: "GPRS_RETRY",
      41: "ANTENNE_DISCONNECTED",
      42: "AT_CCID_OK",
      43: "SOCKET_OK",
      44: "ERROR_CO",
      45: "AT_COPS_0_NO_NETWORK_SERVICE",
      46: "ROGERS_CO",
      47: "STOP_GPRS_ASK",
      48: "GPRS_STOPPED_PROPERLY",
      49: "SOCKET_START",
      50: "UPSD_1_OK",
      51: "UPSD_2_OK",
      52: "UPSD_3_OK",
      53: "UPSD_7_OK",
      54: "UPSDA_3_OK",
      55: "UPSND_OK",
      56: "NO_ANSWER_RECEIVED",
      57: "ERROR_NO_FRAME_RECEIVED",
      58: "ERROR_WRONG_FRAME_RECEIVED",
      59: "USOCR_OK",
      60: "USOCO_OK",
      61: "TRAME_NOK",
      62: "CONNEXION_OK",
      63: "UPSDA_3_NOT_OK",
      64: "UPSND_NOT_OK",
      65: "UDNSRN_NOT_OK",
      66: "CGACT_NOT_OK",
      67: "CGACT_OK",
      68: "UPSDA_0_OK",
      69: "UPSDA_0_NOT_OK",
      70: "UPSDA_2_NOT_OK",
      71: "UPSDA_1_NOT_OK",
      72: "ERROR_INIT_GPRS",
      73: "IMP_TO_START",
      74: "FIRST_INIT_LTE",
      75: "FAST_START",
      76: "LTE_NETWORK",
      77: "GPRS_NETWORK"
    }
  },
  80: {
    label: "BATT_GAUG_STATUS_MSB"
  },
  81: {
    label: "BATT_BATT_STATUS_MSB"
  },
  82: {
    label: "BATT_OPSTATA_STATUS_MSB"
  },
  83: {
    label: "BATT_SAFSTATA_STATUS_MSB"
  },
  90: {
    label: "BATT_GAUG_STATUS_LSB"
  },
  91: {
    label: "BATT_BATT_STATUS_LSB"
  },
  92: {
    label: "BATT_OPSTATA_STATUS_LSB"
  },
  93: {
    label: "BATT_SAFSTATA_STATUS_LSB"
  },
  255: {
    label: "WATCHDOG_DETECT_ID"
  }
};
export default module;
