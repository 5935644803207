export const getUserMe = client => client.get(`/users/me`);
export const getUsers = client => client.get(`/users`);
export const getUserById = client => id => client.get(`/users/${id}`);
export const getUsersByIds = client => ids => client.get(`/users`, { params: { id: ids } });
export const createUser = client => params => client.post(`/users`, { ...params });
export const createUserAndLinkToCluster = client => (clusterId, params) =>
  client.post(`/clusters/${clusterId}/users`, { ...params });
export const updateUser = client => (id, params) => client.put(`/users/${id}`, { ...params });
export const searchUsers = (client, token = null) => query =>
  client.get(`/users`, { params: { q: query }, cancelToken: token });
export const getClusterUsers = client => clusterId => client.get(`/clusters/${clusterId}/users`);
export const resetUserPassword = client => userId => client.post(`/users/${userId}/reset_password`);
export const generateOauthToken = client => userId => client.post(`/support/visio_access_token`, { user_id: userId });
export const getUserEmails = client => id => {
  return client.get(`/users/${id}/emails`);
};

export const exportUser = client => (format = "csv") => {
  const headers = {};
  if (format === "csv") {
    headers.Accept = "text/csv";
  }
  return client.get(`/users/export?format=${format}`, { headers });
};

export const getUserMetadataById = client => userId => client.get(`/billing/sales/${userId}`);
export const createUserMetadata = client => params => client.post(`/billing/sales`, { ...params });
export const updateUserMetadata = client => (userId, params) => client.put(`/billing/sales/${userId}`, { ...params });

export const getUserSessions = client => (userID, params, withDetails = true) =>
  client.get(`/reports/users`, { params: { ...params, userID, withDetails } });

export const changePassword = client => (userId, params) =>
  client.put(`/users/${userId}/update_password`, { ...params });
