import { render, staticRenderFns } from "./MultipleOtaModal.vue?vue&type=template&id=521f0f88&scoped=true"
import script from "./MultipleOtaModal.vue?vue&type=script&lang=js"
export * from "./MultipleOtaModal.vue?vue&type=script&lang=js"
import style0 from "./MultipleOtaModal.vue?vue&type=style&index=0&id=521f0f88&prod&scoped=true&lang=css"
import style1 from "./MultipleOtaModal.vue?vue&type=style&index=1&id=521f0f88&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521f0f88",
  null
  
)

export default component.exports