<template>
  <div>
    <b-modal :active="isActive" :onCancel="resetDataAndCloseModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">{{ title }}</header>
        <section class="modal-card-body">
          <div class="content">
            <b-field label="Du :" :type="fromDateStatus" :message="fromDateMessage">
              <b-datetimepicker v-model="startAt" placeholder="Type or select a date..." icon="calendar-today" editable>
              </b-datetimepicker>
            </b-field>
            <b-field label="Au :" :type="toDateStatus" :message="toDateMessage">
              <b-datetimepicker v-model="endAt" placeholder="Type or select a date..." icon="calendar-today" editable>
              </b-datetimepicker>
            </b-field>
            <b-field label="Commentaires :">
              <input v-model="comment" class="textarea" type="text" />
            </b-field>
            <b-field label="Catégories :" :type="categoriesStatus" :message="categoriesMessage">
              <b-taginput v-model="categories" ellipsis icon="label" placeholder="Ajouter une catégorie"> </b-taginput>
            </b-field>
          </div>
        </section>
        <b-button @click="createOrUpdateAnalysis">Terminer</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createAnalysis, updateAnalysis } from "Api/device";
import { formatToRFC3339 } from "Utils";

export default {
  mounted() {
    this.analysisPublisher((id, comment, categories, fromDate, toDate) => {
      this.id = id;
      this.comment = comment;
      this.categories = categories;
      this.startAt = this.getDateWithoutSeconds(fromDate);
      this.endAt = this.getDateWithoutSeconds(toDate);
      this.title = `${id ? "Mettre à jour" : "Créer"} une analyse`;
    });
  },
  methods: {
    getDateWithoutSeconds(date) {
      const d = date ? new Date(Date.parse(date)) : new Date();
      d.setSeconds(0);
      return d;
    },
    createOrUpdateAnalysis() {
      let valid = true;
      if (this.categories.length === 0) {
        this.categoriesStatus = "is-danger";
        valid = false;
        this.categoriesMessage = "Au moins une catégorie doit être saisie";
      }

      if (this.startAt == null) {
        this.fromDateStatus = "is-danger";
        valid = false;
        this.fromDateMessage = "Une date de début doit être saisie";
      }

      if (this.endAt == null) {
        this.toDateStatus = "is-danger";
        valid = false;
        this.toDateMessage = "Une date de fin doit être saisie";
      }

      if (valid) {
        const { apiClient } = this.$store.getters;
        this.isLoading = true;

        const fn = this.id ? updateAnalysis(apiClient) : createAnalysis(apiClient)(this.deviceId);
        fn({
          id: this.id,
          categories: this.categories,
          start_at: formatToRFC3339(this.startAt),
          end_at: formatToRFC3339(this.endAt),
          comment: this.comment
        })
          .then(this.resetDataAndCloseModal)
          .catch(this.setError);
      }
    },
    resetData() {
      this.title = "";
      this.id = null;
      this.categories = [];
      this.fromDateStatus = "";
      this.toDateStatus = "";
      this.categoriesStatus = "";
      this.fromDateMessage = "";
      this.toDateMessage = "";
      this.categoriesMessage = "";
      this.comment = "";
      this.startAt = null;
      this.endAt = null;
    },
    resetDataAndCloseModal() {
      this.resetData();
      this.closeModal(true);
    }
  },
  props: ["isActive", "closeModal", "deviceId", "analysisPublisher"],
  data() {
    return {
      title: "",
      id: null,
      categories: [],
      fromDateStatus: "",
      toDateStatus: "",
      categoriesStatus: "",
      fromDateMessage: "",
      toDateMessage: "",
      categoriesMessage: "",
      comment: "",
      startAt: null,
      endAt: null
    };
  }
};
</script>
<style scoped>
.modal-card {
  width: 800px;
  min-height: 300px;
}
</style>
