<template>
  <div id="map-container">
    <div id="map"></div>
  </div>
</template>

<script>
/// Below line disables no-undef google Maps related warnings
/// (because Google maps is loaded in a script tag rather than
/// in the bundle.).
/* global google */
import karnottAvailableImg from "../../assets/pin_karnott_AVAILABLE.png";
import karnottInUseImg from "../../assets/pin_karnott_IN_USE.png";
import karnottErrorImg from "../../assets/pin_karnott_ERROR.png";

const infoWindow = (date, device) => {
  let content;
  if (device.cluster_name) {
    content = `[${device.serialnumber}, ${device.cluster_name}] - ${date}`;
  } else {
    content = `[${device.serialnumber}] - ${date}`;
  }
  return new google.maps.InfoWindow({ content: content, disableAutoPan: true });
};

export default {
  props: ["devices"],
  mounted() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      tilt: 0,
      rotateControl: false
    });
    this.buildDataForMap(this.devices);
  },
  watch: {
    devices: function() {
      this.buildDataForMap(this.devices);
    }
  },
  methods: {
    buildDataForMap(devices) {
      this.markers.forEach(m => m.setMap(null));
      this.markers = [];
      let eventPoints = [];
      if (!devices) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      devices.forEach(d => {
        const eventPoint = new google.maps.LatLng(d.location_latitude, d.location_longitude);
        eventPoints.push(eventPoint);
        const iconImg = {
          url: karnottAvailableImg,
          scaledSize: new google.maps.Size(48, 48)
        };
        if (d.health.status !== "UP") {
          iconImg.url = karnottErrorImg;
        } else if (d.status === "IN_USE") {
          iconImg.url = karnottInUseImg;
        }
        const m = new google.maps.Marker({
          position: eventPoint,
          map: this.map,
          icon: iconImg,
          optimized: true
        });
        bounds.extend(eventPoint);

        const date = d.location_date ? new Date(d.location_date).toLocaleString() : null;
        m.addListener(
          "click",
          (() => {
            if (this.defaultInfoWindow != null) {
              this.defaultInfoWindow.close();
            }
            this.defaultInfoWindow = infoWindow(date, d);
            this.defaultInfoWindow.open(this.map, m);
          }).bind(this)
        );
        this.markers.push(m);
      });
      this.map.fitBounds(bounds);
    }
  },
  data() {
    return {
      map: null,
      markers: [],
      defaultInfoWindow: null
    };
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
}
#map-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px;
}
#map {
  width: 90%;
  height: 600px;
}
.button-field {
  padding: 31px 0 11px;
}
</style>

<style>
.loading-overlay {
  z-index: 100;
}
</style>
